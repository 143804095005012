import axios from "axios";
import Consts from "../core/consts";
import React from "react"
import Status from "../core/status";
import {FormContextWrapper} from "../core/form_context";
import {TextareaInput} from "../core/input_fields"

const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

class Uploader extends React.Component {
    constructor(props) {
        super(props);

        this.fieldName = props.tag === undefined ? "fileID" : props.tag;
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
        this.setState = this.setState.bind(this);
    }

    saveFile = () => {
        this.setState({loading: true});

        var formData = new FormData();

        for (let i = 0; i < this.fileField.current.files.length; i++) {
            formData.append("file" + i, this.fileField.current.files[i]);
        }
        formData.append("note", this.state.note || "")
        axios.post(Consts.API_PREFIX + "/files/add?" + encodeGetParams({parent_ID: this.props.folder, Cmp_key: this.props.Cmp_key}), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",
                        [this.fieldName]: response.data.file_name,
                        loading: false, renew: false
                    }, this.props.reloadFiles)
                }
            })
            .catch((error) => {
                console.log("error", error);
                //this.setState({loading: false})
                this.props.setStateParent({
                    error: error.message,
                    status: "",
                    loading: false
                })
            });
    };

    render() {
        return <div id="excel_file">
            <Status type={"error"} text={this.state.error}/>
            <Status type={"success"} text={this.state.status}/>
            <label>
                        <span>
                            Add File
                        </span>
                <input
                    style={{paddingTop: "20px"}}
                    type={"file"}
                    ref={this.fileField}
                    multiple
                />
                {this.state.loading &&
                <label>
                    <img alt={"loader"}
                         src="/src/img/ajax-loader-fff.gif"/> {this.state.progress} % {this.state.progress === 100 &&
                <span>(processing file)</span>}
                </label>
                }
            </label>
            <FormContextWrapper value={{state: this.state, setState: this.setState}}>
                <TextareaInput name={"Note (optional)"} tag={"note"}/>
            </FormContextWrapper>
            <button type={"button"} onClick={this.saveFile}>upload</button>
        </div>

    }
}

export default Uploader
