import React, {useContext, useMemo, useState} from "react";
import {CheckboxInput, DateInput, FileUpload, InfoTooltip, InsertIfAdmin, Loader, MaxBtn, MiniBtn, MyModal, SelectField, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import {FaPlus, FaTrashAlt} from "react-icons/fa";
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts";
import GroupCompanyRegistrations from "./group_registrations";
import TagInput from "../core/tag_input";
import Status from "../core/status";
import {maxiDelete, maxiGet, maxiPost} from "../core/maxios";
import {UserContext} from "../user/UserContext";
import {getUsers} from "../core/core";

export function GroupContacts({data, setState, scope, disabled, id, reload}) {
    const [contacts, setContacts] = useState((data || {})[scope] || []);
    const updateState = n => setState(old => ({...old, data: {...old.data, [scope]: n.filter(c => c).sort((c1, c2) => c1.name < c2.name ? -1 : 1)}}))

    return <div>
        <InsertIfAdmin>
            <div style={{float: "right"}}>
                <MiniBtn onClick={() => setContacts(c => [...c, {}])}>
                    <FaPlus/> Add Contact
                </MiniBtn>
            </div>
        </InsertIfAdmin>
        <h2>{scope[0].toUpperCase()}{scope.slice(1)}</h2>
        {
            !contacts?.length && <>No {scope} available yet.</>
        }
        {
            contacts && contacts
                .map((contact, i) => contact && <div style={{border: "1px solid var(--colorLight)", borderRadius: 5, marginTop: 10, padding: 10, opacity: contact?.deregistered ? 0.66 : 1}}>
                    <InsertIfAdmin>
                        <div style={{float: "right"}}>
                            <MiniBtn onClick={() => window.confirm(`Do you want to delete this Contact?`) && setContacts(oldContacts => {
                                const n = [...oldContacts];
                                n[i] = null;
                                updateState(n)
                                return n
                            })
                            }>
                                <FaTrashAlt/>
                            </MiniBtn>
                        </div>
                    </InsertIfAdmin>

                    <ContactEditor data={contact}
                                   key={i}
                                   id={id}
                                   reload={reload}
                                   disabled={disabled}
                                   update={(a, b, c) => setContacts(oldContacts => {

                                       const n = [...oldContacts];
                                       n[i][a] = c;
                                       updateState(n)
                                       return n
                                   })
                                   }/>
                </div>)
        }
    </div>
}


export function GroupCountries({data, scope, groupcompany_ID, disabled, id, reload}) {
    const objectName = {countries: "Country", contacts: "Contact"}[scope] || scope;
    const Editor = {contacts: ContactEditor, countries: CountriesEditor}[scope] || React.Fragment;
    const countries = data || [];

    const [newObject, setNewObject] = useState(null)

    //const [countries, setCountries] = useState(data || []);
    return <div>
        <InsertIfAdmin>
            <div style={{float: "right"}}>
                <MiniBtn onClick={() => setNewObject({groupcompany_ID})}>
                    <FaPlus/> Add {objectName}
                </MiniBtn>
            </div>
        </InsertIfAdmin>
        <h2>{scope[0].toUpperCase()}{scope.slice(1)}</h2>
        {
            !countries?.length && <>No {scope} available yet.</>
        }
        {
            countries && [...(newObject ? [newObject] : []), ...countries]
                .map((country, i) => <div style={{border: "1px solid var(--colorLight)", borderRadius: 5, marginTop: 10, padding: 10, opacity: country?.deregistered ? 0.66 : 1}}>
                    <Editor data={country}
                            key={i + "|" + (country?.ID || "")}
                            {...{
                                groupcompany_ID, reload: !country.ID ? () => {
                                    setNewObject(null);
                                    reload()
                                } : reload, disabled
                            }}
                    />
                </div>)
        }
    </div>
}

function ContactEditor({data, groupcompany_ID, update, disabled, reload}) {
    const [state, setState] = useState(data)
    const [{loading, error}, setStatusVar] = useState({});
    const upsert = () => maxiPost("/companies/groups/contacts", state, {setStatusVar}).then(reload);


    return <>
        <InsertIfAdmin>
            <div style={{float: "right"}}>
                <MiniBtn onClick={() => window.confirm(`Do you want to delete this contact?`) &&
                    maxiDelete("/companies/groups/contacts/" + state.ID,).then(reload)
                }>
                    <FaTrashAlt/>
                </MiniBtn>
            </div>
        </InsertIfAdmin>
        <FormContextWrapper value={{state, setState}} onSubmit={upsert}>
            <Status type={"error"} text={error}/>
            <TextfieldInput name={"Name"} tag={"name"} ph={"John Doe"} {...{disabled}}/>
            <TextfieldInput name={"Position"} tag={"position"} {...{disabled}}/>
            <br/>
            <TextfieldInput name={"Email Address"} style={{width: 400, maxWidth: "60%"}} ph={"john@doe.com"} tag={"emailAddress"}{...{disabled}}/>
            <TextfieldInput name={"Phone Number"} tag={"phone"} ph={"+1 234567890"} {...{disabled}}/><br/>
            <CheckboxInput name={<>Send Reminders <InfoTooltip>Send a reminder on the day after period end, one day before the internal deadline and one day before the actual deadline.</InfoTooltip></>} tag={"sendReminders"}/> &nbsp; &nbsp;
            <CheckboxInput name={<>Send Submission Confirmation</>} tag={"sendSubmissionConfirmation"}/>
            <br/>
            <SelectField selectives={[
                ["-1", "no"],
                ["0", "Read only"],
                ["1", "Read and Write"],

            ]} name={<>Collaborator <InfoTooltip>Creates this user (if it doesn't exist) and grants access to all companies.</InfoTooltip></>} tag={"collaborator"}/> &nbsp; &nbsp;
            <SelectField name={<>Reviewer <InfoTooltip>Creates this user (if it doesn't exist) and grants access to all companies.</InfoTooltip></>} tag={"reviewerLevel"} selectives={[
                ["-1", "no"],
                ["0", "Level 1"],
                ["1", "Level 2"],
                ["2", "Level 3"],
                ["3", "Level 4"],
                ["4", "Level 5"],
                ["5", "Level 6"],
            ]}/> &nbsp; &nbsp;
            <br/>
            <MiniBtn>Save</MiniBtn><Loader loading={loading}/>
        </FormContextWrapper>
    </>
}


function CountriesEditor({data, groupcompany_ID, disabled, reload}) {
    const [state, setState] = useState(data)
    const [{loading, error}, setStatusVar] = useState({});
    const [selectUsers, setSelectUsers] = useState({})
    const userContext = useContext(UserContext);

    const upsert = () => maxiPost("/companies/groups/countries", state, {setStatusVar}).then(reload);
    useMemo(() => getUsers(maxiGet, (a) => setSelectUsers(a.selectUsers)), [])

    const Selector = ({userContext, ...props}) => <SelectField
        type={"reactselect"}
        width={Math.min(window.innerWidth - 50, 400)}
        multiple
        disabled={disabled}
        selectives={selectUsers?.filter && selectUsers?.filter(u => u.role > 30).sort((a, b) => a.name < b.name ? -1 : 1).map(u => ({value: u.ID?.toString() /*json search needs string*/, label: u.name + (userContext?.user?.Role > 10 ? " (" + u.email + ")" : "")})) || []}
        {...props}
    />;
    return <>
        <InsertIfAdmin>
            <div style={{float: "right"}}>
                <MiniBtn onClick={() => window.confirm(`Do you want to delete this ${state.country}?`) &&
                    maxiDelete("/companies/groups/countries/" + state.ID,).then(reload)
                }>
                    <FaTrashAlt/>
                </MiniBtn>
            </div>
        </InsertIfAdmin>
        <FormContextWrapper value={{state, setState}} onSubmit={upsert}>
            <Status type={"error"} text={error}/>
            <SelectField name="Country" tag="country" selectives={Consts.reportCountriesSelect} demandSelect noBreaks disabled={!!state.ID}/>
            <br/>
            <DateInput name={"Effective Date of Registration"} tag={"data_dateOfRegistration"}{...{disabled}} labelStyle={{width: 250, display: "inline-block"}}/>
            <DateInput name={"Effective Date of Transfer"} tag={"data_dateOfTransfer"}{...{disabled}}/>
            <p>

                <CheckboxInput name={"EORI Registered"} tag={"data_eoriRegistered"}{...{disabled}} labelStyle={{width: 250, display: "inline-block"}}/>&nbsp;&nbsp;
                {
                    !!state.data?.eoriRegistered && <TextfieldInput name={"EORI number"} tag={"data_eoriNumber"} ph={"1234567890"}/>
                }
            </p>
            <p>
                <CheckboxInput name={"Deregistered"} tag={"data_deregistered"}{...{disabled}} labelStyle={{width: 250, display: "inline-block"}}/>&nbsp;&nbsp;
                {
                    !!state.data?.deregistered && <>
                        <DateInput name={"Date of Deregistration"} tag={"data_deregisteredDate"}{...{disabled}}/>
                        <FileUpload name={"Confirmation of Deregistration"} tag={"data_deregistrationConfirmation"} labelStyle={{width: 245}} {...{disabled}} setStateParent={(obj) => {
                            setState(old => ({...old, data: {...old.data, deregistrationConfirmation: obj.deregistrationConfirmation,}}))
                            //update("deregistrationConfirmation", null, obj.deregistrationConfirmation)
                        }
                        }/>
                    </>
                }
            </p>
            <p>
                <CheckboxInput name={"Switched Compliance Company"} tag={"data_switched"}{...{disabled}} labelStyle={{width: 250, display: "inline-block"}}/>&nbsp;&nbsp;
                {
                    !!state.data?.switched && <>
                        <DateInput name={"Date of Switch"} tag={"data_switchDate"}{...{disabled}}/>
                        <SelectField name={"Reason"} tag={"data_switchReason"} noBreaks {...{disabled}} demandSelect selectives={[
                            ["unresponsiveWhiteLabel", "Unresponsive – White Label request no further action"],
                            ["unresponsiveEssentia", "UnresponsiveEssentia – Essentia request no further action"]
                        ]}/>
                    </>
                }
            </p>

            {
                state.country === "NL" && <>
                    <CheckboxInput name={"Article 23 Registered"} tag={"data_articleRegistered"} labelStyle={{width: 250, display: "inline-block"}}{...{disabled}}/>
                    <br/>
                </>
            }
            {
                state.country === "BE" && <>
                    <CheckboxInput name={"E1400 Registered"} tag={"data_e1400Registered"} labelStyle={{width: 250, display: "inline-block"}}{...{disabled}}/>
                    <br/>
                </>
            }
            {
                Consts.euCountries.includes(state.country) && <>
                    <CheckboxInput name={"VIES Registered"} tag={"data_viesRegistered"}{...{disabled}} labelStyle={{width: 250, display: "inline-block"}}/>
                    <br/>
                </>
            }
            <div style={{marginTop: 5}}>
                <Selector tag={"data_fileUploadNotification"} name={"File Upload Notification"} labelStyle={{width: 250, display: "inline-block"}}  {...{userContext, disabled}}/><br/>
            </div>
            <TagInput type={state.country + "_agent"} name={"Agent"} tag={"agentID"} labelStyle={{width: 250, display: "inline-block"}}/>

            <TagInput type={state.country + "_fiscalRep"} name={"Fiscal Representative"} tag={"fiscalRepresentativeID"} labelStyle={{width: 250, display: "inline-block"}}/>

            <MiniBtn>Save</MiniBtn><Loader loading={loading}/>
        </FormContextWrapper>
        <GroupCompanyRegistrations id={groupcompany_ID} key={state.country} country={state.country} reload={_ => {
        }}/>
    </>
}

