import React from 'react';
import {Link, Redirect} from "react-router-dom";
import Status from '../core/status';
import Consts from '../core/consts'
import {apiGet} from "../core/api";
import {DEPeriods, formatReportName, GBPeriods, GBPeriods_date} from "../filing/filing_support";
import {CheckboxInput, InsertIfAdmin, Loader, SelectField, TextfieldInput} from "../core/input_fields";
import {FormContext} from "../core/form_context";
import {FaArrowCircleDown, FaArrowCircleUp, FaPlus} from "react-icons/fa";
import ViewConfig from "../core/view_config";

const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export default class GroupCompaniesList extends React.Component {
    constructor(props) {
        super(props);
        this.config = new ViewConfig();
        this.state = {
            credentialExpanded: 0,
            companies: [],
            companiesRaw: [],
            loginStatus: null,
            error: "",
            loading: true,
            search: {
                country: "all"
            },
            searchjava: {
                name: ""
            },
            sort: {
                attribute: this.config.get("sortGroupCompanies", "Name"),
                asc: this.config.get("sortGroupCompaniesAsc", true),
            }
        };

        this.setExpanded = this.setExpanded.bind(this);
        this.setState = this.setState.bind(this);
        this.apiGet = apiGet.bind(this);
    }

    componentDidMount = () => {
        this.loadCompanies();
    }

    componentDidUpdate = () => {

        if (this.state === undefined || this.state.search === undefined || this.state.search === null) {
            return
        }
        this.config.save({
            sortCompanies: this.state.sort.attribute,
            sortCompaniesAsc: this.state.sort.asc,
        })
    }

    setExpanded(id) {

    }

    loadCompanies = () => {
        this.setState({loading: true})
        this.apiGet("/companies/groups?" + encodeGetParams({country: this.state.search.country}), resp => {
            const companies = resp
            if (this.state.search.country === "all" && companies.length === 1) {
                this.setState({redirect: companies[0].Cmp_key})
            }

            this.setState({
                companies: companies.filter(n => !this.state.searchjava?.name || n.name?.toLowerCase().includes(this.state.searchjava.name.toLowerCase())),
                companiesRaw: companies,
                loading: false,
                error: ""
            })
        });
    };
    handleChange = (e) => {
        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        if (!this.state.submitted) {
            const s = e.target.name.split("_");
            if (s.length === 2) {
                this.updateState(s[0], s[1], val)
            }

        } else {
            this.setState({
                error: "already submitted files cannot be changed"
            })
        }

    };
    updateState = (parent, child, value) => {
        if (child === "") {
            this.setState({parent: value});
        } else {
            this.setState((prevState, props) => ({
                [parent]: {
                    ...prevState[parent],
                    [child]: value
                }
            }), () => parent === "search" ? this.loadCompanies() : () => {
            });
        }
    };
    toggleSort = (attribute) => {
        const sort = this.state.sort;
        this.setState({sort: {attribute: attribute, asc: attribute !== sort.attribute ? true : !sort.asc}})
    };
    sortCompanies = (a, b) => {
        const sort = this.state.sort;
        const accessor = this.attributeMaps[sort.attribute.replace(" ", "")];
        if (!accessor) {
            return 1
        }
        const val = accessor(a) > accessor(b);
        //const val = accessor ? accessor(a) > accessor(b): null;
        //console.log(a, b, accessor(a), accessor(b),val);
        return (sort.asc ? val : !val) ? 1 : -1
    };
    attributeMaps = {
        Name: c => c.name,
        Registrations: c => c.registrations,
    };

    render() {

        const companiesFiltered = this.state.companies
            .filter(n =>
                (!this.state.searchjava?.name || n.name?.toLowerCase().includes(this.state.searchjava.name.toLowerCase()))
            );
        return <div id={"filings_list"} className={"company_list"}>
            <Status type={"error"} text={this.state.error}/>

            <InsertIfAdmin>
                <div style={{float: "right"}}>
                    <a href={"/groupcompanies/new"} class={"btn mini"}><FaPlus style={{fontSize: 14}}/> Add Company</a>
                </div>
            </InsertIfAdmin>

            <h1>Companies ({companiesFiltered.length})</h1>

            <FormContext.Provider value={{state: this.state, setState: this.setState, updateState: this.updateState}}>
                <form onChange={this.handleChange}>
                    <TextfieldInput name={"Name of Company"} tag={"searchjava_name"} ph={"Example Ltd"}/>&nbsp;&nbsp;&nbsp;
                    <Loader loading={this.state.loading}/>
                </form>
            </FormContext.Provider>

            <table style={{width: "100%"}} className={"overview"}>

                {
                    this.state.companies.length > 0 ? <>

                            <tr>
                                <SortOrderChanger style={{paddingLeft: "35px"}} change={this.toggleSort} sort={this.state.sort}>Name</SortOrderChanger>
                                <SortOrderChanger change={this.toggleSort} sort={this.state.sort}>Registrations</SortOrderChanger>
                            </tr>

                            {
                                companiesFiltered.sort(this.sortCompanies).map((f) => <>
                                        <tbody className={"overview"}>
                                        <tr key={f.ID} id={"f" + f.ID} onClick={() => {

                                        }}>


                                            <td>
                                                <Link to={"/groupcompanies/" + f.ID} style={{color: f.status === 'suspended' ? "red" : ""}}>
                                                    {f.name}
                                                </Link>
                                                {
                                                    f.status === 'suspended' && " (suspended)"
                                                }

                                            </td>
                                            <td>
                                                {
                                                    f.data?.countries?.map(a => a.country).join(", ")
                                                }
                                            </td>

                                        </tr>
                                        </tbody>
                                        <tbody>
                                        </tbody>
                                    </>
                                )}
                        </>
                        :
                        "There is none yet"

                }
            </table>

        </div>
    }
}

const SortOrderChanger = ({change, children, sort, style}) => {

    return <th style={style}>
        <em onClick={() => change(children)}>
            {children}
            &nbsp;
            &nbsp;
            {sort.attribute === children ?
                !sort.asc ? <FaArrowCircleUp style={{fontSize: "15px"}}/> : <FaArrowCircleDown style={{fontSize: "15px"}}/> :
                null
            }
        </em>
    </th>;
}

