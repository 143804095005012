import {Container, SelectField, TextfieldInput} from "../../core/input_fields";
import _ from "lodash";
import {CredentialsSelect} from "../filing_gb";
import React from "react";
import Consts from "../../core/consts";
import {FormContext} from "../../core/form_context";

class DEECSL extends React.Component {
    static contextType = FormContext;

    render() {
        let dateNow = new Date();
        return <>

            {
                this.context.state.fillIn !== undefined && this.context.state.fillIn.length > 0 && this.context.state.company !== undefined &&
                <div>do you mean? {[this.context.state.fillIn.map(a => {
                    return [<em href={null} onClick={() => {
                        this.context.fillLoadedData({}, a)
                    }}>{a.company.name}</em>, <span>, </span>]
                })]}</div>
            }
            <TextfieldInput name="Company Name" tag="company_name" ph={"Example Ltd"}/>
            <SelectField name={"Country"} tag={"company_country"} demandSelect selectives={[...Consts.countries.filter(([a,])=>a!=="GB"),["XU","United Kingdom without Northern Ireland"],["XI","Northern Ireland"]]}/>
            <TextfieldInput name="Street" tag="company_street" ph={"Musterstraße"}/>
            <TextfieldInput name="House Number" tag="company_houseNumber" ph={123}/>
            <TextfieldInput name="Post Code" tag="company_postcode" ph={12345}/>
            <TextfieldInput name="City" tag="company_city" ph={"Musterstadt"}/>

            <TextfieldInput name="VAT ID" tag="company_vatId" ph={"DE651351513"}/>
            <TextfieldInput name="Note (optional)" tag="company_note" ph={"ref nr 1234"}/>
            <br/>
            {
                this.context.state.general.report !== "manual_ec_sl" && <CredentialsSelect/>
            }

        </>
    }
}

export {DEECSL};
