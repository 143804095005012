export default {
    "General Company Data Required For Application": {
        "Field": "General Company Data Required For Application",
        "ID": "To be filled in by client",
        "type": "heading",
        "onlyShowIf": false,
        "required": false,
        "Ireland": "Please consider the following points:\n1. This text is longer than anythign else."
    },
    "Company details in home country": {"Field": "Company details in home country", "ID": false, "type": "subheading", "onlyShowIf": false, "required": false, "Ireland": false},
    "Company name": {"Field": "Company name", "ID": "companyName", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Company number (country of establishment)": {"Field": "Company number (country of establishment)", "ID": "companyNumber", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "VAT number (country of establishment)": {"Field": "VAT number (country of establishment)", "ID": "vatNumber", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Date of incorporation(country of establishment)": {"Field": "Date of incorporation(country of establishment)", "ID": "DateOfIncorpation", "type": "date", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Address of the company": {"Field": "Address of the company", "ID": "addressOfTheCompany", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Telephone": {"Field": "Telephone", "ID": "telephone", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Email": {"Field": "Email", "ID": "email", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Website": {"Field": "Website", "ID": "website", "type": "text", "onlyShowIf": false, "required": 0.0, "Ireland": "x"},
    "Bank account number (necessary for VAT refunds)": {"Field": "Bank account number (necessary for VAT refunds)", "ID": "bankAccoutNumber", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Shareholder/shareholders Name": {"Field": "Shareholder/shareholders Name", "ID": "shareholdes", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "% of owed shares for each shareholder": {"Field": "% of owed shares for each shareholder", "ID": "ShareholderPercentage", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Details of company representative entitled to sign documents": {"Field": "Details of company representative entitled to sign documents", "ID": false, "type": false, "onlyShowIf": false, "required": false, "Ireland": false},
    "Name of company's representative ": {"Field": "Name of company's representative ", "ID": "CompanyRepresentative", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Address of the representative(private address)": {"Field": "Address of the representative(private address)", "ID": "Addressoftherepresentative(privateaddress)", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Date of birth of company representative": {"Field": "Date of birth of company representative", "ID": "DOB", "type": "date", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Place of birth company representative": {"Field": "Place of birth company representative", "ID": "POB", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Name of Person Authorised to Sign the Documents": {"Field": "Name of Person Authorised to Sign the Documents", "ID": "nameOfPersonAuthorisedtoSignTheDocuments", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Position of Person authorsied to Sign the Documents": {"Field": "Position of Person authorsied to Sign the Documents", "ID": "PositionofPersonauthorsiedtoSigntheDocuments", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Contact Person in the Company": {"Field": "Contact Person in the Company", "ID": "ContactPersonintheCompany", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Contact Person Email Address": {"Field": "Contact Person Email Address", "ID": "ContactPersonEmailAddress", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Details Related to Business Activity In The Country Of Registration": {"Field": "Details Related to Business Activity In The Country Of Registration", "ID": "To be filled in by client", "type": "heading", "onlyShowIf": false, "required": false, "Ireland": false},
    "SALES in country of registration": {"Field": "SALES in country of registration", "ID": false, "type": "subheading", "onlyShowIf": false, "required": false, "Ireland": false},
    "Business Activity: (What does the company do?)": {"Field": "Business Activity: (What does the company do?)", "ID": "businessActivity", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Start date of activity requiring registration for VAT in a new country": {"Field": "Start date of activity requiring registration for VAT in a new country", "ID": "startOfActivity", "type": "date", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Expected yearly turnover in EUR": {"Field": "Expected yearly turnover in EUR", "ID": "turnover", "type": "text", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Is there a warehouse in the country of registration? ": {"Field": "Is there a warehouse in the country of registration? ", "ID": "warehouseInCountry", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Warehouse Address": {"Field": "Warehouse Address", "ID": "warehouseAddress", "type": "text", "onlyShowIf": "warehouseInCountry", "required": false, "Ireland": "x"},
    "Who do you sell your goods to? Individual customers or companies? ": {"Field": "Who do you sell your goods to? Individual customers or companies? ", "ID": "typeOfCustomers", "type": "select(Invidual Customers|Companies)", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Do you do intra-community acquisitions in country of registration?": {"Field": "Do you do intra-community acquisitions in country of registration?", "ID": "intracommunityAcquisitions", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "In which countries do you do intra-community acquisitions?": {"Field": "In which countries do you do intra-community acquisitions?", "ID": "intracommunityAcquisitionsCountries", "type": "selectEuCountries", "onlyShowIf": "intracommunityAcquisitions", "required": false, "Ireland": "x"},
    "Do you do intra-community deliveries from country of registration?": {"Field": "Do you do intra-community deliveries from country of registration?", "ID": "intracommunityDeliveries", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "In which countries do you do intra-community deliveries?": {"Field": "In which countries do you do intra-community deliveries?", "ID": "intracommunityDeliveriesCountries", "type": "selectEuCountries", "onlyShowIf": "intracommunityDeliveries", "required": false, "Ireland": "x"},
    "Are you going to purchase goods in the country of registration?": {"Field": "Are you going to purchase goods in the country of registration?", "ID": "domesticPurchases", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Are you going to purchase goods from EU countries?": {"Field": "Are you going to purchase goods from EU countries?", "ID": "euPurchases", "type": "checkbox", "onlyShowIf": "euPurchases", "required": false, "Ireland": "x"},
    "From which countries are you going to purchase goods?": {"Field": "From which countries are you going to purchase goods?", "ID": "euPurchasesCountries", "type": "selectEuCountries", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Are you going to import goods from non EU countries?": {"Field": "Are you going to import goods from non EU countries?", "ID": "importingGoodsFromNonEu", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "From which non EU countries are you going to import goods?": {"Field": "From which non EU countries are you going to import goods?", "ID": "importingGoodsFromNonEuCountries", "type": "selectNonEuCountries", "onlyShowIf": "importingGoodsFromNonEu", "required": false, "Ireland": "x"},
    "Do you intend to supply goods to other EU member states?": {"Field": "Do you intend to supply goods to other EU member states?", "ID": "intracommunityDeliveriesGoods", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Do you intend to supply services to other EU member states?": {"Field": "Do you intend to supply services to other EU member states?", "ID": "intracommunityDeliveriesServices", "type": "checkbox", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "What due diligence measures and checks are conducted in relation to current and prospective suppliers or customers in the EU?": {
        "Field": "What due diligence measures and checks are conducted in relation to current and prospective suppliers or customers in the EU?",
        "ID": "dueDiligence",
        "type": "text",
        "onlyShowIf": false,
        "required": false,
        "Ireland": "x"
    },
    "How do you intend to make supplies to your customers?": {"Field": "How do you intend to make supplies to your customers?", "ID": "supplyChain", "type": "select(Direct Sales|Via an Intermediary|Third Party|Both)", "onlyShowIf": false, "required": false, "Ireland": "x"},
    "Please provide us with the documents states below ": {"Field": "Please provide us with the documents states below ", "ID": false, "type": "heading", "onlyShowIf": false, "required": false, "Ireland": false},
    "Certificate of incorporation": {"Field": "Certificate of incorporation", "ID": "incorporationCertificate", "type": "doc", "onlyShowIf": false, "required": false, "Ireland": "Copy (scanned copy)"},
    "Articles of association with information about the person/people entitled to sign documents on behalf of the company": {
        "Field": "Articles of association with information about the person/people entitled to sign documents on behalf of the company",
        "ID": "articlesOfAssocation",
        "type": "doc",
        "onlyShowIf": false,
        "required": false,
        "Ireland": "Copy (scanned copy)"
    },
    "VAT registration in country of establishment": {"Field": "VAT registration in country of establishment", "ID": "vatRegistrationCertificate", "type": "doc", "onlyShowIf": false, "required": false, "Ireland": "Copy (scanned copy)"},
    "Passport copies of persons entitled to sign documents (all if there is more than one)": {"Field": "Passport copies of persons entitled to sign documents (all if there is more than one)", "ID": "passport", "type": "doc", "onlyShowIf": false, "required": false, "Ireland": "Copy (scanned copy)"},
    "Proof of activity(order form, contract, invoice from a customer dated less that 2 months)": {
        "Field": "Proof of activity(order form, contract, invoice from a customer dated less that 2 months)",
        "ID": "proofOfActivity",
        "type": "doc",
        "onlyShowIf": false,
        "required": false,
        "Ireland": "Copy (scanned copy)"
    },
    "Company bank statement: bank name, account number ( not older than 3 months)": {"Field": "Company bank statement: bank name, account number ( not older than 3 months)", "ID": "companyBankStatement", "type": "doc", "onlyShowIf": false, "required": false, "Ireland": "Copy (scanned copy)"},
    "inform essentia": {"Field": "inform essentia", "ID": false, "type": "inform", "onlyShowIf": false, "required": false, "Ireland": false},
    "For Internal Use Only (client to sign once compliance team has prepared the forms)": {"Field": "For Internal Use Only (client to sign once compliance team has prepared the forms)", "ID": false, "type": "heading", "onlyShowIf": false, "required": false, "Ireland": false},
    "VAT Application form": {"Field": "VAT Application form", "ID": "VatApplicationForm", "type": "doc", "onlyShowIf": false, "required": false, "Ireland": "Copy (scanned copy)"},
    "Agent link form": {"Field": "Agent link form", "ID": "POA", "type": "doc", "onlyShowIf": false, "required": false, "Ireland": "Copy (scanned copy)"},
    "inform client": {"Field": "inform client", "ID": false, "type": "inform", "onlyShowIf": false, "required": false, "Ireland": false}
}
