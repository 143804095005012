import React from 'react';
import {apiGet} from "../core/api";
import {CheckboxInput, LightContainer, SelectField} from "../core/input_fields";
import "react-table/react-table.css";
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts"
import Popup from "reactjs-popup";
import {FaInfoCircle} from "react-icons/fa";

const currencies = {
    'AF': 'AFN',
    'AL': 'ALL',
    'DZ': 'DZD',
    'AS': 'USD',
    'AD': 'EUR',
    'AO': 'AOA',
    'AI': 'XCD',
    'AQ': 'XCD',
    'AG': 'XCD',
    'AR': 'ARS',
    'AM': 'AMD',
    'AW': 'AWG',
    'AU': 'AUD',
    'AT': 'EUR',
    'AZ': 'AZN',
    'BS': 'BSD',
    'BH': 'BHD',
    'BD': 'BDT',
    'BB': 'BBD',
    'BY': 'BYR',
    'BE': 'EUR',
    'BZ': 'BZD',
    'BJ': 'XOF',
    'BM': 'BMD',
    'BT': 'BTN',
    'BO': 'BOB',
    'BA': 'BAM',
    'BW': 'BWP',
    'BV': 'NOK',
    'BR': 'BRL',
    'IO': 'USD',
    'BN': 'BND',
    'BG': 'BGN',
    'BF': 'XOF',
    'BI': 'BIF',
    'KH': 'KHR',
    'CM': 'XAF',
    'CA': 'CAD',
    'CAQ': 'CAD',
    'CAF': 'CAD',
    'CAS': 'CAD',
    'CV': 'CVE',
    'KY': 'KYD',
    'CF': 'XAF',
    'TD': 'XAF',
    'CL': 'CLP',
    'CN': 'CNY',
    'HK': 'HKD',
    'CX': 'AUD',
    'CC': 'AUD',
    'CO': 'COP',
    'KM': 'KMF',
    'CG': 'XAF',
    'CD': 'CDF',
    'CK': 'NZD',
    'CR': 'CRC',
    'HR': 'HRK',
    'CU': 'CUP',
    'CY': 'EUR',
    'CZ': 'CZK',
    'DK': 'DKK',
    'DJ': 'DJF',
    'DM': 'XCD',
    'DO': 'DOP',
    'EC': 'USD',
    'EG': 'EGP',
    'SV': 'SVC',
    'GQ': 'XAF',
    'ER': 'ERN',
    'EE': 'EUR',
    'ET': 'ETB',
    'FK': 'FKP',
    'FO': 'DKK',
    'FJ': 'FJD',
    'FI': 'EUR',
    'FR': 'EUR',
    'GF': 'EUR',
    'TF': 'EUR',
    'GA': 'XAF',
    'GM': 'GMD',
    'GE': 'GEL',
    'DE': 'EUR',
    'GH': 'GHS',
    'GI': 'GIP',
    'GR': 'EUR',
    'GL': 'DKK',
    'GD': 'XCD',
    'GP': 'EUR',
    'GU': 'USD',
    'GT': 'GTQ',
    'GG': 'GGP',
    'GN': 'GNF',
    'GW': 'XOF',
    'GY': 'GYD',
    'HT': 'HTG',
    'HM': 'AUD',
    'HN': 'HNL',
    'HU': 'HUF',
    'IS': 'ISK',
    'IN': 'INR',
    'ID': 'IDR',
    'IR': 'IRR',
    'IQ': 'IQD',
    'IE': 'EUR',
    'IM': 'GBP',
    'IL': 'ILS',
    'IT': 'EUR',
    'JM': 'JMD',
    'JP': 'JPY',
    'JE': 'GBP',
    'JO': 'JOD',
    'KZ': 'KZT',
    'KE': 'KES',
    'KI': 'AUD',
    'KP': 'KPW',
    'KR': 'KRW',
    'KW': 'KWD',
    'KG': 'KGS',
    'LA': 'LAK',
    'LV': 'EUR',
    'LB': 'LBP',
    'LS': 'LSL',
    'LR': 'LRD',
    'LY': 'LYD',
    'LI': 'CHF',
    'LT': 'EUR',
    'LU': 'EUR',
    'MK': 'MKD',
    'MG': 'MGA',
    'MW': 'MWK',
    'MY': 'MYR',
    'MV': 'MVR',
    'ML': 'XOF',
    'MT': 'EUR',
    'MH': 'USD',
    'MQ': 'EUR',
    'MR': 'MRO',
    'MU': 'MUR',
    'YT': 'EUR',
    'MX': 'MXN',
    'FM': 'USD',
    'MD': 'MDL',
    'MC': 'EUR',
    'MN': 'MNT',
    'ME': 'EUR',
    'MS': 'XCD',
    'MA': 'MAD',
    'MZ': 'MZN',
    'MM': 'MMK',
    'NA': 'NAD',
    'NR': 'AUD',
    'NP': 'NPR',
    'NL': 'EUR',
    'AN': 'ANG',
    'NC': 'XPF',
    'NZ': 'NZD',
    'NI': 'NIO',
    'NE': 'XOF',
    'NG': 'NGN',
    'NU': 'NZD',
    'NF': 'AUD',
    'MP': 'USD',
    'NO': 'NOK',
    'OM': 'OMR',
    'PK': 'PKR',
    'PW': 'USD',
    'PA': 'PAB',
    'PG': 'PGK',
    'PY': 'PYG',
    'PE': 'PEN',
    'PH': 'PHP',
    'PN': 'NZD',
    'PL': 'PLN',
    'PT': 'EUR',
    'PR': 'USD',
    'QA': 'QAR',
    'RE': 'EUR',
    'RO': 'RON',
    'RU': 'RUB',
    'RW': 'RWF',
    'SH': 'SHP',
    'KN': 'XCD',
    'LC': 'XCD',
    'PM': 'EUR',
    'VC': 'XCD',
    'WS': 'WST',
    'SM': 'EUR',
    'ST': 'STD',
    'SA': 'SAR',
    'SN': 'XOF',
    'RS': 'RSD',
    'SC': 'SCR',
    'SL': 'SLL',
    'SG': 'SGD',
    'SK': 'EUR',
    'SI': 'EUR',
    'SB': 'SBD',
    'SO': 'SOS',
    'ZA': 'ZAR',
    'GS': 'GBP',
    'SS': 'SSP',
    'ES': 'EUR',
    'LK': 'LKR',
    'SD': 'SDG',
    'SR': 'SRD',
    'SJ': 'NOK',
    'SZ': 'SZL',
    'SE': 'SEK',
    'CH': 'CHF',
    'SY': 'SYP',
    'TW': 'TWD',
    'TJ': 'TJS',
    'TZ': 'TZS',
    'TH': 'THB',
    'TG': 'XOF',
    'TK': 'NZD',
    'TO': 'TOP',
    'TT': 'TTD',
    'TN': 'TND',
    'TR': 'TRY',
    'TM': 'TMT',
    'TC': 'USD',
    'TV': 'AUD',
    'UG': 'UGX',
    'UA': 'UAH',
    'AE': 'AED',
    'GB': 'GBP',
    'US': 'USD',
    'UM': 'USD',
    'UY': 'UYU',
    'UZ': 'UZS',
    'VU': 'VUV',
    'VE': 'VEF',
    'VN': 'VND',
    'VI': 'USD',
    'WF': 'XPF',
    'EH': 'MAD',
    'YE': 'YER',
    'ZM': 'ZMW',
    'ZW': 'USD',
};
const thresholds = {
    AR: 0,
    AU: 75000,
    BH: 0,
    BY: 0,
    BR: 0,
    CL: -1,
    CN: 0,
    CO: 0,
    EG: -1,
    GH: 12000,
    GI: -1,
    GL: -1,
    GG: -1,
    HK: -1,
    IS: 2000000,
    IN: 2000000,
    ID: -1,
    IL: -1,
    JP: 10000000,
    JE: -1,
    KZ: -1,
    KE: 5000000,
    KR: 0,
    KW: -1,
    MY: -1,
    MX: -1,
    MN: -1,
    NZ: 60000,
    NO: 50000,
    PK: -1,
    PE: -1,
    QA: -1,
    RU: 0,
    SA: 375000,
    RS: 0,
    SG: -1,
    ZA: 50000,
    CH: 0,
    TW: 480000,
    TZ: 40000000,
    TH: -1,
    TR: 0,
    AE: 375000,
    UY: 0,
    VN: -1,
    CAS: 0,
    CAQ: 30000,
};
const hideCountries = [
    "BV", "IO", "CX", "CC", "CK", "GF", "TF", "GP", "HM", "MH", "FM", "YT", "MQ", "NC", "NU", "NF", "MP", "PN", "RE", "PM", "TK", "WF", "CA"
];
const EUCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "HR",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
];
const notes = {
    RU: <Popup trigger={<FaInfoCircle/>}><b>Also include B2B sales.</b></Popup>,
    ZA: <Popup trigger={<FaInfoCircle/>}><b>Also include B2B sales.</b></Popup>,

}
export default class ThresholdsCalculator extends React.Component {
    state = {
        turnovers: {},
        general: {
            currency: "EUR",
            showAll: 1
        },
        rates: {}
    };

    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
        this.apiGet = apiGet.bind(this)

    }

    getCurrencyRates = () => {
        window.setTimeout(() => {
            if (this.state.rates[this.state.general.currency] === undefined) {
                this.apiGet("/currencies/rates/EUR", (resp) => {
                    this.setState(resp)
                })
            }
        }, 200)

    };
    printMyObligation = ({cc}) => {
        if (this.state.rates === undefined) {
            return null
        }
        const currency = currencies[cc];
        if (this.state.rates[currency] === undefined) {
            return "not existing"
        }
        const tInLocalCurrency = this.state.turnovers[cc] * this.state.rates[currency]/this.state.rates[this.state.general.currency];
        const threshold = EUCountries.indexOf(cc) > -1 ? 0 : (thresholds[cc]===undefined ? null : thresholds[cc]);
        return <>
            &nbsp;
            = &nbsp;{currency} &nbsp;

            {
                this.state.turnovers[cc] > 0 ?
                    (tInLocalCurrency).toFixed(2)
                    : "0.00"
            }
            &nbsp;
            - {
            (threshold === -1 || (threshold === null)) ?
                "no obligation" :
                (
                    tInLocalCurrency > threshold ?
                        "obliged to register" +
                        (EUCountries.indexOf(cc) > -1 ? ", can be accounted for via combined EU MOSS" : "") +
                        (cc==="CH" ? " only if global turnover is greater than 100,000 CHF":"") :
                        "no obligation yet"
                )
        }
        </>

    };

    render() {
        //console.log(this.state.turnovers)
        const PrintObligation = this.printMyObligation;
        this.getCurrencyRates()
        return <FormContextWrapper value={{state: this.state, setState: this.setState}}>
            <LightContainer name={"Digital Sales Calculator"}>
                <SelectField name={"Currency"} tag={"general_currency"} selectives={["EUR", "USD", "GBP"]}/>
                <CheckboxInput name={"Show all countries"} tag={"general_showAll"}/>
                <br/>

                <h2>Turnover</h2>
                {
                    Consts.countries
                        .filter(([cc, longname]) => {
                                return currencies[cc] !== undefined &&
                                    (this.state.general.showAll || thresholds[cc]) &&
                                    hideCountries.indexOf(cc) === -1
                            }
                        )
                        .concat([["CAQ", "Canada Quebec"], ["CAF", "Canada Federal"], ["CAS", "Canada Saskatchewan"]])
                        .sort(([a, longa], [b, longb]) => {
                            return longa > longb ? 1 : -1
                        })

                        .map(([cc, longname]) => <>
                                <div style={{width: "180px", display: "inline-block"}}>{longname} {notes[cc]}</div>
                                {this.state.general.currency}
                                <input name={"turnovers_" + cc} style={{width: "100px",}} placeholder={"0.00"}/>
                                <PrintObligation cc={cc}/>
                                <br/>
                            </>
                        )
                }
            </LightContainer>
        </FormContextWrapper>
    }
}
