import React, {useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import {encodeGetParams} from "../core/api";
import {Container, LightContainer, MyModal} from "../core/input_fields";
import {MyReactTable} from "../core/myreacttable";
import {dateFormat, dateFormatTime} from "./filing_support";
import Consts from "../core/consts";
import {FaArrowRight} from "react-icons/fa";

const EnlargeModal = ({children}) => <MyModal trigger={children}>
    <LightContainer name={"Changes"}>
        {children}
    </LightContainer>
</MyModal>


const hiddenAttributes = ["Cmp_key"];

function KeyFormatter(key) {
    if (key.includes(".")) {
        return key.split(".").map(sk => KeyFormatter(sk)).join(" ")
    }
    const keyPretty = key.replace(/_/, " ").replace(/([A-Z]+)/g, " $1").replace(/(^|\s)[a-z]/, v => v.toUpperCase()).replace(/Id/g, "ID");

    return Consts.labels[key] || keyPretty
}

function ChangeFormatter(row) {
    if (!row || typeof row !== 'object') {
        return row;
    }
    if (!row.o && !row.n) {
        return false
    }

    const o = ChangeFormatterCore(row.k, row.o);
    const n = ChangeFormatterCore(row.k, row.n);

    //console.log(row, !row.o, !row.n, o, n)
    if (o === false && n === false) {
        return false;
    }

    return <>
        {o ?? "undefined"} <FaArrowRight fontSize={16}/> {n ?? "undefined"}
    </>
}

function ChangeFormatterCore(key, data) {
    try {
        if (["submissionFiles", "inputFiles"].includes(key)) {
            const l = data?.length;
            return data?.map((file, i) => <><a target={"_blank"} href={Consts.API_PREFIX + "/file/download/" + file.path + "?prettyName=" + (file.name || "")}>{file.name}</a>{i + 1 !== l && ","} </>) ?? "none"
        }
        if (key === "fileID") {
            return <a target={"_blank"} href={Consts.API_PREFIX + "/file/download/" + data}>file</a>
        }
        if (key === "specific.submitTime") {
            return data ? dateFormat(data * 1000) : "";
        }
        if (key === "reminders") {
            return data?.sort((a, b) => a.email < b.email ? -1 : 1).map(row => `${row.email} (${row.daysBefore.join(", ")})`).join(", ")
        }
        if (["specific.internalNotes", "company.emailAdd", "reminder.text", "matrix.instructions"].includes(key)) {
            return <div dangerouslySetInnerHTML={{__html: data}}/>;
        }

        if (data === "1" || data === 1) {
            return "true"
        }
        if (data === "0" || data === 0) {
            return "false"
        }
        if (data === null || data === undefined) {
            return false
        }

        if (Array.isArray(data)) {
            if (data.length === 0) {
                return false
            }
            if (typeof data[0] !== "object") {
                return data.join(", ")
            }
            return JSON.stringify(data)
        }

        if (typeof (data) === "object" && Object.keys(data).length === 0) {
            return false
        }
    } catch (e) {
        console.log(e)
    }

    return JSON.stringify(data)
}


export default function FilingHistory({object_type, object_ID}) {
    const [history, setHistory] = useState([]);
    useMemo(() => object_ID && maxiGet("/history?" + encodeGetParams({
        object_type, object_ID
    })).then(setHistory), [object_ID]);

    return <Container name={"History"}>
        <MyReactTable
            data={history}
            columns={[
                {
                    Header: "Kind",
                    accessor: "action",
                    maxWidth: 80,
                    filterable: true,
                },
                {
                    Header: "User",
                    accessor: "user",
                    filterable: true,
                    maxWidth: 180,
                    Cell: ({value, original}) => <MyModal trigger={value}>
                        <div>{value} - {original.email}</div>
                    </MyModal>
                },
                {
                    Header: "Change",
                    accessor: "data",
                    Cell: ({original, value}) => <>
                        {
                            original.action === "update" && <EnlargeModal>{
                                value
                                    .filter(row => !hiddenAttributes.includes(row.k))
                                    .map(row => {
                                        const ct = ChangeFormatter(row);
                                        if (ct === false) {
                                            return null
                                        }
                                        return <li style={{fontStyle: "initial"}}>
                                            <b>{KeyFormatter(row.k)}</b>: {ct}
                                        </li>
                                    })
                            }
                            </EnlargeModal>
                        }
                        {
                            original.action==="reminder" && value.split(",").join(", ")
                        }
                    </>
                },
                {
                    Header: "Time",
                    accessor: "timeAdded",
                    maxWidth: 150,
                    Cell: ({value}) => dateFormatTime(new Date(value * 1000)),
                }
            ]}
        />
    </Container>
}
