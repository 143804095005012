import React from 'react';
import axios from 'axios';
import Consts from '../core/consts'
import Status from '../core/status';
import {Link} from 'react-router-dom'
import {Container} from "../core/input_fields";
import {getFraudData} from "../core/api";

class AuthRedirect extends React.Component {

    constructor(props) {
        super(props);
        this.get_param = this.get_param.bind(this);

        if (this.get_param("error") !== null) {
            this.state = {
                error: this.get_param("error") + " - " + this.get_param("error_description"),
                retry: this.get_param("token"),
            };
            return
        }

        this.state = {
            token: this.get_param("token"),
            code: this.get_param("code"),
            cc: props.match.params.cc,
            loading: true,
            error: "",
            status: "",
        };
        console.log(this.state);
        axios.post(Consts.API_PREFIX + "/authentication/oauth/add", this.state, {
            headers: {'Content-Type': 'application/json',...getFraudData()}
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false,
                    })
                } else {
                    this.setState({
                        error: "",
                        status: "",
                        loading: false,
                    })

                }

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false,
                })
            });

    }

    get_param(param) {
        const t = this.props.location.search.substr(1);
        const re = new RegExp(param + "=(.*?)(&|$)");
        const m = t.match(re);
        if (m != null && m.length === 3) {
            return m[1]
        }
        return null
    }

    componentWillUnmount() {

    }

    componentDidMount() {

    }

    handleChange(e) {
        const s = e.target.name.split("_");
        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.value}
            )
        });

    }

    handleSubmit(event) {
        event.preventDefault();
        try {
            if (this.state.credentials.credname === "") {
                this.setState({
                    error: "please fill out a name for the credentials",
                    status: "",
                });
                return
            }
            this.setState({
                error: "",
                status: "",

                loading: true,
            });

            this.loadToken();
        } catch (e) {
            this.setState({
                'error': e
            });
        }
    }

    render() {
        return (
            <div>
                <h1>Credentials Add</h1>

                <Status type="error" text={this.state.error}/>
                <Status type="success" text={this.state.status}/>
                {this.state.retry !== undefined &&
                <Container name={"Try Again"} visible>try again with the following link: <br/><br/><a className={"btn"}
                                                                                              style={{color: "white"}}
                                                                                              target='_blank'
                                                                                              rel="noopener noreferrer"
                                                                                              href={'//' + Consts.GB_WEBSERVICE_HOST + '/oauth/authorize?response_type=code&redirect_uri=' + Consts.GB_REDIR_URL + '/credentials/auth-redirect/gb/?token=' + this.state.retry + '&scope=read:vat%20write:vat&client_id=DHgDHFSs5QQ5fc6pvGaWQLqzvrMa'}>HMRC
                    Login</a></Container>
                }
                {this.state.loading &&
                <Container name={"Receiving Credentials ..."} visible><img alt={"loader"}
                    src="/src/img/ajax-loader-fff.gif"/></Container>}
                {this.state.loading === false && this.state.error === "" &&
                <Container name={"Credentials"} visible>The Identity has been added successfully! <Link to={"/credentials"}>show
                    all credentials</Link></Container>}
            </div>
        );
    }
}


export default AuthRedirect;
