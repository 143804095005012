import React from 'react';
import {apiGet} from "../core/api";
import {LightContainer, SelectField} from "../core/input_fields";
import "react-table/react-table.css";
import {FormContextWrapper} from "../core/form_context";
import ViewConfig from "../core/view_config";

const yesNo = "yesno";
const headings = {
    taxregn: "Your Current Tax Registration and Administration",
    sales: "Sales",
    purchases: "Purchases",
    contracts: "Contracts",
};
const questions = {
    taxregn: [
        {
            text: "Do you currently file European Community Sales Lists?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit these will no longer be necessary."],
                //["0", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."]
            ],
        },

        {
            text: "Do you currently file Intrastat Supplementary Declarations?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit these will no longer be necessary."],
                //["0", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."]
            ],
        },
        {
            text: "Do you currently maintain a \"MOSS\" registration for B2C sales of electronic services?",
            type: yesNo,
            conditions: [
                ["1", "The MOSS scheme will end in the UK. As a non-EU provider of MOSS services into the EU market, you will obliged to set up a MOSS registration in another EU state. Due to language and ease of compliance, many UK companies have chosen Ireland for this. "]
            ],
        },
        {
            text: "Do you currently have a UK Duty Deferment Account?",
            type: yesNo,
            conditions: [
                ["1", "In the event of a hard Brexit you may need to consider enlarging the guarantees due to the fact that it will now also cover imports from EU countries"],
                ["0", "In the event of a hard Brexit you may need to consider setting up an account if you import goods from the EU."],
            ],
        },
        {
            text: "Do you currently operate Inward Processing Relief or Outward Processing Relief agreements with HMRC?",
            type: yesNo,
            conditions: [
                ["1", "In the event of a hard Brexit these will need to be reviewed as they will now have to capture both goods inward, and goods outward, that are coming from/going to the EU."],
            ]
        },
        {
            text: "Do you currently operate a VAT partial exemption method that contains, as a factor, ratios of supplies to EU and non-EU customers?",
            type: yesNo,
            conditions: [
                ["1", "It is likely that the EU:non-EU ratios in partial exemption methods will rapidly change to UK:non-UK. You should monitor this as it may afford you better VAT recovery ratios in future. "]
            ]
        },
        {
            text: "Are you registered for VAT in any EU state due to the distance selling rules?",
            type: yesNo,
            conditions: [
                ["1", "You may need to consider enlarging the guarantees due to the fact that it will now also cover imports from EU countries"]
            ]
        },
        {
            text: "Are you curently registered for VAT in EU states for some reason other than distance selling rules (e.g. sales of goods form EU stocks, land related serices, sales of event admissions)?",
            type: yesNo,
            conditions: [
                ["1", "It is likely that these registrations will continue. Some EU jurisdictions (e.g. France, Belgium, Spain, italy, Denmark, Poland, Greece) enforce \"Fiscal Representative\" requirements where, for a non-EU taxpayer registered locally, it is necessary to appoint a local fiscal Representative. This will carry cost implications for local registrations that should be investigated. "]
            ]
        },

    ],
    sales: [
        {
            text: "Do you sell and dispatch goods from the UK to EU B2B customers?",
            type: yesNo,
            conditions: [
                ["1"],
                ["0", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements"]
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements"],
                ["CA, CFR, CPT, CIF, DAT, DAP, DDU", "After a hard Brexit, you will be responsible under your terms of delivery for customs clearing goods for export. This will require your EORI number and access by either you or your customs agent to HMRC's customs systems."],
                ["DDP", "After a hard Brexit, your DDP delivery terms will mean you are responsible for customs clearance of goods into the EU territory of sale. You may need a local EORI number and VAT registration and may have to charge local VAT on the sale. "],
            ]

        },
        {
            text: "Do you sell and dispatch goods from the UK to EU B2C customers?",
            type: yesNo,
            conditions: [
                ["1", ""]
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "we ship to customer", "they organise shipment",
            ],
            conditions: [
                ["we ship to customer", "After a hard Brexit, you will have a choice of letting your customers take responsibility for import taxes and pay any VAT and duty (normally either to the courier company or post office) or, if this is not an acceptable buying experience, registering as an importer into the EU and registering for/charging local VAT. You will have to do this in all territories you sell to unless you organise a single point of importation and then distribute to the EU from there.  "],
                ["they organise shipment", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."]
            ]

        },
        {
            text: "Are you registered for VAT in any state due to the distance selling rules?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit these registrations may no longer be necessary, depending on whether you wish to import/sell locally "]
            ]
        },
        {
            text: "Do you sell goods from stocks located within the EU to EU customers?",
            type: yesNo,
            conditions: [
                ["1", "These will continue to subject to EU VAT as currently"]
            ]
        },
        {
            text: "Do you sell goods from stocks located within the EU to UK customers?",
            type: yesNo,
            conditions: [
                ["1", ""],
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."],
                ["FCA, CFR, CPT, CIF, DAT, DAP, DDU", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK. "],
                ["DDP", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK. "]
            ]

        },
        {
            text: "Do you send goods through the EU non-EU customers?",
            type: yesNo,
            conditions: [
                ["1", "You will need to consult with your freight forwarder about customs transit provisions and any guarantees/deposits that will need to be lodged"]
            ]
        },
        {
            text: "Do you send goods to EU customers from UK stocks that were sourced in the EU or have EU origin contents/components? ",
            type: "select",
            options: [
                "No", "Yes, EU goods", "Yes, EU content/components",
            ],
            conditions: [
                ["Yes, EU content/components", "You will need consider applying for temporary import relief in the UK to relieve UK duties/VAT, and you or your customer should consider applying temporary export relief in the EU to avoid EU duties. Both processes may require customs deposits/guarantees"],
                ["Yes, EU goods", "You will need consider applying for temporary import relief in the UK to relieve UK duties/VAT, and you or your customer should consider applying temporary export relief in the EU to avoid EU duties. Both processes may require customs deposits/guarantees"],
                ["No", "You should make sure origin certification is available to mitigate EU import duties. You should also investigate temporary import relief to mitigate UK duties/VAT (the latter may require customs deposits/guarantees)."],
            ]
        },
        {
            text: "Do you send goods to EU customers from UK stocks that were sourced from countries with which the EU has preferential tariff arrangements? ",
            type: yesNo,
            conditions: [
                ["1", "You should make sure origin certification is available to mitigate EU import duties. You should also investigate temporary import relief to mitigate UK duties/VAT (the latter may require customs deposits/guarantees)"]
            ]
        },
        {
            text: "Do you supply B2B services to EU customers VAT-free under current reverse charge arrangements?",
            type: yesNo,
            conditions: [
                ["1", "The VAT-free status of B2B services will continue "]
            ]
        },
        {
            text: "Do you supply B2C services to EU customers that are taxed in the UK under arrangements?",
            type: yesNo,
            conditions: [
                ["1", "The VATable status of these services will at the moment continue, but this is an area where change to VAT-free status can be expected in due course when the VAT law is amended."]
            ]
        },
        {
            text: "Do you supply electronically delivered B2C services that are currently subject to the VAT rates of the customers' countries under the MOSS scheme?",
            type: yesNo,
            conditions: [
                ["1", "The MOSS scheme will end in the UK. As a non-EU provider of MOSS services into the EU market, you will obliged to set up a MOSS registration in another EU state. Due to language and ease of compliance, many UK companies have chosen Ireland for this. "]
            ]
        },
        {
            text: "Do you sell services that are currently taxed via local VAT registrations in EU states (e.g. services related to land, event admissions…..)",
            type: yesNo,
            conditions: [
                ["1", "These will continue to subject to EU VAT as currently."]
            ]
        },
    ],
    purchases: [
        {
            text: "Do you purchase goods B2B into the UK from EU suppliers?",
            type: yesNo,
            conditions: [
                ["1", ""]
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "Since your supplier's terms of delivery do not include export clearance from the EU, you may need to register as an exporter from the EU. This is likely to carry complex local VAT consequences depending on rules in the territory so you should investigate these in detail.  "],
                ["FCA, CFR, CPT, CIF, DAT, DAP, DDU", "After a hard Brexit, you will be responsible under your terms of delivery for customs clearing goods for import. This will require an EORI number and access by either you or your customs agent to HMRC's customs systems.VAT will be payable via your VAT returns but duty may need to be accounted for. If you wish to avoid having to pay on each shipment, a duty deferral account can be set up, which may require the posting of a customs guarantee.  "],
                ["DDP", "After a hard Brexit, your supplier's DDP delivery terms will mean it is responsible for customs clearance of goods into the UK. It may need a local EORI number and VAT registration and may have to charge you UK VAT on the sale. While this is entirely the supplier's responsibility, you may wish to make sure that this is regularised so as not to interfere with shipment timing, etc."]
            ]

        },
        {
            text: "Do you purchase goods from the EU that you then resell back to the EU, or export to non-EU states, either unaltered or after processing?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit, any duties on these imports may be either suspendable or reclaimable. You should look into temporary import or bonded storage reliefs for unaltered goods, or Inward Processing Relief for goods used in an industrial process."]
            ]
        },
        /*{
            text: "Do you sell and dispatch goods from the UK to EU B2C customers?",
            type: yesNo,
            conditions: [
                ["1", ""]
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "After a hard Brexit, you will have a choice of letting your customers take responsibility for import taxes and pay any VAT and duty (normally either to the courier company or post office) or, if this is not an acceptable buying experience, registering as an importer into the EU and registering for/charging local VAT. You will have to do this in all territories you sell to unless you organise a single point of importation and then distribute to the EU from there."],
                ["FCA, CFR, CPT, CIF, DAT, DAP, DDU", "Since your supplier's terms of delivery do not include export clearance from the EU, you may need to register as an exporter from the EU. This is likely to carry complex local VAT consequences depending on rules in the territory so you should investigate these in detail.  "],
                //["DDP", ""]
            ]

        },
        {
            text: "Are you registered for VAT in any state due to the distance selling rules?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit these registrations may no longer be necessary, depending on whether you wish to import/sell locally."],
            ]
        },
        {
            text: "Do you sell goods from stocks located within the EU to UK customers?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK."]
            ]
        },
        {
            text: "Do you sell goods from stocks located within the EU to UK customers?",
            type: yesNo,
            conditions: [
                ["1", ""]
            ]
        },*/
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "Since your terms of delivery do not include export clearance from the EU, you may need to charge EU VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements"],
                ["FCA, CFR, CPT, CIF, DAT, DAP, DDU", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK. "],
                ["DDP", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK. "]
            ]

        },
        {
            text: "Do you source goods from the EU and send these through the UK to EU or non-EU customers?",
            type: yesNo,
            conditions: [
                ["1", "You will need to consult with your freight forwarder about customs transit provisions and any guarantees/deposits that will need to be lodged"]
            ]
        },
        {
            text: "Do you acquire goods into the UK from the EU on a temporary basis (e.g. hired equipment)? ",
            type: yesNo,
            conditions: [
                ["1", "You will need consider applying for temporary import relief in the UK to relieve UK duties/VAT. "]
            ]
        },
        {
            text: "Do you acquire goods shipped from EU suppliers that may be wholly or partly of UK origin? ",
            type: yesNo,
            conditions: [
                ["1", "You should make sure origin certification is available to mitigate any UK import duties. "]
            ]
        },
        {
            text: "Do you buy B2B services from EU customers VAT-free that lead you to account for a UK reverse charge?",
            type: yesNo,
            conditions: [
                ["1", "The reverse charge status of B2B services will continue "]
            ]
        },
        {
            text: "Do you buy services that are currently taxed via local VAT registration of the seller in the UK (e.g. services related to land, event admissions…..)?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK. "]
            ]
        },
    ],
    contracts: [
        {
            text: "Do you have contracts of sale or purchase of goods with EU customers or suppliers?",
            type: yesNo,
            conditions: [
                ["1", "Review terms of delivery to make sure you know, and accept any customs obligations that these contracts will impose upon you."],
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."],
                ["FCA, CFR, CPT, CIF, DAT, DAP, DDU", "After a hard Brexit, you will be responsible under your terms of delivery for customs clearing goods for export. This will require your EORI number and access by either you or your customs agent to HMRC's customs systems."],
                ["DDP", "After a hard Brexit, your DDP delivery terms will mean you are responsible for customs clearance of goods into the EU territory of sale. You may need a local EORI number and VAT registration and may have to charge local VAT on the sale."]
            ]

        },
        /*{
            text: "Do you sell and dispatch goods from the UK to EU B2C customers?",
            type: yesNo,
            conditions: [
                ["1", ""],
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "we ship to the customer", "they organise shipment",
            ],
            conditions: [
                ["we ship to the customer", "After a hard Brexit, you will have a choice of letting your customers take responsibility for import taxes and pay any VAT and duty (normally either to the courier company or post office) or, if this is not an acceptable buying experience, registering as an importer into the EU and registering for/charging local VAT. You will have to do this in all territories you sell to unless you organise a single point of importation and then distribute to the EU from there."],
                ["they organise shipment", "Since your terms of delivery do not include export clearance from the UK, you may need to charge UK VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."],
            ]

        },
        {
            text: "Are you registered for VAT in any state due to the distance selling rules?",
            type: yesNo,
            conditions: [
                ["1", "After a hard Brexit these registrations may no longer be necessary, depending on whether you wish to import/sell locally."],
            ]
        },
        {
            text: "Do you sell goods from stocks located within the EU to EU customers?",
            type: yesNo,
            conditions: [
                ["1", "These will continue to subject to EU VAT as currently."],
            ]
        },
        {
            text: "Do you sell goods from stocks located within the EU to UK customers?",
            type: yesNo,
            conditions: [
                ["1", ""],
            ]
        },
        {
            text: "What are your terms of delivery?",
            type: "select",
            showIf: (chapter, nr, answers) => answers[chapter + (nr - 1)] === "1",
            options: [
                "EXW", "FCA, CFR, CPT, CIF, DAT, DAP, DDU", "DDP",
            ],
            conditions: [
                ["EXW", "Since your terms of delivery do not include export clearance from the EU, you may need to charge EU VAT post-Brexit. You may wish to change terms so you act as exporter, or look into indirect export arrangements."],
                ["FCA, CFR, CPT, CIF, DAT, DAP, DDU", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK."],
                ["DDP", "After a hard Brexit, these will be exports from the EU. You will need to obtain an EU EORI number and consider whether you wish you or your customers to be importers to the UK."]
            ]

        },
        {
            text: "Do you have contracts for sale or purchase of goods with EU customers or suppliers, where the goods will bear a customs duty liability post-Brexit?",
            type: yesNo,
            conditions: [
                ["1", "Review the contract pricing terms to see if an additional duty cost is allowed for in the price of the goods and, if not, who will be obliged to bear this cost. Consider renegotiating prices accordingly."]
            ]
        }*/
    ]
};
/*

{
            text: "",
            type: yesNo,
            conditions: [
                ["1",""]
            ]
        },
        {
            text: "",
            type: yesNo,
            conditions: [
                ["1",""]
            ]
        },
        {
            text: "",
            type: yesNo,
            conditions: [
                ["1",""]
            ]
        },
        {
            text: "",
            type: yesNo,
            conditions: [
                ["1",""]
            ]
        },
        {
            text: "",
            type: yesNo,
            conditions: [
                ["1",""]
            ]
        },

 */
export default class BrexitDiagnosis extends React.Component {


    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
        this.apiGet = apiGet.bind(this);
        this.config = new ViewConfig();
        this.state = {

            answers: this.config.config,
            questionMode: true,
        };
        window.setInterval(() => {
            this.config.save(this.state.answers)
        }, 2000)
    }

    componentWillUnmount = () => {
        this.config.save(this.state.answers)
    };
    componentDidMount() {
       document.title="Hard Brexit Diagnosis Tool - VATomator"
    }


    render() {

        const {answers, questionMode} = this.state;
        const QuestionsPrint = this.questionsPrint;
        return <FormContextWrapper
            value={{state: this.state, setState: this.setState}}
            onSubmit={e => e.preventDefault()}
        >


            <LightContainer name={"Brexit Diagnosis Tool"}>
                This questionnaire is designed to flag action points in the event of a No Deal Brexit; in the event of a Brexit under any draft withdrawal agreements so far seen, most VAT issues would remain unchanged during the interim
                period. It is
                also geared to businesses in mainland Great Britain; in Northern Ireland some special measures may apply.

                {
                    Object.keys(questions).map((chapterName, chapter) => {
                        const numOfAnswers = questions[chapterName].reduce((count, o, nrCore) => count + (!!answers[chapterName + nrCore] ? 1 : 0), 0);
                        const numOfPositiveAnswers = questions[chapterName].reduce((count, o, nrCore) => {
                            return count + (o.conditions.reduce((val, [value]) => answers[chapterName + nrCore] === value || val, false) ? 1 : 0)
                        }, 0);

                        return <div style={{padding: "20px 0"}}>
                            <h1>{chapter + 1}. {headings[chapterName]}</h1>
                            {
                                (numOfAnswers === 0 && !questionMode) ?
                                    "Please answer the questions first."
                                    : (
                                        (numOfPositiveAnswers === 0 && !questionMode) ?
                                            "You do not have to consider anything for in this chapter!" :
                                            <QuestionsPrint chapter={chapter} chapterName={chapterName} questionMode={questionMode}/>
                                    )
                            }
                        </div>
                    })
                }


                <div className={"hideOnPrint"}>
                    <button  onClick={() => this.setState({questionMode: !questionMode})}>{questionMode ? "Show Answers Only" : "Show Questions"}</button> &nbsp;&nbsp;
                    <button onClick={() => {
                        window.scrollTo(0,0)
                        if (!questionMode) {
                            window.print()
                        }
                        this.setState({questionMode: false}, () => window.print());
                    }}>
                        Save Results As PDF
                    </button>


                </div>
                <br/>
                Speak to an expert to get individual advice:
                <br/>
                <a href={"https://www.essentiaglobalservices.com/contact/"} className={"btn"} target={"_blank"}>Contact Form</a> or <a className={"btn"} href={"tel:00442037133535"}> +44 (0) 203 713 3535</a>


            </LightContainer>
        </FormContextWrapper>
    }

    questionsPrint = ({chapter, chapterName, questionMode}) => {

        const {answers} = this.state;

        return questions[chapterName].map(({text, type, options, conditions, showIf}, nrCore) => {
            const nr = chapterName + nrCore;

            if (!!showIf && showIf(chapterName, nrCore, answers) === false) {
                return null;
            }
            if (!questionMode) {
                const anyMatching = conditions.reduce((val, [value]) => answers[nr] === value || val, false);
                if (!anyMatching) {
                    return null
                }
            }
            const key = "answers_" + nr;
            return <div style={{padding: "10px 0", paddingLeft: !!showIf ? "20px" : "0px"}}>
                <h2>{chapter + 1}. {nrCore + 1}. {text}</h2>

                {questionMode ? <>

                        {
                            type === yesNo &&
                            <SelectField name={""} tag={key} hideLabel selectives={[[1, "yes"], [0, "no"]]} demandSelect/>
                        }
                        {
                            type === "select" &&
                            <SelectField name={""} tag={key} hideLabel selectives={options} demandSelect/>
                        }


                        {
                            answers[nr] !== undefined &&
                            (
                                conditions.map(([value, text]) => {
                                    if (answers[nr] === value) {
                                        return <p>{text}</p>;
                                    }
                                    return null
                                }).reduce((am, curr) => curr || am, null) || <p>No action necessary</p>
                            )
                        }
                    </> :
                    conditions.map(([value, text]) => {
                        if (answers[nr] === value) {
                            return <p>
                                Because your answer was&nbsp;<strong style={{fontWeight: 500}}>
                                {type === yesNo && (value === "1" ? "yes" : "no")}
                                {type === "select" && value}
                            </strong>
                                :
                                <br/>
                                {text}</p>;
                        }
                        return null
                    })
                }

            </div>


        })

    }
}