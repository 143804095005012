import React from 'react';

const FormContext = React.createContext("formcontext");

class FormContextWrapper extends React.Component {
    constructor(props) {
        super(props);
        if (!this.props.value || !this.props.value.state || !this.props.value.setState) {
            throw new Error("<FormContextWrapper /> needs state and setState in the value attribute")
        }
    }

    handleChange = (e) => {
        if (!e.target.name) {
            return
        }
        const s = e.target.name.split("_");

        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        const valueContainer = s.length > 1 ? {...this.props.value.state[s[0]], [s[1]]: val} : val;
        this.props.value.setState(old => ({...old, [s[0]]: valueContainer}));

        if (this.props.afterUpdate !== undefined) {
            this.props.afterUpdate(s[0], s[1], valueContainer)
        }
    };


    render() {
        const updateState = (a, b, c) => {
            this.props.value.setState && this.props.value.setState(old => ({...old, [a]: b ? {...old[a], [b]: c} : c}));
            this.props.afterUpdate && this.props.afterUpdate(a, b, b ? {[b]: c} : c);
        }
        return <FormContext.Provider value={{updateState, ...this.props.value}}>
            <form onSubmit={
                this.props.onSubmit !== undefined ? e => {
                        e.preventDefault()
                        this.props.onSubmit(e)
                    } :
                    (e) => e.preventDefault()
            }
                  onChange={this.handleChange}
                  style={this.props.style || {}}
            >
                {this.props.children}
            </form>
        </FormContext.Provider>
    }
}

export {FormContext, FormContextWrapper};
