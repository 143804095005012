import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {Switch} from "react-router";
import {Container} from "./core/input_fields";
// ie stuff
import "babel-polyfill";
import {polyfill} from 'es6-promise';

import FilingsList from './filing/filings_list';
import FilingNew from './filing/filing_new';
import CredentialsAdd from './credentials/credential_add';
import CredentialsList from './credentials/credentials_list';
import AuthRedirect from './credentials/auth_redirect';

import FilingQuickAdd from "./filing/filing_quickadd";
import Finanzamt from "./filing/DE/finanzamt";
import FilingsByCompany from "./filing/filings_bycompany";
import {UserContext} from "./user/UserContext";
import CheckLogin from "./user/check_login";
import Login from "./user/login";
import ChangePW from "./user/changepw";
import Nav from "./nav";
import CompanyView from "./company/company_view";

import MaxRedirect from "./core/redirect";
import FilesView from "./files/files_view";
import UsersList from "./user/user_list";
import ErrorBoundary from "./core/error_boundary";
import ThresholdsCalculator from "./company/thresholds";
import BrexitDiagnosis from "./company/brexit";
import CompanyRouter from "./company/router";
import GBFraudPreventionHeadersTest from "./filing/gb_testheaders";
import cssVars from 'css-vars-ponyfill';
import "./core/general.sass"
import './core/style.css'
import branding from "./core/branding";
import {getLocalIP} from "./core/api";
import GroupCompaniesList from "./company/groups";
import GroupCompany from "./company/group";

polyfill();


export default class App extends React.Component {
    state = {
        user: {},
    };

    addUserFromStatus = resp => this.setState({user: resp})

    setCssVars = () => cssVars({
        rootElement: document,
        variables: {
            colorLight: branding.colorLight,
            colorLight20p: branding.colorLight20p,
            colorDark: branding.colorDark,
        },
    });
    componentWillMount = () => {
        this.setCssVars()
    }
    componentDidMount() {
        getLocalIP();
    }

    render() {

        return <Router>
            <UserContext.Provider value={this.state}>
                <div style={{position: "fixed", bottom: 0, right: 0, fontSize: "10px", backgroundColor: "white", padding: 5, zIndex: 100}}>
                    version {window.vatomatorVersion}
                </div>
                <Route path="/" component={Nav}/>
                <article className={"mitte " + (window.navigator.userAgent.includes("Safari") && (window.navigator.userAgent.match(/([0-9][0-9])[0-9\.]+ Safari/) || {})[1] < 13 ? "safariWithSelectBug" : "")}>
                    <Route path="/" render={(props) => <CheckLogin {...props} addUserFromStatus={this.addUserFromStatus}/>}/>
                    <ErrorBoundary>
                        <Switch>

                            <Route exact path="/login.html" component={Login}/>
                            <Route exact path="/user/login" component={Login}/>
                            <Route exact path="/user/logout" component={Login}/>
                            <Route exact path="/user/changepw/:token" component={ChangePW}/>
                            <Route exact path="/" component={FilingsList}/>
                            <Route exact path="/dashboard" component={FilingsList}/>

                            <Route exact path="/finanzamt/:id" component={Finanzamt}/>

                            <Route exact path="/filings" component={FilingsList}/>


                            <Route exact path="/filings/new" component={FilingNew}/>
                            <Route exact path="/filings/quickadd" component={FilingQuickAdd}/>
                            <Route exact path="/filings/bycompany/:key" component={FilingsByCompany}/>
                            <Route exact path="/filings/quickadd/:id" component={FilingQuickAdd}/>
                            <Route exact path="/filings/edit/:id" component={FilingNew}/>
                            <Route exact path="/filings/status/:status" component={FilingsList}/>
                            <Route exact path="/filings/:id" component={FilingsList}/>
                            <Route exact path="/filings_gb/fraud_prevention_test" component={GBFraudPreventionHeadersTest}/>


                            <Route exact path="/files/:token" component={FilesView}/>
                            <Route exact path="/files/:token/:parent_ID" component={FilesView}/>


                            <Route exact path="/groupcompanies" component={GroupCompaniesList}/>
                            <Route exact path="/groupcompanies/:id" component={GroupCompany}/>
                            <Route exact path="/companies" component={CompanyRouter}/>
                            <Route exact path="/companies/:key" component={CompanyRouter}/>
                            <Route exact path="/companies/:key/:parent_ID" component={CompanyRouter}/>
                            <Route exact path="/company/:key" component={CompanyView}/>
                            <Route exact path="/company/:key/:parent_ID" component={CompanyView}/>

                            <Route exact path="/users" component={UsersList}/>

                            <Route path="/api/:id" component={MaxRedirect}/>
                            <Route path="/calculator" component={ThresholdsCalculator}/>
                            <Route path="/digitalsales" component={ThresholdsCalculator}/>
                            <Route path="/brexitdiagnosis" component={BrexitDiagnosis}/>


                            <Route path="/credentials/auth-redirect/:cc/" component={AuthRedirect}/>
                            <Route exact path="/credentials" component={CredentialsList}/>
                            <Route exact path="/credentials/add" component={CredentialsAdd}/>
                            <Route path={"/"} component={Notfound}/>

                        </Switch>
                    </ErrorBoundary>
                </article>
            </UserContext.Provider>
        </Router>
    }
}

function Notfound(props) {

    return <Container name={"Error"} visible nomanip>Page not found!</Container>
}


