import React from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import Status from '../core/status';
import _ from 'lodash'
import Consts from "../core/consts";
import JSONPretty from "react-json-pretty"
import {Container, FileUpload, SelectField, TextfieldInput} from "../core/input_fields";
import {CredentialsSelect} from "./filing_gb"
import 'react-confirm-alert/src/react-confirm-alert.css'
import ViewConfig from "../core/view_config";

class FilingQuickAdd extends React.Component {
    constructor(props) {
        super(props);
        this.config = new ViewConfig();
        //let s = localStorage.getItem('form_temp_data');
        let s = null;
        if (props.match && props.match.params.id !== undefined) {
            let d = new Date();

            axios.get(Consts.API_PREFIX + `/vat_return/get/${props.match.params.id}?${d.getMilliseconds()}`)
                .then(response => {
                    if (response.data.status === "error") {
                        this.setState({
                            error: response.data.message,
                            status: "",
                            loading: false,
                            loadedMain: true,
                        })
                    } else {
                        const s = Object.assign(
                            {},
                            this.state,
                            response.data.data.data,
                            {
                                loading: false,
                                submitted: response.data.data.status !== 0,
                                loadedMain: true,
                            }
                        );
                        this.setState(s);
                        this.mainLoader(s)
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setState({
                        error: error.message,
                        status: "",
                        loading: false,
                        loadedMain: true,
                    })
                });
            this.state = {
                id: 0,

                /*account: {
                    name: "",
                    password: "",
                },*/
                general: {
                    country: this.config.config.country || "GB",
                    report: this.config.get("report") || "vat_return",
                },
                lastSaved: 0,
                fileID: "",
                status: "",
                error: "",
                loading: true,
                submitted: false,
                loadedMain: false,
                mainLoading: [],

            };
        } else {
            if (s !== "null" && s !== null && props.location.pathname !== "/filings/new") {
                this.state = JSON.parse(s);
            } else {
                this.state = {
                    id: 0,

                    general: {
                        country: this.config.get("country") || "GB",
                        report: this.config.get("report") || "vat_return",
                    },
                    fillIn: [],
                    lastSaved: 0,
                    fileID: "",
                    status: "",
                    error: "",
                    loading: false,
                    submitted: false,
                    loadedMain: true,
                    mainLoading: [],
                };
            }
        }
        // prerequisites


        this.localVars = [
            "status", "error", "excel", "loading", "changed", "submitted", "loadedMain", "mainLoading", "valid", "submitting", "fillIn", "loadedFromFile",
        ];
        this.handleChange = this.handleChange.bind(this);
        this.updateState = this.updateState.bind(this);
        this.saveFormLocal = this.saveFormLocal.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.saveFormWorker = this.saveFormWorker.bind(this);
        this.mainLoader = this.mainLoader.bind(this);
        this.loadFromFile = this.loadFromFile.bind(this);
        this.fillLoadedData = this.fillLoadedData.bind(this);
        this.searchByName = this.searchByName.bind(this);
        this.setState = this.setState.bind(this);
        this.type = this.type.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);


    }

    deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    mainLoader(state) {
        state.mainLoading.forEach(s => {
            s(state)
        })
    }

    type(cc, report = "") {
        if (this.state.general !== undefined && this.state.general.country !== undefined && this.state.general.country === cc) {
            if (report === "" || (this.state.general.report !== undefined && this.state.general.report === report)) {
                return true
            }
        }
        return false
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    componentDidMount() {
        this.mainLoader(this.state);
        /*this.timer = window.setInterval(() => {
            this.saveFormLocal()
        }, 2000);*/

        const selects = document.getElementsByTagName("select");
        for (var i = 0; i < selects.length; i++) {
            const name = selects[i].getAttribute("name").split("_");
            //console.log(name,selects[i].value)
            this.updateState(name[0], name[1], selects[i].value)
        }

    }

    componentDidUpdate() {
        this.config.save({country: this.state.general.country, report: this.state.general.report})
    }
    saveFormLocal() {
        let s = this.state;
        s.error = "";
        s.status = "";
        s.excel = "";
        //localStorage.setItem('form_temp_data', JSON.stringify(s));
    }


    saveForm() {
        try {
            this.saveFormWorker();
        } catch (e) {
            this.setState({
                'error': e
            });

        }
    }


    saveFormWorker() {


        let s = this.deepCopy(this.state);
        if (s.submitted) {
            throw new Error("The filing cannot be saved if already submitted!");
        }

        this.setState({loading: true});
        this.localVars.forEach(n => s[n] = undefined);

        axios.post(Consts.API_PREFIX + `/vat_return/save`, s, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",
                        id: response.ID,
                        loading: false,
                    })

                }

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false
                })
            });
    }


    updateState(parent, child, value) {
        if (child === "") {
            this.setState({parent: value});
        } else {
            this.setState((prevState, props) => {
                return {
                    [parent]: Object.assign(
                        {},
                        prevState[parent],
                        {[child]: value}
                    )
                }
            });
        }

    }

    handleChange(e) {
        if (!this.state.submitted) {
            const s = e.target.name.split("_");
            if (s.length === 2) {
                this.updateState(s[0], s[1], e.target.value)
            }
            if (s[0] === "company" && s[1] === "name") {
                this.searchByName(e.target.value, this.state.company !== undefined && this.state.company.note !== undefined ? this.state.company.note : "")
            } else if (s[0] === "company" && s[1] === "note") {
                this.searchByName(this.state.company !== undefined && this.state.company.name !== undefined ? this.state.company.name : "", e.target.value)
            } else if (s[0] === "company") {
                this.setState(({
                    fillIn: [],
                }))
            }
        } else {
            this.setState({
                error: "already submitted files cannot be changed"
            })
        }

    }

    componentWillMount() {
        if (this.state.submitted || this.state.status !== 0) {
        }
    }

    fillLoadedData(d = {}, company = {}) {
        console.log(d, company);
        if (d.company === undefined && company !== {}) {
            d["company"] = company["company"];
            d["specific"] = company["specific"]
        } else {
            d = d["company"]
        }
        if (d.company !== undefined && d.company !== null) {
            this.updateState("company", "name", d.company.name);
            this.updateState("company", "street", d.company.street);
            this.updateState("company", "city", d.company.city);
            this.updateState("company", "country", d.company.country);
            //this.updateState("company", "prename", d.company.prename);
            //this.updateState("company", "lastname", d.company.lastname);
            this.updateState("company", "houseNumber", d.company.houseNumber);
            this.updateState("company", "postcode", d.company.postcode);
            this.updateState("company", "email", d.company.email);

            this.updateState("company", "vatId", d.company.vatId);

            this.updateState("company", "note", d.company.note);
        }

        if (d.vatid !== undefined) {
            console.log("putting in the vat id");
            this.updateState("company", "vatId", d.vatid);
        } else {
            console.log("lnoisdnlnf")
        }
        if (d.specific !== undefined) {
            this.updateState("specific", "tnr1", d.specific.tnr1);
            this.updateState("specific", "tnr2", d.specific.tnr2);
            this.updateState("specific", "tnr3", d.specific.tnr3);
            this.updateState("specific", "taxnr", d.specific.taxnr);
            this.updateState("specific", "county", d.specific.county);

        }

        this.setState(({
            fillIn: [],
        }))
    }

    loadFromFile() {
        axios.get(`${Consts.API_PREFIX}/${this.state.general.report}/${this.state.general.country.toLowerCase()}/excel_data/${this.state.fileID}`, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,

                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",
                        loadedFromFile: true,
                    });
                    this.fillLoadedData(response.data)
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,

                })
            })
    }

    searchByName(name, note) {
        if ((name.length + note.length < 3) || (this.state.company.street !== undefined && this.state.company.street !== "")) {
            this.setState({
                fillIn: []
            });
            return
        }

        axios.get(Consts.API_PREFIX + `/vat_return/search/?name=` + name + `&note=` + note + `&type=` + this.state.general.report + "_" + this.state.general.country.toLowerCase(), {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,

                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",

                    });
                    this.setState({
                        fillIn: response.data.results
                    })
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,

                })
            })
    }

    render() {

        if (this.state.id !== 0 && this.props.match.params.id === undefined) {
            this.props.history.push('/filings/quickadd/' + this.state.id);
            //hashHistory.replace('/filings/edit/' + this.state.id);
            return null
        }

        if (
            this.state.general !== undefined &&
            (this.state.general.country === "DE" || this.state.general.country === "GB") &&
            this.state.loadedFromFile === undefined
            && this.state.fileID !== undefined
            && (
            this.state.company === undefined ||
            (this.state.company !== undefined
                && (
                    ((this.state.company.name === undefined || (this.state.company.name !== undefined && this.state.company.name === "")) && this.state.general.country === "DE") ||
                    ((this.state.company.vatId === undefined || (this.state.company.vatId !== undefined && this.state.company.vatId === "")) && this.state.general.country === "GB")
                )

            ))
            && this.state.fileID.length > 0
            && this.state.error === "") {
            this.loadFromFile()
        }

        let dateNow = new Date();
        // | <a onClick={this.resetForm}>reset Form</a>
        return (
            <div id="filing_new">
                <div style={{float: "right"}}>
                    <Link style={{fontsize: "16px"}} to="/filings">back to all filings</Link>
                </div>
                <h1>Add new filing</h1>


                <Status type="success" text={this.state.status}>

                </Status>
                <Status type="error" text={this.state.error}/>


                {Consts.PHASE === "development2" &&
                <div style={{float: "right", width: "300px"}}>
                    <JSONPretty id="json-pretty" json={this.state}/>
                </div>
                }

                <form id="form" name="form" onSubmit={this.handleSubmit} onChange={this.handleChange}>
                    <Container name={"General"}>
                        <SelectField name="Country" tag="general_country" width="100px" selectives={Consts.reportCountries} state={this.state} noBreaks/>
                        <SelectField name="Type" tag="general_report" noBreaks selectives={Consts.reports[this.state.general.country].map(a => [a.toLowerCase().split(" ").join("_"), a])}
                                     state={this.state}/>
                        <br/>
                    </Container>
                    <Container name={"Financial Data as Excel"}>
                        <FileUpload name={"File"} state={this.state} setStateParent={this.setState}/>
                    </Container>
                    {this.type("GB", "vat_return") &&
                    <span>
                        <Container>
                            <TextfieldInput type={"hidden"} name="VAT ID" tag="company_vatId" state={this.state} ph={"123456789"}/>
                            <TextfieldInput type={"hidden"} name="Note (optional)" tag="company_note" state={this.state} ph={"ref nr 1234"}/>
                        </Container>
                    </span>
                    }

                    {this.type("GB", "ec_sl") &&
                    <span>
                        <Container name={"Company Data"} visible>
                            <TextfieldInput name="VAT ID" tag="company_vatId" state={this.state} ph={"123456789"}/>
                            <TextfieldInput name="Post Code" tag="company_postcode" state={this.state} ph={"ABC 123"}/>
                            <TextfieldInput name="Branch Number" tag="company_branchnr" state={this.state} ph={"000"}/>
                            <TextfieldInput name="Note (optional)" tag="company_note" state={this.state} ph={"ref nr 1234"}/>
                        </Container><br/>
                        <Container name={"Period Data"} visible>
                        <SelectField name={"Year"} tag={"specific_year"} state={this.state} demandSelect noBreaks
                                     selectives={_.range(dateNow.getFullYear() - 7, dateNow.getFullYear() + 1).reverse()}/>
                            <SelectField name={"Period"} tag={"specific_period"} state={this.state} demandSelect
                                         selectives={[
                                             ["Jan", "January"],
                                             ["Feb", "February"],
                                             ["Mar", "March"],
                                             ["Apr", "April"],
                                             ["May", "May"],
                                             ["Jun", "June"],
                                             ["Jul", "July"],
                                             ["Aug", "August"],
                                             ["Sep", "September"],
                                             ["Oct", "October"],
                                             ["Nov", "November"],
                                             ["Dec", "December"],
                                         ]}/>
                        </Container>
                        <CredentialsSelect state={this.state} setStateParent={this.setState} updateParent={this.updateState}/>
                    </span>
                    }


                    {this.type("DE") &&
                    <span>
                        {
                            this.type("DE", "vat_return") &&

                            <Container>

                                <TextfieldInput type={"hidden"} name={"Company Name"} tag="company_name" state={this.state} ph={"Example Ltd"}/>
                                <TextfieldInput type={"hidden"} name={"Country"} tag={"company_country"} state={this.state} ph={"UK"}/>
                                <TextfieldInput type={"hidden"} name="Street" tag="company_street" state={this.state} ph={"Musterstraße"}/>
                                <TextfieldInput type={"hidden"} name="House Number" tag="company_houseNumber" ph={123} state={this.state}/>
                                <TextfieldInput type={"hidden"} name="Post Code" tag="company_postcode" state={this.state} ph={12345}/>
                                <TextfieldInput type={"hidden"} name="City" tag="company_city" state={this.state} ph={"Musterstadt"}/>
                                {/*<TextfieldInput name="Prename" tag="company_prename" state={this.state} ph={"Max"}/>
                                <TextfieldInput name="Lastname" tag="company_lastname" state={this.state} ph={"Mustermann"}/>*/}
                                <TextfieldInput type={"hidden"} name="Note (optional)" tag="company_note" state={this.state} ph={"ref nr 1234"}/>

                            </Container>
                        }
                        {
                            this.type("DE", "ec_sl") &&
                            <Container>

                                <TextfieldInput type={"hidden"} name="Company Name" tag="company_name" state={this.state} ph={"Example Ltd"}/>
                                <SelectField type={"hidden"} name={"Country"} tag={"company_country"} state={this.state} demandSelect selectives={Consts.countries}/>
                                <TextfieldInput type={"hidden"} name="Street" tag="company_street" state={this.state} ph={"Musterstraße"}/>
                                <TextfieldInput name="House Number" tag="company_houseNumber" type={"hidden"} ph={123} state={this.state}/>
                                <TextfieldInput type={"hidden"} name="Post Code" tag="company_postcode" state={this.state} ph={12345}/>
                                <TextfieldInput type={"hidden"} name="City" tag="company_city" state={this.state} ph={"Musterstadt"}/>

                                <TextfieldInput type={"hidden"} name="VAT ID" tag="company_vatId" state={this.state} ph={"DE651351513"}/>
                                <TextfieldInput type={"hidden"} name="Note (optional)" tag="company_note" state={this.state} ph={"ref nr 1234"}/>
                            </Container>
                        }


                        {this.type("DE", "vat_return") &&
                        <Container name={"VAT Return Data"}>

                            <SelectField name={"Year"} tag={"specific_year"} state={this.state} noBreaks
                                         selectives={_.range(dateNow.getFullYear() - 7, dateNow.getFullYear() + 1).reverse()}/>
                            <SelectField name={"Period"} tag={"specific_period"} state={this.state}
                                         selectives={[
                                             ["41", "I. Quarter"],
                                             ["42", "II. Quarter"],
                                             ["43", "III. Quarter"],
                                             ["44", "IV. Quarter"],
                                         ].filter(a => {
                                             if (this.state.specific === undefined || this.state.specific.year === undefined || dateNow.getFullYear() === this.state.specific.year) {
                                                 if ((a[0] % 40) * 3 >= dateNow.getMonth()) {
                                                     return false
                                                 }
                                             }

                                             return true
                                         }).concat([
                                                 ["01", "January"],
                                                 ["02", "February"],
                                                 ["03", "March"],
                                                 ["04", "April"],
                                                 ["05", "May"],
                                                 ["06", "June"],
                                                 ["07", "July"],
                                                 ["08", "August"],
                                                 ["09", "September"],
                                                 ["10", "October"],
                                                 ["11", "November"],
                                                 ["12", "December"],
                                             ].filter(a => {
                                                 if (this.state.specific === undefined || this.state.specific.year === undefined || dateNow.getFullYear() === this.state.specific.year) {
                                                     if (a[0] * 1 > dateNow.getMonth()) {
                                                         return false
                                                     }
                                                 }
                                                 return true
                                             })
                                         ).reverse()}/>

                        </Container>

                        }

                        {this.type("DE", "ec_sl") &&
                        <Container name={"Period"}>

                            {/* <SelectField name="Authority" tag="vr_authority" type={"select2"}  selectives={finanzamter} state={this.state}  updateParent={this.updateState}/>*/}
                            <SelectField name={"Year"} tag={"specific_year"} state={this.state} noBreaks
                                         selectives={_.range(dateNow.getFullYear() - 7, dateNow.getFullYear() + 1).reverse()}/>
                            <SelectField name={"Period"} tag={"specific_period"} state={this.state} demandSelect selectives={[["21", "January"],
                                ["22", "February"],
                                ["23", "March"],
                                ["24", "April"],
                                ["25", "May"],
                                ["26", "June"],
                                ["27", "July"],
                                ["28", "August"],
                                ["29", "September"],
                                ["30", "October"],
                                ["31", "November"],
                                ["32", "December"],
                                ["11", "Jan - Feb"],
                                ["12", "Apr - May"],
                                ["13", "Juli - Aug"],
                                ["14", "Okt - Nov"],
                                ["1", "I. Quarter"],
                                ["2", "II. Quarter"],
                                ["3", "III. Quarter"],
                                ["4", "IV. Quarter"],
                                ["5", "Year"],]}/>
                        </Container>

                        }

                    </span>
                    }


                    {this.state.loading &&
                    <span><img alt="loader" src="/src/img/ajax-loader-fff.gif"/>&nbsp;&nbsp;&nbsp;</span>
                    }

                    {(
                        (this.type("DE") && this.state.company !== undefined && this.state.company.name !== undefined && this.state.company.name !== "") ||
                        (this.type("GB", "vat_return") && this.state.company !== undefined && this.state.company.vatId !== undefined && this.state.company.vatId !== "")
                    )

                    &&
                    <button type={"button"} onClick={this.saveForm}>Save</button>
                    }
                    {
                        this.state.id !== 0 &&
                        <span>
                            &nbsp;
                            <button type={"button"} onClick={() => {
                                window.location.href = "/filings/quickadd"
                            }}>do new</button>
                        </span>
                    }

                </form>
            </div>
        );
    }
}

var months = ["January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"];

function zeroPad(num, places) {
    var zero = places - num?.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

function createATMonths() {
    let dateNow = new Date();
    let thisYear = _.range(1, dateNow.getMonth() + 1).map(x => {
        return [dateNow.getFullYear() + "-" + zeroPad(x, 2), dateNow.getFullYear() + " " + months[x - 1]]
    });
    let lastYear = _.range(dateNow.getMonth(), 13).map(x => {
        return [dateNow.getFullYear() - 7 + "-" + zeroPad(x, 2), dateNow.getFullYear() - 7 + " " + months[x - 1]]
    });

    let select = _.range(1, 14).map((i) => {
        if (dateNow.getMonth() - i >= 0) {
            return thisYear[dateNow.getMonth() - i]
        } else {
            return lastYear[12 - i + 1]
        }
    });
    return select
}

createATMonths();
/*
                            </select>*/


export default FilingQuickAdd;
