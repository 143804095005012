import React from 'react';
import {Link} from "react-router-dom";
import Status from '../core/status';
import Consts from '../core/consts';
import {Container, FileUpload, SelectField, TextfieldInput} from "../core/input_fields";

import JSONPretty from "react-json-pretty"
import ViewConfig from "../core/view_config";
import {apiPost} from "../core/api";
import {FormContext} from "../core/form_context";


class CredentialsAdd extends React.Component {
    constructor(props) {
        super(props);
        this.config = new ViewConfig();
        this.state = {
            credentials: {
                country: this.config.get("country") || "GB",
                credname: "",
                report: "vat_return",
                public: 1,
            },

            status: "",
            error: "",
            loading: false,
            token: ""
        };
        this.setState = this.setState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadToken = this.loadToken.bind(this);
        this.updateState = this.updateState.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.apiPost = apiPost.bind(this);

        console.log("loading now")

    }


    componentDidUpdate() {
        //this.config.save({country: this.state.credentials.country, report: this.state.credentials.report})
    }

    updateState(parent, child, value) {
        if (child === "") {
            this.setState({parent: value});
        } else {
            this.setState({
                [parent]: Object.assign(
                    {},
                    this.state[parent],
                    {[child]: value}
                )
            });
        }

    }

    handleChange(e) {
        if (e.target.name === "")
            return;

        const s = e.target.name.split("_");
        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.type === "checkbox" ? (e.target.checked ? "1" : 0) : e.target.value}
            )
        });
        if (s[0] === "country") {
            console.log("country=" + e.target.value)

        }
    }

    handleSubmit(event) {
        event.preventDefault();
        try {
            if (this.state.credentials.credname === "") {
                this.setState({
                    error: "please fill out a name for the credentials",
                    status: "",
                    loading: false,
                });
                return
            }
            this.setState({
                error: "",
                status: "",

                loading: true,
            });

            this.loadToken();
        } catch (e) {
            this.setState({
                'error': e,
                loading: false,
            });

        }


        //this.submitForm();
    }

    loadToken() {
        let s = this.state;
        console.log(s.credentials)
        this.apiPost(
            (s.credentials.country === "GB" && s.credentials.report === "vat_return" ? "/authentication/oauth/genlink" : "/credentials/save"),
            s,
            resp => {
                this.setState({
                    error: "",
                    status: resp.message,
                    token: resp.token !== undefined ? resp.token : "",
                    user_id: resp.user_id,
                    loading: false,
                })
            }
        )

    }

    render() {
        const countries = ["GB", "DE", "IE","FR"];
        const credCountries = Consts.reports[this.state.credentials.country];
        return <FormContext.Provider value={{
            state: this.state, setState: this.setState, updateState: this.updateState
        }}>
            <div id="identity_new">

                <h1>Add new Credentials</h1>
                <Link style={{fontsize: "16px"}} to="/credentials">back to all credentials</Link><br/><br/>
                <Status type="success" text={this.state.status}/>
                <Status type="error" text={this.state.error}/>

                {
                    Consts.PHASE === "development2" &&
                    <div style={{float: "right", width: "300px"}}>
                        <JSONPretty id="json-pretty" json={this.state}/>
                    </div>
                }

                <form id="form" name="form" onSubmit={this.handleSubmit} onChange={this.handleChange}>
                    <Container name={"Credentials"} visible>
                        <SelectField name="Country" tag="credentials_country" selectives={countries.map(c => [c, Consts.countriesMap[c]])} noBreaks/>
                        {
                            this.state.credentials.country !== "DE" &&
                            <SelectField name="Type" tag="credentials_report" noBreaks selectives={credCountries !== undefined ? credCountries.filter(a => !a.includes("Manual")).map(a => [a.toLowerCase().split(" ").join("_"), a]) : []}/>
                        }
                        <br/>

                        <TextfieldInput name="Label Name" tag="credentials_credname" state={this.state} ph={this.state.credentials.country === "GB" ? "HMRC account name" : "certificate 1"}/><br/>

                        <label style={{display: "none"}}>
                            <span>
                                Available to All Users
                        </span>
                            <label className={"switch"}>
                                <input type="checkbox" name={"credentials_public"} checked={this.state.credentials.public === 1 ? "checked" : ""}/>
                                <span className="slider"/>
                            </label>
                        </label>


                        {
                            this.state.credentials.country === "DE" &&
                            <div>
                                <TextfieldInput name="Password" tag="credentials_password" type={"password"} ph={"qwerty123"}
                                                state={this.state}/><br/><br/>
                                <FileUpload name={"Elster Certificate"} setStateParent={this.setState}/>
                            </div>

                        }
                        {
                            this.state.credentials.country === "IE" && this.state.credentials.report === "vat_return" &&
                            <div>
                                <TextfieldInput name="Password" tag="credentials_password" type={"password"} ph={"qwerty123"}
                                                state={this.state}/><br/><br/>
                                <FileUpload name={"ROS Certificate"} setStateParent={this.setState}/>
                            </div>

                        }

                        {
                            ((this.state.credentials.country === "GB" && this.state.credentials.report === "ec_sl") || ["FR"].includes(this.state.credentials.country)) &&
                            <div>
                                <TextfieldInput name="User Name" tag="credentials_username" state={this.state} ph={"user123"}/><br/>
                                <TextfieldInput name="Password" tag="credentials_password" type={"password"} ph={"qwerty123"} state={this.state}/><br/>
                            </div>

                        }

                        <button>SAVE</button>
                        <br/>

                    </Container>
                    {this.state.loading && <Container name={"Loading"} visible><img alt={"loader"} src="/src/img/ajax-loader-fff.gif"/></Container>}
                    {this.state.loading === false && this.state.credentials.country === "GB" && this.state.token !== "" &&
                    <Container name={"HMRC Login"} visible>
                        To complete adding your credentials please hit the following link to login at the
                        HMRC:<br/><br/>
                        <a className={"btn"} style={{color: "white"}} target='_blank' rel="noopener noreferrer"
                           href={'//' + Consts.GB_WEBSERVICE_HOST(this.state.user_id) + '/oauth/authorize?response_type=code&redirect_uri=' + Consts.GB_REDIR_URL() + '/credentials/auth-redirect/gb/?token=' + this.state.token + '&scope=read:vat%20write:vat&client_id=' + Consts.GB_CLIENT_ID(this.state.user_id)}>HMRC
                            Login</a>
                        <br/>
                    </Container>
                    }

                </form>

            </div>
        </FormContext.Provider>

    }
}

/*

 */

export default CredentialsAdd;
