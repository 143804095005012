import {FaFile, FaFileInvoice, FaFolder, FaImage, FaTable} from "react-icons/fa";
import Consts from "../core/consts";
import React from "react"
const FileIcon = ({type}) => {
    switch (type) {
        case "fold":
            return <FaFolder/>;
        case "xlsx":
        case "xls":
            return <FaTable/>;
        case "pdf":
            return <FaFileInvoice/>;
        case "jpg":
        case "png":
            return <FaImage/>;
        default:
            return <FaFile/>
    }
};
const createLink = (row) => {
    switch (row.original.file.type) {
        case "fold":
            return "/company/" + row.original.file.Cmp_key + "/" + row.original.file.ID;//this.setState({parent_ID: row.original.file.ID}, this.loadFiles)
        case "xlsx":
        case "pptx":
        case "docx":
            return Consts.API_PREFIX + "/files/office_open/" + row.original.file.ID;
        default:
            return Consts.API_PREFIX + "/files/download/" + row.original.file.ID + "/" + encodeURIComponent(encodeURIComponent(row.original.file.name));
        //alert("undefined default in switch")
    }
}
export {FileIcon,createLink};
