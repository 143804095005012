import React from 'react';
import {InsertIfNonClient, LightContainer, Loader, MyModal, SelectField, TextfieldInput} from "../core/input_fields";
import Status from "../core/status";
import {apiGet, apiPost, encodeGetParams} from "../core/api";

import ReactTable from "react-table";
import {dateFormatTime} from "../filing/filing_support";
import {UserContext} from "../user/UserContext";
import {FaArrowCircleLeft, FaArrowCircleRight, FaClone, FaDownload, FaFileArchive, FaFolderOpen, FaFolderPlus, FaInfoCircle, FaLock, FaLockOpen, FaPlusCircle, FaShareAlt, FaTrashAlt, FaTrashRestoreAlt} from "react-icons/fa";
import Uploader from "./upload"
import Consts from "../core/consts"
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";
import {FormContext} from "../core/form_context";

import {createLink, FileIcon} from "./core";
import {maxiGet, maxiPost} from "../core/maxios";
import {downloadAsZip} from "../core/download";

function MoveFileToFolder({closeAndReload, file, folders}) {
    const foldersSelection = [...(file.parent_ID !== 0 ? [{file: {name: "parent folder", ID: 0}}] : []), ...folders]

    return <LightContainer name={`Select folder to move file ${file.name}`}>
        <ul style={{marginLeft: 30}}>
            {foldersSelection.map(({file: folder}) => <li style={{cursor: "pointer"}} onClick={() => maxiPost("/files/move_to_folder", {file_ID: file.ID, folder_ID: folder.ID}).then(closeAndReload)}><FaArrowCircleRight/> {folder?.name}</li>)}
        </ul>

    </LightContainer>
}


class FilesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            files: [],
            newFile: "",
            parent_ID: this.props.match.params.parent_ID !== undefined ? (this.props.match.params.parent_ID || 0) : 0,
            parent: null,
            newFolderName: "",
            editMode: false,
            initial: true,
            shareOpen: 0,
            showHiddenFiles: false,
        };
        this.key = (this.props.match.params === undefined || this.props.match.params.key === undefined) ? this.props.key : this.props.match.params.key;

        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.loadFiles();
        this.setState = this.setState.bind(this)
    }

    loadFiles = () => {
        //console.log("loading")
        if (!this.state.initial) {
            this.setState({loading: true})
        }
        const pID = this.props.match.params.parent_ID !== undefined ? (this.props.match.params.parent_ID || 0) : 0
        this.apiGet(`/files/list/${this.key}/${(pID || 0)}?` + encodeGetParams({showHiddenFiles: this.state.showHiddenFiles}), resp => {
            this.setState({
                ...resp,
                loading: false,
                error: "",
                showingParent_ID: pID,
                initial: false
            })
        });
    };
    static contextType = UserContext;
    addFolder = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        this.apiPost("/files/add_folder", {parent_ID: this.state.showingParent_ID, Cmp_key: this.key, name: this.state.newFolderName}, resp => {
            this.setState({
                loading: false,
                error: "",
                newFolderName: "",
            });
            this.loadFiles()
        });
    };
    renderEditable = (cellInfo) => {
        return <div
            style={{backgroundColor: "#fafafa"}}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
                const data = [...this.state.files];
                data[cellInfo.index].file.name = e.target.innerHTML;
                this.apiPost("/files/rename/" + data[cellInfo.index].file.ID, {"name": e.target.innerHTML})
                this.setState({data});
            }}
            dangerouslySetInnerHTML={{
                __html: this.state.files[cellInfo.index].file.name
            }}
        />
    };

    resetShareOpen = () => {
        this.setState({shareOpen: 0})
    };

    render() {
        if (this.props.match.params.parent_ID !== this.state.showingParent_ID && !this.state.loading && this.state.error === "") {
            console.log(this.props.match.params.parent_ID !== this.state.showingParent_ID, !this.state.loading, this.state.error === "")
            this.loadFiles()
        }
        const parent = this.state.parent;
        return (

            <LightContainer name={"Workbench" + (this.state.parent !== null ? " in folder " + this.state.parent.name : "")}>
                <Status type={"error"} text={this.state.error}/>

                <Loader loading={this.state.loading} div/>
                {
                    /*this.state.parent !== null &&
                    <em onClick={() => {
                        this.setState({parent_ID: parent.parent_ID}, this.loadFiles)
                    }
                    }>
                    </em>*/
                }
                <div style={{float: "right"}}>
                    <Popup className={"popup-modal"} trigger={<em> <FaPlusCircle/> Add File</em>} modal>
                        {close => <LightContainer name={"Upload file"}>
                            <Uploader
                                Cmp_key={this.key}
                                folder={this.state.showingParent_ID}
                                setStateParent={this.setState}
                                reloadFiles={() => {
                                    this.loadFiles()
                                    close()
                                }}
                            />
                        </LightContainer>}
                    </Popup>
                    &nbsp;&nbsp;
                    <Popup className={"popup-modal"} trigger={<em> <FaPlusCircle/> Create Folder</em>} modal>
                        {close => <LightContainer name={"Create folder"}>
                            <form onSubmit={(e) => {
                                this.addFolder(e)
                                close()
                            }} onChange={e => this.setState({[e.target.name]: e.target.value})}>
                                <div>
                                    <TextfieldInput state={this.state} tag={"newFolderName"} name={"Add New Folder"}/>
                                    <button>Add</button>
                                </div>
                            </form>
                        </LightContainer>
                        }
                    </Popup>
                    &nbsp;&nbsp;
                    <em>
                        <DownloadFolder withText folder={{ID: this.state.showingParent_ID, Cmp_key: this.key}} parentSetState={this.setState} fileName={this.state.parent?.name || this.key}/>
                    </em>
                    {
                        this.context.user.Role >= 80 && <>
                            &nbsp;
                            &nbsp;

                            <em onClick={() => {
                                this.setState({showHiddenFiles: !this.state.showHiddenFiles}, this.loadFiles)
                            }}>
                                {!this.state.showHiddenFiles && <> <FaFileArchive/> Show Deleted Files</>}
                                {this.state.showHiddenFiles && <> <FaFileArchive/> Hide Deleted Files</>}
                            </em>
                        </>
                    }

                    &nbsp;
                    &nbsp;

                    <em onClick={() => {
                        this.setState({editMode: !this.state.editMode})
                    }}>
                        {this.state.editMode && <> <FaLockOpen/> Turn Off Edit Mode</>}
                        {!this.state.editMode && <> <FaLock/> Turn On Edit Mode</>}
                    </em>


                </div>


                {
                    this.state.parent !== null ?
                        <Link to={"/company/" + this.key + "/" + parent.parent_ID}>
                            <FaArrowCircleLeft/> back to {parent.name}
                        </Link> : <b>Parent Folder of {this.key}</b>
                }


                <div id={"files_table"}>
                    <ReactTable
                        data={this.state.files}
                        style={{marginTop: "10px"}}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Type",
                                        Cell: row => <FileIcon type={row.original.file.type}/>,
                                        maxWidth: 50
                                    },
                                    {
                                        Header: "Name",
                                        id: "name",
                                        accessor: "file.name",
                                        Cell: row =>
                                            this.state.editMode ?
                                                this.renderEditable(row) :
                                                <>
                                                    <Link style={{opacity: row.original.file.hidden ? "0.5" : ""}} to={createLink(row)} target={row.original.file.type !== "fold" ? "_blank" : ""}>{row.value} </Link>
                                                    {!!row.original.file?.note && <MyModal trigger={<em><FaInfoCircle/></em>}>
                                                        <div>
                                                            <h2>Note by {row.original.user?.name}: </h2>
                                                            <>{row.original.file?.note.split("\n").map(a => [a, <br/>])}</>
                                                        </div>
                                                    </MyModal>}
                                                </>

                                    },
                                    {
                                        Header: "Created By",
                                        accessor: "user.name",
                                        maxWidth: 250,
                                    },
                                    {
                                        Header: "Last Modified",
                                        accessor: "file.last_modified",
                                        Cell: row => row.value > 0 ? dateFormatTime(row.value * 1000) : "unknown",
                                        maxWidth: 150,
                                    },

                                    {
                                        Header: "Actions",
                                        accessor: "file.ID",

                                        Cell: row => <>

                                            {
                                                (this.context.user.Role > 20 || this.context.user.ID === row.original?.file?.Usr_ID) && <>&nbsp;
                                                    {
                                                        row.original.file?.hidden ?
                                                            <span title={"Recover File"}>
                                                    <FaTrashRestoreAlt style={{cursor: "pointer"}} onClick={() => window.confirm("Do you want to recover this file?") && this.apiGet("/files/delete/" + row.value, this.loadFiles)}/>
                                                </span> :
                                                            <span title={"Delete File"}>
                                                    <FaTrashAlt style={{cursor: "pointer"}} onClick={() => window.confirm("Do you want to delete this file?") && this.apiGet("/files/delete/" + row.value, this.loadFiles)}/>
                                                </span>
                                                    }

                                                    {
                                                        row.original?.file?.type !== "fold" && <>
                                                            &nbsp;
                                                            <span title={"Move file"}>
                                                                <MyModal trigger={<FaFolderOpen style={{cursor: "pointer"}}/>}>
                                                                    {
                                                                        close => <MoveFileToFolder file={row.original?.file} folders={this.state.files.filter(({file}) => file?.type === 'fold')} closeAndReload={() => {
                                                                            this.loadFiles();
                                                                            close()
                                                                        }
                                                                        }/>
                                                                    }
                                                                </MyModal>
                                                            </span>
                                                        </>
                                                    }
                                                </>
                                            }
                                            <InsertIfNonClient>


                                                {
                                                    row.original.file.type !== "fold" &&
                                                    <span title={"Clone File"}>
                                                     &nbsp;
                                                        <FaClone style={{cursor: "pointer"}} onClick={() => {
                                                            this.apiGet("/files/clone/" + row.original.file.ID, resp => {
                                                                console.log("loading files with", resp)
                                                                this.loadFiles()
                                                            })
                                                        }
                                                        }/>
                                                </span>
                                                }
                                            </InsertIfNonClient>
                                            {
                                                row.original.file.type === "fold" ?
                                                    <div style={{display: "inline"}} title={"Download Folder"}>
                                                        &nbsp;
                                                        <DownloadFolder folder={row.original.file} parentSetState={this.setState}/>
                                                    </div> :
                                                    <div style={{display: "inline"}} title={"Download File"}>
                                                        &nbsp;
                                                        <a href={Consts.API_PREFIX + "/files/download/" + row.original.file.ID + "/" + row.original.file.name}>
                                                            <FaDownload style={{color: "#444"}}/>
                                                        </a>
                                                    </div>
                                            }


                                            <InsertIfNonClient>
                                                {
                                                    row.original.file.type === "xlsx" && this.props.latestFiling !== null && <>
                                                        &nbsp;
                                                        &nbsp;
                                                        <Link to={"/filings/new/?likeId=" + this.props.latestFiling.ID + "&fileToken=" + row.original.file.token}>do filing</Link>
                                                    </>
                                                }

                                                {
                                                    row.original.file.type === "fold" && <>
                                                        &nbsp;
                                                        &nbsp;
                                                        <span style={{cursor: "pointer"}} onClick={() => {
                                                            this.setState({shareOpen: row.original.file.ID})
                                                        }}>
                                                        <FaShareAlt/>
                                                    </span>
                                                    </>
                                                }
                                            </InsertIfNonClient>
                                        </>,
                                        maxWidth: 180,
                                        //show: this.state.editMode,
                                        sortable: false,
                                    }

                                ]
                            }
                        ]}
                        pageSize={Math.max(this.state.files.length, 2)}
                        className="-striped -highlight"
                        showPagination={false}
                        noDataText={"no file found"}
                        defaultSorted={[
                            {
                                id: "file.last_modified",
                                desc: true
                            }
                        ]}
                    />
                </div>

                <FolderShare Files_ID={this.state.shareOpen} key={this.state.shareOpen} close={this.resetShareOpen} companyName={this.props?.latestFiling?.data?.company?.name}/>

                <br/>
                {
                    /*this.state.editMode && <>
                        <Uploader
                            Cmp_key={this.key}
                            folder={this.state.showingParent_ID}
                            reloadFiles={this.loadFiles}
                            setStateParent={this.setState}
                        />
                        <form onSubmit={this.addFolder} onChange={e => this.setState({[e.target.name]: e.target.value})}>
                            <div>
                                <TextfieldInput state={this.state} tag={"newFolderName"} name={"Add New Folder"}/>
                                <button>Add</button>
                            </div>
                        </form>

                    </>*/
                }


            </LightContainer>
        );
    }

}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}


function DownloadFolder({parentSetState, folder, withText, fileName}) {
    const setStatusVar = parentSetState;
    const downloadFolder = (e) => {
        setStatusVar({loading: true})
        maxiGet(`/files/download_folder/${folder.Cmp_key}/${folder.ID}`, {setStatusVar}).then(({content}) => {
            downloadAsZip(s2ab(atob(content)), (folder?.name || fileName) + ".zip")
        })
        e.preventDefault()
    }
    return <a style={{cursor: "pointer"}} onClick={downloadFolder}>
        <FaDownload/>
        {withText && " Download all"}
    </a>
}

class FolderShare extends React.Component {
    state = {
        loading: true,
        error: "",
        links: [],
        shareType: "email"
    };

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this)
        if (this.props.Files_ID > 0) {
            this.apiGet("/files/get_links/" + this.props.Files_ID, resp => this.setState({loading: false, links: resp.shareTokens}))
        }
    }

    render() {
        const typeMapping = {email: "E-Mailbox", upload: "Send Files Only Link", read: "Read Only Link"};
        if (!this.props.Files_ID > 0) {
            return null
        }
        return <Popup
            className={"popup-modal"}
            open={this.props.Files_ID > 0}
            onClose={() => {
                this.props.close()
            }}
            closeOnDocumentClick={true}
            modal>
            {close => <LightContainer name={"Links for this folder"}>
                <Loader loading={this.state.loading}/>
                <Status type={"error"} text={this.state.error}/>
                <ReactTable
                    data={this.state.links}
                    columns={[
                        {

                            columns: [
                                {
                                    Header: "Link",
                                    Cell: row => {
                                        const l = row.original
                                        let link = null;
                                        switch (l.type) {
                                            case "email":
                                                const email = `${this.props.companyName.replaceAll(".", "").replaceAll(" ", "_").replaceAll("@", "_").replaceAll("/", "").replaceAll("&", "").replaceAll("__", "_") || "transfer"}.${l.token}@vatomator.com`;
                                                link = <a href={"mailto:" + email}>{email}</a>;
                                                break;
                                            default:
                                                const url = Consts.f("http://localhost:3006", "https://vatomator.com") + "/files/" + l.token;
                                                link = <a href={url}>{url}</a>;
                                        }
                                        return link
                                    }
                                },
                                {
                                    Header: "Type",
                                    accessor: "type",
                                    Cell: row => typeMapping[row.value],
                                    maxWidth: 150,
                                },
                                {
                                    Header: "Creation",
                                    accessor: "creation_time",
                                    Cell: row => dateFormatTime(row.value * 1000),
                                    maxWidth: 140,
                                },

                                {
                                    Header: "Delete",
                                    maxWidth: 50,
                                    Cell: row => <em onClick={() => {
                                        this.apiGet("/files/del_link/" + row.original.ID, resp => {
                                            this.setState({links: this.state.links.filter(a => a.ID !== row.original.ID)})
                                        })
                                    }
                                    }><FaTrashAlt/></em>
                                }
                            ]
                        }]}

                    pageSize={this.state.links.length}
                    className="-striped -highlight"
                    showPagination={false}
                    noDataText={"no file found"}
                    defaultSorted={[
                        {
                            id: "name",
                            desc: false
                        }
                    ]}

                />
                {
                    this.state.links.length === 0 &&
                    <>No link add yet to this folder</>
                }
                <br/>
                <FormContext.Provider value={{state: this.state}}>
                    <form style={{marginTop: "-8px", marginBottom: "-10px"}} onSubmit={(e) => e.preventDefault()} onChange={(e) => {
                        this.setState({[e.target.name]: e.target.value});

                        //this.apiGet(`/user/${row.original.ID}/update_role_to/${e.target.value}`)
                    }}
                    >
                        <SelectField name={""} tag={"shareType"} selectives={[
                            ["email", "E-Mailbox"],
                            ["upload", "Send Files Only Link"],
                            ["read", "Read Only Link"],
                        ]}/>

                        <button onClick={() => {
                            this.setState({loading: true})
                            this.apiGet("/files/add_link/" + this.props.Files_ID + "/" + this.state.shareType, resp => {
                                this.setState({loading: false, links: resp.shareTokens, error: ""})
                            })
                        }}>Add Link
                        </button>

                    </form>
                </FormContext.Provider>

            </LightContainer>}
        </Popup>
    }
}


export default FilesList;
