import {SelectField, TextfieldInput} from "../../core/input_fields";
import {CredentialsSelect} from "../filing_gb";
import React from "react";
import {FormContext} from "../../core/form_context";
import {SteuernummerInput} from "./filing_de";

class DEVATReturnCompany extends React.Component {
    static contextType = FormContext;
    render() {
        const {report} = this.props;

        return <>
            <>

                {this.context.state.fillIn !== undefined && this.context.state.fillIn.length > 0 && this.context.state.company !== undefined &&
                <div>do you mean? {[this.context.state.fillIn.map(a => {
                    return [<em href={null} onClick={() => {
                        this.context.fillLoadedData({}, a)
                    }}>{a.company.name}</em>, <span>, </span>]
                })]}</div>
                }
                <TextfieldInput name="Company Name" tag="company_name" ph={"Example Ltd"}/>
                {
                    !report.startsWith("manual") && <>
                        <TextfieldInput name={"Country"} tag={"company_country"} ph={"UK"}/>
                        <TextfieldInput name="Street" tag="company_street" ph={"Musterstraße"}/>
                        <TextfieldInput name="House Number" tag="company_houseNumber" ph={123}/>
                        <TextfieldInput name="Post Code" tag="company_postcode" ph={12345}/>
                        <TextfieldInput name="City" tag="company_city" ph={"Musterstadt"}/>
                    </>
                }

                {/*<TextfieldInput name="Prename" tag="company_prename"  ph={"Max"}/>
                            <TextfieldInput name="Lastname" tag="company_lastname"  ph={"Mustermann"}/>*/}
                <TextfieldInput name="Note (optional)" tag="company_note" ph={"ref nr 1234"}/>

                <SteuernummerInput state={this.context.state} updateParent={this.context.updateState} setStateParent={this.context.setState}/>
            </>
            {
                !report.startsWith("manual") &&
                <CredentialsSelect/>
            }
        </>
    }
}


class DEVATReturn extends React.Component {
    static contextType = FormContext;

    render() {
        const state = this.context.state;
        return <>

            {/* <SelectField name="Authority" tag="vr_authority" type={"select2"}  selectives={finanzamter}   updateParent={this.updateState}/>*/}
            <SelectField name={"Period"} tag={"specific_period"} demandSelect
                         selectives={[
                             ["01", "January"],
                             ["02", "February"],
                             ["03", "March"],
                             ["04", "April"],
                             ["05", "May"],
                             ["06", "June"],
                             ["07", "July"],
                             ["08", "August"],
                             ["09", "September"],
                             ["10", "October"],
                             ["11", "November"],
                             ["12", "December"],
                             ["41", "Quarter I"],
                             ["42", "Quarter II"],
                             ["43", "Quarter III"],
                             ["44", "Quarter IV"],
                             ["yf", "Yearly"],
                         ]}/>

            <br/>
            <label>
                <span>
                    Filing Extension
                </span>
                <label className={"switch"}>
                    <input type="checkbox" name={"specific_extension"} checked={
                        state.specific !== undefined && state.specific.extension !== undefined && state.specific.extension === 1 ? "checked" : ""
                    }/>
                    <span className="slider"/>
                </label>
            </label>
        </>
    }
}

export {DEVATReturn, DEVATReturnCompany};
