import RegistrationMatrix from "./registration_matrix";
import React, {useContext, useState} from "react";
import {CheckboxInput, DateInput, EditorInput, FileUpload, LightContainer, Loader, SelectfieldInput, TextareaInput, TextfieldInput} from "../core/input_fields";
import {FormContext} from "../core/form_context";
import Consts from "../core/consts";
import "./registration.sass"
import JSONPretty from "react-json-pretty";
import Popup from "reactjs-popup";
import {FaPlusCircle} from "react-icons/fa";
import Uploader from "../files/upload";
import branding from "../core/branding";
import Status from "../core/status";
import {maxiPost} from "../core/maxios";

function InputWrapper({type, tag, Field, ID}) {
    const {state, setState, updateState} = useContext(FormContext)
    if (typeof type === 'string' && type.startsWith("select")) {
        if (type.startsWith("select(")) {
            const selects = type.replace("select(", "").replace(/\)$/, "").split("|")
            return <SelectfieldInput noLabel type={"reactselect"} tag={tag} selectives={selects.map(a => ({value: a, label: a}))}/>
        }

        const selectives = Consts.countries.filter(([cc, name]) => Consts.euCountries.includes(cc) === (type === "selectEuCountries")).map(([cc, name]) => ({label: name, value: cc}))
        return <SelectfieldInput inline noLabel type={"reactselect"} tag={tag} width={Math.min(window.innerWidth - 50, 500)} multiple selectives={selectives}/>

    }

    if (type === "doc") {

        return <>&nbsp;<FileUpload forceAllowWrite tag={tag} onUpload={({file_name}) => updateState("matrix", ID, file_name)}/></>
    }
    if (type === "checkbox") {
        return <CheckboxInput tag={tag}/>
    }
    if (type === "text") {
        return <TextfieldInput tag={tag} forceAllowWrite style={{width: "300px"}}/>
    }
    if (type === "date") {
        return <DateInput tag={tag}/>
    }

    return null;
}

/*
<form onChange={e => {
        e.preventDefault();
        e.persist()
        const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        console.log(value)
        setLocalState(a => ({...a, [e.target.name.split("_")[1]]: value}))
        return false;
    }
    }>
 */
function InformAccountManagerAboutCompletion({type}) {
    //const [localState, setLocalState] = useState({})
    const {state} = useContext(FormContext);

    const [{error, success, loading}, setStatusVar] = useState({});

    return <>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>
        <TextareaInput tag="ignore_note" name={"Note to " + branding.name}/>
        {
            type==="accountManager" && <CheckboxInput name={"I am confirming the accuracy of the inputted data."} tag={"ignore_confirm"} labelStyle={{width: 300, maxWidth: "100%"}}/>
        }<br/>
        <button onClick={(e) => {
            e.preventDefault()
            if (loading) return false
            if (type==="accountManager" && !state.ignore?.confirm) {
                alert("Please first confirm the accuracy of the data.")
                return false;
            }
            maxiPost("/vat_registration/inform_" + type, {"note": state.ignore?.note, "filing_ID": state.id}, {setStatusVar}).then(resp => setStatusVar({success: resp.message}))

            return false;
        }
        }>inform {branding.name}</button>

        <Loader loading={loading}/>
    </>


}


export default function NewRegistration({countryName}) {
    const {state, setState} = useContext(FormContext)
    return <LightContainer name={"VATRegistration"} hideHeading visible>

        <h2>Instructions for VAT Registration</h2>
        {
            state.write_allowed || !state.id ? <EditorInput name={""} tag={"matrix_instructions"}/> : <div dangerouslySetInnerHTML={{__html: state.matrix?.instructions || ""}}/>
        }
        {
            Object.entries(RegistrationMatrix)
                .filter(([key, countries]) => (!!countries[countryName] && !["companyName"].includes(countries["ID"])) || ["heading", "subheading", "inform"].includes(countries.type))
                .map(([key, countries], i) => {

                    if (countries.type === "heading") {
                        return <>
                            <h2>{countries.Field}</h2>
                            {
                                !!countries.ID && <p>
                                    <div dangerouslySetInnerHTML={{__html: countries.ID?.replace("\n", "<br>")}}/>
                                </p>
                            }
                            {
                                countries[countryName] !== "x" && !!countries[countryName] && <p>
                                    <div dangerouslySetInnerHTML={{__html: countries[countryName]?.replace("\n", "<br>")}}/>
                                </p>
                            }
                        </>
                    }
                    if (countries.type === "subheading") {
                        return <h2>{countries.Field}</h2>
                    }
                    if (countries.type === "inform") {
                        const informType=countries.Field.toLowerCase().includes("essentia")?"accountManager":"client";
                        const allowedRaw = Object.entries(RegistrationMatrix)
                            .filter(([key, countries]) => (!!countries[countryName] && !["companyName"].includes(countries["ID"])) || ["heading", "subheading", "inform"].includes(countries.type))
                            .filter(([key, countries], j) => !state.matrix[countries.ID] && countries.type !== "checkbox" && !["heading", "subheading", "inform"].includes(countries.type) && !countries.onlyShowIf && j < i)
                        const allowed = informType==='client' || allowedRaw.length===0;

                        return <>
                            <br/>

                            <Popup className={"popup-modal"} trigger={<button>Inform {informType==='client' ? 'Client': branding.name} about completion of the above form {!allowed && "(please first fill out all required fields)"}</button>} modal>
                                {close => <LightContainer name={"Inform " + (informType==='client' ? 'Client': branding.name)}>
                                    {
                                        !allowed && "Please first fill out all required fields"
                                    }
                                    {
                                        allowed && <>
                                            <InformAccountManagerAboutCompletion type={countries.Field.toLowerCase().includes("essentia") ? "accountManager" : "client"}/>
                                        </>
                                    }
                                </LightContainer>}
                            </Popup>
                            &nbsp;not filled out: {allowedRaw.length}
                        </>
                    }

                    if (!!countries.onlyShowIf && (!state.matrix || !state.matrix[countries.onlyShowIf])) {
                        return null;
                    }


                    return <ul>
                        <li>
                            {key}{
                            countries[countryName] !== "x" && countries[countryName].length < 40 && <> ({countries[countryName]})</>
                        }{
                            //countries[countryName] !== "x" && countries[countryName].length >= 0 && <InfoTooltip>{countries[countryName]})</InfoTooltip>
                        }:&nbsp;
                            <InputWrapper tag={"matrix_" + countries["ID"]}  {...countries}/>
                        </li>
                    </ul>
                })
        }
        <br/>
        <h2>General Data (filled by account manager)</h2>
    </LightContainer>
}
