import React from 'react';
import Consts from "../core/consts"
import axios from "axios"
import {UserContext} from "./UserContext";
import Popup from "reactjs-popup";
import {LightContainer} from "../core/input_fields";
import {FaArrowRight} from "react-icons/fa";
import {apiGet} from "../core/api";
import Status from "../core/status";
import {deleteUsersCache} from "../core/core";

class CheckLogin extends React.Component {
    static contextType = UserContext;
    state = {};

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        const pn = this.props.location ? this.props.location.pathname || "" : "";
        if (pn.substr(0, 11) !== "/user/login"
            && pn.substr(0, 15) !== "/user/changepw/"
            && pn.substr(0, 6) !== "/files"
            && pn.substr(0, 11) !== "/calculator"
            && pn.substr(0, 16) !== "/brexitdiagnosis"
        ) {
            axios.get(Consts.API_PREFIX + "/user/status?" + (this.props.forceChange ? "forceChange" : ""), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.status === "error") {

                        localStorage.setItem("redir", pn)

                        //window.location.href =  "/user/login"
                    } else {

                        if (response.data.status !== "logged_in") {
                            if (response.data.status === "select_environment_necessary") {
                                this.setState({...response.data})
                            } else {
                                localStorage.setItem("redir", pn);

                                window.location.href = "/user/login"
                            }

                        } else {
                            props.addUserFromStatus(response.data);
                            console.log("logged in as ", response.data.Email)
                        }
                    }

                })
                .catch((error) => {
                    console.log("error", error);

                    localStorage.setItem("redir", pn)
                    //window.location.href =  "/user/login"

                });
        }

    }

    render() {
        if (!this.state.environments) {
            return null
        }
        return <Popup modal open={true} onClose={this.props.onClose}>
            <LightContainer name={""} style={{padding: "20px"}}>
                <Status text={this.state.error} type={"error"}/>
                <h1 style={{color: "#444"}}>Please select your enviroment{this.props.forceChange ? "" : " first"}!</h1>
                <ul>
                    {
                        this.state.environments
                            .sort((a, b) => a.name < b.name ? -1 : 1)
                            .map(e => <><em onClick={() => {
                                this.apiGet("/user/select_env/" + e.ID, resp => {
                                    deleteUsersCache()
                                    window.location.reload()
                                })
                            }}>{e.name} <FaArrowRight/></em><br/></>)
                    }
                    <br/>
                </ul>
            </LightContainer>
        </Popup>
    }

}

export default CheckLogin;
