import React from "react";
import {Container, LightContainer, Loader, SelectField, TextareaInput,} from "../core/input_fields";
import {FormContext, FormContextWrapper} from "../core/form_context";
import {apiGet, apiPost} from "../core/api";
import {getUsers} from "../core/core";
import Status from "../core/status";
import ReactTable from "react-table";
import {dateFormatTime} from "./filing_support";
import Popup from "reactjs-popup";
import {UserContext} from "../user/UserContext";
import {FaCircle, FaTrashAlt} from "react-icons/fa";

export default function ReviewContainer({collaborators, children}) {
    return <Container name={"Review Process"}>
        {children}
        <ReviewerSelection {...{collaborators}}/>
    </Container>
}

class ReviewerSelection extends React.Component {
    state = {
        reviewer: [[]],
        reviewerSelect: {},
        selectUsers: []
    };
    setState = this.setState.bind(this);
    apiGet = apiGet.bind(this)
    apiPost = apiPost.bind(this)

    updateState = (parent, child, value) => {
        if (child === "") {
            this.setState({[parent]: value});
        } else {
            this.setState((prevState, props) => ({[parent]: {...prevState[parent], [child]: value}}));
        }
        if (parent === "reviewerSelect") {
            const index = parseInt(child)
            this.updateReviewer([
                ...this.state.reviewer.slice(0, index),
                (value || []).map(a => a.value),
                ...this.state.reviewer.slice(index + 1)
            ])
        }

    };
    updateReviewer = r => {
        this.setState({reviewer: r})
        this.context.updateState("review", "users", r)
    }
    static contextType = FormContext;
    componentDidMount = async () => {

        const users = await getUsers(this.apiGet, this.setState)
        const reviewer = this.context.state?.review?.users || [[]]
        const userMap = users.reduce((obj, curr) => ({...obj, [curr.ID]: curr}), {})

        this.setState({
            reviewer,
            reviewerSelect: reviewer.reduce((obj, curr, i) => ({...obj, [i]: curr.map(id => ({label: userMap[id]?.name + " (" + userMap[id]?.email + ")", value: id}))}), {})
        })
    }

    render() {
        const {reviewer, selectUsers} = this.state;
        const reviewer_IDs = reviewer.reduce((obj, curr) => ([...obj, ...curr]), []);
        if (!this.context.state.id > 0) {
            return "Please first create the filing."
        }
        return <>
            <FormContextWrapper value={{state: this.state, setState: this.setState, updateState: this.updateState}} onSubmit={e => e.preventDefault()}>
                <h2>Select the Reviewers</h2>

                <div>
                    {
                        reviewer.map((r, i) => <div>
                            <SelectField
                                name={`Level ${(i + 1)} Reviewers`}
                                tag={"reviewerSelect_" + i}
                                type={"reactselect"}
                                multiple
                                selectives={selectUsers
                                    .filter(u => u.role > 30 || this.props.collaborators?.includes(u.ID))
                                    .map(u => ({value: u.ID, label: u.name + " (" + u.email + ")"}))}
                                width={700}
                            />
                            {
                                i === reviewer.length - 1 && <em onClick={() => {
                                    window.confirm("Remove this reviewer?") && this.updateReviewer([
                                        ...this.state.reviewer.slice(0, i),
                                    ])
                                }
                                }><FaTrashAlt/></em>
                            }

                        </div>)
                    }
                    {
                        !this.context.state?.review?.started && <button onClick={() => {
                            this.updateReviewer([...reviewer, []])
                        }}>Add another Layer
                        </button>
                    }
                </div>


            </FormContextWrapper>

            <SelectField
                name={`Final Approval Notification`}
                tag={"approval_notification"}
                type={"reactselect"}
                multiple
                selectives={selectUsers
                    .filter(u => u.role > 30)
                    .map(u => ({value: u.ID, label: u.name + " (" + u.email + ")"}))}
                width={700}
            />
            <br/>

            <Comments reviewer={reviewer} reviewer_IDs={reviewer_IDs} Flg_ID={this.context.state.id}/>
        </>
    }
}

class Comments extends React.Component {
    state = {
        loading: true,
        new: {
            text: ""
        },
        comments: []
    }
    apiGet = apiGet.bind(this)
    apiPost = apiPost.bind(this)
    setState = this.setState.bind(this);
    componentDidMount = () => {
        this.loadComments()
    }
    loadComments = () => {
        this.apiGet("/review/list_comments/" + this.props.Flg_ID, resp => this.setState({comments: resp.comments, loading: false}))
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({loading: true})
        this.apiPost("/review/add_comment/" + this.props.Flg_ID, this.state.new, resp => {
            this.setState({comments: resp.comments, loading: false, new: {text: ""}})
        })
    }
    static contextType = FormContext;

    render() {
        const {comments, loading} = this.state
        const {reviewer_IDs, reviewer} = this.props;
        const highestApprovedLevel = comments
            .filter(c => c.status === 1)
            .sort((a, b) => a.time - b.time)
            .reduce((obj, curr) => curr.level || obj, 0)

        const currentApprovers = reviewer[highestApprovedLevel] || [];

        const approvalStarter = comments
            .filter(c => c.status === 2 && c.level === 1)
            .reduce((obj, curr) => obj || curr, null)
        return <>
            {
                !this.context.state?.review?.started && reviewer_IDs.length > 0 && <>
                    <StartReviewProcess {...{reviewer, loadComments: this.loadComments}}/>
                </>
            }
            {
                this.context.state?.review?.started && <>
                    {/*
                        Consts.f(true, false) && <button onClick={() => {
                            this.context.updateState("review", "started", false)
                        }
                        }>Reset the Approval Process</button>*/
                    }
                    &nbsp;
                    <UserContext.Consumer>
                        {
                            context => <>
                                {currentApprovers.includes(context.user.ID) && <ApproveOrDisapprove level={highestApprovedLevel + 1} loadComments={this.loadComments}/>}
                                {
                                    (approvalStarter || {}).Usr_ID === context.user.ID && (
                                        highestApprovedLevel === reviewer.filter(a => a.length > 0).length ?
                                            <Status type={"success"} text={"The filing has been approved and is ready for submission "}/> :
                                            <Status type={"warning"} text={"The filing has not yet been fully approved."}/>
                                    )
                                }

                            </>

                        }
                    </UserContext.Consumer>
                </>
            }


            <div>
                <Status type={"error"} text={this.state.error}/>
                <h2>Protocol</h2>
                <Loader loading={loading}/>
                {
                    comments && <ReactTable
                        data={comments}
                        pageSize={Math.max(comments.length, 2)}
                        className="-striped -highlight"
                        showPagination={false}
                        noDataText={"no data found"}
                        defaultSorted={[
                            {
                                id: "time",
                                desc: "true"
                            }
                        ]}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Time",
                                        accessor: "time",
                                        maxWidth: 200,
                                        Cell: ({value}) => dateFormatTime(value * 1000),
                                    },
                                    {
                                        Header: "Level",
                                        accessor: "level",
                                        maxWidth: 100,
                                        Cell: ({value, original}) => value && "level " + (value),
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "status",
                                        maxWidth: 170,
                                        Cell: ({value, original}) => ([<><FaCircle style={{marginBottom: "-2px", color: "#FF0000"}}/> disapproved</>, <><FaCircle style={{marginBottom: "-2px", color: "#70FF70"}}/> approved</>])[value] || "",
                                    },
                                    {
                                        Header: "User",
                                        accessor: "user.name",
                                        maxWidth: 300,
                                        Cell: ({value, original}) => (value) + " (" + original.user.email + ")",
                                    },
                                    {
                                        Header: "Text",
                                        accessor: "comment",
                                        Cell: ({value, original}) => <em onClick={() => this.setState({
                                            modalContent: <div style={{whiteSpace: "normal"}}>
                                                {original.user.name} ({original.user.email}), {dateFormatTime(original.time * 1000)}:<br/>
                                                <div dangerouslySetInnerHTML={{__html: value.replace("\n", "<br>")}}/>
                                            </div>
                                        })}>{stripHTML(value).slice(0, 100) + (value.length > 100 ? "..." : "")}</em>,
                                    }
                                ]
                            }
                        ]}
                    />
                }<br/>
                <Popup
                    className={"popup-modal"}
                    modal
                    open={!!this.state.modalContent}
                    onClose={() => {
                        this.setState({modalContent: null})
                    }}
                    closeOnDocumentClick={true}
                >
                    {this.state.modalContent}
                </Popup>
                <Container name={"Add a comment to this Filing"}>
                    <h2></h2>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                        <TextareaInput name={"Text"} tag={"new_text"}/>
                        <button>Add Comment</button>
                    </FormContextWrapper>
                </Container>
            </div>
        </>
    }
}

function stripHTML(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

class ApproveOrDisapprove extends React.Component {
    static contextType = FormContext;
    state = {new: {message: ""}, loading: false}
    setState = this.setState.bind(this)
    apiPost = apiPost.bind(this);
    handleSubmit = close => e => {
        const {loadComments} = this.props;
        e.preventDefault()
        this.setState({loading: true})
        this.apiPost("/review/disapprove/" + this.context.state.id, {level: this.props.level, message: this.state.new.message}, resp => {
            this.context.updateState("review", "approved", false)
            this.context.updateState("review", "started", false)
            loadComments()
            this.setState({loading: false})
            close()
        })
    }
    approve = () => {
        this.setState({loading: true})
        this.apiPost("/review/approve/" + this.context.state.id, {level: this.props.level}, _ => {
            this.props.loadComments()
            this.setState({loading: false})
        })
    }

    render() {
        return <>
            <Status type={"error"} text={this.state.error}/>
            <button onClick={this.approve}>
                Approve Level {this.props.level}
            </button>
            &nbsp;
            <Popup modal className={"popup-modal"} trigger={<button>Disapprove Level {this.props.level}</button>}>{
                close =>

                    <LightContainer name={`Disapprove Level ${this.props.level}`}>
                        <Status type={"error"} text={this.state.error}/>
                        <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit(close)}>
                            <TextareaInput name={"Add a note to the email that is being sent to the creator:"} tag={"new_message"}/>
                            <button>
                                Disapprove
                            </button>
                            <Loader loading={this.state.loading}/>
                        </FormContextWrapper>

                    </LightContainer>
            }
            </Popup>
            <Loader loading={this.state.loading}/>
        </>
    }
}

class StartReviewProcess extends React.Component {
    static contextType = FormContext;
    state = {new: {message: ""}}
    setState = this.setState.bind(this)
    apiPost = apiPost.bind(this);
    handleSubmit = close => e => {
        const {reviewer, loadComments} = this.props;
        e.preventDefault()
        this.setState({loading: true})
        this.apiPost("/review/start/" + this.context.state.id, {reviewer, message: this.state.new.message}, resp => {
            this.context.updateState("review", "started", true)
            loadComments()
            this.setState({loading: false})
            close()
        })
    }

    render() {

        return <Popup modal className={"popup-modal"} trigger={<button>Start the Review Process</button>}>{
            close =>

                <LightContainer name={"Start the Review Process"}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit(close)}>
                        <TextareaInput name={"Add a note to email that is being sent to the reviewers:"} tag={"new_message"}/>
                        <button>
                            Start
                        </button>
                        <Loader loading={this.state.loading}/>
                    </FormContextWrapper>

                </LightContainer>
        }
        </Popup>
    }
}
