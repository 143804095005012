import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios/index";
import Status from '../core/status';
import Consts from '../core/consts'
import {apiGet} from "../core/api";
import {Loader} from "../core/input_fields";


class CredentialsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            credentialExpanded: 0,
            credentials: [],
            loginStatus: null,
            error: "",
            loading: true,
        };

        this.setExpanded = this.setExpanded.bind(this);

        this.apiGet = apiGet.bind(this);

        this.apiGet("/credentials/get_list", resp => {
            this.setState({
                credentials: resp.results,
                loading: false,
                error: ""
            })
        });
    }

    setExpanded(id) {

    }

    render() {
        const numbers = this.state.credentials;
        const a = new Date();
        const inactive = numbers.filter(d => d.data.active !== true || (d.data.expires !== undefined && d.data.expires * 1000 < a.getTime() && d.data.expires > 0) || d.data.expires === undefined);
        const active = numbers.filter(d => d.data.active === true && (d.data.expires !== undefined && (d.data.expires * 1000 > a.getTime() || d.data.expires === -1)));
        return (
            <div id={"filings_list"}>

                <Link to="/credentials/add" className={'btn'}
                      style={{float: "right", color: 'white', display: "inline-block", margin: 0}}>
                    <i style={{color: "white"}} className={"fa fa-plus"}></i>&nbsp;&nbsp;
                    add new
                </Link>
                <Status type={"error"} text={this.state.error}/>


                <h1>Active Credentials</h1>
                <Loader loading={this.state.loading} div />
                <table style={{width: "100%"}}>

                    {active.length > 0 ?
                        [
                            <tr>
                                <th style={{paddingLeft: "35px"}}>Name</th>
                                <th>Type</th>
                                <th>Expiration Date</th>
                                <th>Delete</th>
                                <th>public</th>
                                <th></th>
                            </tr>
                            ,
                            active.map((f) =>
                                <ListActiveItems credential={f} key={f.ID} t={this}/>
                            )] :
                        "There is none yet"

                    }
                </table>
                <br/>
                <h1>Inactive Credentials</h1>
                <Loader loading={this.state.loading} div />
                <table  style={{width: "100%"}}>

                    {inactive.length > 0 ?
                        inactive.map((f) =>
                            <ListInactiveItems credential={f} key={f.ID} t={this}/>
                        ) :
                        "There is none yet."
                    }
                </table>
                <br/>

            </div>
        );
    }
}

class ListActiveItems extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.delete = this.delete.bind(this);
        this.state = {
            displayName: props.credential.display_name,
            InitialDisplayName: props.credential.display_name,
            public: props.credential.public,
            InitialPublic: props.credential.public,
            loading: false
        }
    }

    handleChange(event) {


        this.setState({
            [event.target.name]: event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value
        })

    }

    saveChange() {
        this.setState({loading: true});
        axios.post(Consts.API_PREFIX + "/credentials/update", {
            display_name: this.state.displayName,
            id: this.props.credential.ID,
            public: this.state.public,
        }, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        loading: false,
                        InitialDisplayName: this.state.displayName,
                        InitialPublic: this.state.public,
                    });
                    this.props.t.setState({
                        error: "",
                    })
                } else {
                    this.setState({loading: false});
                    this.props.t.setState({
                        error: response.data.message,
                    })
                }
            }).catch((e) => {
            this.setState({loading: false});
            this.props.t.setState({
                error: e.message
            })
        });
    }


    delete() {
        this.setState({loading: true});

        axios.get(Consts.API_PREFIX + "/credentials/delete/" + this.props.credential.ID)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        loading: false,
                    });
                    const credentials = this.props.t.state.credentials.filter(a => a.ID !== this.props.credential.ID);
                    this.props.t.setState({
                        credentials: credentials,
                        error: "",

                    })
                } else {
                    this.setState({loading: false});
                    this.props.t.setState({
                        error: response.data.message,
                    })
                }
            }).catch((e) => {
            this.setState({loading: false});
            this.props.t.setState({
                error: e.message
            })
        });
    }

    render() {
        const f = this.props.credential;
        //<td><i className={"fa fa-angle-right"}/> {f.data.company.name} </td>
        return [
            <tr key={f.ID} id={f.ID}>
                <td><i className={"fa fa-circle fa-xs"}/>
                    <input onChange={this.handleChange} value={this.state.displayName} name={"displayName"}/>
                    <Loader loading={this.state.loading}/>
                </td>
                <td>{f.tag.toUpperCase().split("_").join(" ")}</td>
                <td>valid till&nbsp;
                    {f.data.expires !== undefined && f.data.expires > 0 && dateFormat(new Date(f.data.expires * 1e3))}
                    {f.data.expires !== undefined && f.data.expires < 0 &&
                    <span style={{fontSize: "23px", fontWeight: "bold"}}>&#x221E;</span>}
                    {f.data.expires === undefined && <span>unknown</span>}
                </td>
                <td>
                    <em onClick={this.delete}><i className={"fa fa-trash"}/> delete</em>
                </td>


                <td>
                    {
                        f.data.user !== undefined &&
                        <span>from {f.data.user}</span>
                    }
                    {
                        f.data.user === undefined &&
                        <span>

                        <label className="switch">

                          <input type="checkbox" onChange={this.handleChange} name={"public"} checked={this.state.public === 1 ? "checked" : ""}/>
                          <span className="slider"/>
                        </label>
                    </span>
                    }
                </td>
                <td>
                    {(this.state.displayName !== this.state.InitialDisplayName || this.state.public !== this.state.InitialPublic) &&
                    <button style={{margin: 0}} onClick={this.saveChange}>save</button>
                    }
                    {!(this.state.displayName !== this.state.InitialDisplayName || this.state.public !== this.state.InitialPublic) &&
                    <button style={{margin: 0, opacity: 0}}>save</button>
                    }
                </td>
            </tr>,
            <tr></tr>]
    }
}

function ListInactiveItems(props) {
    const f = props.credential;
    const expanded = props.t.state.credentialExpanded;
    //<td><i className={"fa fa-angle-right"}/> {f.data.company.name} </td>
    return [<tr key={f.ID} onClick={() => {
        props.t.setExpanded(f.id === expanded ? 0 : f.id)
    }}>
        <td><i
            className={"fa fa-circle-o fa-xs"}/> {f.display_name}
        </td>
        <td>{f.tag.toUpperCase().split("_").join(" ")}</td>
        <td>expired
            on {f.data.expires !== undefined && dateFormat(new Date(f.data.expires * 1e3))} {f.data.expires === undefined &&
            <span>unknown</span>}</td>
        <td>
            Creator: {f.user}
        </td>
    </tr>,
        <tr></tr>]


}

/*

}
*/
function dateFormat(inp) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    }).format(inp);
}


export default CredentialsList;