class ViewConfig {
    constructor() {
        this.config = JSON.parse(localStorage.getItem('filing_config'));
        this.configHash = "";
        //console.log("lsdjf", this.config);
        this.get = this.get.bind(this);
        this.save = this.save.bind(this)
    }

    get(a, alt=undefined) {
        if (this.config !== null && this.config.hasOwnProperty(a)) {
            return this.config[a]
        }
        return alt
    }

    save(doc) {
        this.config = Object.assign(
            {},
            this.config,
            doc);
        const tmp = JSON.stringify(this.config);
        if (tmp !== this.configHash) {
            localStorage.setItem('filing_config', tmp);
            this.configHash = tmp
        }
    }
}

export default ViewConfig;