import {Container, SelectField} from "../core/input_fields";
import React from "react";
import {getUsers} from "../core/core";
import Consts from "../core/consts";
import {Link} from "react-router-dom";
import {UserContext} from "../user/UserContext";

export default class FileUploadNotification extends React.Component {
    state = {
        selectUsers: []
    }
    setState = this.setState.bind(this);
    static contextType=UserContext;
    async componentDidMount() {
        const users = await getUsers(this.apiGet, this.setState)
    }

    render() {
        const {selectUsers} = this.state;
        return <Container name={"File Upload Notification"}>
            {
                [Consts.ENV_ESSENTIA, Consts.ENV_DEMO].includes(this.context.user.EnvID) ? <>
                        Sending Notification To: {this.props.country?.data?.fileUploadNotification?.map(a => a.label).join(", ")}
                        &nbsp;(edit on <Link to={"/groupcompanies/" + this.props.GroupCompany_ID}>Companies Page</Link>)
                    </> :
                    <SelectField
                        name={`Users to Notify`}
                        tag={"company_notifyOnUpload"}
                        type={"reactselect"}
                        multiple
                        selectives={selectUsers
                            .filter(u => u.role > 30 || this.props.collaborators?.includes(u.ID))
                            .map(u => ({value: u.ID, label: u.name + " (" + u.email + ")"}))
                        }
                        width={700}
                    />
            }
            <br/>
        </Container>
    }
}
