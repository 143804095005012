import React from 'react';
import {LightContainer, Loader, SelectField, TextfieldInput} from "../core/input_fields";
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";

import ReactTable from "react-table";
import {dateFormatTime} from "../filing/filing_support";
import {UserContext} from "./UserContext";
import {FormContext} from "../core/form_context";
import {FaTrashAlt} from "react-icons/fa";


class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            users: [],
        };

        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.loadUsers()
    }

    loadUsers = () => {
        this.apiGet("/user/list", resp => {
            this.setState({
                users: resp,
                loading: false,
                error: "",
            })

        });
    };
    deleteUser = (id) => {
        this.setState({loading: true})
        this.apiPost("/user/delete", {id}, resp => {
            this.setState({
                users: resp,
                loading: false,
                error: "",
            })
        });
    }

    static contextType = UserContext;

    render() {
        const filterSize = 10
        return (

            <LightContainer name={"Users"} visible>
                <Status type={"error"} text={this.state.error}/>

                <Loader loading={this.state.loading}/>
                {
                    this.state.users.length > 0 &&
                    <ReactTable
                        data={this.state.users}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Name",
                                        accessor: "name",
                                        filterable: this.state.users.length > filterSize,
                                        maxWidth: 200,
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                        filterMethod: ({id, value: search}, row) => (row[id] ?? "").toLowerCase().includes(search.toLowerCase()),
                                        filterable: this.state.users.length > filterSize,
                                    },

                                    {
                                        id: "role",
                                        Header: "Role",
                                        accessor: "role",
                                        maxWidth: 170,
                                        filterable: this.state.users.length > filterSize,
                                        Cell: row => {
                                            let role_name;
                                            switch (true) {
                                                case row.value >= 80:
                                                    role_name = "admin";
                                                    break;
                                                case row.value <= 20:
                                                    role_name = "client";
                                                    break;
                                                default:
                                                    role_name = "normal user";
                                            }
                                            if (this.context.user.Role >= 80) {
                                                return <FormContext.Provider value={{state: {role: row.value}}}>
                                                    <form style={{marginTop: "-8px", marginBottom: "-10px"}} onChange={(e) => {
                                                        let users = this.state.users;

                                                        users[row.index].role = e.target.value;
                                                        this.setState({users: users});

                                                        this.apiGet(`/user/${row.original.ID}/update_role_to/${e.target.value}`)
                                                    }}
                                                    >
                                                        <SelectField name={""} tag={"role"} selectives={[
                                                            [10, "Client"],
                                                            [40, "Filer"],
                                                            //[40, "Subadmin"],
                                                            [80, "Admin"],
                                                        ]}/>
                                                    </form>
                                                </FormContext.Provider>
                                            }
                                            return role_name;
                                        }
                                    },

                                    {
                                        Header: "Last Login",
                                        accessor: "last_login",
                                        maxWidth: 150,
                                        Cell: row => row.value > 0 ? dateFormatTime(row.value * 1000) : "never"
                                    },
                                    {
                                        Header: "",
                                        id: "remove",
                                        maxWidth: 45,
                                        Cell: ({original}) => <FaTrashAlt style={{cursor: "pointer"}} onClick={() => window.confirm(`Do you really want to delete the user ${original.email}?`) && this.deleteUser(original.ID)}/>

                                    }

                                ]
                            }
                        ]}
                        pageSize={this.state.users.length}
                        className="-striped -highlight"
                        showPagination={false}
                        defaultSorted={[
                            {
                                id: "role",
                                desc: true
                            }
                        ]}
                    />
                }

                <br/>
                {
                    this.context.user.Role >= 80 &&
                    <UserAdd loadUsers={this.loadUsers}/>
                }


            </LightContainer>
        );
    }
}

class UserAdd extends React.Component {
    state = {
        name: "",
        email: "",
        role: 40,
        loading: false,
        success: ""
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };
    apiPost = apiPost.bind(this);


    submit = e => {
        e.preventDefault();
        this.setState({loading: true, success: ""})
        this.apiPost("/user/add", this.state, resp => {
            this.setState({loading: false, success: resp.status, error: "", name: "", email: ""})
            this.props.loadUsers();
            //this.props.loadFiles();
        })
    }

    render() {
        return <form onChange={this.handleChange} onSubmit={this.submit}>
            <LightContainer name={"Add a New User"} visible>
                <FormContext.Provider value={{
                    state: this.state, updateState: (name, child, value) => {
                        this.updateState({[name]: value})
                    }
                }}>
                    <Status type={"error"} text={this.state.error}/>
                    <Status type={"success"} text={this.state.success}/>

                    <TextfieldInput name={"Name"} tag={"name"} ph={"John Doe"}/><br/>
                    <TextfieldInput name={"Email"} tag={"email"} type={"email"} ph={"john.doe@vatomator.com"}/><br/>
                    <SelectField name={"Role"} tag={"role"} selectives={[
                        [10, "Client"],
                        [40, "Filer"],
                        //[40, "Subadmin"],
                        [80, "Admin"],
                    ]}/>

                    <br/>
                    <button>
                        add
                    </button>
                    <Loader loading={this.state.loading}/>
                </FormContext.Provider>
            </LightContainer>
        </form>

    }
}

export default UsersList;
