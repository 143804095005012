import {dateFormat, dateFormatTime, DEPeriods, formatReportName, GBPeriods, GBPeriods_date} from "./filing_support";
import FilingDetail from "./filing_detail";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {FaCircle} from "react-icons/fa";
import {UserContext} from "../user/UserContext";


function ListFilingOverviewCore({filing: f, children, t}) {
    const expanded = t.state.filingExpanded;
    if (f === undefined || f.data === undefined) {
        console.log("returing with null")
        return null;
    }
    //console.log(f)
    try {
        return <tbody key={"main" + f.ID} className={"overview"}>
        <tr key={f.ID} id={"f" + f.ID} onClick={() => {
            t.setExpanded(f.ID === expanded ? 0 : f.ID)
        }}>


            <td style={{color: f.GroupCompanyStatus === "suspended" ? "red" : ""}}>
                <i className={"fa fa-angle-" + (f.ID !== expanded ? "right" : "down")}/>&nbsp;
                {f.data.company !== undefined && f.data.company.name !== undefined && f.data.company.name.trim().length > 0 && f.data.company.name.substr(0, 45)}
                {f.data.company !== undefined && f.data.company.name !== undefined && f.data.company.name.length > 45 &&
                    <span>...</span>}
                {f.data.company !== undefined && (f.data.company.name === undefined || f.data.company.name === "") && f.data.company.vatId !== undefined && f.data.company.vatId}
                {f.data.company !== undefined && (f.data.company.name === undefined || f.data.company.name === "") && f.data.company.taxNrAT !== undefined && f.data.company.taxNrAT}
                {f.data.company !== undefined && (f.data.company.name === undefined || f.data.company.name === "") && f.data.company.vatId === undefined && f.data.company.taxNrAT === undefined &&
                    <span>No Name</span>}
                {f.data.company === undefined && <span>No Name</span>}
                {
                    !!f.data.company?.note &&
                    <span> ({f.data.company.note})</span>
                }
                {
                    (!!f.GroupCompanyData?.whitelabel && f.data.company?.note !== f.GroupCompanyData?.whitelabel) &&
                    <span> ({f.GroupCompanyData?.whitelabel})</span>
                }

                {f.GroupCompanyStatus === "suspended" && " (suspended)"}
            </td>
            <td>
                <img width={"25"} className={"flag"} height={"auto"} src={"https://uid-suche.eu/src/img/flags/" + f.data.general.country.toLowerCase().split("-")[0] + ".png"}/>
                {{AG: "Aggregated"}[f.data.general.country] || f.data.general.country} {formatReportName(f.data.general.report.replace("manual_", ""))}
            </td>
            <td>

                {
                    !(f.data.general.country === "GB" && f.data.general.report === "vat_return") && f.data.specific !== undefined && f.data.specific.period !== undefined && f.data.specific.year !== undefined &&
                    <span>  {f.data.specific.year} - {DEPeriods[f.data.specific.period] !== undefined ? DEPeriods[f.data.specific.period] : f.data.specific.period}</span>
                }
                {
                    f.data.general.country === "GB" && f.data.general.report === "vat_return" && f.data.specific !== undefined &&
                    <span> {GBPeriods_date(f.data.specific.period)} </span>
                }
                {
                    /*
                    "--" + (f.data.specific || {}).period
                //*/
                }
            </td>

            {
                children
            }


        </tr>
        </tbody>
    } catch (e) {
        console.log(e, f)
    }


}

function ListSubmittedItems({filing: f, t}) {
    const expanded = t.state.filingExpanded;
    return [
        <ListFilingOverviewCore filing={f} t={t}>
            <td>
                {
                    f.data.specific?.notRequiredToSubmit === "0" ?
                        "not required" :
                        <>
                            {
                                f.submit_time !== 0 && dateFormat(new Date(f.submit_time * 1000))
                            }
                            {
                                !!f.data.specific?.submitTime && dateFormat(new Date(f.data.specific.submitTime * 1000))
                            }
                        </>
                }
            </td>
        </ListFilingOverviewCore>,
        <tbody key={f.ID + "sub"} className={"filing_detail"}>
        {
            expanded === f.ID &&
            <FilingDetail key={f.ID + "tbody_sub"} filing={f}/>
        }
        </tbody>
    ]
}

// https://werner-zenk.de/tools/farbverlauf.php#form
const colors = [
    "#FF0000",
    "#FF0900",
    "#FF1100",
    "#FF1A00",
    "#FF2200",
    "#FF2B00",
    "#FF3400",
    "#FF3C00",
    "#FF4500",
    "#FF4D00",
    "#FF5600",
    "#FF5F00",
    "#FF6700",
    "#FF7000",
    "#FF7800",
    "#FF8100",
    "#FF8A00",
    "#FF9200",
    "#FF9B00",
    "#FFA300",
    "#FFAC00",
    "#FFB500",
    "#FFBD00",
    "#FFC600",
    "#FFCE00",
    "#FFD700",
    "#F5D900",
    "#EBDA00",
    "#E0DC00",
    "#D6DD00",
    "#CCDF00",
    "#C2E100",
    "#B8E200",
    "#ADE400",
    "#A3E500",
    "#99E700",
    "#8FE900",
    "#85EA00",
    "#7AEC00",
    "#70ED00",
    "#66EF00",
    "#5CF100",
    "#52F200",
    "#47F400",
    "#3DF500",
    "#33F700",
    "#29F900",
    "#1FFA00",
    "#14FC00",
    "#0AFD00",
    "#00FF00",
    "#05FF05",
    "#0AFF0A",
    "#0FFF0F",
    "#14FF14",
    "#1AFF1A",
    "#1FFF1F",
    "#24FF24",
    "#29FF29",
    "#2EFF2E",
    "#33FF33",
    "#38FF38",
    "#3DFF3D",
    "#42FF42",
    "#47FF47",
    "#4DFF4D",
    "#52FF52",
    "#57FF57",
    "#5CFF5C",
    "#61FF61",
    "#66FF66",
    "#6BFF6B",
    "#70FF70",
    "#75FF75",
    "#7AFF7A",
    "#80FF80",
    "#85FF85",
    "#8AFF8A",
    "#8FFF8F",
    "#94FF94",
    "#99FF99",
    "#9EFF9E",
    "#A3FFA3",
    "#A8FFA8",
    "#ADFFAD",
    "#B3FFB3",
    "#B8FFB8",
    "#BDFFBD",
    "#C2FFC2",
    "#C7FFC7",
    "#CCFFCC",
    "#D1FFD1",
    "#D6FFD6",
    "#DBFFDB",
    "#E0FFE0",
    "#E6FFE6",
    "#EBFFEB",
    "#F0FFF0",
    "#F5FFF5",
    "#FAFFFA",
    "#FFFFFF",
];

const getColor = (days, type = null) => {

    days = Math.max(0, days)


    if (type.includes("13th_directive") || type.includes("vat_refund_directive") || type.includes("vat_notice_723a")) {
        if (days <= 30) {
            return "#FF0000"
        }
        if (days <= 60) {
            return "#FF7800"
        }


        return "#14FF14"

    }

    const val = Math.exp(-0.028 * Math.pow(days, 1.2)) * 50;
    const index = 50 - Math.round(val);
    //const val = Math.exp(-0.005 * Math.pow(days, 1.4)) * 100;
    //const index = 100 - Math.round(val);
    /* console.log(days, val, index);*/
    return colors[index];
};

function ListUnsubmittedItems({filing: f, t, anyInternalDuedate}) {
    const context = useContext(UserContext);
    const expanded = t.state.filingExpanded;
    const review = f.data?.review || {};
    const myID = context?.user?.ID
    return [
        <ListFilingOverviewCore filing={f} t={t}>
            <td>
                {
                    f.duedate !== null &&
                    <>
                        <FaCircle className={f.duedateDays === 1 ? "blinking" : ""} color={getColor(f.duedateDays, f.type)} style={{marginBottom: "-2px"}}/> &nbsp;
                        {Math.abs(f.duedateDays)} day{Math.abs(f.duedateDays) !== 1 && "s"} {f.duedateDays < 0 ? "overdue" : "left"}
                    </>
                }
            </td>
            {
                anyInternalDuedate && <td>
                    {
                        !!f.iduedate &&
                        <>
                            <FaCircle className={f.iduedateDays === 1 ? "blinking" : ""} color={getColor(f.iduedateDays, f.type)} style={{marginBottom: "-2px"}}/> &nbsp;
                            {Math.abs(f.iduedateDays)} day{Math.abs(f.iduedateDays) !== 1 && "s"} {f.iduedateDays < 0 ? "overdue" : "left"}
                        </>
                    }
                </td>
            }
            <td>

                <FaCircle color={getColor((!!f.data?.fileID || (f.data?.inputFiles || [])?.length) ? 40 : 2, f.type)} style={{marginBottom: "-2px"}}/>

                {
                    //review.users?.length>0 && review.users[0]?.length>0 && review.started && <><FaCircle className={!review.approved && review.users.some(a => a?.some(b => b === myID)) ? "blinking" : ""} color={getColor(review.approved ? 100 : (f.duedateDays > 14 ? 10 : 1))} style={{marginBottom: "-2px"}}/> &nbsp;</>
                }
                {
                    /* review.users?.length>0 && review.users[0]?.length
                 }
                 {
                     review.users?.some(a => a?.some(b => b === myID))?"me":"notme"*/
                }
            </td>
        </ListFilingOverviewCore>,
        <tbody key={f.ID + "tbody3"} className={"filing_detail"}>
        {
            expanded === f.ID &&
            <FilingDetail filing={f} t={t} key={f.ID + "opendetail"} filings={t.state.filings.unsubmitted}/>
        }
        </tbody>
    ]
}

function ListUnsubmittedItems2(props) {

    const f = props.filing;
    const expanded = props.t.state.filingExpanded;
    return [
        <tbody key={f.ID + "main2"} className={"overview"}>
        <tr id={"f" + f.ID} onClick={() => {
            props.t.setExpanded(f.ID === expanded ? 0 : f.ID)
        }}>
            <td>
                <i className={"fa fa-angle-" + (f.ID !== expanded ? "right" : "down")}/>&nbsp;
                {f.data.company !== undefined && f.data.company.name !== undefined && f.data.company.name.trim().length > 0 && f.data.company.name.substr(0, 45)}
                {f.data.company !== undefined && f.data.company.name !== undefined && f.data.company.name.length > 45 &&
                    <span>...</span>}
                {f.vat_name !== null && f.vat_name !== "" && f.vat_name}
                {f.data.company !== undefined && f.data.company.name === undefined && f.vat_name === null && f.data.company.vatId !== undefined && f.data.company.vatId}
                {f.data.company !== undefined && f.data.company.name === undefined && f.data.company.taxNrAT !== undefined && f.data.company.taxNrAT}
                {f.data.company !== undefined && f.data.company.name === undefined && f.vat_name === null && f.data.company.vatId === undefined && f.data.company.taxNrAT === undefined &&
                    <span>No Name</span>}
                {f.data.company === undefined && f.vat_name === null && <span>No Name</span>}

                {f.data.company !== undefined && f.data.company.note !== undefined && f.data.company.note !== "" &&
                    <span> ({f.data.company.note})</span>}

            </td>
            <td>
                <img width={"25"} className={"flag"} height={"auto"} src={"https://uid-suche.eu/src/img/flags/" + f.data.general.country.toLowerCase() + ".png"}/>
                {{AG: "Aggregated"}[f.data.general.country] || f.data.general.country} {formatReportName(f.data.general.report.replace("manual_", ""))}
            </td>
            <td>

                {
                    !(f.data.general.country === "GB" && f.data.general.report === "vat_return") && f.data.specific !== undefined && f.data.specific.period !== undefined && f.data.specific.year !== undefined &&
                    <span>  {f.data.specific.year} - {DEPeriods[f.data.specific.period] !== undefined ? DEPeriods[f.data.specific.period] : f.data.specific.period}</span>
                }
                {
                    f.data.general.country === "GB" && f.data.specific !== undefined && (f.data.specific.period || "").length === 2 && GBPeriods[(f.data.specific.period || "").substr(2)] !== undefined &&
                    <span> {GBPeriods_date(f.data.specific.period || "")}</span>
                }
            </td>
            {/*<td>{dateFormatTime(f.data.lastSaved * 1000)}</td>*/}
            <td>
                {
                    f.duedate !== null &&
                    <>
                        <FaCircle className={f.duedateDays === 1 ? "blinking" : ""} color={getColor(f.duedateDays)} style={{marginBottom: "-2px", color: getColor(f.duedateDays)}}/> &nbsp;
                        {Math.abs(f.duedateDays)} day{Math.abs(f.duedateDays) !== 1 && "s"} {f.duedateDays < 0 ? "overdue" : "left"}
                    </>
                }
            </td>
        </tr>
        </tbody>,
        <tbody key={f.ID + "tbody3"} className={"filing_detail"}>
        {
            expanded === f.ID &&
            <FilingDetail filing={f} t={props.t} key={f.ID + "opendetail"} filings={props.t.state.filings.unsubmitted}/>
        }
        </tbody>

    ]
}


function ListOpenItems(props) {
    const f = props.filing;
    const expanded = props.t.state.filingExpanded;
    //<td><i className={"fa fa-angle-right"}/> {f.data.company.name} </td>

    return [
        <tbody className={"overview"}>
        <tr key={f.ID} id={"f" + f.ID} onClick={() => {
            props.t.setExpanded(f.ID === expanded ? 0 : f.ID)
        }}>


            <td>
                <i className={"fa fa-angle-" + (f.ID !== expanded ? "right" : "down")}/>&nbsp;
                {f.data.company !== undefined && f.data.company.name !== undefined && f.data.company.name.substr(0, 45)}
                {f.data.company !== undefined && f.data.company.name !== undefined && f.data.company.name.length > 45 &&
                    <span>...</span>}
                {f.data.company !== undefined && f.data.company.name === undefined && f.data.company.vatId !== undefined && f.data.company.vatId}
                {f.data.company !== undefined && f.data.company.name === undefined && f.data.company.vatId === undefined &&
                    <span>No Name</span>}
                {f.data.company === undefined && <span>No Name</span>}
                {f.data.company !== undefined && f.data.company.note !== undefined && f.data.company.note !== "" &&
                    <span> ({f.data.company.note})</span>}

            </td>
            <td>
                <Link to={{pathname: "/filings/new", search: "likeId=" + f.ID}}> do new filing</Link>
            </td>


        </tr>
        </tbody>
        ,
        <tbody key={f.ID + "tbody"} className={"filing_detail"}>
        {expanded === f.ID &&
            <FilingDetail key={f.ID + "tbody_sub"} filing={f}/>
        }
        </tbody>

    ]


}

export {ListSubmittedItems, ListUnsubmittedItems, ListOpenItems};
