const finanzamter = [{'id': '1010', 'text': 'Saarlouis'}, {'id': '1020', 'text': 'Merzig'}, {
    'id': '1030',
    'text': 'Neunkirchen'
}, {'id': '1040', 'text': 'Saarbrücken Am Stadtgraben'}, {
    'id': '1055',
    'text': 'Saarbrücken MainzerStr'
}, {'id': '1060', 'text': 'St. Wendel'}, {
    'id': '1070',
    'text': 'Saarbrücken Am Stadtgraben - Außenst. Sulzbach'
}, {'id': '1075', 'text': 'Homburg'}, {'id': '1085', 'text': 'Homburg - Außenst. St. Ingbert'}, {
    'id': '1090',
    'text': 'Saarbrücken Am Stadtgraben - Außenst. Völklingen'
}, {'id': '1113', 'text': 'Charlottenburg'}, {'id': '1114', 'text': 'Friedrichshain-Kreuzberg'},{'id': '1115', 'text': 'Berlin International'}, {
    'id': '1116',
    'text': 'Neukölln'
}, {'id': '1117', 'text': 'Reinickendorf'}, {'id': '1118', 'text': 'Schöneberg'}, {
    'id': '1119',
    'text': 'Spandau'
}, {'id': '1120', 'text': 'Steglitz'}, {'id': '1121', 'text': 'Tempelhof'}, {
    'id': '1123',
    'text': 'Wedding'
}, {'id': '1124', 'text': 'Wilmersdorf'}, {'id': '1125', 'text': 'Zehlendorf'}, {
    'id': '1127',
    'text': 'für Körperschaften I'
}, {'id': '1129', 'text': 'für Körperschaften III'}, {'id': '1130', 'text': 'für Körperschaften IV'}, {
    'id': '1131',
    'text': 'Prenzlauer Berg'
}, {'id': '1132', 'text': 'Lichtenberg'}, {'id': '1133', 'text': 'Marzahn-Hellersdorf'}, {
    'id': '1134',
    'text': 'Mitte/Tiergarten'
}, {'id': '1135', 'text': 'Pankow/Weißensee'}, {'id': '1136', 'text': 'Treptow-Köpenick'}, {
    'id': '1137',
    'text': 'für Körperschaften II'
}, {'id': '1138', 'text': 'für Fahndung und Strafsachen Berlin'}, {
    'id': '1191',
    'text': 'Technisches Finanzamt Berlin'
}, {'id': '2111', 'text': 'Bad Segeberg'}, {'id': '2113', 'text': 'Elmshorn'}, {
    'id': '2115',
    'text': 'Flensburg'
}, {'id': '2116', 'text': 'Dithmarschen'}, {'id': '2117', 'text': 'Nordfriesland Außenstelle Husum'}, {
    'id': '2118',
    'text': 'Itzehoe'
}, {'id': '2120', 'text': 'Kiel'}, {'id': '2121', 'text': 'Nordfriesland'}, {
    'id': '2122',
    'text': 'Lübeck'
}, {'id': '2123', 'text': 'Dithmarschen'}, {'id': '2124', 'text': 'Neumünster'}, {
    'id': '2125',
    'text': 'Ostholstein'
}, {'id': '2126', 'text': 'Plön'}, {'id': '2127', 'text': 'Ratzeburg'}, {
    'id': '2128',
    'text': 'Rendsburg'
}, {'id': '2129', 'text': 'Eckernförde-Schleswig'}, {'id': '2130', 'text': 'Stormarn'}, {
    'id': '2131',
    'text': 'Pinneberg'
}, {'id': '2132', 'text': 'Bad Segeberg Außenst. Norderstedt'}, {
    'id': '2137',
    'text': 'Zentrale Prüfungsdienste'
}, {'id': '2201', 'text': 'Hamburg für Steuererhebung in Hamburg'}, {
    'id': '2210',
    'text': 'Hamburg für Verkehrst. und Grundbesitz'
}, {'id': '2216', 'text': 'Hamburg für Verkehrst. und Grundbesitz'}, {
    'id': '2217',
    'text': 'Hamburg-Nord'
}, {'id': '2220', 'text': 'Hamburg für Verkehrst. und Grundbesitz'}, {
    'id': '2227',
    'text': 'Hamburg für Großunternehmen in Hamburg'
}, {'id': '2228', 'text': 'Hamburg für Prüfungsd. und Strafsachen'}, {
    'id': '2230',
    'text': 'Hamburg für Verkehrst. und Grundbesitz'
}, {'id': '2235', 'text': 'Hamburg für Verkehrst. und Grundbesitz'}, {
    'id': '2241',
    'text': 'Hamburg-Altona'
}, {'id': '2242', 'text': 'Hamburg-Am Tierpark'}, {'id': '2243', 'text': 'Hamburg-Barmbek-Uhlenhorst'}, {
    'id': '2244',
    'text': 'Hamburg-Ost'
}, {'id': '2245', 'text': 'Hamburg-Eimsbüttel'}, {'id': '2246', 'text': 'Hamburg-Hansa'}, {
    'id': '2247',
    'text': 'Hamburg-Harburg'
}, {'id': '2248', 'text': 'Hamburg-Mitte'}, {'id': '2249', 'text': 'Hamburg-Nord'}, {
    'id': '2250',
    'text': 'Hamburg-Oberalster'
}, {'id': '2251', 'text': 'Hamburg-Ost'}, {'id': '2311', 'text': 'Alfeld (Leine)'}, {
    'id': '2312',
    'text': 'Bad Gandersheim'
}, {'id': '2313', 'text': 'Braunschweig-Altewiekring'}, {
    'id': '2314',
    'text': 'Braunschweig-Wilhelmstr.'
}, {'id': '2315', 'text': 'Buchholz in der Nordheide'}, {'id': '2316', 'text': 'Burgdorf'}, {
    'id': '2317',
    'text': 'Celle'
}, {'id': '2318', 'text': 'Cuxhaven'}, {'id': '2319', 'text': 'Gifhorn'}, {
    'id': '2320',
    'text': 'Göttingen'
}, {'id': '2321', 'text': 'Goslar'}, {'id': '2322', 'text': 'Hameln'}, {
    'id': '2323',
    'text': 'Hannover-Land I'
}, {'id': '2324', 'text': 'Hannover-Mitte'}, {'id': '2325', 'text': 'Hannover-Nord'}, {
    'id': '2326',
    'text': 'Hannover-Süd'
}, {'id': '2327', 'text': 'Hannover-Land II'}, {'id': '2328', 'text': 'Helmstedt'}, {
    'id': '2329',
    'text': 'Herzberg am Harz'
}, {'id': '2330', 'text': 'Hildesheim'}, {'id': '2331', 'text': 'Holzminden'}, {
    'id': '2332',
    'text': 'Lüchow'
}, {'id': '2333', 'text': 'Lüneburg'}, {'id': '2334', 'text': 'Nienburg/Weser'}, {
    'id': '2335',
    'text': 'Northeim'
}, {'id': '2336', 'text': 'Osterholz-Scharmbeck'}, {'id': '2338', 'text': 'Peine'}, {
    'id': '2340',
    'text': 'Rotenburg (Wümme)'
}, {'id': '2341', 'text': 'Soltau'}, {'id': '2342', 'text': 'Hannover-Land I Außenstelle Springe'}, {
    'id': '2343',
    'text': 'Stade'
}, {'id': '2344', 'text': 'Stadthagen'}, {'id': '2345', 'text': 'Sulingen'}, {
    'id': '2346',
    'text': 'Syke'
}, {'id': '2347', 'text': 'Uelzen'}, {'id': '2348', 'text': 'Verden (Aller)'}, {
    'id': '2349',
    'text': 'Wesermünde'
}, {'id': '2350', 'text': 'Winsen (Luhe)'}, {'id': '2351', 'text': 'Wolfenbüttel'}, {
    'id': '2352',
    'text': 'Zeven'
}, {'id': '2353', 'text': 'Papenburg'}, {'id': '2354', 'text': 'Aurich'}, {
    'id': '2355',
    'text': 'Bad Bentheim'
}, {'id': '2356', 'text': 'Cloppenburg'}, {'id': '2357', 'text': 'Delmenhorst'}, {
    'id': '2358',
    'text': 'Emden'
}, {'id': '2360', 'text': 'Leer (Ostfriesland)'}, {'id': '2361', 'text': 'Lingen (Ems)'}, {
    'id': '2362',
    'text': 'Norden'
}, {'id': '2363', 'text': 'Nordenham'}, {'id': '2364', 'text': 'Oldenburg (Oldenburg)'}, {
    'id': '2365',
    'text': 'Osnabrück-Land'
}, {'id': '2366', 'text': 'Osnabrück-Stadt'}, {'id': '2367', 'text': 'Quakenbrück'}, {
    'id': '2368',
    'text': 'Vechta'
}, {'id': '2369', 'text': 'Westerstede'}, {'id': '2370', 'text': 'Wilhelmshaven'}, {
    'id': '2371',
    'text': 'Wittmund'
}, {'id': '2380', 'text': 'Braunschweig für Großbetriebsprüfung'}, {
    'id': '2381',
    'text': 'Göttingen für Großbetriebsprüfung'
}, {'id': '2382', 'text': 'Hannover für Großbetriebsprüfung'}, {
    'id': '2384',
    'text': 'Stade für Großbetriebsprüfung'
}, {'id': '2385', 'text': 'Oldenburg für Großbetriebsprüfung'}, {
    'id': '2386',
    'text': 'Osnabrück für Großbetriebsprüfung'
}, {'id': '2390', 'text': 'für Fahndung und Strafsachen Braunschweig'}, {
    'id': '2391',
    'text': 'Hannover für Fahndung und Strafsachen'
}, {'id': '2392', 'text': 'Lüneburg für Fahndung und Strafsachen'}, {
    'id': '2393',
    'text': 'Oldenburg für Fahndung und Strafsachen'
}, {'id': '2457', 'text': 'Bremerhaven Bewertung Bremen'}, {'id': '2460', 'text': 'Bremen'}, {
    'id': '2471',
    'text': 'Landeshauptkasse Bremen'
}, {'id': '2475', 'text': 'Bremerhaven'}, {'id': '2477', 'text': 'Bremerhaven Bewertung'}, {
    'id': '2478',
    'text': 'Bremen für Außenprüfung'
}, {'id': '2485', 'text': 'Bremerhaven Arbeitnehmerbereich'}, {
    'id': '2601',
    'text': 'Alsfeld-Lauterbach Verwaltungsstelle Alsfeld'
}, {'id': '2602', 'text': 'Hersfeld-Rotenburg Verwaltungsstelle Bad Hersfeld'}, {
    'id': '2603',
    'text': 'Bad Homburg v.d. Höhe'
}, {'id': '2604', 'text': 'Rheingau-Taunus Verwaltungsst. Bad Schwalbach'}, {
    'id': '2605',
    'text': 'Bensheim'
}, {'id': '2606', 'text': 'Marburg-Biedenkopf Verwaltungsstelle Biedenkopf'}, {
    'id': '2607',
    'text': 'Darmstadt'
}, {'id': '2608', 'text': 'Dieburg'}, {'id': '2609', 'text': 'Dillenburg'}, {
    'id': '2610',
    'text': 'Eschwege-Witzenhausen Verwaltungsstelle Eschwege'
}, {'id': '2611', 'text': 'Korbach-Frankenberg Verwaltungsstelle Frankenberg'}, {
    'id': '2612',
    'text': 'Frankfurt am Main II'
}, {'id': '2613', 'text': 'Frankfurt am Main I'}, {'id': '2614', 'text': 'Frankfurt am Main IV'}, {
    'id': '2615',
    'text': 'Frankfurt/M. V-Höchst Verwaltungsstelle Höchst'
}, {'id': '2616', 'text': 'Friedberg (Hessen)'}, {'id': '2617', 'text': 'Bensheim Außenstelle Fürth'}, {
    'id': '2618',
    'text': 'Fulda'
}, {'id': '2619', 'text': 'Gelnhausen'}, {'id': '2620', 'text': 'Gießen'}, {
    'id': '2621',
    'text': 'Groß-Gerau'
}, {'id': '2622', 'text': 'Hanau'}, {
    'id': '2623',
    'text': 'Kassel II-Hofgeismar Verwaltungsstelle Hofgeismar'
}, {'id': '2624', 'text': 'Schwalm-Eder Verwaltungsstelle Fritzlar'}, {'id': '2625', 'text': 'Kassel I'}, {
    'id': '2626',
    'text': 'Kassel II-Hofgeismar Verwaltungsstelle Kassel'
}, {'id': '2627', 'text': 'Korbach-Frankenberg Verwaltungsstelle Korbach'}, {
    'id': '2628',
    'text': 'Langen'
}, {'id': '2629', 'text': 'Alsfeld-Lauterbach Verwaltungsstelle Lauterbach'}, {
    'id': '2630',
    'text': 'Limburg-Weilburg Verwaltungsstelle Limburg'
}, {'id': '2631', 'text': 'Marburg-Biedenkopf Verwaltungsstelle Marburg'}, {
    'id': '2632',
    'text': 'Schwalm-Eder Verwaltungsstelle Melsungen'
}, {'id': '2633', 'text': 'Michelstadt'}, {'id': '2634', 'text': 'Nidda'}, {
    'id': '2635',
    'text': 'Offenbach am Main I'
}, {'id': '2636', 'text': 'Hersfeld-Rotenburg Verwaltungsstelle Rotenburg'}, {
    'id': '2637',
    'text': 'Rheingau-Taunus Verwaltungsstelle Rüdesheim'
}, {'id': '2638', 'text': 'Limburg-Weilburg Verwaltungsstelle Weilburg'}, {
    'id': '2639',
    'text': 'Wetzlar'
}, {'id': '2640', 'text': 'Wiesbaden I'}, {
    'id': '2641',
    'text': 'Eschwege-Witzenhausen Verwaltungsstelle Witzenhausen'
}, {'id': '2642', 'text': 'Schwalm-Eder Verwaltungsstelle Schwalmstadt'}, {
    'id': '2643',
    'text': 'Wiesbaden II'
}, {'id': '2644', 'text': 'Offenbach am Main II'}, {'id': '2645', 'text': 'Frankfurt am Main III'}, {
    'id': '2646',
    'text': 'Hofheim am Taunus'
}, {'id': '2647', 'text': 'Frankfurt/M. V-Höchst Verwaltungsstelle Frankfurt'}, {
    'id': '2701',
    'text': 'Bad Neuenahr-Ahrweiler'
}, {'id': '2702', 'text': 'Altenkirchen-Hachenburg'}, {'id': '2706', 'text': 'Bad Kreuznach'}, {
    'id': '2708',
    'text': 'Bingen-Alzey'
}, {'id': '2709', 'text': 'Idar-Oberstein'}, {'id': '2710', 'text': 'Bitburg-Prüm'}, {
    'id': '2713',
    'text': 'Landesfinanzkasse Daun'
}, {'id': '2714', 'text': 'Montabaur-Diez Aussenstelle Diez'}, {
    'id': '2718',
    'text': 'Altenkirchen-Hachenburg Aussenstelle Hachenburg'
}, {'id': '2719', 'text': 'Kaiserslautern'}, {'id': '2722', 'text': 'Koblenz'}, {
    'id': '2723',
    'text': 'Kusel-Landstuhl'
}, {'id': '2724', 'text': 'Landau'}, {'id': '2725', 'text': 'Kusel-Landstuhl Aussenstelle Landstuhl'}, {
    'id': '2726',
    'text': 'Mainz-Mitte'
}, {'id': '2727', 'text': 'Ludwigshafen'}, {'id': '2728', 'text': 'Mainz-Süd'}, {
    'id': '2729',
    'text': 'Mayen'
}, {'id': '2730', 'text': 'Montabaur-Diez'}, {'id': '2731', 'text': 'Neustadt'}, {
    'id': '2732',
    'text': 'Neuwied'
}, {'id': '2734', 'text': 'Kusel-Landstuhl für Erb/Schenk.St. Saarland'}, {
    'id': '2735',
    'text': 'Pirmasens'
}, {'id': '2736', 'text': 'Bitburg-Prüm Aussenstelle Prüm'}, {'id': '2740', 'text': 'Simmern-Zell'}, {
    'id': '2741',
    'text': 'Speyer-Germersheim'
}, {'id': '2742', 'text': 'Trier'}, {'id': '2743', 'text': 'Wittlich'}, {
    'id': '2744',
    'text': 'Worms-Kirchheimbolanden'
}, {'id': '2745', 'text': 'Simmern-Zell Aussenstelle Zell'}, {
    'id': '2801',
    'text': 'Offenburg Außenstelle Achern'
}, {'id': '2804', 'text': 'Villingen-Schwenningen Außenstelle Donaueschingen'}, {
    'id': '2805',
    'text': 'Emmendingen'
}, {'id': '2806', 'text': 'Freiburg-Stadt'}, {'id': '2807', 'text': 'Freiburg-Land'}, {
    'id': '2808',
    'text': 'Offenburg Außenstelle Kehl'
}, {'id': '2809', 'text': 'Konstanz'}, {'id': '2810', 'text': 'Lahr'}, {'id': '2811', 'text': 'Lörrach'}, {
    'id': '2812',
    'text': 'Müllheim'
}, {'id': '2813', 'text': 'Freiburg-Land Außenstelle Titisee-Neustadt'}, {
    'id': '2814',
    'text': 'Offenburg'
}, {'id': '2815', 'text': 'Rottweil Außenstelle Oberndorf'}, {
    'id': '2816',
    'text': 'Waldshut-Tiengen Außenstelle Bad Säckingen'
}, {'id': '2818', 'text': 'Singen'}, {'id': '2819', 'text': 'Rottweil'}, {
    'id': '2820',
    'text': 'Waldshut-Tiengen'
}, {'id': '2821', 'text': 'Tuttlingen'}, {'id': '2822', 'text': 'Villingen-Schwenningen'}, {
    'id': '2823',
    'text': 'Offenburg Außenstelle Wolfach'
}, {'id': '2830', 'text': 'Bruchsal'}, {'id': '2831', 'text': 'Ettlingen'}, {
    'id': '2832',
    'text': 'Heidelberg'
}, {'id': '2833', 'text': 'Baden-Baden'}, {'id': '2834', 'text': 'Karlsruhe-Durlach'}, {
    'id': '2835',
    'text': 'Karlsruhe-Stadt'
}, {'id': '2836', 'text': 'Baden-Baden Außenstelle Bühl'}, {
    'id': '2837',
    'text': 'Mannheim-Neckarstadt'
}, {'id': '2838', 'text': 'Mannheim-Stadt'}, {'id': '2839', 'text': 'Rastatt'}, {
    'id': '2840',
    'text': 'Mosbach'
}, {'id': '2841', 'text': 'Pforzheim'}, {'id': '2842', 'text': 'Freudenstadt'}, {
    'id': '2843',
    'text': 'Schwetzingen'
}, {'id': '2844', 'text': 'Sinsheim'}, {'id': '2845', 'text': 'Calw'}, {
    'id': '2846',
    'text': 'Mosbach Außenstelle Walldürn'
}, {'id': '2847', 'text': 'Weinheim'}, {'id': '2848', 'text': 'Mühlacker'}, {
    'id': '2849',
    'text': 'Pforzheim Außenstelle Neuenbürg'
}, {'id': '2850', 'text': 'Aalen'}, {'id': '2851', 'text': 'Backnang'}, {
    'id': '2852',
    'text': 'Tauberbischofsheim Außenstelle Bad Mergentheim'
}, {'id': '2853', 'text': 'Balingen'}, {'id': '2854', 'text': 'Biberach'}, {
    'id': '2855',
    'text': 'Bietigheim-Bissingen'
}, {'id': '2856', 'text': 'Böblingen'}, {'id': '2857', 'text': 'Schwäbisch Hall Außenstelle Crailsheim'}, {
    'id': '2858',
    'text': 'Ehingen'
}, {'id': '2859', 'text': 'Esslingen'}, {'id': '2861', 'text': 'Friedrichshafen'}, {
    'id': '2862',
    'text': 'Göppingen Außenstelle Geislingen'
}, {'id': '2863', 'text': 'Göppingen'}, {'id': '2864', 'text': 'Heidenheim'}, {
    'id': '2865',
    'text': 'Heilbronn'
}, {'id': '2869', 'text': 'Nürtingen Außenstelle Kirchheim'}, {'id': '2870', 'text': 'Leonberg'}, {
    'id': '2871',
    'text': 'Ludwigsburg'
}, {'id': '2874', 'text': 'Nürtingen'}, {'id': '2876', 'text': 'Öhringen'}, {
    'id': '2877',
    'text': 'Ravensburg'
}, {'id': '2878', 'text': 'Reutlingen'}, {'id': '2879', 'text': 'Biberach Außenstelle Riedlingen'}, {
    'id': '2880',
    'text': 'Tauberbischofsheim'
}, {'id': '2881', 'text': 'Sigmaringen Außenstelle Bad Saulgau'}, {'id': '2882', 'text': 'Schorndorf'}, {
    'id': '2883',
    'text': 'Schwäbisch Gmünd'
}, {'id': '2884', 'text': 'Schwäbisch Hall'}, {'id': '2885', 'text': 'Sigmaringen'}, {
    'id': '2886',
    'text': 'Tübingen'
}, {'id': '2887', 'text': 'Überlingen (Bodensee)'}, {'id': '2888', 'text': 'Ulm'}, {
    'id': '2889',
    'text': 'Bad Urach'
}, {'id': '2890', 'text': 'Waiblingen'}, {'id': '2891', 'text': 'Wangen'}, {
    'id': '2892',
    'text': 'Stuttgart IV'
}, {'id': '2893', 'text': 'Stuttgart I'}, {'id': '2895', 'text': 'Stuttgart II'}, {
    'id': '2896',
    'text': 'Stuttgart Zentrales Konzernprüfungsamt'
}, {'id': '2897', 'text': 'Stuttgart III'}, {'id': '2899', 'text': 'Stuttgart-Körpersch.'}, {
    'id': '3046',
    'text': 'Potsdam'
}, {'id': '3048', 'text': 'Brandenburg'}, {'id': '3049', 'text': 'Königs Wusterhausen'}, {
    'id': '3050',
    'text': 'Luckenwalde'
}, {'id': '3051', 'text': 'Nauen'}, {'id': '3052', 'text': 'Kyritz'}, {
    'id': '3053',
    'text': 'Oranienburg'
}, {'id': '3056', 'text': 'Cottbus'}, {'id': '3057', 'text': 'Calau'}, {
    'id': '3061',
    'text': 'Frankfurt (Oder)'
}, {'id': '3062', 'text': 'Angermünde'}, {'id': '3064', 'text': 'Strausberg'}, {
    'id': '3065',
    'text': 'Eberswalde'
}, {'id': '3102', 'text': 'Magdeburg'}, {'id': '3103', 'text': 'Genthin'}, {
    'id': '3105',
    'text': 'Haldensleben'
}, {'id': '3106', 'text': 'Salzwedel'}, {'id': '3107', 'text': 'Staßfurt'}, {
    'id': '3108',
    'text': 'Stendal'
}, {'id': '3110', 'text': 'Halle (Saale)'}, {'id': '3112', 'text': 'Merseburg'}, {
    'id': '3114',
    'text': 'Dessau-Roßlau'
}, {'id': '3115', 'text': 'Wittenberg'}, {'id': '3116', 'text': 'Bitterfeld-Wolfen'}, {
    'id': '3117',
    'text': 'Quedlinburg'
}, {'id': '3118', 'text': 'Eisleben'}, {'id': '3119', 'text': 'Naumburg'}, {
    'id': '3202',
    'text': 'Dresden-Nord'
}, {'id': '3203', 'text': 'Dresden-Süd'}, {'id': '3204', 'text': 'Bautzen'}, {
    'id': '3207',
    'text': 'Görlitz'
}, {'id': '3208', 'text': 'Löbau'}, {'id': '3209', 'text': 'Meißen'}, {'id': '3210', 'text': 'Pirna'}, {
    'id': '3213',
    'text': 'Hoyerswerda'
}, {'id': '3214', 'text': 'Chemnitz-Süd'}, {'id': '3215', 'text': 'Chemnitz-Mitte'}, {
    'id': '3217',
    'text': 'Annaberg'
}, {'id': '3218', 'text': 'Schwarzenberg'}, {'id': '3220', 'text': 'Freiberg'}, {
    'id': '3221',
    'text': 'Hohenstein-Ernstthal'
}, {'id': '3222', 'text': 'Mittweida'}, {'id': '3223', 'text': 'Plauen'}, {
    'id': '3224',
    'text': 'Stollberg'
}, {'id': '3227', 'text': 'Zwickau'}, {'id': '3228', 'text': 'Zschopau'}, {
    'id': '3231',
    'text': 'Leipzig II'
}, {'id': '3232', 'text': 'Leipzig I'}, {'id': '3236', 'text': 'Döbeln'}, {
    'id': '3237',
    'text': 'Eilenburg'
}, {'id': '3238', 'text': 'Grimma'}, {'id': '3239', 'text': 'Oschatz'}, {
    'id': '4069',
    'text': 'Neubrandenburg - RAB'
}, {'id': '4070', 'text': 'Neubrandenburg - RIA (Rentenempfänger im Ausland)'}, {
    'id': '4072',
    'text': 'Neubrandenburg'
}, {'id': '4075', 'text': 'Waren'}, {'id': '4079', 'text': 'Rostock'}, {'id': '4080', 'text': 'Wismar'}, {
    'id': '4081',
    'text': 'Ribnitz-Damgarten'
}, {'id': '4082', 'text': 'Stralsund'}, {'id': '4084', 'text': 'Greifswald'}, {
    'id': '4086',
    'text': 'Güstrow'
}, {'id': '4087', 'text': 'Hagenow'}, {'id': '4090', 'text': 'Schwerin'}, {
    'id': '4151',
    'text': 'Erfurt'
}, {'id': '4154', 'text': 'Ilmenau'}, {'id': '4155', 'text': 'Eisenach'}, {
    'id': '4156',
    'text': 'Gotha'
}, {'id': '4157', 'text': 'Mühlhausen'}, {'id': '4159', 'text': 'Sondershausen'}, {
    'id': '4161',
    'text': 'Gera'
}, {'id': '4162', 'text': 'Jena'}, {'id': '4165', 'text': 'Pößneck'}, {
    'id': '4166',
    'text': 'Altenburg'
}, {'id': '4170', 'text': 'Sonneberg'}, {'id': '4171', 'text': 'Suhl'}, {
    'id': '5101',
    'text': 'Dinslaken'
}, {'id': '5102', 'text': 'Viersen'}, {'id': '5103', 'text': 'Düsseldorf-Altstadt'}, {
    'id': '5105',
    'text': 'Düsseldorf-Nord'
}, {'id': '5106', 'text': 'Düsseldorf-Süd'}, {'id': '5107', 'text': 'Duisburg-Hamborn'}, {
    'id': '5109',
    'text': 'Duisburg-Süd'
}, {'id': '5111', 'text': 'Essen-NordOst'}, {'id': '5112', 'text': 'Essen-Süd'}, {
    'id': '5113',
    'text': 'Geldern'
}, {'id': '5114', 'text': 'Grevenbroich'}, {'id': '5115', 'text': 'Kempen'}, {
    'id': '5116',
    'text': 'Kleve'
}, {'id': '5117', 'text': 'Krefeld'}, {'id': '5119', 'text': 'Moers'}, {
    'id': '5120',
    'text': 'Mülheim an der Ruhr'
}, {'id': '5121', 'text': 'Mönchengladbach'}, {'id': '5122', 'text': 'Neuss'}, {
    'id': '5123',
    'text': 'Oberhausen-Nord'
}, {'id': '5124', 'text': 'Oberhausen-Süd'}, {'id': '5126', 'text': 'Remscheid'}, {
    'id': '5128',
    'text': 'Solingen'
}, {'id': '5130', 'text': 'Wesel'}, {'id': '5131', 'text': 'Wuppertal-Barmen'}, {
    'id': '5132',
    'text': 'Wuppertal-Elberfeld'
}, {'id': '5133', 'text': 'Düsseldorf-Mitte'}, {'id': '5134', 'text': 'Duisburg-West'}, {
    'id': '5135',
    'text': 'Hilden'
}, {'id': '5139', 'text': 'Velbert'}, {'id': '5147', 'text': 'Düsseldorf-Mettmann'}, {
    'id': '5149',
    'text': 'Rechenzentrum d. FinVerw NRW'
}, {'id': '5170', 'text': 'Düsseldorf I für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5171',
    'text': 'Düsseldorf II für Groß- und Konzernbetriebsprüfung'
}, {'id': '5172', 'text': 'Essen für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5173',
    'text': 'Krefeld für Groß- und Konzernbetriebsprüfung'
}, {'id': '5174', 'text': 'Bergisches Land für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5176',
    'text': 'Mönchengladbach für Groß- und Konzernbetriebsprüfung'
}, {'id': '5181', 'text': 'Düsseldorf f. Steuerfahndung und Steuerstrafsachen'}, {
    'id': '5182',
    'text': 'Essen f. Steuerfahndung und Steuerstrafsachen'
}, {'id': '5183', 'text': 'Wuppertal f. Steuerfahndung und Steuerstrafsachen'}, {
    'id': '5201',
    'text': 'Aachen-Stadt'
}, {'id': '5202', 'text': 'Aachen-Kreis'}, {'id': '5203', 'text': 'Bergheim'}, {
    'id': '5204',
    'text': 'Bergisch Gladbach'
}, {'id': '5205', 'text': 'Bonn-Innenstadt'}, {'id': '5206', 'text': 'Bonn-Außenstadt'}, {
    'id': '5207',
    'text': 'Düren'
}, {'id': '5208', 'text': 'Erkelenz'}, {'id': '5209', 'text': 'Euskirchen'}, {
    'id': '5210',
    'text': 'Geilenkirchen'
}, {'id': '5211', 'text': 'Schleiden'}, {'id': '5212', 'text': 'Gummersbach'}, {
    'id': '5213',
    'text': 'Jülich'
}, {'id': '5214', 'text': 'Köln-Altstadt'}, {'id': '5215', 'text': 'Köln-Mitte'}, {
    'id': '5216',
    'text': 'Köln-Porz'
}, {'id': '5217', 'text': 'Köln-Nord'}, {'id': '5218', 'text': 'Köln-Ost'}, {
    'id': '5219',
    'text': 'Köln-Süd'
}, {'id': '5220', 'text': 'Siegburg'}, {'id': '5221', 'text': 'Wipperfürth'}, {
    'id': '5222',
    'text': 'Sankt Augustin'
}, {'id': '5223', 'text': 'Köln-West'}, {'id': '5224', 'text': 'Brühl'}, {
    'id': '5230',
    'text': 'Leverkusen'
}, {'id': '5270', 'text': 'Köln für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5271',
    'text': 'Aachen für Groß- und Konzernbetriebsprüfung'
}, {'id': '5272', 'text': 'Bonn für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5281',
    'text': 'Aachen f. Steuerfahndung und Steuerstrafsachen'
}, {'id': '5282', 'text': 'Bonn f. Steuerfahndung und Steuerstrafsachen'}, {
    'id': '5283',
    'text': 'Köln f. Steuerfahndung und Steuerstrafsachen'
}, {'id': '5301', 'text': 'Ahaus'}, {'id': '5302', 'text': 'Altena'}, {'id': '5303', 'text': 'Arnsberg'}, {
    'id': '5304',
    'text': 'Beckum'
}, {'id': '5305', 'text': 'Bielefeld-Innenstadt'}, {'id': '5306', 'text': 'Bochum-Mitte'}, {
    'id': '5307',
    'text': 'Borken'
}, {'id': '5308', 'text': 'Bottrop'}, {'id': '5309', 'text': 'Brilon'}, {'id': '5310', 'text': 'Bünde'}, {
    'id': '5311',
    'text': 'Steinfurt'
}, {'id': '5312', 'text': 'Coesfeld'}, {'id': '5313', 'text': 'Detmold'}, {
    'id': '5314',
    'text': 'Dortmund-West'
}, {'id': '5315', 'text': 'Dortmund-Hörde'}, {'id': '5316', 'text': 'Dortmund-Unna'}, {
    'id': '5317',
    'text': 'Dortmund-Ost'
}, {'id': '5319', 'text': 'Gelsenkirchen'}, {'id': '5321', 'text': 'Hagen'}, {
    'id': '5322',
    'text': 'Hamm'
}, {'id': '5323', 'text': 'Hattingen'}, {'id': '5324', 'text': 'Herford'}, {
    'id': '5325',
    'text': 'Herne'
}, {'id': '5326', 'text': 'Höxter'}, {'id': '5327', 'text': 'Ibbenbüren'}, {
    'id': '5328',
    'text': 'Iserlohn'
}, {'id': '5329', 'text': 'Lemgo'}, {'id': '5330', 'text': 'Lippstadt'}, {
    'id': '5331',
    'text': 'Lübbecke'
}, {'id': '5332', 'text': 'Lüdenscheid'}, {'id': '5333', 'text': 'Lüdinghausen'}, {
    'id': '5334',
    'text': 'Meschede'
}, {'id': '5335', 'text': 'Minden'}, {'id': '5336', 'text': 'Münster-Außenstadt'}, {
    'id': '5337',
    'text': 'Münster-Innenstadt'
}, {'id': '5338', 'text': 'Olpe'}, {'id': '5339', 'text': 'Paderborn'}, {
    'id': '5340',
    'text': 'Recklinghausen'
}, {'id': '5341', 'text': 'Schwelm'}, {'id': '5342', 'text': 'Siegen'}, {'id': '5343', 'text': 'Soest'}, {
    'id': '5345',
    'text': 'Warburg'
}, {'id': '5346', 'text': 'Warendorf'}, {'id': '5347', 'text': 'Wiedenbrück'}, {
    'id': '5348',
    'text': 'Witten'
}, {'id': '5349', 'text': 'Bielefeld-Außenstadt'}, {'id': '5350', 'text': 'Bochum-Süd'}, {
    'id': '5351',
    'text': 'Gütersloh'
}, {'id': '5359', 'text': 'Marl'}, {
    'id': '5371',
    'text': 'Bielefeld für Groß- und Konzernbetriebsprüfung'
}, {'id': '5372', 'text': 'Herne für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5373',
    'text': 'Detmold für Groß- und Konzernbetriebsprüfung'
}, {'id': '5374', 'text': 'Dortmund für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5375',
    'text': 'Hagen für Groß- und Konzernbetriebsprüfung'
}, {'id': '5376', 'text': 'Münster für Groß- und Konzernbetriebsprüfung'}, {
    'id': '5381',
    'text': 'Bielefeld f. Steuerfahndung und Steuerstrafsachen'
}, {'id': '5382', 'text': 'Bochum f. Steuerfahndung und Steuerstrafsachen'}, {
    'id': '5383',
    'text': 'Hagen f. Steuerfahndung und Steuerstrafsachen'
}, {'id': '5384', 'text': 'Münster f. Steuerfahndung und Steuerstrafsachen'}, {
    'id': '9101',
    'text': 'Augsburg-Stadt Arbeitnehmerbereich'
}, {'id': '9102', 'text': 'Augsburg-Land'}, {'id': '9103', 'text': 'Augsburg-Stadt'}, {
    'id': '9104',
    'text': 'Wolfratshausen - Bad Tölz'
}, {'id': '9105', 'text': 'Berchtesgaden-Laufen'}, {'id': '9106', 'text': 'Burghausen'}, {
    'id': '9107',
    'text': 'Dachau'
}, {'id': '9108', 'text': 'Deggendorf'}, {'id': '9109', 'text': 'Dillingen'}, {
    'id': '9110',
    'text': 'Dingolfing'
}, {'id': '9111', 'text': 'Nördlingen mit ASt Donauwörth'}, {'id': '9112', 'text': 'Ebersberg'}, {
    'id': '9113',
    'text': 'Eggenfelden'
}, {'id': '9114', 'text': 'Erding'}, {'id': '9115', 'text': 'Freising'}, {
    'id': '9116',
    'text': 'Bundeszentralamt für Steuern'
}, {'id': '9117', 'text': 'Fürstenfeldbruck'}, {'id': '9119', 'text': 'Garmisch-Partenkirchen'}, {
    'id': '9121',
    'text': 'Günzburg'
}, {'id': '9123', 'text': 'Kempten-Immenstadt'}, {'id': '9124', 'text': 'Ingolstadt'}, {
    'id': '9125',
    'text': 'Kaufbeuren m. ASt Füssen'
}, {'id': '9126', 'text': 'Kelheim'}, {'id': '9127', 'text': 'Kempten-Immenstadt'}, {
    'id': '9131',
    'text': 'Landsberg'
}, {'id': '9132', 'text': 'Landshut'}, {'id': '9134', 'text': 'Lindau'}, {
    'id': '9138',
    'text': 'Memmingen-Mindelheim'
}, {'id': '9139', 'text': 'Miesbach'}, {'id': '9140', 'text': 'Memmingen-Mindelheim'}, {
    'id': '9141',
    'text': 'Mühldorf'
}, {'id': '9142', 'text': 'München Bewertung des Grundbesitzes'}, {
    'id': '9143',
    'text': 'München Körpersch./PersonenGes'
}, {'id': '9144', 'text': 'München Selbständige/Gewerbe/VuV'}, {
    'id': '9145',
    'text': 'München Selbständige/Gewerbe/VuV'
}, {'id': '9146', 'text': 'München Selbständige/Gewerbe/VuV'}, {
    'id': '9147',
    'text': 'München Selbständige/Gewerbe/VuV'
}, {'id': '9148', 'text': 'München Selbständige/Gewerbe/VuV'}, {
    'id': '9149',
    'text': 'München Erhebung'
}, {'id': '9151', 'text': 'Neu-Ulm'}, {'id': '9152', 'text': 'Nördlingen mit ASt Donauwörth'}, {
    'id': '9153',
    'text': 'Passau mit Außenstellen'
}, {'id': '9154', 'text': 'Pfaffenhofen'}, {'id': '9156', 'text': 'Rosenheim m. ASt Wasserburg'}, {
    'id': '9157',
    'text': 'Grafenau'
}, {'id': '9159', 'text': 'Schrobenhausen m. ASt Neuburg'}, {'id': '9161', 'text': 'Starnberg'}, {
    'id': '9162',
    'text': 'Straubing'
}, {'id': '9163', 'text': 'Traunstein'}, {'id': '9168', 'text': 'Weilheim-Schongau'}, {
    'id': '9169',
    'text': 'Wolfratshausen - Bad Tölz'
}, {'id': '9170', 'text': 'Zwiesel m. ASt Viechtach'}, {'id': '9171', 'text': 'Eichstätt'}, {
    'id': '9181',
    'text': 'München Arbeitn./Renten/Hausgem'
}, {'id': '9182', 'text': 'München AN/Renten/HG/Beschränkt Stpfl.'}, {
    'id': '9183',
    'text': 'München Arbeitn./Renten/Hausgem'
}, {'id': '9184', 'text': 'München Arbeitn./Renten/Hausgem'}, {
    'id': '9185',
    'text': 'München Arbeitn./Renten/Hausgem'
}, {'id': '9187', 'text': 'München Körperschaften'}, {'id': '9188', 'text': 'München Betriebsprüfung'}, {
    'id': '9189',
    'text': 'München Erhebung'
}, {'id': '9201', 'text': 'Amberg'}, {
    'id': '9202',
    'text': 'Obernburg am Main mit Außenstelle Amorbach'
}, {'id': '9203', 'text': 'Ansbach mit Außenstellen'}, {'id': '9204', 'text': 'Aschaffenburg'}, {
    'id': '9205',
    'text': 'Bad Kissingen'
}, {'id': '9206', 'text': 'Bad Neustadt a.d.S.'}, {'id': '9207', 'text': 'Bamberg'}, {
    'id': '9208',
    'text': 'Bayreuth'
}, {'id': '9211', 'text': 'Cham mit Außenstellen'}, {'id': '9212', 'text': 'Coburg'}, {
    'id': '9216',
    'text': 'Erlangen'
}, {'id': '9217', 'text': 'Forchheim'}, {'id': '9218', 'text': 'Fürth'}, {
    'id': '9220',
    'text': 'Gunzenhausen'
}, {'id': '9221', 'text': 'Hersbruck'}, {'id': '9222', 'text': 'Hilpoltstein'}, {
    'id': '9223',
    'text': 'Hof mit Außenstellen'
}, {'id': '9227', 'text': 'Kitzingen'}, {'id': '9228', 'text': 'Kronach'}, {
    'id': '9229',
    'text': 'Kulmbach'
}, {'id': '9230', 'text': 'Lichtenfels'}, {'id': '9231', 'text': 'Lohr a. Main mit Außenstellen'}, {
    'id': '9232',
    'text': 'Lohr a. Main mit Außenstellen'
}, {'id': '9235', 'text': 'Neumarkt i.d.Opf.'}, {'id': '9238', 'text': 'Nürnberg-Nord'}, {
    'id': '9240',
    'text': 'Nürnberg-Süd'
}, {'id': '9241', 'text': 'Nürnberg-Zentral'}, {'id': '9244', 'text': 'Regensburg'}, {
    'id': '9247',
    'text': 'Schwabach'
}, {'id': '9248', 'text': 'Schwandorf mit Außenstelle Neunburg v. W.'}, {
    'id': '9249',
    'text': 'Schweinfurt'
}, {'id': '9252', 'text': 'Uffenheim'}, {'id': '9254', 'text': 'Waldsassen'}, {
    'id': '9255',
    'text': 'Weiden i.d.Opf.'
}, {'id': '9257', 'text': 'Würzburg mit Außenstelle Ochsenfurt'}, {
    'id': '9258',
    'text': 'Wunsiedel mit Außenstelle Selb'
}, {'id': '9259', 'text': 'Zeil am Main mit Außenstelle Ebern'}]
const id2finanzamt = {
    '1010': 'Saarlouis',
    '1020': 'Merzig',
    '1030': 'Neunkirchen',
    '1040': 'Saarbrücken Am Stadtgraben',
    '1055': 'Saarbrücken MainzerStr',
    '1060': 'St. Wendel',
    '1070': 'Saarbrücken Am Stadtgraben - Außenst. Sulzbach',
    '1075': 'Homburg',
    '1085': 'Homburg - Außenst. St. Ingbert',
    '1090': 'Saarbrücken Am Stadtgraben - Außenst. Völklingen',
    '1113': 'Charlottenburg',
    '1114': 'Friedrichshain-Kreuzberg',
    '1115': 'Berlin International',
    '1116': 'Neukölln',
    '1117': 'Reinickendorf',
    '1118': 'Schöneberg',
    '1119': 'Spandau',
    '1120': 'Steglitz',
    '1121': 'Tempelhof',
    '1123': 'Wedding',
    '1124': 'Wilmersdorf',
    '1125': 'Zehlendorf',
    '1127': 'für Körperschaften I',
    '1129': 'für Körperschaften III',
    '1130': 'für Körperschaften IV',
    '1131': 'Prenzlauer Berg',
    '1132': 'Lichtenberg',
    '1133': 'Marzahn-Hellersdorf',
    '1134': 'Mitte/Tiergarten',
    '1135': 'Pankow/Weißensee',
    '1136': 'Treptow-Köpenick',
    '1137': 'für Körperschaften II',
    '1138': 'für Fahndung und Strafsachen Berlin',
    '1191': 'Technisches Finanzamt Berlin',
    '2111': 'Bad Segeberg',
    '2113': 'Elmshorn',
    '2115': 'Flensburg',
    '2116': 'Dithmarschen',
    '2117': 'Nordfriesland Außenstelle Husum',
    '2118': 'Itzehoe',
    '2120': 'Kiel',
    '2121': 'Nordfriesland',
    '2122': 'Lübeck',
    '2123': 'Dithmarschen',
    '2124': 'Neumünster',
    '2125': 'Ostholstein',
    '2126': 'Plön',
    '2127': 'Ratzeburg',
    '2128': 'Rendsburg',
    '2129': 'Eckernförde-Schleswig',
    '2130': 'Stormarn',
    '2131': 'Pinneberg',
    '2132': 'Bad Segeberg Außenst. Norderstedt',
    '2137': 'Zentrale Prüfungsdienste',
    '2201': 'Hamburg für Steuererhebung in Hamburg',
    '2210': 'Hamburg für Verkehrst. und Grundbesitz',
    '2216': 'Hamburg für Verkehrst. und Grundbesitz',
    '2217': 'Hamburg-Nord',
    '2220': 'Hamburg für Verkehrst. und Grundbesitz',
    '2227': 'Hamburg für Großunternehmen in Hamburg',
    '2228': 'Hamburg für Prüfungsd. und Strafsachen',
    '2230': 'Hamburg für Verkehrst. und Grundbesitz',
    '2235': 'Hamburg für Verkehrst. und Grundbesitz',
    '2241': 'Hamburg-Altona',
    '2242': 'Hamburg-Am Tierpark',
    '2243': 'Hamburg-Barmbek-Uhlenhorst',
    '2244': 'Hamburg-Ost',
    '2245': 'Hamburg-Eimsbüttel',
    '2246': 'Hamburg-Hansa',
    '2247': 'Hamburg-Harburg',
    '2248': 'Hamburg-Mitte',
    '2249': 'Hamburg-Nord',
    '2250': 'Hamburg-Oberalster',
    '2251': 'Hamburg-Ost',
    '2311': 'Alfeld (Leine)',
    '2312': 'Bad Gandersheim',
    '2313': 'Braunschweig-Altewiekring',
    '2314': 'Braunschweig-Wilhelmstr.',
    '2315': 'Buchholz in der Nordheide',
    '2316': 'Burgdorf',
    '2317': 'Celle',
    '2318': 'Cuxhaven',
    '2319': 'Gifhorn',
    '2320': 'Göttingen',
    '2321': 'Goslar',
    '2322': 'Hameln',
    '2323': 'Hannover-Land I',
    '2324': 'Hannover-Mitte',
    '2325': 'Hannover-Nord',
    '2326': 'Hannover-Süd',
    '2327': 'Hannover-Land II',
    '2328': 'Helmstedt',
    '2329': 'Herzberg am Harz',
    '2330': 'Hildesheim',
    '2331': 'Holzminden',
    '2332': 'Lüchow',
    '2333': 'Lüneburg',
    '2334': 'Nienburg/Weser',
    '2335': 'Northeim',
    '2336': 'Osterholz-Scharmbeck',
    '2338': 'Peine',
    '2340': 'Rotenburg (Wümme)',
    '2341': 'Soltau',
    '2342': 'Hannover-Land I Außenstelle Springe',
    '2343': 'Stade',
    '2344': 'Stadthagen',
    '2345': 'Sulingen',
    '2346': 'Syke',
    '2347': 'Uelzen',
    '2348': 'Verden (Aller)',
    '2349': 'Wesermünde',
    '2350': 'Winsen (Luhe)',
    '2351': 'Wolfenbüttel',
    '2352': 'Zeven',
    '2353': 'Papenburg',
    '2354': 'Aurich',
    '2355': 'Bad Bentheim',
    '2356': 'Cloppenburg',
    '2357': 'Delmenhorst',
    '2358': 'Emden',
    '2360': 'Leer (Ostfriesland)',
    '2361': 'Lingen (Ems)',
    '2362': 'Norden',
    '2363': 'Nordenham',
    '2364': 'Oldenburg (Oldenburg)',
    '2365': 'Osnabrück-Land',
    '2366': 'Osnabrück-Stadt',
    '2367': 'Quakenbrück',
    '2368': 'Vechta',
    '2369': 'Westerstede',
    '2370': 'Wilhelmshaven',
    '2371': 'Wittmund',
    '2380': 'Braunschweig für Großbetriebsprüfung',
    '2381': 'Göttingen für Großbetriebsprüfung',
    '2382': 'Hannover für Großbetriebsprüfung',
    '2384': 'Stade für Großbetriebsprüfung',
    '2385': 'Oldenburg für Großbetriebsprüfung',
    '2386': 'Osnabrück für Großbetriebsprüfung',
    '2390': 'für Fahndung und Strafsachen Braunschweig',
    '2391': 'Hannover für Fahndung und Strafsachen',
    '2392': 'Lüneburg für Fahndung und Strafsachen',
    '2393': 'Oldenburg für Fahndung und Strafsachen',
    '2457': 'Bremerhaven Bewertung Bremen',
    '2460': 'Bremen',
    '2471': 'Landeshauptkasse Bremen',
    '2475': 'Bremerhaven',
    '2477': 'Bremerhaven Bewertung',
    '2478': 'Bremen für Außenprüfung',
    '2485': 'Bremerhaven Arbeitnehmerbereich',
    '2601': 'Alsfeld-Lauterbach Verwaltungsstelle Alsfeld',
    '2602': 'Hersfeld-Rotenburg Verwaltungsstelle Bad Hersfeld',
    '2603': 'Bad Homburg v.d. Höhe',
    '2604': 'Rheingau-Taunus Verwaltungsst. Bad Schwalbach',
    '2605': 'Bensheim',
    '2606': 'Marburg-Biedenkopf Verwaltungsstelle Biedenkopf',
    '2607': 'Darmstadt',
    '2608': 'Dieburg',
    '2609': 'Dillenburg',
    '2610': 'Eschwege-Witzenhausen Verwaltungsstelle Eschwege',
    '2611': 'Korbach-Frankenberg Verwaltungsstelle Frankenberg',
    '2612': 'Frankfurt am Main II',
    '2613': 'Frankfurt am Main I',
    '2614': 'Frankfurt am Main IV',
    '2615': 'Frankfurt/M. V-Höchst Verwaltungsstelle Höchst',
    '2616': 'Friedberg (Hessen)',
    '2617': 'Bensheim Außenstelle Fürth',
    '2618': 'Fulda',
    '2619': 'Gelnhausen',
    '2620': 'Gießen',
    '2621': 'Groß-Gerau',
    '2622': 'Hanau',
    '2623': 'Kassel II-Hofgeismar Verwaltungsstelle Hofgeismar',
    '2624': 'Schwalm-Eder Verwaltungsstelle Fritzlar',
    '2625': 'Kassel I',
    '2626': 'Kassel II-Hofgeismar Verwaltungsstelle Kassel',
    '2627': 'Korbach-Frankenberg Verwaltungsstelle Korbach',
    '2628': 'Langen',
    '2629': 'Alsfeld-Lauterbach Verwaltungsstelle Lauterbach',
    '2630': 'Limburg-Weilburg Verwaltungsstelle Limburg',
    '2631': 'Marburg-Biedenkopf Verwaltungsstelle Marburg',
    '2632': 'Schwalm-Eder Verwaltungsstelle Melsungen',
    '2633': 'Michelstadt',
    '2634': 'Nidda',
    '2635': 'Offenbach am Main I',
    '2636': 'Hersfeld-Rotenburg Verwaltungsstelle Rotenburg',
    '2637': 'Rheingau-Taunus Verwaltungsstelle Rüdesheim',
    '2638': 'Limburg-Weilburg Verwaltungsstelle Weilburg',
    '2639': 'Wetzlar',
    '2640': 'Wiesbaden I',
    '2641': 'Eschwege-Witzenhausen Verwaltungsstelle Witzenhausen',
    '2642': 'Schwalm-Eder Verwaltungsstelle Schwalmstadt',
    '2643': 'Wiesbaden II',
    '2644': 'Offenbach am Main II',
    '2645': 'Frankfurt am Main III',
    '2646': 'Hofheim am Taunus',
    '2647': 'Frankfurt/M. V-Höchst Verwaltungsstelle Frankfurt',
    '2701': 'Bad Neuenahr-Ahrweiler',
    '2702': 'Altenkirchen-Hachenburg',
    '2706': 'Bad Kreuznach',
    '2708': 'Bingen-Alzey',
    '2709': 'Idar-Oberstein',
    '2710': 'Bitburg-Prüm',
    '2713': 'Landesfinanzkasse Daun',
    '2714': 'Montabaur-Diez Aussenstelle Diez',
    '2718': 'Altenkirchen-Hachenburg Aussenstelle Hachenburg',
    '2719': 'Kaiserslautern',
    '2722': 'Koblenz',
    '2723': 'Kusel-Landstuhl',
    '2724': 'Landau',
    '2725': 'Kusel-Landstuhl Aussenstelle Landstuhl',
    '2726': 'Mainz-Mitte',
    '2727': 'Ludwigshafen',
    '2728': 'Mainz-Süd',
    '2729': 'Mayen',
    '2730': 'Montabaur-Diez',
    '2731': 'Neustadt',
    '2732': 'Neuwied',
    '2734': 'Kusel-Landstuhl für Erb/Schenk.St. Saarland',
    '2735': 'Pirmasens',
    '2736': 'Bitburg-Prüm Aussenstelle Prüm',
    '2740': 'Simmern-Zell',
    '2741': 'Speyer-Germersheim',
    '2742': 'Trier',
    '2743': 'Wittlich',
    '2744': 'Worms-Kirchheimbolanden',
    '2745': 'Simmern-Zell Aussenstelle Zell',
    '2801': 'Offenburg Außenstelle Achern',
    '2804': 'Villingen-Schwenningen Außenstelle Donaueschingen',
    '2805': 'Emmendingen',
    '2806': 'Freiburg-Stadt',
    '2807': 'Freiburg-Land',
    '2808': 'Offenburg Außenstelle Kehl',
    '2809': 'Konstanz',
    '2810': 'Lahr',
    '2811': 'Lörrach',
    '2812': 'Müllheim',
    '2813': 'Freiburg-Land Außenstelle Titisee-Neustadt',
    '2814': 'Offenburg',
    '2815': 'Rottweil Außenstelle Oberndorf',
    '2816': 'Waldshut-Tiengen Außenstelle Bad Säckingen',
    '2818': 'Singen',
    '2819': 'Rottweil',
    '2820': 'Waldshut-Tiengen',
    '2821': 'Tuttlingen',
    '2822': 'Villingen-Schwenningen',
    '2823': 'Offenburg Außenstelle Wolfach',
    '2830': 'Bruchsal',
    '2831': 'Ettlingen',
    '2832': 'Heidelberg',
    '2833': 'Baden-Baden',
    '2834': 'Karlsruhe-Durlach',
    '2835': 'Karlsruhe-Stadt',
    '2836': 'Baden-Baden Außenstelle Bühl',
    '2837': 'Mannheim-Neckarstadt',
    '2838': 'Mannheim-Stadt',
    '2839': 'Rastatt',
    '2840': 'Mosbach',
    '2841': 'Pforzheim',
    '2842': 'Freudenstadt',
    '2843': 'Schwetzingen',
    '2844': 'Sinsheim',
    '2845': 'Calw',
    '2846': 'Mosbach Außenstelle Walldürn',
    '2847': 'Weinheim',
    '2848': 'Mühlacker',
    '2849': 'Pforzheim Außenstelle Neuenbürg',
    '2850': 'Aalen',
    '2851': 'Backnang',
    '2852': 'Tauberbischofsheim Außenstelle Bad Mergentheim',
    '2853': 'Balingen',
    '2854': 'Biberach',
    '2855': 'Bietigheim-Bissingen',
    '2856': 'Böblingen',
    '2857': 'Schwäbisch Hall Außenstelle Crailsheim',
    '2858': 'Ehingen',
    '2859': 'Esslingen',
    '2861': 'Friedrichshafen',
    '2862': 'Göppingen Außenstelle Geislingen',
    '2863': 'Göppingen',
    '2864': 'Heidenheim',
    '2865': 'Heilbronn',
    '2869': 'Nürtingen Außenstelle Kirchheim',
    '2870': 'Leonberg',
    '2871': 'Ludwigsburg',
    '2874': 'Nürtingen',
    '2876': 'Öhringen',
    '2877': 'Ravensburg',
    '2878': 'Reutlingen',
    '2879': 'Biberach Außenstelle Riedlingen',
    '2880': 'Tauberbischofsheim',
    '2881': 'Sigmaringen Außenstelle Bad Saulgau',
    '2882': 'Schorndorf',
    '2883': 'Schwäbisch Gmünd',
    '2884': 'Schwäbisch Hall',
    '2885': 'Sigmaringen',
    '2886': 'Tübingen',
    '2887': 'Überlingen (Bodensee)',
    '2888': 'Ulm',
    '2889': 'Bad Urach',
    '2890': 'Waiblingen',
    '2891': 'Wangen',
    '2892': 'Stuttgart IV',
    '2893': 'Stuttgart I',
    '2895': 'Stuttgart II',
    '2896': 'Stuttgart Zentrales Konzernprüfungsamt',
    '2897': 'Stuttgart III',
    '2899': 'Stuttgart-Körpersch.',
    '3046': 'Potsdam',
    '3048': 'Brandenburg',
    '3049': 'Königs Wusterhausen',
    '3050': 'Luckenwalde',
    '3051': 'Nauen',
    '3052': 'Kyritz',
    '3053': 'Oranienburg',
    '3056': 'Cottbus',
    '3057': 'Calau',
    '3061': 'Frankfurt (Oder)',
    '3062': 'Angermünde',
    '3064': 'Strausberg',
    '3065': 'Eberswalde',
    '3102': 'Magdeburg',
    '3103': 'Genthin',
    '3105': 'Haldensleben',
    '3106': 'Salzwedel',
    '3107': 'Staßfurt',
    '3108': 'Stendal',
    '3110': 'Halle (Saale)',
    '3112': 'Merseburg',
    '3114': 'Dessau-Roßlau',
    '3115': 'Wittenberg',
    '3116': 'Bitterfeld-Wolfen',
    '3117': 'Quedlinburg',
    '3118': 'Eisleben',
    '3119': 'Naumburg',
    '3202': 'Dresden-Nord',
    '3203': 'Dresden-Süd',
    '3204': 'Bautzen',
    '3207': 'Görlitz',
    '3208': 'Löbau',
    '3209': 'Meißen',
    '3210': 'Pirna',
    '3213': 'Hoyerswerda',
    '3214': 'Chemnitz-Süd',
    '3215': 'Chemnitz-Mitte',
    '3217': 'Annaberg',
    '3218': 'Schwarzenberg',
    '3220': 'Freiberg',
    '3221': 'Hohenstein-Ernstthal',
    '3222': 'Mittweida',
    '3223': 'Plauen',
    '3224': 'Stollberg',
    '3227': 'Zwickau',
    '3228': 'Zschopau',
    '3231': 'Leipzig II',
    '3232': 'Leipzig I',
    '3236': 'Döbeln',
    '3237': 'Eilenburg',
    '3238': 'Grimma',
    '3239': 'Oschatz',
    '4069': 'Neubrandenburg - RAB',
    '4070': 'Neubrandenburg - RIA (Rentenempfänger im Ausland)',
    '4072': 'Neubrandenburg',
    '4075': 'Waren',
    '4079': 'Rostock',
    '4080': 'Wismar',
    '4081': 'Ribnitz-Damgarten',
    '4082': 'Stralsund',
    '4084': 'Greifswald',
    '4086': 'Güstrow',
    '4087': 'Hagenow',
    '4090': 'Schwerin',
    '4151': 'Erfurt',
    '4154': 'Ilmenau',
    '4155': 'Eisenach',
    '4156': 'Gotha',
    '4157': 'Mühlhausen',
    '4159': 'Sondershausen',
    '4161': 'Gera',
    '4162': 'Jena',
    '4165': 'Pößneck',
    '4166': 'Altenburg',
    '4170': 'Sonneberg',
    '4171': 'Suhl',
    '5101': 'Dinslaken',
    '5102': 'Viersen',
    '5103': 'Düsseldorf-Altstadt',
    '5105': 'Düsseldorf-Nord',
    '5106': 'Düsseldorf-Süd',
    '5107': 'Duisburg-Hamborn',
    '5109': 'Duisburg-Süd',
    '5111': 'Essen-NordOst',
    '5112': 'Essen-Süd',
    '5113': 'Geldern',
    '5114': 'Grevenbroich',
    '5115': 'Kempen',
    '5116': 'Kleve',
    '5117': 'Krefeld',
    '5119': 'Moers',
    '5120': 'Mülheim an der Ruhr',
    '5121': 'Mönchengladbach',
    '5122': 'Neuss',
    '5123': 'Oberhausen-Nord',
    '5124': 'Oberhausen-Süd',
    '5126': 'Remscheid',
    '5128': 'Solingen',
    '5130': 'Wesel',
    '5131': 'Wuppertal-Barmen',
    '5132': 'Wuppertal-Elberfeld',
    '5133': 'Düsseldorf-Mitte',
    '5134': 'Duisburg-West',
    '5135': 'Hilden',
    '5139': 'Velbert',
    '5147': 'Düsseldorf-Mettmann',
    '5149': 'Rechenzentrum d. FinVerw NRW',
    '5170': 'Düsseldorf I für Groß- und Konzernbetriebsprüfung',
    '5171': 'Düsseldorf II für Groß- und Konzernbetriebsprüfung',
    '5172': 'Essen für Groß- und Konzernbetriebsprüfung',
    '5173': 'Krefeld für Groß- und Konzernbetriebsprüfung',
    '5174': 'Bergisches Land für Groß- und Konzernbetriebsprüfung',
    '5176': 'Mönchengladbach für Groß- und Konzernbetriebsprüfung',
    '5181': 'Düsseldorf f. Steuerfahndung und Steuerstrafsachen',
    '5182': 'Essen f. Steuerfahndung und Steuerstrafsachen',
    '5183': 'Wuppertal f. Steuerfahndung und Steuerstrafsachen',
    '5201': 'Aachen-Stadt',
    '5202': 'Aachen-Kreis',
    '5203': 'Bergheim',
    '5204': 'Bergisch Gladbach',
    '5205': 'Bonn-Innenstadt',
    '5206': 'Bonn-Außenstadt',
    '5207': 'Düren',
    '5208': 'Erkelenz',
    '5209': 'Euskirchen',
    '5210': 'Geilenkirchen',
    '5211': 'Schleiden',
    '5212': 'Gummersbach',
    '5213': 'Jülich',
    '5214': 'Köln-Altstadt',
    '5215': 'Köln-Mitte',
    '5216': 'Köln-Porz',
    '5217': 'Köln-Nord',
    '5218': 'Köln-Ost',
    '5219': 'Köln-Süd',
    '5220': 'Siegburg',
    '5221': 'Wipperfürth',
    '5222': 'Sankt Augustin',
    '5223': 'Köln-West',
    '5224': 'Brühl',
    '5230': 'Leverkusen',
    '5270': 'Köln für Groß- und Konzernbetriebsprüfung',
    '5271': 'Aachen für Groß- und Konzernbetriebsprüfung',
    '5272': 'Bonn für Groß- und Konzernbetriebsprüfung',
    '5281': 'Aachen f. Steuerfahndung und Steuerstrafsachen',
    '5282': 'Bonn f. Steuerfahndung und Steuerstrafsachen',
    '5283': 'Köln f. Steuerfahndung und Steuerstrafsachen',
    '5301': 'Ahaus',
    '5302': 'Altena',
    '5303': 'Arnsberg',
    '5304': 'Beckum',
    '5305': 'Bielefeld-Innenstadt',
    '5306': 'Bochum-Mitte',
    '5307': 'Borken',
    '5308': 'Bottrop',
    '5309': 'Brilon',
    '5310': 'Bünde',
    '5311': 'Steinfurt',
    '5312': 'Coesfeld',
    '5313': 'Detmold',
    '5314': 'Dortmund-West',
    '5315': 'Dortmund-Hörde',
    '5316': 'Dortmund-Unna',
    '5317': 'Dortmund-Ost',
    '5319': 'Gelsenkirchen',
    '5321': 'Hagen',
    '5322': 'Hamm',
    '5323': 'Hattingen',
    '5324': 'Herford',
    '5325': 'Herne',
    '5326': 'Höxter',
    '5327': 'Ibbenbüren',
    '5328': 'Iserlohn',
    '5329': 'Lemgo',
    '5330': 'Lippstadt',
    '5331': 'Lübbecke',
    '5332': 'Lüdenscheid',
    '5333': 'Lüdinghausen',
    '5334': 'Meschede',
    '5335': 'Minden',
    '5336': 'Münster-Außenstadt',
    '5337': 'Münster-Innenstadt',
    '5338': 'Olpe',
    '5339': 'Paderborn',
    '5340': 'Recklinghausen',
    '5341': 'Schwelm',
    '5342': 'Siegen',
    '5343': 'Soest',
    '5345': 'Warburg',
    '5346': 'Warendorf',
    '5347': 'Wiedenbrück',
    '5348': 'Witten',
    '5349': 'Bielefeld-Außenstadt',
    '5350': 'Bochum-Süd',
    '5351': 'Gütersloh',
    '5359': 'Marl',
    '5371': 'Bielefeld für Groß- und Konzernbetriebsprüfung',
    '5372': 'Herne für Groß- und Konzernbetriebsprüfung',
    '5373': 'Detmold für Groß- und Konzernbetriebsprüfung',
    '5374': 'Dortmund für Groß- und Konzernbetriebsprüfung',
    '5375': 'Hagen für Groß- und Konzernbetriebsprüfung',
    '5376': 'Münster für Groß- und Konzernbetriebsprüfung',
    '5381': 'Bielefeld f. Steuerfahndung und Steuerstrafsachen',
    '5382': 'Bochum f. Steuerfahndung und Steuerstrafsachen',
    '5383': 'Hagen f. Steuerfahndung und Steuerstrafsachen',
    '5384': 'Münster f. Steuerfahndung und Steuerstrafsachen',
    '9101': 'Augsburg-Stadt Arbeitnehmerbereich',
    '9102': 'Augsburg-Land',
    '9103': 'Augsburg-Stadt',
    '9104': 'Wolfratshausen - Bad Tölz',
    '9105': 'Berchtesgaden-Laufen',
    '9106': 'Burghausen',
    '9107': 'Dachau',
    '9108': 'Deggendorf',
    '9109': 'Dillingen',
    '9110': 'Dingolfing',
    '9111': 'Nördlingen mit ASt Donauwörth',
    '9112': 'Ebersberg',
    '9113': 'Eggenfelden',
    '9114': 'Erding',
    '9115': 'Freising',
    '9116': 'Bundeszentralamt für Steuern',
    '9117': 'Fürstenfeldbruck',
    '9119': 'Garmisch-Partenkirchen',
    '9121': 'Günzburg',
    '9123': 'Kempten-Immenstadt',
    '9124': 'Ingolstadt',
    '9125': 'Kaufbeuren m. ASt Füssen',
    '9126': 'Kelheim',
    '9127': 'Kempten-Immenstadt',
    '9131': 'Landsberg',
    '9132': 'Landshut',
    '9134': 'Lindau',
    '9138': 'Memmingen-Mindelheim',
    '9139': 'Miesbach',
    '9140': 'Memmingen-Mindelheim',
    '9141': 'Mühldorf',
    '9142': 'München Bewertung des Grundbesitzes',
    '9143': 'München Körpersch./PersonenGes',
    '9144': 'München Selbständige/Gewerbe/VuV',
    '9145': 'München Selbständige/Gewerbe/VuV',
    '9146': 'München Selbständige/Gewerbe/VuV',
    '9147': 'München Selbständige/Gewerbe/VuV',
    '9148': 'München Selbständige/Gewerbe/VuV',
    '9149': 'München Erhebung',
    '9151': 'Neu-Ulm',
    '9152': 'Nördlingen mit ASt Donauwörth',
    '9153': 'Passau mit Außenstellen',
    '9154': 'Pfaffenhofen',
    '9156': 'Rosenheim m. ASt Wasserburg',
    '9157': 'Grafenau',
    '9159': 'Schrobenhausen m. ASt Neuburg',
    '9161': 'Starnberg',
    '9162': 'Straubing',
    '9163': 'Traunstein',
    '9168': 'Weilheim-Schongau',
    '9169': 'Wolfratshausen - Bad Tölz',
    '9170': 'Zwiesel m. ASt Viechtach',
    '9171': 'Eichstätt',
    '9181': 'München Arbeitn./Renten/Hausgem',
    '9182': 'München AN/Renten/HG/Beschränkt Stpfl.',
    '9183': 'München Arbeitn./Renten/Hausgem',
    '9184': 'München Arbeitn./Renten/Hausgem',
    '9185': 'München Arbeitn./Renten/Hausgem',
    '9187': 'München Körperschaften',
    '9188': 'München Betriebsprüfung',
    '9189': 'München Erhebung',
    '9201': 'Amberg',
    '9202': 'Obernburg am Main mit Außenstelle Amorbach',
    '9203': 'Ansbach mit Außenstellen',
    '9204': 'Aschaffenburg',
    '9205': 'Bad Kissingen',
    '9206': 'Bad Neustadt a.d.S.',
    '9207': 'Bamberg',
    '9208': 'Bayreuth',
    '9211': 'Cham mit Außenstellen',
    '9212': 'Coburg',
    '9216': 'Erlangen',
    '9217': 'Forchheim',
    '9218': 'Fürth',
    '9220': 'Gunzenhausen',
    '9221': 'Hersbruck',
    '9222': 'Hilpoltstein',
    '9223': 'Hof mit Außenstellen',
    '9227': 'Kitzingen',
    '9228': 'Kronach',
    '9229': 'Kulmbach',
    '9230': 'Lichtenfels',
    '9231': 'Lohr a. Main mit Außenstellen',
    '9232': 'Lohr a. Main mit Außenstellen',
    '9235': 'Neumarkt i.d.Opf.',
    '9238': 'Nürnberg-Nord',
    '9240': 'Nürnberg-Süd',
    '9241': 'Nürnberg-Zentral',
    '9244': 'Regensburg',
    '9247': 'Schwabach',
    '9248': 'Schwandorf mit Außenstelle Neunburg v. W.',
    '9249': 'Schweinfurt',
    '9252': 'Uffenheim',
    '9254': 'Waldsassen',
    '9255': 'Weiden i.d.Opf.',
    '9257': 'Würzburg mit Außenstelle Ochsenfurt',
    '9258': 'Wunsiedel mit Außenstelle Selb',
    '9259': 'Zeil am Main mit Außenstelle Ebern'
}

const bundesland2prefix = {
    28: 28,
    91: 91,
    11: 11,
    30: 30,
    24: 24,
    22: 22,
    26: 26,
    40: 40,
    23: 23,
    51: 51,
    27: 27,
    10: 10,
    32: 32,
    31: 31,
    21: 21,
    41: 41,
}
const bundeslanderPrefixLength = {
    28: 2,
    91: 1,
    11: 2,
    30: 1,
    24: 2,
    22: 2,
    26: 2,
    40: 1,
    23: 2,
    51: 1,
    27: 2,
    10: 1,
    32: 1,
    31: 1,
    21: 2,
    41: 1,
}
export {finanzamter, id2finanzamt, bundesland2prefix, bundeslanderPrefixLength}
