import React from "react";
import Consts from "../core/consts";
import axios from "axios/index";
import {Container, InsertIfAdmin, LightContainer, Loader, SelectField} from "../core/input_fields";
import {Link} from "react-router-dom";
import {FormContext} from "../core/form_context";
import {encodeGetParams, getFraudData} from "../core/api";

class CredentialsSelect extends React.Component {
    static contextType = FormContext;
    load = key => {
        key = key.toLowerCase();
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (this.state.key === key || key === "_" || !state.loadedMain) {
            return;
        }
        this.setState({
            key: key,
            loading: true,
            avail: [],
        });

        axios.get(Consts.API_PREFIX + "/credentials/get_by_tag/" + key)
            .then(response => {
                if (response.data.status === "error") {
                    this.context.setState({
                        error: response.data.message,
                        status: ""
                    });
                    this.setState({loading: false})
                } else {
                    let foundInAvailCreds = false;
                    response.data.creds.forEach(a => {
                        if (state.general.credentials !== undefined && state.general.credentials !== 0 && state.general.credentials.split("|")[1] === a.split("|")[1]) {
                            foundInAvailCreds = true;
                            this.context.updateState("general", "credentials", a)
                        }
                    });
                    if (!foundInAvailCreds) {
                        this.context.updateState("general", "credentials", response.data.creds[0])
                    }
                    this.context.setState({"userID": response.data.user_id});
                    this.setState({
                        avail: response.data.creds,
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.context.setState({
                    error: error.message,
                    status: ""
                });
                this.setState({loading: false})
            });
    };
    componentWillMount = () => {
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;

        const s = state.mainLoading;
        s.push((state) => {
            this.load(state.general.country + "_" + state.general.report)
        });
        this.context.setState({mainLoading: s})
    };
    render = () => {
        window.setTimeout(() => {
            const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;

            this.load(state.general.country + "_" + state.general.report);
        }, 10);

        return <>
            <h3><strong style={{fontWeight: 400}}>Finance Authority Information</strong></h3>
            <Loader loading={this.state.loading}/>
            {!this.state.loading &&
                <SelectField name="Credentials" tag="general_credentials"
                             selectives={this.state.avail}>
                    <InsertIfAdmin>
                        <Link to={"/credentials/add"}>add new</Link>
                    </InsertIfAdmin>
                </SelectField>
            }
        </>
    };

    constructor(props) {
        super(props);
        this.state = {
            avail: [],
            key: "",
            loading: false,
        }
    };
}

class VATObligationsSelect extends React.Component {
    static contextType = FormContext;

    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            avail: [],
            key: "",
            loading: false
        }
    };

    get = (n, def = "", obj = this.context.state) => {
        const names = n.split(".");

        return names.reduce((acc, cur, ind) => {
            if (acc === def || acc[cur] === undefined || acc[cur] === null) {
                return def
            }
            return acc[cur];
        }, obj)

    };

    load(state) {
        if (this.context?.state?.submitted) {
            return
        }
        let key = "";
        if (state !== undefined) {

            key = JSON.stringify([
                this.get("company.vatId", "nothing", state),
                this.get("general", "nothing", state),
                this.get("credentials", "nothing", state),
            ]);
        } else {

            key = JSON.stringify([
                this.get("company.vatId", "nothing"),
                this.get("general", "nothing"),
                this.get("credentials", "nothing"),
            ]);
        }
        if (this.state.key === key) {
            return null
        }
        this.setState({
            key: key,
            loading: true,
            avail: [],
            showingNr: 0,
        });
        const s = Object.assign({}, this.context.state);

        this.props.localVars.forEach(n => s[n] = undefined);

        if (s.company === undefined) {
            return
        }
        this.setState({
            showingNr: s.company.vatId,
            avail: [],
        });


        axios.post(Consts.API_PREFIX + "/vat_return/gb/load_obligations?" + encodeGetParams({filing_ID: this.props.filing_ID}), s, {
            headers: {'Content-Type': 'application/json', ...getFraudData()}
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.context.setState({
                        error: response.data.message,
                        status: "",
                    });
                    this.setState({loading: false})
                } else {
                    let foundInAvailOblg = false;
                    response.data.forEach(a => {
                        if (this.context.state.specific !== undefined && this.context.state.specific.period !== undefined && this.context.state.specific.period === a.periodKey) {
                            foundInAvailOblg = true;
                            this.context.updateState("specific", "period", a.periodKey);
                            this.context.updateState("specific", "periodKey", a.periodKey);
                            this.context.updateState("specific", "start", a.start);
                            this.context.updateState("specific", "end", a.end);
                            this.context.updateState("specific", "due", a.due);
                            //this.context.updateState("specific", "period", calcPeriodForGB(a));
                        }
                    });
                    if (!foundInAvailOblg && response.data.length > 0) {
                        this.context.updateState("specific", "period", response.data[0].period);
                        this.context.updateState("specific", "periodKey", response.data[0].periodKey);
                        this.context.updateState("specific", "start", response.data[0].start);
                        this.context.updateState("specific", "end", response.data[0].end);
                        this.context.updateState("specific", "due", response.data[0].due);
                        //this.context.updateState("specific", "period", calcPeriodForGB(response.data[0]));
                    }
                    this.context.setState({
                        error: "",
                        status: "",
                    });
                    this.setState({
                        avail: response.data,
                        loading: false,
                    })
                }
            }).catch((error) => {
            console.log("error", error);
            this.context.setState({
                error: error.message,
                status: "",
            });

            this.setState({loading: false})
        });

    }

    componentWillMount() {
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        const s = state.mainLoading;
        s.push((state) => {
            if (state.company !== undefined && state.company.vatId !== undefined && state.company.vatId !== "" && state.company.vatId.length === 9 && state.general.credentials !== "") {
                this.load(state)
            } else {
                // console.log("problem happend as not enough information is available in obligations main loading")
            }
        });
        this.context.setState({mainLoading: s})
    }

    render() {
        const s = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s.company === undefined || s.company.vatId === undefined || s.general.credentials === undefined) {
            return null;
        }
        if (s.company.vatId !== "" && s.company.vatId.length === 9 && s.general.credentials !== "") {
            this.load()
        }
        return <>
            <h2><b style={{fontWeight: 400}}>VAT Obligations</b></h2>
            {s.company.vatId !== "" && s.company.vatId.length === 9 && s.general.credentials !== "" && this.state.avail !== undefined && this.state.avail.length !== 0 && !this.state.loading &&
                <ul className={"noliststyle"}>
                    Select one of the following periods for VAT IDs {this.state.showingNr}:
                    {this.state.avail.map(a => {
                        const checked = (s.specific.periodKey || s.specific.period  )=== a.periodKey ? "true" : "";
                        return <li><input type={"radio"} name={"specific_periodKey"}
                                          value={a.periodKey} checked={checked}
                                          defaultChecked={false}/>{dateFormatString(a.start)} to {dateFormatString(a.end)} ({a.status === "F" ? "filed" : "open"})
                            (due {dateFormatString(a.due)})</li>
                    })
                    }
                </ul>

            }
            {s.company.vatId !== "" && s.company.vatId.length === 9 && s.general.credentials !== "" && !s.submitted && <Loader loading={this.state.loading}/>}
            {
                (s.company.vatId === "" || s.company.vatId.length !== 9 || s.general.credentials === "" || s.general.credentials === undefined || this.state.avail === undefined || this.state.avail.length === 0) && this.state.loading === false && !s.submitted &&
                <span>please select information above first<br/></span>
            }
            {
                s.submitted && <span>Period from {dateFormatString(s.specific.start)} to {dateFormatString(s.specific.end)}</span>
            }
        </>

    }
}


function dateFormatString(date) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }).format(Date.parse(date))
}

export {VATObligationsSelect, CredentialsSelect};
