import React from 'react';
import {LightContainer, Loader} from "../core/input_fields";
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";

import ReactTable from "react-table";
import {dateFormatTime} from "../filing/filing_support";
import {FaArrowCircleLeft, FaExternalLinkAlt} from "react-icons/fa";
import {Link} from "react-router-dom";
import {FormContext} from "../core/form_context";
import {MdAutorenew} from "react-icons/md";
import {createLink, FileIcon} from "./core";
import JSONPretty from "react-json-pretty";

class FilesPick extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            files: [],
            newFile: "",
            parent_ID: context.state.parent_ID || 0,
            parent: null,
            newFolderName: "",
            editMode: false,
            initial: true
        };
        this.fieldName = props.tag === undefined ? "fileID" : props.tag;

        this.context = context;
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.loadFiles();
        this.setState = this.setState.bind(this)
    }

    static contextType = FormContext;
    loadFiles = () => {
        console.log("context", this.context)
        if (!this.state.initial) {
            this.setState({loading: true})
        }
        if (this.context === undefined || this.context.state === undefined) {
            return
        }
        const pID = this.state.parent_ID;

        this.apiPost("/files/listwithdata/" + (pID || 0), this.context.state, resp => {
            this.setState({
                ...resp,
                loading: false,
                error: "",
                showingParent_ID: pID,
                initial: false,
            })
        });
    };


    render() {
        if (this.context.parent_ID !== this.state.showingParent_ID && !this.state.loading && this.state.error === "") {
            //this.loadFiles()
        }
        const parent = this.state.parent;
        return (

            <LightContainer name={"Files" + (this.state.parent !== null ? " in folder " + this.state.parent.name : "")}>
                <Status type={"error"} text={this.state.error}/>
                <div style={{minHeight: "30px"}}>
                    <em style={{float: "right"}} onClick={() => {
                        this.loadFiles()
                    }}>
                        <MdAutorenew/> Refresh
                    </em>

                    {
                        this.state.parent !== null ?
                            <em onClick={() => {
                                this.setState({parent_ID: parent.parent_ID}, this.loadFiles)
                            }}>
                                <FaArrowCircleLeft/> back to {parent.name}
                            </em>
                            : null
                    }

                    <Loader style={{height: "20px"}} loading={this.state.loading}/>

                </div>

                <div id={"files_table"}>
                    <ReactTable
                        data={this.state.files}
                        style={{marginTop: "10px"}}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Type",
                                        Cell: row => <FileIcon type={row.original.file.type}/>,
                                        maxWidth: 50
                                    },

                                    {
                                        Header: "Name",
                                        id: "name",
                                        accessor: "file.name",
                                        Cell: ({original,value: name}) => original.file.type === "fold" ?
                                            <em onClick={() => {
                                                this.setState({parent_ID: original.file.ID}, this.loadFiles)
                                            }}>{name}</em>
                                            : <em onClick={() => {
                                                this.props.onSelect(original.file)
                                                /*//alert("picked " + row.original.file.ID + " - " + row.original.file.token)
                                                //console.log(this.context)
                                                this.context.updateState(this.fieldName, "", original.file.token)
                                                this.context.updateState(this.fieldName+"Name", "", name)
                                                //this.context.updateState("parent_ID", "", this.state.parent_ID)
                                                this.props.close()*/
                                            }}>{name}</em>
                                    },
                                    {
                                        Header: "Last Modified",
                                        accessor: "file.last_modified",
                                        Cell: row => row.value > 0 ? dateFormatTime(row.value * 1000) : "unknown",
                                        maxWidth: 150,
                                    },

                                    {
                                        Header: "Actions",
                                        accessor: "file.ID",
                                        Cell: row => row.original.file.type === "xlsx" ? <Link to={createLink(row)} target={row.original.file.type !== "fold" ? "_blank" : ""}>open <FaExternalLinkAlt/> </Link> : null,
                                        maxWidth: 150,
                                        sortable: false,
                                    }

                                ]
                            }
                        ]}
                        pageSize={Math.max(this.state.files.length,2)}
                        className="-striped -highlight"
                        showPagination={false}
                        noDataText={"no file found"}
                        defaultSorted={[
                            {
                                id: "name",
                                desc: false
                            }
                        ]}
                    />
                </div>


            </LightContainer>
        );
    }
}



export default FilesPick;
