import React from 'react';
import {Link, Redirect} from "react-router-dom";
import Status from '../core/status';
import Consts from '../core/consts'
import {apiGet, encodeGetParams} from "../core/api";
import {DEPeriods, formatReportName, GBPeriods, GBPeriods_date} from "../filing/filing_support";
import {CheckboxInput, Loader, SelectField, TextfieldInput} from "../core/input_fields";
import {FormContext} from "../core/form_context";
import {FaArrowCircleDown, FaArrowCircleUp} from "react-icons/fa";
import ViewConfig from "../core/view_config";


class CompaniesList extends React.Component {
    constructor(props) {
        super(props);
        this.config = new ViewConfig();
        this.state = {
            credentialExpanded: 0,
            companies: [],
            companiesRaw: [],
            loginStatus: null,
            error: "",
            loading: true,
            search: {
                country: this.props.country || "all"
            },
            searchjava: {
                name: ""
            },
            sort: {
                attribute: this.config.get("sortCompanies", "Name"),
                asc: this.config.get("sortCompaniesAsc", true),
            }
        };

        this.setExpanded = this.setExpanded.bind(this);
        this.setState = this.setState.bind(this);
        this.apiGet = apiGet.bind(this);
    }

    componentDidMount = () => {
        this.loadCompanies();
    }

    componentDidUpdate = () => {

        if (this.state === undefined || this.state.search === undefined || this.state.search === null) {
            return
        }
        this.config.save({
            sortCompanies: this.state.sort.attribute,
            sortCompaniesAsc: this.state.sort.asc,
        })
    }

    setExpanded(id) {

    }

    loadCompanies = () => {
        this.setState({loading: true})
        this.apiGet("/companies/list?" + encodeGetParams({country: this.state.search.country}), resp => {
            const companies = resp.results.filter(c => c.Cmp_key.length > 0)
            if (this.state.search.country === "all" && companies.length === 1) {
                this.setState({redirect: companies[0].Cmp_key})
            }

            this.setState({
                companies: companies.filter(n => !this.state.searchjava?.name || n.data.company.name?.toLowerCase().includes(this.state.searchjava.name.toLowerCase())),
                companiesRaw: companies,
                loading: false,
                error: ""
            })
        });
    };
    handleChange = (e) => {
        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        if (!this.state.submitted) {
            const s = e.target.name.split("_");
            if (s.length === 2) {
                this.updateState(s[0], s[1], val)
            }

        } else {
            this.setState({
                error: "already submitted files cannot be changed"
            })
        }

    };
    updateState = (parent, child, value) => {
        if (child === "") {
            this.setState({parent: value});
        } else {
            this.setState((prevState, props) => ({
                [parent]: {
                    ...prevState[parent],
                    [child]: value
                }
            }), () => parent === "search" ? this.loadCompanies() : () => {
            });
        }
    };
    toggleSort = (attribute) => {
        const sort = this.state.sort;
        this.setState({sort: {attribute: attribute, asc: attribute !== sort.attribute ? true : !sort.asc}})
    };
    sortCompanies = (a, b) => {
        const sort = this.state.sort;
        const accessor = this.attributeMaps[sort.attribute.replace(" ", "")];
        if (!accessor) {
            return 1
        }
        const val = accessor(a) > accessor(b);
        //console.log(a, b, accessor(a), accessor(b),val);
        return (sort.asc ? val : !val) ? 1 : -1
    };
    attributeMaps = {
        Name: c => c.data && c.data.company ? c.data.company.name || "z" : "z",
        Type: c => c.data && c.data.company ? c.data.general.country + c.data.general.report || "z" : "z",
        LastFiling: c => c.data && c.data.specific ? (c.data.specific.year || 0) + "_" + c.data.specific.period || "z" : "z",
    };

    render() {

        const companiesFiltered = this.state.companies
            .filter(n =>
                (!this.state.searchjava.active || n.due_in_days >= 0) &&
                (!this.state.searchjava?.name || n.data.company.name?.toLowerCase().includes(this.state.searchjava.name.toLowerCase()))
            );
        return <div id={"filings_list"} className={"company_list"}>
            <Status type={"error"} text={this.state.error}/>
            {
                this.state.redirect !== undefined &&
                <Redirect to={"/company/" + this.state.redirect + "/0"}/>
            }
            <h1>Companies ({companiesFiltered.length})</h1>

            <FormContext.Provider value={{state: this.state, setState: this.setState, updateState: this.updateState}}>
                <form onChange={this.handleChange}>
                    <SelectField name="Choose Country" tag="search_country" width="100px" selectives={[["all", "all"]].concat(Consts.reportCountriesSelect)} noBreaks update/> &nbsp;
                    <TextfieldInput name={"Name of Company"} tag={"searchjava_name"} ph={"Example Ltd"}/>&nbsp;&nbsp;&nbsp;
                    <CheckboxInput name={<>Show Only Active?&nbsp;</>} tag={"searchjava_active"}/>
                    <Loader loading={this.state.loading}/>
                </form>
            </FormContext.Provider>

            <table style={{width: "100%"}} className={"overview"}>

                {
                    this.state.companies.length > 0 ? <>

                            <tr>
                                <SortOrderChanger style={{paddingLeft: "35px"}} change={this.toggleSort} sort={this.state.sort}>Name</SortOrderChanger>
                                {
                                    this.props.columnHeading && <th>{this.props.columnHeading}</th>
                                }
                                <SortOrderChanger change={this.toggleSort} sort={this.state.sort}>Type</SortOrderChanger>
                                <SortOrderChanger change={this.toggleSort} sort={this.state.sort}>Last Filing</SortOrderChanger>
                            </tr>

                            {
                                companiesFiltered.sort(this.sortCompanies).map((f) => <>
                                        <tbody className={"overview"}>
                                        <tr key={f.ID} id={"f" + f.ID} onClick={() => {

                                        }}>


                                            <td>
                                                <Link to={"/companies/" + f.Cmp_key + "/0"}>
                                                    {!!f.data.company?.name && f.data.company.name.trim().length > 0 && f.data.company.name.substr(0, 45)}
                                                    {!!f.data.company?.name && f.data.company.name.length > 45 && <span>...</span>}
                                                    {!f.data.company?.name && !!f.data.company?.vatId && f.data.company.vatId}
                                                    {!f.data.company?.name && !!f.data.company?.taxNrAT && f.data.company.taxNrAT}
                                                    {!f.data.company?.name && !f.data.company?.vatId && !f.data.company?.taxNrAT && <span>No Name</span>}
                                                    {f.data.company === undefined && <span>No Name</span>}
                                                    {!!f.data.company?.note && <span> ({f.data.company.note})</span>}
                                                </Link>


                                            </td>
                                            {
                                                this.props.column && <td>{this.props.column(f)}</td>
                                            }
                                            <td>
                                                {f.data.general.country} {["DE"].indexOf(f.data.general.country) > -1 && formatReportName(f.data.general.report.replace("manual_", ""))}
                                            </td>
                                            <td>
                                                {
                                                    f.data?.specific?.period &&
                                                    <>
                                                        {
                                                            !(f.data.general.country === "GB" && f.data.general.report === "vat_return") && f.data.specific?.year &&
                                                            <span>  {f.data.specific.year} - {DEPeriods[f.data.specific.period] || f.data.specific.period}</span>
                                                        }
                                                        {
                                                            f.data.general.country === "GB" && GBPeriods[f.data.specific.period.substr(2)] !== undefined &&
                                                            <span> {GBPeriods_date(f.data.specific.period)}</span>
                                                        }
                                                    </>
                                                }


                                            </td>

                                        </tr>
                                        </tbody>
                                        <tbody>
                                        </tbody>
                                    </>
                                )}
                        </>
                        :
                        "There is none yet"

                }
            </table>

        </div>
    }
}

const SortOrderChanger = ({change, children, sort, style}) => {

    return <th style={style}>
        <em onClick={() => change(children)}>
            {children}
            &nbsp;
            &nbsp;
            {sort.attribute === children ?
                !sort.asc ? <FaArrowCircleUp style={{fontSize: "15px"}}/> : <FaArrowCircleDown style={{fontSize: "15px"}}/> :
                null
            }
        </em>
    </th>;
}


export default CompaniesList;
