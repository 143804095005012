import React, {useState} from 'react';
import Status from '../core/status';
import {apiDelete, apiGet, apiPost, encodeGetParams} from "../core/api";
import {formatReportName} from "../filing/filing_support";
import {InsertIfAdmin, LightContainer, MaxBtn, MiniBtn, MyModal, TextfieldInput} from "../core/input_fields";
import {FaCheck, FaInfoCircle, FaPlus, FaTrashAlt} from "react-icons/fa";
import CompaniesList from "./company_list";
import {FormContextWrapper} from "../core/form_context";
import {Link} from "react-router-dom";


export default class GroupCompanyRegistrations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            error: "",
            loading: false,

        };
        this.id = props.id

        this.setState = this.setState.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.apiDelete = apiDelete.bind(this);


    }

    componentDidMount = () => this.loadRegistrations()

    loadRegistrations = () => {
        if (!this.props.country && !this.props.showAll) {
            return
        }
        this.setState({loading: true, error: ""})
        this.apiGet("/companies/groups/" + this.id + "/registrations?country=" + (this.props.country || ""), resp => {
            this.setState({
                registrations: resp,
                loading: false,
                error: ""
            })
            this.props.reload()
        });
    };
    addRegistration = (Cmp_key, cb) => this.apiPost("/companies/groups/registrations", {"groupcompany_ID": this.id, Cmp_key}, (resp) => {
        this.loadRegistrations(resp)
        cb && cb()
    })

    render() {
        const {groupcompany, id, country} = this.props
        const alreadyKeys = this.state.registrations?.map(({GroupCompanyFilings}) => GroupCompanyFilings?.Cmp_key);


        return <div id={"filings_list"} className={"company_list"}>

            <LightContainer name={""}>
                <h2>{country || !groupcompany ? "" : "All Tax Numbers"}</h2>
                <Status type={"error"} text={this.state.error}/>
                <table style={{width: "100%"}} className={"overview"}>
                    <tr>
                        <th style={{paddingLeft: 0}}>Tax Number</th>
                        <th style={{paddingLeft: 0}}>Types</th>
                        <th></th>
                    </tr>
                    {
                        this.state.registrations
                            ?.sort((a, b) => a.types?.split("_"))
                            .map(({GroupCompanyFilings, types, notes}) => <tr>
                                <td><a href={"/company/" + GroupCompanyFilings?.Cmp_key + "/0"}>{GroupCompanyFilings.Cmp_key}</a></td>
                                <td>{
                                    !!types ?
                                        notes.map(({note, type}) => formatReportName(type) + (!!note ? (": " + note) : "")).join(", ") :
                                        <Link to={"/filings/new?" + encodeGetParams({
                                            country: GroupCompanyFilings?.Cmp_key.slice(0, 2),
                                            vatId: GroupCompanyFilings?.Cmp_key.slice(GroupCompanyFilings?.Cmp_key.includes("DETAX") ? 0 : 2),
                                        })
                                        }>create new filing</Link>
                                }</td>
                                <td>
                                    <InsertIfAdmin>
                                        <MiniBtn onClick={() => {
                                            window.confirm("Do you want to revoke this connection?") &&
                                            this.apiDelete("/companies/groups/registrations", {GroupCompanyFilings_ID: GroupCompanyFilings.ID}, this.loadRegistrations)
                                        }}>
                                            <FaTrashAlt/>
                                        </MiniBtn>
                                    </InsertIfAdmin>
                                </td>
                            </tr>)
                    }
                </table>


                <InsertIfAdmin>
                    <MyModal trigger={<MiniBtn><FaPlus/> Add Registration</MiniBtn>}>
                        <div>
                            <AddFromNumber add={this.addRegistration}/>
                            <Status type={"error"} text={this.state.error}/>
                            <CompaniesList columnHeading={"Select"} country={country} column={(data) => <button className={"mini"} disabled={alreadyKeys.includes(data.Cmp_key)} onClick={() => this.addRegistration(data.Cmp_key)}>
                                {alreadyKeys.includes(data.Cmp_key) ? "already" : "select"}
                            </button>
                            }/>
                        </div>
                    </MyModal>
                </InsertIfAdmin>
            </LightContainer>


        </div>
    }
}


function AddFromNumber({add}) {
    const [state, setState] = useState({key: ""})
    const [showExplanation, setShowExplanation] = useState(false);
    return <FormContextWrapper value={{state, setState}} onSubmit={() => {
        setState({added: false})
        add(state.key, () => setState({added: true, key: ""}))
    }}>
        <h1>Add Registration</h1>
        <TextfieldInput name={<>VAT / TAX / GST Number &nbsp;</>} tag={"key"} ph={"GB12345679"}/> <FaInfoCircle onClick={() => setShowExplanation(true)}/><br/>
        <MaxBtn>Add Registration</MaxBtn>
        {
            state.added && <FaCheck/>
        }

        {
            showExplanation && <p>
                Please insert the VAT / TAX / GST Number with the leading 2-character country code i.e. GB123456789.<br/>
                For German Tax Numbers please use the country wide format and <i>DETAX</i> as the prefix. You can convert the traditional format (3 numbers divided with a slash) to the required format with the help of the add filing form by:
                <ul>
                    <li>
                        selecting to add a German VAT Return
                    </li>
                    <li>
                        entering the State of Tax Authority
                    </li>
                    <li>
                        entering the Tax Number in the three separate boxes.
                    </li>
                </ul>
                The General Format will then be shown, for example: <i>DETAX9123456789012</i>
            </p>
        }
    </FormContextWrapper>
}
