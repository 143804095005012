import React from "react";
import {apiPost} from "../core/api";
import {CredentialsSelect} from "./filing_gb";
import {FormContextWrapper} from "../core/form_context";
import JSONPretty from "react-json-pretty";

export default class GBFraudPreventionHeadersTest extends React.Component {
    apiPost = apiPost.bind(this)
    Test = () => {
        this.apiPost("/vat_return/gb/test_fraud_prevention", this.state, resp=>this.setState({testResp: resp}))
    }
    state = {
        general: {
            country: "GB",
            report: "vat_return",
        },
        loadedMain: true,
        mainLoading: [],
    }
    setState = this.setState.bind(this)
    updateState = (parent, child, value) => {
        if (child === "") {
            this.setState({[parent]: value}, this.checkForAutosave);
        } else {
            this.setState((prevState, props) => ({
                [parent]: {
                    ...prevState[parent], [child]: value,
                },
            }), this.checkForAutosave);
        }
    };

    render() {

        return <>
            <FormContextWrapper value={{state: this.state, setState: this.setState, updateState: this.updateState}}>
                <CredentialsSelect state={this.state} setStateParent={this.setState} updateParent={this.updateState}/>
            </FormContextWrapper>

            <button onClick={this.Test}>test</button>
            <br/>
            <JSONPretty id="json-pretty" json={this.state.testResp}/>
        </>
    }
}
