import React from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import Status from '../core/status';
import _ from 'lodash'
import Consts from "../core/consts";
import JSONPretty from "react-json-pretty"
import {CheckboxInput, Container, DateInput, EditorInput, FileUpload, InsertIfNonClient, ListStringHuman, SelectField, TextfieldInput} from "../core/input_fields";
import {CredentialsSelect, VATObligationsSelect} from "./filing_gb"
import {confirmAlert} from "react-confirm-alert"
import 'react-confirm-alert/src/react-confirm-alert.css'
import {dateFormat, dateFormatTime, dateFromDay} from "./filing_support"
import ViewConfig from "../core/view_config"
import {FormContext} from "../core/form_context";
import {apiGet, apiPost, getFraudData} from "../core/api";
// Countries
import {DEECSL} from "./DE/de_ecsl";
import {DEVATReturn, DEVATReturnCompany} from "./DE/de_vat_return";
import Reminder from "./reminder"
import FileSelection from "./file_selection";
import {IoMdCloseCircle} from "react-icons/io";
import SearchUID from "./searchuid";
import {UserContext} from "../user/UserContext";
import Popup from "reactjs-popup";
import ReviewContainer from "./review";
import FileUploadNotification from "./file_upload_notifications";
import AccountManagers from "./filing_accountManagers";
import NewRegistration from "../registration/registration";
import {FaArrowCircleLeft, FaArrowCircleRight, FaDownload} from "react-icons/fa";
import {downloadAsExcel} from "../core/download";
import UploadMultipleFiles from "../core/multi_file";
import FilingHistory from "./filing_history";
import {NilReturnsRequired} from "../core/nil_returns_required";
import {FRLoadImportFigures} from "./FR/fr_helpers";

function get_param(search, param) {
    const t = (search || "").substr(1);
    const re = new RegExp(param + "=(.*?)(&|$)");
    const m = t.match(re);
    if (m != null && m.length === 3) {
        return m[1]
    }
    return null
}

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

class FilingNew extends React.Component {
    state = {};
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this)
        this.apiPost = apiPost.bind(this)
        this.setState = this.setState.bind(this);

        this.config = new ViewConfig();
        let s = null;
        const getParams = getQueryStringParams(props.location.search);


        let stateTemplate = {
            id: 0,
            general: {
                country: getParams?.country ?? this.config.get("country", "GB"),
                report: getParams?.report ?? this.config.get("report", "vat_return"),
            },
            company: {
                note: "",

            },
            lastSaved: 0,
            fileID: get_param(props.location.search, "fileToken") || "",
            status: "",
            error: "",
            loading: false,
            submitted: false,
            loadedMain: true,
            mainLoading: [],
            remind: {
                daysBeforeSelect: []
            },
            matrix: {},
            review: {},
            reminder: {},
            inputFields: [],
            emailTo: [],
            emailToTmp: "",
            autosaveKey: undefined,
            submissionFileMode: 0,
        };
        if (getParams?.vatId?.includes("DETAX")) {
            stateTemplate["specific"] = {tnr1: getParams?.vatId.slice(5)}
        } else if (!!getParams?.vatId) {
            stateTemplate["company"]["vatId"] = getParams?.vatId;
        }
        this.stateTemplate = stateTemplate;
        if (props.match && props.match.params.id !== undefined) {
            let d = new Date();

            axios.get(Consts.API_PREFIX + `/vat_return/get/${props.match.params.id}?${d.getMilliseconds()}`, {
                headers: {'Content-Type': 'application/json', ...getFraudData()}
            })
                .then(response => {
                    if (response.data.status === "error") {
                        this.setState({
                            error: response.data.message,
                            status: "",
                            loading: false,
                            loadedMain: true,
                        })
                    } else {
                        //console.log(response.data)

                        let s = Object.assign(
                            {},
                            this.state,
                            response.data.data.data,
                            {
                                Cmp_key: response.data.data.Cmp_key,
                                loading: false,
                                submitted: response.data.data.status !== 0,
                                loadedMain: true,
                                write_allowed: response.data.write_allowed,
                                duedate: response.data.data.duedate,
                                iduedate: response.data.data.iduedate,
                                iduedateDefaultOffset: response.data.data.iduedateDefaultOffset,
                                collaborators: response.data.data.collaborators,
                                GroupCompany: response.data.data.GroupCompany,
                                GroupCompanyContacts: response.data.data.GroupCompanyContacts,
                                GroupCompanyCountry: response.data.data.GroupCompanyCountry,
                            }
                        );
                        s.autosaveKey = JSON.stringify(this.cleanState(s));
                        this.setState(s);
                        this.mainLoader(s)
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setState({
                        error: error.message,
                        status: "",
                        loading: false,
                        loadedMain: true,
                    })
                });
            this.state = stateTemplate;
            this.state.loading = true;
            this.state.loadedMain = false;
        } else {
            if (s !== "null" && s !== null && props.location.pathname !== "/filings/new") {
                this.state = JSON.parse(s);
            } else {
                this.state = stateTemplate;
                this.checkLogin()
            }
        }
        this.localVars = [
            "status", "error", "excel", "loading", "changed", "submitted", "loadedMain", "mainLoading", "valid", "submitting", "fillIn", "loadedFromFile", "userID", "remind", "remindKey", "loginStatus",
            "emailToTmp", "timer", "autosaveTimer", "autosaveKey", "inputFields", "submissionFileTmp", "submissionFileMode", "write_allowed", "ignore", "duedate", "collaborators", "GroupCompany", "GroupCompanyContacts", "GroupCompanyCountry", "iduedate", "iduedateDefaultOffset",
        ];

        this.likeID = get_param(props.location.search, "likeId");
        //console.log("likeID", this.likeID);
        if (this.likeID !== null) {
            this.searchByName("", "", this.likeID)
        }
    }

    deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    mainLoader = (state) => {
        state.mainLoading.forEach(s => {
            s(state)
        })
    };

    type = (cc, report = "") => {
        if ((this.state.general || {}).country === cc) {
            if (report === "" || this.state.general.report === report) {
                return true
            }
        }
        return false
    };

    componentWillUnmount = () => {
        //console.log("unmounting new file")
        this.checkForAutosave(0)
        //clearInterval(this.timer)
    };

    componentDidMount = () => {
        this.mainLoader(this.state);
        /*this.timer = window.setInterval(() => {
        this.saveFormLocal()
    }, 2000);*/
        const selects = document.getElementsByTagName("select");
        for (var i = 0; i < selects.length; i++) {
            const name = selects[i].getAttribute("name").split("_");
            //console.log(name,selects[i].value)
            this.updateState(name[0], name[1], selects[i].value)
        }
    };

    /*
    // doing in handleChange > general instead
    componentDidUpdate = () => {

        this.config.save({country: this.state.general.country, report: this.state.general.report})
    };
    */


    sendResponse = () => {
        let s = this.deepCopy(this.state);

        this.localVars.forEach(n => s[n] = undefined);
        this.setState({loading: true});
        this.apiPost("/vat_return/send_response", s)
    };

    handleSubmit = (event) => {
        event.preventDefault();

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.doSubmit("live")
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })
    };

    doSubmit = (level = "testing") => {
        /*if(!this.state.id>0) {
            try {
                this.saveFormWorker();
            } catch (e) {
                this.setState({
                    'error': e
                });
                return;
            }
        }*/
        this.setState({loading: true});
        let s = this.deepCopy(this.state);

        this.localVars.forEach(n => s[n] = undefined);

        axios.post(Consts.API_PREFIX + `/` + s.general.report + `/` + s.general.country.toLowerCase() + `/submit/` + level, s, {
            headers: {'Content-Type': 'application/json', ...getFraudData()}
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    this.setState(prevState => ({
                        status: response.data.message,
                        error: "",
                        loading: false,
                        submitted: level === "live",
                        fname: response.data.fname !== undefined ? response.data.fname : "",
                        test_submits: response.data.fname !== undefined ? (prevState.test_submits !== undefined ? [...prevState.test_submits, response.data.fname] : [response.data.fname]) : [],
                    }))
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false
                })
            });


    };
    saveFormLocal = () => {
        let s = this.state;
        s.error = "";
        s.status = "";
        s.excel = "";
        //localStorage.setItem('form_temp_data', JSON.stringify(s));
    };


    saveForm = () => {
        try {
            this.saveFormWorker();
        } catch (e) {
            this.setState({
                'error': e
            });

        }
    };
    cleanState = (stateArg = this.state) => {
        let s = this.deepCopy(stateArg);
        this.localVars.forEach(n => s[n] = undefined);
        return s;
    };
    saveFormWorker = (background = false) => {
        const s = this.cleanState();
        if (s.submitted) {
            throw new Error("The filing cannot be saved if already submitted!")
        }
        this.setState({loading: true, autosaveKey: JSON.stringify(this.cleanState()),});

        axios.post(Consts.API_PREFIX + `/vat_return/save?create=` + (background ? "false" : "true"), s, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    //console.log("lajsdf", response.data)
                    this.setState({
                        status: background ? "" : response.data.message,
                        error: "",
                        id: response.data.id,
                        loading: false,
                        duedate: response.data.duedate,

                    })

                }

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false
                })
            });
    };
    checkForAutosave = (timeout = Consts.f(500, 5000)) => {
        if (this.state.id < 0 && (this.context.user || {}).Role <= 10) {
            return;
        }
        const key = JSON.stringify(this.cleanState());
        if (this.state.autosaveTimer) {
            clearTimeout(this.state.autosaveTimer)
        }
        if (this.state.autosaveKey !== undefined && key !== this.state.autosaveKey) {
            //console.log("having diff", key, this.state.autosaveKey)
            const timer = setTimeout(() => {
                console.log("saving form", new Date())
                this.saveFormWorker(true)
            }, timeout)
            this.setState({autosaveTimer: timer})
        } else {
            //console.log("having no diff")
        }

    };

    updateState = (parent, child, value) => {
        if (child === "") {
            this.setState({[parent]: value}, this.checkForAutosave);
        } else {
            this.setState((prevState, props) => {
                return {
                    [parent]: Object.assign(
                        {},
                        prevState[parent],
                        {[child]: value}
                    )
                }
            }, this.checkForAutosave);
        }

    };

    handleChange = (e) => {

        if (this.state.write_allowed === false && e.target.name.split("_")[0] !== "matrix") {
            return
        }
        if (!this.state.submitted || ["specific_chargeable", "specific_nil", "specific_correction", "specific_directDebitSetUp"].includes(e.target.name)) {

            let s = e.target.name.split("_");
            let value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;

            /*if (e.target.name === "specific_submitted" && this.state.specific?.notRequiredToSubmit === 1) {
                s = ["specific", "notRequiredToSubmit"];
                value = 0;
                if (!this.state.specific?.submitted) {
                    this.updateState("specific", "submitted", 1)
                }
            }*/


            if (s[0] === "general" && s[1] !== "credentials") {
                // reset the rest of the state if general is changed
                //this.setState(Object.assign({},this.stateTemplate,{general: {country: }})
                this.setState((prevState, props) => {
                    const newState = Object.assign({},
                        Object.keys(prevState).reduce((acc, cur, i) => {
                            //console.log(cur,acc)
                            return Object.assign({}, acc, {[cur]: undefined})
                        }, {}),
                        this.stateTemplate,
                        {
                            [s[0]]: Object.assign(
                                {},
                                prevState[s[0]],
                                {[s[1]]: value}
                            )
                        },
                        {reminders: prevState.reminders},
                    );
                    //console.log(newSTate,prevState)
                    this.config.save({country: newState.general.country, report: newState.general.report})
                    return newState
                }, this.checkForAutosave);


            } else {
                if (s.length === 2) {
                    this.updateState(s[0], s[1], value);
                }
                if (s[0] === "company" && s[1] === "name") {
                    this.searchByName(e.target.value, this.state.company?.note || "")
                } else if (s[0] === "company" && s[1] === "note") {
                    this.searchByName(this.state.company?.name || "", e.target.value)
                } else if (s[0] === "company") {
                    this.setState(({
                        fillIn: [],
                    }))
                }
            }
        } else {
            this.setState({
                error: "already submitted files cannot be changed"
            })
        }

    };
    checkLogin = () => {
        this.apiGet("/user/status", resp => {
            if (this.state.reminders === undefined || this.state.reminders.length === 0) {
                this.setState({
                    loginStatus: resp,
                    reminders: [
                        {
                            email: resp.user_details.Email,
                            daysBefore: [10]
                        }
                    ]
                })
            }

        });

    };
    componentWillMount = () => {
        if (this.state.submitted || this.state.status !== 0) {
        }
    };

    fillLoadedData = (d = {}, company = {}) => {
        //console.log(d, company);
        if (d.company === undefined && company !== {}) {
            d["company"] = company["company"];
            d["specific"] = company["specific"];
            d["general"] = company["general"]
        } else if (d.company !== undefined && d.company !== null) {
            d = d["company"]
        }

        if (d.company !== undefined && d.company !== null && d.company.name !== undefined) {
            this.updateState("company", "name", d.company.name);
            if (company.emailTo) {
                this.updateState("emailTo", "", company.emailTo);
            }
            this.updateState("company", "street", d.company.street);
            this.updateState("company", "city", d.company.city);
            this.updateState("company", "country", d.company.country);
            //this.updateState("company", "prename", d.company.prename);
            //this.updateState("company", "lastname", d.company.lastname);
            this.updateState("company", "houseNumber", d.company.houseNumber);
            this.updateState("company", "postcode", d.company.postcode);
            this.updateState("company", "email", d.company.email);
            this.updateState("company", "vatId", d.company.vatId);
            this.updateState("company", "internalID", d.company.internalID);

            this.updateState("company", "note", d.company.note);
            this.updateState("company", "notifyOnUpload", d.company.notifyOnUpload);
            this.updateState("company", "accountManagers", d.company.accountManagers);
            this.updateState("company", "accountManagersBackup", d.company.accountManagersBackup);
        }
        if (d.vatid !== undefined) {
            this.updateState("company", "vatId", d.vatid);
        }


        if (d.specific !== undefined && d.specific.tnr1 !== undefined) {
            this.updateState("specific", "tnr1", d.specific.tnr1);
            this.updateState("specific", "tnr2", d.specific.tnr2);
            this.updateState("specific", "tnr3", d.specific.tnr3);
            this.updateState("specific", "taxnr", d.specific.taxnr);
            this.updateState("specific", "county", d.specific.county);

        }

        if (this.likeID !== null) {
            this.updateState("general", "country", d.general.country)
            this.updateState("general", "report", d.general.report);
        }

        this.setState(({
            fillIn: [],
        }))
    };

    loadFromFile = () => {
        if (this.state.general.country.toLowerCase() !== "de") {
            //return
        }
        axios.get(`${Consts.API_PREFIX}/${this.state.general.report}/${this.state.general.country.toLowerCase()}/excel_data/${this.state.fileID}`, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,

                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",
                        loadedFromFile: true,
                    });
                    this.fillLoadedData(response.data)
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,

                })
            })
    };

    searchByName = (name, note, id = 0) => {

        if ((name.length + note.length < 3 && id === 0) || (this.state.company !== undefined && ((this.state.company.street !== undefined && this.state.company.street !== "") || (this.state.company.name !== undefined && this.state.company.name !== "")))) {
            this.setState({
                fillIn: []
            });
            return
        }
        if (this.state.timer) {
            clearTimeout(this.state.timer);
        }
        const timer = setTimeout(() => {
            axios.get(Consts.API_PREFIX + `/vat_return/search/?name=` + name + `&note=` + note + `&type=` + this.state.general.report + "_" + this.state.general.country.toLowerCase() + "&id=" + id, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if (response.data.status === "error") {
                        this.setState({
                            error: response.data.message,

                        })
                    } else {
                        this.setState({
                            status: response.data.message,
                            error: "",

                        });
                        if (response.data.results.length === 1) {
                            this.fillLoadedData({}, response.data.results[0])
                        } else {
                            this.setState({
                                fillIn: response.data.results
                            })
                        }

                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setState({
                        error: error.message,

                    })
                })
        }, 800)
        this.setState({timer: timer})

    };

    isManual = () => {
        return this.state.general !== undefined && this.state.general.report.indexOf("manual") > -1
    };
    deleteableList = ({children, content}) => <b>{children} <IoMdCloseCircle style={{marginBottom: "-4px", cursor: "pointer"}} title={"remove from list"} onClick={() => {
        this.setState({emailTo: this.state.emailTo.filter(e => e !== content)})
    }}/></b>;


    render() {
        //console.log(this.state.inputFields)
        if (this.state.id !== 0 && this.props.match.params.id === undefined) {
            this.props.history.push('/filings/edit/' + this.state.id);
            //hashHistory.replace('/filings/edit/' + this.state.id);
            return null
        }
        if (
            this.state.general !== undefined &&
            (this.state.general.country === "DE" || this.state.general.country === "GB") &&
            this.state.loadedFromFile === undefined
            && this.state.fileID !== undefined
            && (
                this.state.company === undefined ||
                (this.state.company !== undefined
                    && (
                        ((this.state.company.name === undefined || (this.state.company.name !== undefined && this.state.company.name === "")) && this.state.general.country === "DE") ||
                        ((this.state.company.vatId === undefined || (this.state.company.vatId !== undefined && this.state.company.vatId === "")) && this.state.general.country === "GB")
                    )

                ))
            && this.state.fileID.length > 0
            && this.state.error === "") {
            this.loadFromFile()
        }
        const countryName = Consts.countriesMap[this.state.general.country]

        return (
            <FormContext.Provider value={{
                state: this.state, setState: this.setState, updateState: this.updateState,
                type: this.type, fillLoadedData: this.fillLoadedData,
            }}>

                <div id="filing_new">
                    <div style={{float: "right"}}>
                        {
                            !!this.state.id && <em onClick={() => this.apiGet(`/vat_return/download/${this.state.id}`, resp => {
                                downloadAsExcel(resp.file, `Filing ${this.state.id} ${this.state.company?.name} ${this.state.general.country} ${this.state.general.report}.xlsx`)
                            })}>
                                <FaDownload/> download as Excel
                            </em>
                        }
                        &nbsp;
                        <Link style={{fontsize: "16px"}} to={{pathname: "/filings", state: {updating: true}}}><FaArrowCircleLeft/> back to all filings</Link>
                    </div>
                    <h1>{this.state.id > 0 ? "Edit" : "Add New"} Filing</h1>


                    <Status type="success" text={this.state.status}>

                        {this.state.submitted &&
                            <span><br/>submitted - <Link to={"/filings/" + this.state.id}>see details</Link></span>
                        }
                        {this.state.submitted && this.type("DE") &&
                            <span></span>
                        }
                        {this.state.submitted && this.type("DE") && this.state.fname !== undefined && this.state.fname !== "" &&
                            <span> <br/><a target="_blank" rel="noopener noreferrer" href={Consts.API_PREFIX + "/file/download/" + this.state.fname}>see result (pdf)</a></span>
                        }
                        {this.state.submitted && this.type("DE") && this.state.response_file !== undefined && this.state.response_file !== "" &&
                            <span> <br/><a target="_blank" rel="noopener noreferrer" href={Consts.API_PREFIX + "/file/download/" + this.state.response_file}>see result (pdf)</a></span>
                        }
                    </Status>
                    <Status type="error" text={this.state.error}/>


                    {
                        Consts.PHASE === "development2" &&
                        <div style={{float: "right", width: "300px"}}>
                            <JSONPretty id="json-pretty" json={this.state.specific}/>
                        </div>
                    }

                    <form id="form" name="form" onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }} onChange={this.handleChange}>

                        <Container name={"General"} visible>
                            <SelectField name="Country" tag="general_country" disabled={this.state.id > 0} selectives={Consts.reportCountriesSelect} noBreaks/>
                            <SelectField name="Type" tag="general_report" disabled={this.state.id > 0} updateParent={this.updateState} noBreaks
                                         selectives={Consts.reports[this.state.general.country === "all" ? "GB" : this.state.general.country].map(a => [a.toLowerCase().split(" ").join("_"), a.replace("EC SL", "ECSL")])}
                            />
                            <br/>
                            {
                                /*this.type("CA") && <>
                                    <SelectField name={"Region"} tag={"general_region"} disabled={this.state.id > 0} demandSelect selectives={[this.type("CA", "manual_gst") ? "Federal" : "Quebec"]}/>
                                    <br/>
                                </>*/
                            }
                        </Container>


                        {/*company data*/}


                        <Container name={"Company Information"} visible>
                            {
                                ["manual_vat_refund_directive", "manual_13th_directive", "manual_vat_notice_723a"].includes(this.state.general.report) ?
                                    <>
                                        <CompanyNameInput ph={"Company Name"}/>
                                        <TextfieldInput name="Internal ID" tag="company_internalID" ph={"123456789"}/><br/>
                                    </> :
                                    <>

                                        {
                                            (this.type("DE", "vat_return") || this.type("DE", "manual_vat_return") || this.type("DE", "manual_intrastat")) && <DEVATReturnCompany {...this.state.general}/>
                                        }


                                        {
                                            (this.type("DE", "ec_sl") || this.type("DE", "manual_ec_sl")) && <DEECSL/>
                                        }

                                        {
                                            this.type("AT") && <>
                                                <CompanyNameInput ph={"Example GmbH"}/>
                                                {
                                                    this.type("AT", "manual_intrastat") ?
                                                        <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/> :
                                                        <TextfieldInput name={Consts.labels.taxNrAt} tag={"company_taxNrAT"} ph={"034567891"} type={"number"}/>

                                                }
                                                <br/>
                                            </>
                                        }

                                        {this.type("DE") && !(
                                                this.type("DE", "ec_sl") || this.type("DE", "manual_ec_sl") || this.type("DE", "manual_intrastat") || this.type("DE", "vat_return") || this.type("DE", "manual_vat_return")
                                            ) &&
                                            <>
                                                <CompanyNameInput ph={"Example GmbH"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("GB") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/>
                                                {
                                                    this.type("GB", "ec_sl") && <>
                                                        <TextfieldInput name="Branch Number" tag="company_branchnr" ph={"000"}/>
                                                        <TextfieldInput name="Post Code" tag="company_postcode" ph={"ABC 123"}/>
                                                        <CredentialsSelect/>
                                                    </>
                                                }
                                                <br/>
                                            </>
                                        }
                                        {this.type("NL") &&
                                            <>
                                                <CompanyNameInput ph={"Example BV"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"8284812845B01"}/><br/>
                                            </>
                                        }
                                        {this.type("BE") &&
                                            <>
                                                <CompanyNameInput ph={"Example SA"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"06981249184"}/><br/>
                                            </>
                                        }
                                        {this.type("IE") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                {
                                                    !this.type("IE", "manual_vat_registration") && <TextfieldInput name="VAT Number" tag="company_vatId" ph={"4749148U"}/>
                                                }<br/>
                                            </>
                                        }
                                        {this.type("FR") &&
                                            <>
                                                <CompanyNameInput ph={"Example SAS"}/>
                                                <TextfieldInput name="Siren (VAT Number)" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("IT") &&
                                            <>
                                                <CompanyNameInput ph={"Example SRL"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789001"}/><br/>
                                            </>
                                        }
                                        {this.type("CH") &&
                                            <>
                                                <CompanyNameInput ph={"Example GmbH"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("PL") &&
                                            <>
                                                <CompanyNameInput ph={"Example SOO"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"1234567890"}/><br/>
                                            </>
                                        }
                                        {this.type("SE") &&
                                            <>
                                                <CompanyNameInput ph={"Example AB"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"556712345001"}/><br/>
                                            </>
                                        }
                                        {this.type("FI") &&
                                            <>
                                                <CompanyNameInput ph={"Example Oy"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("DK") &&
                                            <>
                                                <CompanyNameInput ph={"Example AS"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("ES") &&
                                            <>
                                                <CompanyNameInput ph={"Example SL"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"B12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("CZ") &&
                                            <>
                                                <CompanyNameInput ph={"Example s.r.o."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("NO") &&
                                            <>
                                                <CompanyNameInput ph={"Example AS"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.state.general?.country?.slice(0, 2) === "CA" && this.state.general?.country?.slice(3, 5) !== "QC" &&
                                            <>
                                                <CompanyNameInput ph={"Example Inc"}/>
                                                <TextfieldInput name="Canada Business Number" tag="company_bnr" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("CA-QC") &&
                                            <>
                                                <CompanyNameInput ph={"Example Inc"}/>
                                                <TextfieldInput name="Quebec Enterprise Number" tag="company_bnr" ph={"1234567890"}/><br/>
                                            </>
                                        }

                                        {this.type("HU") &&
                                            <>
                                                <CompanyNameInput ph={"Example Kft."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("SK") &&
                                            <>
                                                <CompanyNameInput ph={"Example s.r.o."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"1234567890"}/><br/>
                                            </>
                                        }
                                        {this.type("SI") &&
                                            <>
                                                <CompanyNameInput ph={"Example DOO"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("EE") &&
                                            <>
                                                <CompanyNameInput ph={"Example OÜ"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"101023456"}/><br/>
                                            </>
                                        }
                                        {this.type("LT") &&
                                            <>
                                                <CompanyNameInput ph={"UAB Example"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"100012345678"}/><br/>
                                            </>
                                        }
                                        {this.type("LV") &&
                                            <>
                                                <CompanyNameInput ph={"SIA Example"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"40001234567"}/><br/>
                                            </>
                                        }
                                        {this.type("MT") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("PT") &&
                                            <>
                                                <CompanyNameInput ph={"Example LBA"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("GR") &&
                                            <>
                                                <CompanyNameInput ph={"Example SIA"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"012345678"}/><br/>
                                            </>
                                        }
                                        {this.type("RO") &&
                                            <>
                                                <CompanyNameInput ph={"Example SRL"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("BG") &&
                                            <>
                                                <CompanyNameInput ph={"Example Eood"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"212345678"}/><br/>
                                            </>
                                        }
                                        {this.type("HR") &&
                                            <>
                                                <CompanyNameInput ph={"Example D.O.O."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678901"}/><br/>
                                            </>
                                        }
                                        {this.type("CY") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"3512345678901"}/><br/>
                                            </>
                                        }
                                        {this.type("LU") &&
                                            <>
                                                <CompanyNameInput ph={"Example Sarl"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("SG") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                <TextfieldInput name="Business Number" tag="company_bnr" ph={"12345678A"}/><br/>
                                            </>
                                        }

                                        {this.type("TR") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }
                                        {this.type("KR") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"12345678"}/><br/>
                                            </>
                                        }

                                        {this.type("RU") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"1234567801"}/><br/>
                                            </>
                                        }
                                        {this.type("AU") &&
                                            <>
                                                <CompanyNameInput ph={"Example Inc"}/>
                                                <TextfieldInput name="ABN" tag="company_vatId" ph={"12345678901"}/><br/>
                                            </>
                                        }
                                        {this.type("NZ") &&
                                            <>
                                                <CompanyNameInput ph={"Example Inc"}/>
                                                <TextfieldInput name="NZBN" tag="company_vatId" ph={"12345678901"}/><br/>
                                            </>
                                        }
                                        {this.type("AE") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="TRN" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("TW") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="TSN" tag="company_vatId" ph={"A100123456"}/><br/>
                                            </>
                                        }
                                        {this.type("UA") &&
                                            <>
                                                <CompanyNameInput ph={"Example OVG"}/>
                                                <TextfieldInput name="VAT Number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("JP") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="Main VAT number" tag="company_vatId" ph={"100123456"}/><br/>
                                            </>
                                        }
                                        {this.type("ZA") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"4123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("AR") &&
                                            <>
                                                <CompanyNameInput ph={"Example SRL"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"12345678901"}/><br/>
                                            </>
                                        }
                                        {this.type("CL") &&
                                            <>
                                                <CompanyNameInput ph={"Example SA"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"4123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("CO") &&
                                            <>
                                                <CompanyNameInput ph={"Example SAS"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("IS") &&
                                            <>
                                                <CompanyNameInput ph={"Example ehf"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"123456789"}/><br/>
                                            </>
                                        }
                                        {this.type("MA") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"1234567890123"}/><br/>
                                            </>
                                        }
                                        {this.type("MX") &&
                                            <>
                                                <CompanyNameInput ph={"Example C.V."}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"AB1234567AB1"}/><br/>
                                            </>
                                        }
                                        {this.type("NG") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"123456780001"}/><br/>
                                            </>
                                        }
                                        {this.type("SA") &&
                                            <>
                                                <CompanyNameInput ph={"Example Company"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"123456789012345"}/><br/>
                                            </>
                                        }
                                        {this.type("IN") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd"}/>
                                                <TextfieldInput name="VAT number" tag="company_vatId" ph={"123456789012345"}/><br/>
                                            </>
                                        }
                                        {this.type("EU") &&
                                            <>
                                                <CompanyNameInput ph={"Example GmbH"}/>
                                                <TextfieldInput name="IOSS Number" tag="company_vatId" ph={"IM123456789"}/><br/>
                                            </>
                                        }
                                        {this.state.general?.country?.includes("US-") &&
                                            <>
                                                <CompanyNameInput ph={"Example Inc"}/>
                                                <TextfieldInput name="EIN Number" tag="company_vatId" ph={"12-3456910"}/><br/>
                                            </>
                                        }
                                        {this.type("AG") &&
                                            <>
                                                <CompanyNameInput ph={"Example Ltd."}/>
                                                <TextfieldInput name="ABN" tag="company_vatId" ph={"GB1234567891"}/><br/>
                                            </>
                                        }
                                    </>
                            }

                            <TextfieldInput name="Note (optional)" tag="company_note" ph={"ref nr 1234"}/>
                            <br/>
                            {
                                this.state.GroupCompany?.ID && <>
                                    <p>
                                        <b style={{display: "inline-block", width: 150}}>Fiscal Representative</b>&nbsp;
                                        {
                                            this.state.GroupCompanyCountry?.fiscalRepresentative?.label
                                        }

                                    </p>

                                    <p>
                                        <b style={{display: "inline-block", width: 150}}>Agent</b>&nbsp;
                                        {
                                            this.state.GroupCompanyCountry?.agent?.label
                                        }
                                    </p>
                                    <p>
                                        <b style={{display: "inline-block", width: 150}}>White Label</b>&nbsp;
                                        {
                                            this.state.GroupCompany?.data?.whitelabel
                                        }

                                    </p>
                                </>
                            }


                            {
                                this.state.Cmp_key && <>
                                    <Link to={`/companies/${this.state.Cmp_key}/0`}>Go to Company Page <FaArrowCircleRight/></Link>
                                    <br/>
                                </>
                            }

                            {
                                (this.type("IE", "vat_return") || this.type("FR", "vat_return")) && <>
                                    <br/>
                                    <CredentialsSelect state={this.state} setStateParent={this.setState} updateParent={this.updateState}/>
                                </>
                            }
                        </Container>


                        {/*################################################################################################################################################################    */}


                        {
                            this.state.general?.report !== "manual_vat_registration" &&
                            <Container name={"Specific Data"} visible>
                                {
                                    !this.type("GB", "vat_return") && <SelectYear/>
                                }
                                {
                                    ["manual_vat_refund_directive", "manual_13th_directive", "manual_vat_notice_723a"].includes(this.state.general.report) ?
                                        <>
                                            {["manual_vat_refund_directive", "manual_13th_directive"].includes(this.state.general.report) ? <>
                                                    <SelectField name={"Number of Claim"} tag={"specific_period"} demandSelect
                                                                 selectives={[
                                                                     ["c1", "Claim 1"],
                                                                     ...(["CH", "AE"].includes(this.state.general?.country) ?
                                                                         [] :
                                                                         [
                                                                             ["c2", "Claim 2"],
                                                                             ["c3", "Claim 3"],
                                                                             ["c4", "Claim 4"]
                                                                         ]),
                                                                 ]}/>
                                                    {
                                                        !["CH", "AE"].includes(this.state.general?.country) && <>
                                                            <DateInput name={"Start of Period"} default={this.state.specific?.year && new Date(`${this.state.specific?.year}-01-01`)} tag={"specific_start"}/>
                                                            <DateInput name={"End of Period"} default={this.state.specific?.year && new Date(`${this.state.specific?.year}-12-31`)} tag={"specific_end"}/>
                                                        </>
                                                    }
                                                    <br/>
                                                </> :
                                                <>
                                                    <SelectField name={"Number of Claim"} tag={"specific_period"} demandSelect
                                                                 selectives={[
                                                                     ["ch1", "Claim 1"],
                                                                     ["ch2", "Claim 2"],
                                                                     ["ch3", "Claim 3"],
                                                                     ["ch4", "Claim 4"],
                                                                 ]
                                                                 }/>
                                                    <DateInput name={"Start of Period"} default={this.state.specific?.year && new Date(`${this.state.specific?.year}-07-01`)} tag={"specific_start"}/>
                                                    <DateInput name={"End of Period"} default={this.state.specific?.year && new Date(`${parseInt(this.state.specific?.year) + 1}-06-30`)} tag={"specific_end"}/>
                                                    <br/>
                                                </>
                                            }
                                        </>
                                        :
                                        <>

                                            {
                                                (this.type("GB", "ec_sl") || this.type("GB", "manual_ec_sl")) && <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                            }
                                            {
                                                this.type("GB", "vat_return") && <>

                                                    <CredentialsSelect state={this.state} setStateParent={this.setState} updateParent={this.updateState}/>
                                                    <VATObligationsSelect localVars={this.localVars} setStateParent={this.setState} filing_ID={this.state.id} updateParent={this.updateState}/>
                                                </>
                                            }
                                            {
                                                this.type("NL") && (this.type("NL", "manual_corporate_tax") ?
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectYearCombinations}/> :
                                                    <>

                                                        <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectQuarters, ...(this.type("NL", "manual_statistical_survey") ? [] : [...selectMonths, ...selectYearly])]}/>
                                                        <br/>
                                                        <label>
                                                        <span>
                                                            {Consts.labels.extension}
                                                        </span>
                                                            <label className={"switch"}>
                                                                <input type="checkbox" name={"specific_extension"} checked={
                                                                    this.state.specific !== undefined && this.state.specific.extension !== undefined && this.state.specific.extension === 1 ? "checked" : ""
                                                                }/>
                                                                <span className="slider"/>
                                                            </label>
                                                        </label>
                                                    </>)
                                            }

                                            {
                                                (this.type("BE", "manual_vat_return") || this.type("BE", "manual_ec_sl") || this.type("BE", "manual_intrastat")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("BE", "manual_b2b_sales_list") &&

                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectYearly}/>
                                            }
                                            {
                                                (this.type("IE", "manual_vat_return") || this.type("IE", "vat_return")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect
                                                             selectives={[
                                                                 ["71", "2m - January-February"],
                                                                 ["73", "2m - March-April"],
                                                                 ["75", "2m - May-June"],
                                                                 ["77", "2m - July-August"],
                                                                 ["79", "2m - September-October"],
                                                                 ["81", "2m - November-December"],
                                                                 ["51", "4m - January-April"],
                                                                 ["55", "4m - May-August"],
                                                                 ["59", "4m - September-December"],
                                                                 ["61", "6m - January-June"],
                                                                 ["67", "6m - July-December"],
                                                                 ...selectYearCombinations
                                                             ]}/>
                                            }
                                            {
                                                this.type("IE", "manual_ec_sl") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                (this.type("IE", "manual_intrastat/arrivals") || this.type("IE", "manual_intrastat/dispatches")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                (this.type("IE", "manual_corporate_tax")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ...selectYearCombinations,
                                                ]}/>
                                            }
                                            {
                                                (this.type("FR", "manual_vat_return") || this.type("FR", "vat_return")) && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                                    <br/>
                                                    <label>
                                                <span>
                                                    Fiscal Rep Filing Extension
                                                </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_extension"} checked={
                                                                this.state.specific !== undefined && this.state.specific.extension !== undefined && this.state.specific.extension === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }
                                            {
                                                (this.type("FR", "manual_intrastat") || this.type("FR", "manual_ec_sl")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("IT", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters.concat(selectYearly)}/>
                                            }
                                            {
                                                this.type("IT", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("IT", "manual_esterometro") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                (this.type("IT", "manual_vat_payment") || this.type("IT", "manual_vat_payment_with_f24")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters, ["prepay", "Pre Payment"]]}/>
                                            }
                                            {
                                                this.type("DE", "manual_vat_payment") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[["prepay", "Filing Extension Pre Payment"]]}/>
                                            }
                                            {
                                                this.type("IT", "manual_vat_refund") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters.filter(([a,]) => a <= 43)}/>
                                            }
                                            {
                                                this.type("DE", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                (this.type("DE", "manual_ec_sl") || this.type("DE", "ec_sl")) && <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ...selectMonths,
                                                    ...selectQuarters,
                                                    ["71", "January-February (2m)"],
                                                    ["74", "April-May (2m)"],
                                                    ["77", "July-August (2m)"],
                                                    ["80", "October-November (2m)"],
                                                    ...selectYearly,
                                                ]}/>
                                            }
                                            {
                                                this.type("GB", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("GB", "manual_vat_return") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat([
                                                        ["91", "Quarter I end. Mar"],
                                                        ["92", "Quarter I end. Apr"],
                                                        ["93", "Quarter I end. May"],
                                                        ["94", "Quarter II end. Jun"],
                                                        ["95", "Quarter II end. Jul"],
                                                        ["96", "Quarter II end. Aug"],
                                                        ["97", "Quarter III end. Sep"],
                                                        ["98", "Quarter III end. Oct"],
                                                        ["99", "Quarter III end. Nov"],
                                                        ["100", "Quarter IV end. Dec"],
                                                        ["101", "Quarter IV end. Jan"],
                                                        ["102", "Quarter IV end. Feb"],
                                                        ...selectYearCombinations
                                                    ])}/>
                                                </>
                                            }
                                            {
                                                (this.type("GB", "manual_vat_return") || this.type("GB", "vat_return")) && <>
                                                    {
                                                        !(this.state?.specific?.period > 110) && <>
                                                            <br/>
                                                            <label>
                                                    <span>
                                                         Payment on Account{/*#"simplified annual -> misuse of extension"*/}
                                                    </span>
                                                                <label className={"switch"}>
                                                                    <input type="checkbox" name={"specific_paymentOnAccount"} checked={
                                                                        this.state?.specific?.paymentOnAccount === 1 ? "checked" : ""
                                                                    }/>
                                                                    <span className="slider"/>
                                                                </label>
                                                            </label>
                                                        </>
                                                    }
                                                </>
                                            }
                                            {
                                                this.type("GB", "manual_plastic_packaging_tax_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters}/>
                                            }
                                            {
                                                this.type("CH",) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }

                                            {
                                                (this.type("PL", "manual_vat_return") || this.type("PL", "manual_ec_sl")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                (this.type("PL", "manual_intrastat/arrivals") || this.type("PL", "manual_intrastat/dispatches")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("PL", "manual_piva_licence") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectDate}/>
                                            }

                                            {
                                                this.type("SE", "manual_vat_return") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters, ...selectYearly, ["yf2", "Annual (July to June)"]]}/>
                                                    <br/>
                                                    <label>
                                            <span>
                                                 Turnover above 40m SEK{/*#"simplified annual -> misuse of extension"*/}
                                            </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_extension"} checked={
                                                                this.state.specific !== undefined && this.state.specific.extension !== undefined && this.state.specific.extension === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }
                                            {
                                                this.type("SE", "manual_ec_sl") &&
                                                <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                                    <br/>
                                                    <CheckboxInput name={"Filing on Paper?"} tag={"specific_paperfiling"}/>
                                                </>
                                            }
                                            {
                                                this.type("SE", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }

                                            {
                                                (this.type("FI", "manual_ec_sl") || this.type("FI", "manual_intrastat")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("FI", "manual_vat_return") &&
                                                <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters).concat(selectYearly)}/>
                                                    <br/>
                                                    <label>
                                                <span>
                                                {Consts.labels.paperfiling}
                                                </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_paperfiling"} checked={
                                                                this.state.specific !== undefined && this.state.specific.paperfiling !== undefined && this.state.specific.paperfiling === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }
                                            {
                                                this.type("DK", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters, ["61", "January-June"], ["67", "July-December"]]}/>
                                            }
                                            {
                                                (this.type("DK", "manual_intrastat") || this.type("DK", "manual_ec_sl")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("ES", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters).concat(selectYearly)}/>
                                            }
                                            {
                                                (this.type("ES", "manual_ec_sl")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                            }
                                            {
                                                (this.type("ES", "manual_sii")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                            }
                                            {
                                                (this.type("ES", "manual_form_347")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectYearly]}/>
                                            }
                                            {
                                                (this.type("ES", "manual_intrastat/arrivals") || this.type("ES", "manual_intrastat/dispatches")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }

                                            {
                                                (this.type("CZ", "manual_vat_return") || this.type("CZ", "manual_ec_sl")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                (this.type("CZ", "manual_intrastat") || this.type("CZ", "manual_control_statement")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }

                                            {
                                                this.type("NO", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ["71", "January-February"],
                                                    ["73", "March-April"],
                                                    ["75", "May-June"],
                                                    ["77", "July-August"],
                                                    ["79", "September-October"],
                                                    ["81", "November-December"]
                                                ]}/>
                                            }
                                            {
                                                this.type("NO", "manual_voes") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters}/>
                                            }
                                            {
                                                this.state.general?.country?.slice(0, 2) === "CA" && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                        ...selectMonths,
                                                        ["91", "Quarter I end. Mar"],
                                                        ["92", "Quarter I end. Apr"],
                                                        ["93", "Quarter I end. May"],
                                                        ["94", "Quarter II end. Jun"],
                                                        ["95", "Quarter II end. Jul"],
                                                        ["96", "Quarter II end. Aug"],
                                                        ["97", "Quarter III end. Sep"],
                                                        ["98", "Quarter III end. Oct"],
                                                        ["99", "Quarter III end. Nov"],
                                                        ["100", "Quarter IV end. Dec"],
                                                        ["101", "Quarter IV end. Jan"],
                                                        ["102", "Quarter IV end. Feb"],

                                                        ...(this.state.general?.country?.slice(3, 5) === "BC" ? [["61", "6m - January-June"], ["67", "6m - July-December"]] : []),


                                                        ...(this.state.general?.country?.slice(3, 5) !== "MB" ? selectYearCombinations : [["111", "Year start. Jan"]])


                                                    ]}/>

                                                    {
                                                        this.type("CA-SK", "manual_pst") && <CheckboxInput name={"Filing on Paper?"} tag={"specific_paperfiling"}/>
                                                    }
                                                </>
                                            }
                                            {this.type("AT") && <>
                                                {
                                                    this.type("AT", "manual_intrastat") ?
                                                        <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/> :
                                                        <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters, ...selectYearly]}/>
                                                }</>
                                            }
                                            {
                                                this.type("HU", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters, selectYearly)}/>
                                            }
                                            {
                                                this.type("HU", "manual_ec_sl") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("HU", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("HU", "manual_real_time_reporting") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectDate.filter(([a, b]) => ["01", "08", "15", "22", "29"].includes(a.substr(1, 2)))}/>
                                            }
                                            {
                                                this.type("SK", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("SK", "manual_ec_sl") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                (this.type("SK", "manual_intrastat") || this.type("SK", "manual_control_statement")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("SI") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("BG") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("SI", "manual_vat_return") &&
                                                <>
                                                    <br/>
                                                    <label>
                                                <span>
                                                Filing ECSL as well?
                                                </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_ecsl"} checked={
                                                                this.state.specific !== undefined && this.state.specific.ecsl !== undefined && this.state.specific.ecsl === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }

                                            {
                                                this.type("MT", "manual_vat_return") &&
                                                <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                        ["91", "Quarter I end. Mar"],
                                                        ["92", "Quarter I end. Apr"],
                                                        ["93", "Quarter I end. May"],
                                                        ["94", "Quarter II end. Jun"],
                                                        ["95", "Quarter II end. Jul"],
                                                        ["96", "Quarter II end. Aug"],
                                                        ["97", "Quarter III end. Sep"],
                                                        ["98", "Quarter III end. Oct"],
                                                        ["99", "Quarter III end. Nov"],
                                                        ["100", "Quarter IV end. Dec"],
                                                        ["101", "Quarter IV end. Jan"],
                                                        ["102", "Quarter IV end. Feb"],

                                                        ...selectYearCombinations
                                                    ]}/>
                                                    <CheckboxInput name={"Filing on Paper?"} tag={"specific_paperfiling"}/>
                                                </>
                                            }
                                            {
                                                (this.type("MT", "manual_ec_sl") || this.type("MT", "manual_intrastat")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }

                                            {
                                                this.type("LV", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters).concat([
                                                    ["61", "6m - January-June"],
                                                    ["67", "6m - July-December"],
                                                ])}/>
                                            }
                                            {
                                                (this.type("LV", "manual_ec_sl") || this.type("LV", "manual_intrastat")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("LT", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectYearly)}/>
                                            }
                                            {
                                                (this.type("LT", "manual_ec_sl") || this.type("LT", "manual_intrastat") || this.type("LT", "manual_saft")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                (this.type("EE", "manual_vat_return") || this.type("EE", "manual_ec_sl")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectQuarters, ...selectMonths]}/>
                                            }
                                            {
                                                this.type("PT", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters, ...selectYearly]}/>
                                            }
                                            {
                                                this.type("PT", "manual_ec_sl") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                            }
                                            {
                                                (this.type("PT", "manual_intrastat") || this.type("PT", "manual_saft")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("GR", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                (this.type("GR", "manual_ec_sl") || this.type("GR", "manual_intrastat")) &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("RO") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...(this.type("RO", "394") ? [] : selectQuarters)]}/>
                                            }
                                            {
                                                this.type("HR") && !this.type("HR", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("HR") && this.type("HR", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("LU") && !this.type("LU", "manual_intrastat") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters, ...selectYearly]}/>
                                                    <br/>
                                                    <label>
                                            <span>
                                                Simplified Annual {/*#"simplified annual -> misuse of extension"*/}
                                            </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_extension"} checked={
                                                                this.state.specific !== undefined && this.state.specific.extension !== undefined && this.state.specific.extension === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }
                                            {
                                                this.type("LU", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("SG", "manual_gst") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat([
                                                    ["91", "Quarter I end. Mar"],
                                                    ["92", "Quarter I end. Apr"],
                                                    ["93", "Quarter I end. May"],
                                                    ["94", "Quarter II end. Jun"],
                                                    ["95", "Quarter II end. Jul"],
                                                    ["96", "Quarter II end. Aug"],
                                                    ["97", "Quarter III end. Sep"],
                                                    ["98", "Quarter III end. Oct"],
                                                    ["99", "Quarter III end. Nov"],
                                                    ["100", "Quarter IV end. Dec"],
                                                    ["101", "Quarter IV end. Jan"],
                                                    ["102", "Quarter IV end. Feb"],
                                                ])}/>
                                            }
                                            {
                                                this.state.general !== undefined && (this.state.general.report === "manual_oss" || this.state.general.report === "manual_non-eu-oss") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters}/>
                                            }
                                            {
                                                this.type("TR") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("KR") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters}/>
                                            }
                                            {
                                                this.type("RU") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths.concat(selectQuarters)}/>
                                            }
                                            {
                                                this.type("AR") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths]}/>
                                            }
                                            {
                                                this.type("CL") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths]}/>
                                                    <br/>
                                                    <label>
                                                        <span>
                                                            Filing Extension
                                                        </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_extension"} checked={
                                                                this.state.specific !== undefined && this.state.specific.extension !== undefined && this.state.specific.extension === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }
                                            {
                                                this.type("CO") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                        ["71", "2m - January-February"],
                                                        ["73", "2m - March-April"],
                                                        ["75", "2m - May-June"],
                                                        ["77", "2m - July-August"],
                                                        ["79", "2m - September-October"],
                                                        ["81", "2m - November-December"],
                                                        ["51", "4m - January-April"],
                                                        ["55", "4m - May-August"],
                                                        ["59", "4m - September-December"],
                                                    ]}/>
                                                    <TextfieldInput name={Consts.labels.deadlineDays} ph={"10"} tag={"specific_deadlineDays"}/>
                                                </>
                                            }
                                            {
                                                this.type("MA") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                            }
                                            {
                                                this.type("MX") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths]}/>
                                            }
                                            {
                                                this.type("NG") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths]}/>
                                            }
                                            {
                                                this.type("SA") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                            }
                                            {
                                                this.type("IN") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectYearCombinations,
                                                    ["64", "6m - April-September"],
                                                    ["70", "6m - October-March"],
                                                ]}/>
                                            }

                                            {
                                                this.type("CY") && !this.type("CY", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectQuarters}/>
                                            }
                                            {
                                                this.type("CY", "manual_intrastat") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={selectMonths}/>
                                            }
                                            {
                                                this.type("AE") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ["91", "Quarter I end. Mar"],
                                                    ["92", "Quarter I end. Apr"],
                                                    ["93", "Quarter I end. May"],
                                                    ["94", "Quarter II end. Jun"],
                                                    ["95", "Quarter II end. Jul"],
                                                    ["96", "Quarter II end. Aug"],
                                                    ["97", "Quarter III end. Sep"],
                                                    ["98", "Quarter III end. Oct"],
                                                    ["99", "Quarter III end. Nov"],
                                                    ["100", "Quarter IV end. Dec"],
                                                    ["101", "Quarter IV end. Jan"],
                                                    ["102", "Quarter IV end. Feb"],
                                                ]}/>
                                            }
                                            {
                                                this.type("AU") &&
                                                <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                        ...selectMonths,
                                                        ...selectQuarters,
                                                        ["111", "Year start. Jan"],
                                                        ["117", "Year start. Jul"],
                                                    ]}/>
                                                    <br/>
                                                    <label>
                                            <span>
                                                Filing Extension
                                            </span>
                                                        <label className={"switch"}>
                                                            <input type="checkbox" name={"specific_extension"} checked={
                                                                this.state.specific !== undefined && this.state.specific.extension !== undefined && this.state.specific.extension === 1 ? "checked" : ""
                                                            }/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </label>
                                                </>
                                            }
                                            {
                                                this.type("NZ") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ...selectMonths,
                                                    ...selectQuarters,
                                                    ["71", "2m - January-February"],
                                                    ["73", "2m - March-April"],
                                                    ["75", "2m - May-June"],
                                                    ["77", "2m - July-August"],
                                                    ["79", "2m - September-October"],
                                                    ["81", "2m - November-December"],
                                                    ["61", "6m - January-June"],
                                                    ["67", "6m - July-December"],
                                                ]}/>
                                            }
                                            {
                                                this.type("TW") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ["71", "2m - January-February"],
                                                    ["73", "2m - March-April"],
                                                    ["75", "2m - May-June"],
                                                    ["77", "2m - July-August"],
                                                    ["79", "2m - September-October"],
                                                    ["81", "2m - November-December"],
                                                    ...selectMonths,
                                                ]}/>
                                            }
                                            {
                                                this.type("JP", "manual_ct_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ...selectYearCombinations
                                                ]}/>
                                            }
                                            {
                                                this.type("JP", "manual_ct_payment") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ...selectYearly,
                                                    ...selectQuarters,
                                                    ["130", "Yearly (interim)"],
                                                    ["prepay", "Prepayment"],
                                                ]}/>
                                            }
                                            {
                                                this.type("ZA", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ...selectMonths,
                                                    ["71", "2m - January-February"],
                                                    ["72", "2m - February-March"],
                                                    ["73", "2m - March-April"],
                                                    ["74", "2m - April-May"],
                                                    ["75", "2m - May-June"],
                                                    ["76", "2m - June-July"],
                                                    ["77", "2m - July-August"],
                                                    ["78", "2m - August-September"],
                                                    ["79", "2m - September-October"],
                                                    ["80", "2m - October-November"],
                                                    ["81", "2m - November-December"],
                                                    ["82", "2m - December-January"],
                                                    ["63", "6m - March-August"],
                                                    ["69", "6m - September-February"],
                                                    ...selectYearly
                                                ]}/>
                                            }
                                            {
                                                this.type("IS", "manual_vat_return") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[
                                                    ["71", "2m - January-February"],
                                                    ["73", "2m - March-April"],
                                                    ["75", "2m - May-June"],
                                                    ["77", "2m - July-August"],
                                                    ["79", "2m - September-October"],
                                                    ["81", "2m - November-December"],
                                                ]}/>
                                            }
                                            {
                                                this.type("EU") &&
                                                <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths,]}/>
                                            }
                                            {

                                                this.type("UA") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectQuarters, ...selectMonths]}/>
                                                </>
                                            }
                                            {

                                                (this.type("US-IL") || this.type("US-NC") || this.type("US-UT") || this.type("US-TX")) && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectQuarters,]}/>
                                                </>
                                            }
                                            {

                                                this.type("US-NJ") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectQuarters, ...selectMonths]}/>
                                                </>
                                            }
                                            {

                                                this.type("US-WA") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths]}/>
                                                </>
                                            }
                                            {

                                                this.type("AG") && <>
                                                    <SelectField name={"Period"} tag={"specific_period"} demandSelect selectives={[...selectMonths, ...selectQuarters]}/>
                                                    <br/>
                                                    <TextfieldInput name={Consts.labels.deadlineMonths} ph={"1"} tag={"specific_deadlineMonths"}/>
                                                    <TextfieldInput name={Consts.labels.deadlineDays} ph={"10"} tag={"specific_deadlineDays"}/>
                                                </>
                                            }
                                            {(this.type("DE", "vat_return") || this.type("DE", "manual_vat_return")) && <DEVATReturn/>}
                                            <br/>
                                        </>
                                }
                                {
                                    this.state.duedate && <>
                                        Due Date: {dateFormat(new Date(this.state.duedate * 1000))}
                                        {
                                            !!this.state.iduedate && this.state.duedate !== this.state.iduedate && <>
                                                , Internal Due Date: {
                                                dateFormat(new Date(this.state.iduedate * 1000))
                                            }
                                            </>
                                        }
                                    </>
                                }

                                <br/>
                                <TextfieldInput labelStyle={{width: 350}} type={"number"} name={<>
                                    Internal Deadline: Offset in Working Days &nbsp;
                                    <Popup on={"hover"} trigger={<i className={"fa fa-info-circle"} style={{cursor: "pointer"}}/>}>
                                        The number of workings days in the UK the internal deadline should be prior to the actual deadline. If 0 then the internal deadline is ignored. The default is {this.state.iduedateDefaultOffset ?? 0}.
                                    </Popup>
                                </>} ph={this.state.iduedateDefaultOffset ?? 0} tag={"company_internalDuedateOffset"}/>
                            </Container>
                        }
                        <br/>


                        {/*end company data*/}

                        {
                            this.state.general.report !== "manual_vat_registration" && <Container name={Consts.labels.fileID} visible nobreak>
                                <FileSelection allowDelete={this.state.write_allowed} disabled={!this.state.write_allowed}/>
                                <UploadMultipleFiles label={<b style={{fontWeight: 400}}>{Consts.labels.inputFiles}</b>} scope={"inputFiles"} workbench files={this.state.inputFiles} disabled={!this.state.write_allowed} setStateParent={this.setState} checkForAutosave={this.checkForAutosave}/>

                                {
                                    this.type("FR", "vat_return") && <FRLoadImportFigures filing={this.state}/>
                                }

                            </Container>
                        }
                        <br/>
                        <Container name={"Submission"} visible>
                            {
                                (this.isManual() || !(NilReturnsRequired[this.state.general.country] || {})[this.state.general.report]) && <>

                                    {
                                        this.state.specific?.notRequiredToSubmit !== "0" && <>
                                            <label className={"maxCheckbox"}>
                                                <span style={{width: 180}}>
                                                    Submitted?
                                                    &nbsp;<Popup trigger={<i className={"fa fa-info-circle"}/>} on={"hover"}>Has no effect besides showing it under the submitted or unsubmitted section in the list of filings!</Popup>
                                                </span>
                                                <label className={"switch"}>
                                                    <input type="checkbox" name={this.state.general.report === "manual_13th_directive" ? "specific_submittedForApproval" : "specific_submitted"}
                                                           checked={(this.state.specific || {})[this.state.general.report === "manual_13th_directive" ? "submittedForApproval" : "submitted"] === 1 ? "checked" : ""}/>
                                                    <span className="slider"/>
                                                </label>
                                            </label>

                                        </>
                                    }

                                    <SelectField tag={"specific_notRequiredToSubmit"} labelStyle={{width: 180}} name={Consts.labels.notRequiredToSubmit} demandSelectText={"unknown"} selectives={[["0", "not required to submit"], ["1", "required to submit"]]}/>


                                    {
                                        (this.state.specific || {})[this.state.general.report === "manual_13th_directive" ? "submittedForApproval" : "submitted"] === 1 && this.state.specific?.notRequiredToSubmit !== "0" && <>
                                            <DateInput name={Consts.labels.submitTime} default={new Date()} tag={"specific_submitTime"} labelStyle={{width: 180}}/>
                                        </>
                                    }
                                    <br/>

                                </>
                            }
                            {
                                !["manual_vat_refund_directive", "manual_13th_directive", "manual_vat_notice_723a"].includes(this.state.general.report) && <>
                                    <CheckboxInput tag={"specific_nil"} labelStyle={{width: 180}} name={"NIL?"}/>
                                    <CheckboxInput tag={"specific_correction"} labelStyle={{width: 180}} name={"Correction?"}/>
                                    <SelectField tag={"specific_directDebitSetUp"} labelStyle={{width: 180}} name={Consts.labels.directDebitSetUp} demandSelectText={"unknown"} selectives={[[0, "not set up"], [1, "set up"]]}/>
                                </>
                            }

                            <SelectField tag={"specific_chargeable"} labelStyle={{width: 180}} name={"Chargeable?"} demandSelectText={"unknown"} selectives={[["1", "chargeable"], ["0", "not chargeable"]]}/>

                            {
                                this.isManual() && this.state.general.report !== "manual_vat_registration" && <UploadMultipleFiles label={<>
                                    <b style={{fontWeight: 400}}>{Consts.labels.submissionFiles}</b>&nbsp;
                                    <Popup on={"hover"} trigger={<i className={"fa fa-info-circle"} style={{cursor: "pointer"}}/>}>
                                        If the submission is done manually this should be the confirmation / final page of the filing.
                                    </Popup>
                                </>} scope={"submissionFiles"} files={this.state.submissionFiles} setStateParent={this.setState} disabled={!this.state.write_allowed} checkForAutosave={this.checkForAutosave}/>
                                /*
                                todo: additional file for automatic submissions
                                 */
                            }

                            <InsertIfNonClient>
                                <EditorInput name={"Internal Notes"} tag={"specific_internalNotes"}/>
                            </InsertIfNonClient>

                            {/*<TextfieldInput name={"Email filing to"} tag={"company_email"} type={"email"} ph={"john.doe@vatomator.com"} style={{width: "350px"}}/><br/>*/}
                            <h3 style={{marginTop: 20, fontWeight: 400}}>{Consts.labels.emailTo}</h3>
                            {
                                [Consts.ENV_ESSENTIA, Consts.ENV_DEMO].includes(this.context.user.EnvID) ? <>
                                    {
                                        this.state.GroupCompany?.ID && <p>
                                            <b style={{fontWeight: 400}}>Send Confirmation To</b>:&nbsp;
                                            {
                                                this.state.GroupCompanyContacts?.filter(a => a.sendSubmissionConfirmation).map(a => a.emailAddress).join(", ")
                                            } (edit on <Link to={"/groupcompanies/" + this.state.GroupCompany?.ID}>Companies Page</Link>)
                                        </p>
                                    }
                                </> : <>
                                    {
                                        this.state.emailTo.length > 0 && <>
                                            <label>
                                                <span style={{width: 155}}>Send To:</span>
                                                {ListStringHuman(this.state.emailTo, this.deleteableList)}
                                            </label>
                                            <br/>
                                        </>
                                    }
                                    <TextfieldInput name={"Add Email Address"} tag={"company_emailToTmp"} type={"email"} ph={"john.doe@vatomator.com"} style={{width: "350px"}}/>

                                    <em onClick={(e) => {
                                        if (this.state.company.emailToTmp.trim().length > 0) {
                                            this.setState({emailTo: this.state.emailTo.concat([this.state.company.emailToTmp]), company: Object.assign({}, this.state.company, {emailToTmp: ""})})

                                        }
                                    }}>
                                        <i className={"fa fa-plus-circle"}/> add </em>
                                    &nbsp;&nbsp;&nbsp;
                                    <Popup trigger={<i className={"fa fa-info-circle"}/>} on={"hover"}>
                                        <div>
                                            If <em>Submit</em> or <em>Send Response via Email</em> is clicked the submitted files and the response are sent to these email addresses.
                                        </div>
                                    </Popup>
                                    <br/>
                                </>
                            }

                            <Container name={Consts.labels.emailAdd} type={"sub"}>
                                <EditorInput tag={"company_emailAdd"}/>
                            </Container>

                        </Container>


                        {
                            this.state.general.report === "manual_vat_registration" && <NewRegistration {...{countryName}}/>
                        }

                        {
                            ["manual_vat_refund_directive", "manual_13th_directive", "manual_vat_notice_723a"].includes(this.state.general.report) && <Container name={"Paid"}>
                                <SelectField name={"\"PAID\" Received from Tax Office"} tag={"specific_receivedFromTaxOffice"} selectives={[["yes", "Yes in Full"], ["partial", "Partial"], ["no", "No"]]} demandSelect labelStyle={{width: 210}}/>
                                <FileUpload name={"Proof of Reclaim Amount"} tag={"specific_proofReclaimAmount"} setStateParent={a => this.updateState("specific", "proofReclaimAmount", a["proofReclaimAmount"])} labelStyle={{width: 210}}/>
                            </Container>
                        }


                        <InsertIfNonClient>
                            <FileUploadNotification collaborators={this.state.collaborators} country={this.state.GroupCompanyCountry} GroupCompany_ID={this.state.GroupCompany?.ID}/>
                        </InsertIfNonClient>

                        <Reminder checkForAutosave={this.checkForAutosave}/>

                        <AccountManagers/>

                        {
                            this.state.test_submits !== undefined && this.state.test_submits.length > 0 &&

                            <Container name={Consts.labels.test_submits}>
                                {
                                    this.state.test_submits.map(a => {
                                        return <li><a target="_blank" rel="noopener noreferrer" href={Consts.API_PREFIX + "/file/download/" + a}>download (pdf, {
                                            !!a && dateFormatTime(a.split("-")[0].split("/")[1].split(".")[0] * 1000)
                                        })</a></li>
                                    })
                                }
                            </Container>
                        }

                    </form>


                    {
                        this.state.loadedMain && <ReviewContainer collaborators={this.state.collaborators}>
                            <UploadMultipleFiles label={"Files to Review"} scope={"reviewFiles"} workbench files={this.state.reviewFiles} setStateParent={this.setState} checkForAutosave={this.checkForAutosave}/>
                        </ReviewContainer>
                    }
                    {
                        this.state.loading &&
                        <span><img alt="loader" src="/src/img/ajax-loader-fff.gif"/>&nbsp;&nbsp;&nbsp;</span>
                    }

                    <Status type="success" text={this.state.status}>
                        {this.state.submitted &&
                            <span><br/>submitted - <Link to={"/filings/" + this.state.id}>see details</Link></span>
                        }

                        {
                            this.state.submitted && this.type("DE") && this.state.fname !== undefined && this.state.fname !== "" &&
                            <span> <br/><a target="_blank" rel="noopener noreferrer" href={Consts.API_PREFIX + "/file/download/" + this.state.fname}>see result (pdf)</a></span>
                        }
                        {
                            this.state.submitted && this.type("DE") && this.state.response_file !== undefined && this.state.response_file !== "" &&
                            <span> <br/><a target="_blank" rel="noopener noreferrer" href={Consts.API_PREFIX + "/file/download/" + this.state.response_file}>see result (pdf)</a></span>
                        }
                    </Status>
                    <Status type="error" text={this.state.error}/>

                    <InsertIfNonClient otherCondition={this.state.write_allowed}>
                        {
                            !this.state.id > 0 &&
                            <button type={"button"} onClick={() => this.saveForm()}>Create</button>
                        }

                        &nbsp;
                        {
                            !this.isManual() &&
                            <button onClick={() => {
                                this.doSubmit("live")
                            }}> Submit {this.state.userID !== undefined && Consts.f("(Demo)", "", this.state.userID)}</button>
                        }

                        {
                            (this.type("DE", "vat_return") || this.type("DE", "ec_sl")) &&
                            <span>&nbsp;
                                <button type={"button"} onClick={() => {
                                    this.doSubmit("testing")
                                }}>Test Submit</button>
                                </span>
                        }
                        &nbsp;
                        {
                            (this.isManual() || Consts.f(true, false)) &&
                            <button onClick={this.sendResponse}>
                                Send Submission Confirmation via Email
                            </button>
                        }

                        {
                            this.state.write_allowed && <FilingHistory
                                object_type={1}
                                object_ID={this.state.id}
                            />
                        }
                    </InsertIfNonClient>
                    {
                        [0, 1, 2, 3, 4, 5, 6, 7, 8].map(() => <br/>)
                    }


                </div>

            </FormContext.Provider>
        )
            ;
    }
}

let dateNow = new Date();
const SelectYear = () =>
    <SelectField name={"Year"} tag={"specific_year"} demandSelect noBreaks selectives={_.range(dateNow.getFullYear() - 7, dateNow.getFullYear() + 2).reverse()}/>
;

const selectMonths = [
    ["01", "January"],
    ["02", "February"],
    ["03", "March"],
    ["04", "April"],
    ["05", "May"],
    ["06", "June"],
    ["07", "July"],
    ["08", "August"],
    ["09", "September"],
    ["10", "October"],
    ["11", "November"],
    ["12", "December"],
];
const selectQuarters = [
    ["41", "Quarter I"],
    ["42", "Quarter II"],
    ["43", "Quarter III"],
    ["44", "Quarter IV"],
];
const selectYearly = [
    ["yf", "Annual"],
];
const selectYearCombinations = [
    ["111", "Year start. Jan"],
    ["112", "Year start. Feb"],
    ["113", "Year start. Mar"],
    ["114", "Year start. Apr"],
    ["115", "Year start. May"],
    ["116", "Year start. Jun"],
    ["117", "Year start. Jul"],
    ["118", "Year start. Aug"],
    ["119", "Year start. Sep"],
    ["120", "Year start. Oct"],
    ["121", "Year start. Nov"],
    ["122", "Year start. Dec"],
];

const months = [" January",
    " February",
    " March",
    " April",
    " May",
    " June",
    " July",
    " August",
    " September",
    " October",
    " November",
    " December"];

const selectDate = _.range(1001, 1366).map(i => {
    const d = dateFromDay(2023, i - 1000)
    return ["1" + d.getDate().toString().padStart(2, "0") + (d.getMonth() + 1).toString().padStart(2, "0"), d.getDate() + " " + months[d.getMonth()].substring(1, 4)]
})

function zeroPad(num, places) {
    var zero = places - num?.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

function createATMonths() {
    let dateNow = new Date();
    let thisYear = _.range(1, dateNow.getMonth() + 1).map(x => {
        return [dateNow.getFullYear() + "-" + zeroPad(x, 2), dateNow.getFullYear() + " " + months[x - 1]]
    });
    let lastYear = _.range(dateNow.getMonth(), 13).map(x => {
        return [dateNow.getFullYear() + "-" + zeroPad(x, 2), dateNow.getFullYear() + " " + months[x - 1]]
    });

    let select = _.range(1, 14).map((i) => {
        if (dateNow.getMonth() - i >= 0) {
            return thisYear[dateNow.getMonth() - i]
        } else {
            return lastYear[12 - i + 1]
        }
    });
    return select
}

/*
</select>*/

const CompanyNameInput = ({
                              ph
                          }) =>
    <>
        <TextfieldInput name="Company Name" tag="company_name" ph={ph}/>
        <SearchUID/>
        <br/>
    </>
;

function defVal(a, b) {
    if (a !== undefined)
        return a;
    return b
}

defVal("a", "b");

export default FilingNew;


