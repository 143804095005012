import React from 'react';
import {Container, TextfieldInput} from "../core/input_fields"
import Status from "../core/status"
import {apiGet, apiPost} from "../core/api";
import {UserContext} from "./UserContext";
import "./login.sass"
import {FaInfoCircle} from "react-icons/fa";
import Popup from "reactjs-popup";

class ChangePW extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        console.log()
        this.state = {
            displayname: "",
            password1: "",
            password2: "",
            token: props.match.params.token,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value
        })

    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        this.apiPost("/user/changepw", this.state, resp => {
            this.setState({
                error: "",
                status: resp.message,
            });
            if (resp.status === "success") {
                window.location.href = "/"
            }
        });

    }

    render() {
        //<img src={Porträt} style={{float: "right"}} width={"300px"} className={"animgrey"}/>
        return (
            <form id="form" name="form" onSubmit={this.handleSubmit} onChange={this.handleChange}>
                <Container name={"Setup/Change Password"} visible>
                    {
                        this.context.user.status !== "logged_in" && <>
                            <Status type="error" text={this.state.error}/>
                            {/*<Status type="success" text={this.state.status}/>*/}
                            <TextfieldInput name={<>Display Name <Popup trigger={<em><FaInfoCircle/></em>}>The part of your name other users see. Insert your fullname, nickname, forename, surname, etc..</Popup></>} ph={"John Doe"} tag={"displayname"} type={"text"} state={this.state}/><br/>
                            <TextfieldInput name={"Password"} tag={"password1"} type={"password"} state={this.state}/><br/>
                            <TextfieldInput name={"Password again"} tag={"password2"} type={"password"} state={this.state}/><br/>
                            <button className={"maxbtn"}>Setup/Change</button>
                            {this.state.loading && <img alt={"loader"} src="/src/img/ajax-loader-fff.gif"/>}
                        </>
                    }
                    {
                        this.context.user.status === "logged_in" && <>
                            You are already logged in with {this.context.user.Email}!
                        </>
                    }

                </Container>
            </form>
        )
    }
}

export default ChangePW;
