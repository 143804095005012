import React from 'react';
import {LightContainer, Loader, TextfieldInput} from "../core/input_fields"
import Status from "../core/status"
import {apiGet, apiPost} from "../core/api";
import "./login.sass"
import {deleteUsersCache} from "../core/core";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            action: "login",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);

        const pn = this.props.location.pathname || "";
        if (pn.substr(0, 16) === "/user/logout") {
            this.apiGet("/user/logout", () => {
                deleteUsersCache()
                window.location.href = "/user/login"
            });

        } else {
            this.apiGet("/user/status", resp => {
                if (resp.status === "logged_in") {
                    let t = localStorage.getItem("redir");
                    localStorage.setItem("redir", "");
                    if (t === "0" || t === "" || t === null || t === "/0" || t === undefined || t.substr(0, 15) === "/user/login" || t.substr(0, 16) === "/user/logout") {
                        t = "/";
                    }
                    console.log("redir to ", t)
                    window.location.href = t
                }
            });
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true})
        if (this.state.action === "resetpw") {
            this.apiPost("/user/request_pwreset", this.state, resp => {
                this.setState({
                    loading: false,
                    error: "",
                    status: resp.message,
                });
            });

        } else {

            this.apiPost("/user/login", this.state, resp => {
                this.setState({
                    error: "",
                    status: resp.message,
                });
                let t = localStorage.getItem("redir")
                if (t === "0" || t === "" || t === null || t === "/0" || t === undefined || t.substr(0, 15) === "/user/login" || t.substr(0, 16) === "/user/logout") {
                    t = "/";
                }
                localStorage.setItem("redir", "/");
                window.setTimeout(() => {
                    console.log("redir to ", t)
                    window.location.href = t
                }, 500)
            });
        }

    }

    render() {
        //<img src={Porträt} style={{float: "right"}} width={"300px"} className={"animgrey"}/>
        return (
            <form id="form" name="form" onSubmit={this.handleSubmit} onChange={this.handleChange}>
                <LightContainer name={"Login"} visible>
                    <Status type="error" text={this.state.error}/>
                    {/**/}
                    <TextfieldInput ph={"Email "} tag={"username"} state={this.state} /><br/>
                    {
                        this.state.action === "login" ? <>
                                <TextfieldInput ph={"Password"} tag={"password"} type={"password"} state={this.state}/> <br/>
                                <em onClick={() => this.setState({action: "resetpw"})}>Reset Password</em>
                                <button className={"maxbtn"}>Log In</button>
                            </> :
                            <>
                                <button className={"maxbtn"}>Request E-Mail With Password Reset Link</button>
                                <Status type="success" text={this.state.status}/>
                            </>
                    }


                    <Loader loading={this.state.loading}/>
                </LightContainer>
            </form>
        )
    }
}

export default Login;
