import React from "react";
import {Container, EditorInput, InsertIfNonClient, SelectField} from "../core/input_fields";
import {apiGet} from "../core/api";
import {getUsers} from "../core/core";
import Status from "../core/status";
import {FormContext} from "../core/form_context";
import {UserContext} from "../user/UserContext";
import Consts from "../core/consts";


const conversionMap = {
    accountManagers: "accountManagers",
    accountManagersBackup: "accountManagersBackup",
    clientRelationshipManager: "salesTeamPerson",
    consultant: "consultantTeamPerson",
}

export default class AccountManagers extends React.Component {
    state = {}
    setState = this.setState.bind(this);
    apiGet = apiGet.bind(this);
    static contextType = FormContext;
    componentDidMount = () => {
        getUsers(this.apiGet, this.setState)
    }

    render() {
        const Selector = ({userContext, ...props}) => <SelectField
            type={"reactselect"}
            width={Math.min(window.innerWidth - 50, 700)}
            multiple
            //disabled
            selectives={this.state.selectUsers.filter(u => u.role > 70).sort((a, b) => a.name < b.name ? -1 : 1).map(u => ({value: u.ID?.toString() /*json search needs string*/, label: u.name + (userContext?.user?.Role > 10 ? " (" + u.email + ")" : "")}))}
            {...props}
        />;
        const groupCompanyData = this.context.state.GroupCompany?.data || {};
        const Printer = ({tag, name}) => <p><b style={{width: 150, fontWeight: 400, display: "inline-block"}}>{name}:</b>
            {groupCompanyData[conversionMap[tag.split("_")[1]]]?.map(a => a.label).join(", ")}
        </p>

        const Field = ({EnvID, ...rest}) => [Consts.ENV_DEMO, Consts.ENV_ESSENTIA].includes(EnvID) ? <Printer {...rest}/> : <Selector {...rest}/>;
        return <Container name={"Account Manager / Backup"}>
            <Status type={"error"} text={this.state.error}/>
            <UserContext.Consumer>
                {
                    userContext =>
                        this.state.selectUsers && <>
                            <Field tag={"company_accountManagers"} name={"Account Managers"} {...{userContext}} EnvID={userContext.user.EnvID}/>
                            <Field tag={"company_accountManagersBackup"} name={"Backup Managers"} {...{userContext}} EnvID={userContext.user.EnvID}/>
                            <Field tag={"company_clientRelationshipManager"} name={"Sales Team Member"} {...{userContext}} EnvID={userContext.user.EnvID}/>
                            <Field tag={"company_consultant"} name={"Consultant"} {...{userContext}} EnvID={userContext.user.EnvID}/>
                        </>

                    //this.state.selectUsers && <SelectField  tag={"company_accountManager"} name={"Account Manager"} demandSelect selectives={this.state.selectUsers.map(u => ([u.email,u.email]))}/>
                }
            </UserContext.Consumer>

        </Container>
    }
}
