import React from "react";
import {SelectField} from "../../core/input_fields";
import {bundesland2prefix, bundeslanderPrefixLength, id2finanzamt} from "./finanzamter"
import Consts from "../../core/consts";
import axios from "axios/index";

class SteuernummerInput extends React.Component {
    constructor(props) {
        super(props);
        this.a = 1 + 1;
        /*this.period={

        </select>
        }*/
    };

    componentDidMount() {
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {tnr1, tnr2, tnr3, county} = this.props.state.specific || {};

        if (!county && !!tnr1) {
            county = tnr1?.slice(0, 2)
            tnr1 = tnr1.slice(bundeslanderPrefixLength[tnr1.slice(0, 2)])
            const cutFirst = 4 - bundeslanderPrefixLength[county]
            const offset = county === "51" ? 4 : 3;
            this.props.setStateParent({
                specific: {
                    ...this.props.state.specific,
                    county,
                    tnr1: tnr1.slice(0, cutFirst),
                    tnr2: tnr1.slice(cutFirst + 1, cutFirst + 1 + offset),
                    tnr3: tnr1.slice(cutFirst + 1 + offset),
                }
            })
            return
        }
        let input = (tnr1 || "").replace(/[^\d]/g, "");
        if (!!input && input.length > 3) {
            const cutFirst = 4 - bundeslanderPrefixLength[county]
            const offset = county === 51 ? 4 : 3;
            this.props.setStateParent({
                specific: {
                    ...this.props.state.specific,
                    county,
                    tnr1: input.slice(0, cutFirst),
                    tnr2: input.slice(cutFirst, cutFirst + offset),
                    tnr3: input.slice(cutFirst + offset),
                }
            })
        }
    }

    render() {
        //const {tnr1, tnr2, tnr3, county} = this.props.state.specific;

        let taxnr = "";
        let finanzamtName = "";
        let taxnrPrefix = "";
        if (this.props.state.specific !== undefined && this.props.state.specific.county !== undefined && this.props.state.specific.county !== "") {
            const ct = this.props.state.specific.county;
            taxnrPrefix = bundesland2prefix[ct]?.toString().substr(0, bundeslanderPrefixLength[ct]);
            if (this.props.state.specific.tnr1 !== undefined) {
                if (bundeslanderPrefixLength[ct] + this.props.state.specific.tnr1.length === 4 && id2finanzamt[taxnrPrefix + this.props.state.specific.tnr1] !== undefined) {
                    finanzamtName = id2finanzamt[taxnrPrefix + this.props.state.specific.tnr1];

                    const b = this.props.state.specific.tnr2 || "";
                    const c = this.props.state.specific.tnr3 || "";
                    taxnr = taxnrPrefix + this.props.state.specific.tnr1 + "0" + b + c;
                    if (this.a !== taxnr) {
                        this.a = taxnr;
                        this.props.updateParent("specific", "taxnr", taxnr)
                    }
                } else {
                    finanzamtName = "not found - error in input one"
                    //this.props.updateParent("error","","the entered length is not correct")
                }
            }
        }
        const test = this.props.state !== undefined && this.props.state.valid ? {
            width: "50px",
            backgroundColor: "rgba(0,255,0,0.2)"
        } : (this.props.state !== undefined && this.props.state.valid !== undefined && !this.props.state.valid ? {
            width: "50px",
            backgroundColor: "rgba(255,0,0,0.2)"
        } : {width: "50px", backgroundColor: "rgba(255,0,0,0)"});

        return <>
            <SelectField name={"State of Tax Authority"} tag={"specific_county"} demandSelect selectives={[
                ["28", "Baden-Württemberg"],
                ["91", "Bayern"],
                ["11", "Berlin"],
                ["30", "Brandenburg"],
                ["24", "Bremen"],
                ["22", "Hamburg"],
                ["26", "Hessen"],
                ["40", "Mecklenburg-Vorpommern"],
                ["23", "Niedersachsen"],
                ["51", "Nordrhein-Westfalen"],
                ["27", "Rheinland-Pfalz"],
                ["10", "Saarland"],
                ["32", "Sachsen"],
                ["31", "Sachsen-Anhalt"],
                ["21", "Schleswig-Holstein"],
                ["41", "Thüringen"],
            ]} state={this.props.state}/>
            <div style={{display: "inline-block"}}> {/* a label leads to unexpected behaviour as another input field is then a trigger for the focus of the first input*/}
                <span style={{display: "inline-block", width: "150px"}}>
                    Tax Number
                </span>

                <SmallTextInputField
                    tag="specific_tnr1"
                    style={test}
                    //type={"number"}
                    state={this.props.state}
                />/<SmallTextInputField
                    tag="specific_tnr2"
                    style={test}
                    //type={"number"}
                    state={this.props.state}
                />/
                <SmallTextInputField
                    tag="specific_tnr3"
                    style={test}
                    //type={"number"}
                    state={this.props.state}
                /><br/>
            </div>
            <br/>
            <label><span>General Format:</span> {taxnr}</label><br/>
            <label><span>Authority Location:</span> {finanzamtName}</label><br/>
            <CheckTaxNr state={this.props.state} setStateParent={this.props.setStateParent}/>
        </>

    }

}

class SmallTextInputField extends React.Component {
    constructor(props) {
        super(props);
        this.a = 1 + 1;
    };

    componentDidMount() {
    };

    render() {
        const s = this.props.tag.split("_");
        let state = this.props.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;

        }
        let dv = "";
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        return (

            <input
                ref={this.props.myref !== undefined ? this.props.myref : ""}
                name={this.props.tag}
                style={this.props.style}
                type={this.props.type === undefined ? "text" : this.props.type}
                value={dv}
                placeholder={123}
            />
        )
    }

}


class CheckTaxNr extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            avail: [],
            key: "",
            loading: false
        }
    };

    load(key) {
        if (key === this.state.key || key.length !== 13) {
            if (key !== this.state.key && key.length !== 13 && this.state.valid === true) {
                this.setState({valid: false})
            }
            return;
        }
        this.setState({
            key: key,
            loading: true,
            valid: undefined,
            showingNr: 0,
        });


        axios.get(Consts.API_PREFIX + "/vat_return/de/check/" + key)
            .then(response => {
                if (response.data.status === "error") {
                    this.props.setStateParent({
                        error: response.data.message,
                        status: "",
                    });
                    this.setState({loading: false})
                } else {

                    this.props.setStateParent({
                        error: "",
                        status: "",
                        valid: response.data.valid,
                    });
                    this.setState({
                        valid: response.data.valid,
                        loading: false,
                    })
                }
            }).catch((error) => {
            console.log("error", error);
            this.props.setStateParent({
                error: error.message,
                status: "",
            });

            this.setState({loading: false})
        });

    }

    componentWillMount() {
        const s = this.props.state.mainLoading;
        s.push((state) => {
            if (state.specific !== undefined && state.specific.taxnr !== undefined) {
                this.load(state.specific.taxnr)
            }

        });
        this.props.setStateParent({mainLoading: s})
    }

    render() {
        const s = this.props.state;

        if (s.specific !== undefined && s.specific.taxnr !== undefined && s.specific.taxnr.length === 13) {
            this.load(s.specific.taxnr)
        }
        if (this.state.valid !== undefined) {
            return (<label><span>valid:</span> {JSON.stringify(this.state.valid)}<br/></label>);
        }
        return null;
    }
}

export {SteuernummerInput};
