import React from "react";
import {FaWindowClose} from "react-icons/fa";

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayStyle: "block",
            textNotShowing: ""
        };
        this.ref = React.createRef();
    }

    componentDidUpdate() {
        if ((this.props.text === "" || this.props.text === undefined) && (this.props.children === undefined || this.props.children[0] === false)) {
            return
        }


        if (this.props.text !== this.state.textNotShowing) {
            //window.scrollTo(0, 0);
            this.setState({
                displayStyle: "block",
                textNotShowing: this.props.text
            });
        }
        /*let a=document.getElementById("status_"+this.props.type);

        if (a!=null) {

            //a.parentElement.scrollIntoView()
        }*/
    }

    render() {


        if ((this.props.text === "" || this.props.text === undefined) && (this.props.children === undefined || this.props.children[0] === false)) {
            return null
        }
        return <div id={"status_" + this.props.type} className={"status " + this.props.type}
                    style={{display: this.state.displayStyle}}>

            <FaWindowClose style={{float: "right"}} onClick={() => {
                this.setState({
                    displayStyle: "none",
                })
            }} className={"fa fa-window-close"}/>
            <h2>{this.props.type.substr(0, 1).toUpperCase() + this.props.type.substr(1)}</h2>
            {
                this.props.children === undefined ? <div dangerouslySetInnerHTML={{__html: this.props.text}}/> : <div>{this.props.text} {this.props.children}</div>
            }


        </div>

    }
}

export default Status;
