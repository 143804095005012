import React from "react"

import {apiGet} from "../core/api";
import {FormContext} from "../core/form_context";
import Popup from "reactjs-popup";
import {FaSearch} from "react-icons/fa";
const EUCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "HR",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
];
class SearchUID extends React.Component {

    static contextType = FormContext;

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            open: false,
        };

        this.apiGet = apiGet.bind(this)
    }

    search = () => {
        const state = this.context.state;
        if (state.company !== undefined && state.company.name !== undefined && state.company.name.length > 2) {
            this.apiGet("/company/search_uid?n=" + state.company.name + "&cc=" + (state.general.country || "all"), resp => {
                if (resp.Results.length === 1) {
                    this.fillFormWithResult(resp.Results[0])
                }
                if (resp.Results.length === 0) {
                    alert("No company was found matching that name!")
                }
                this.setState({results: resp.Results, open: resp.Results.length > 1})
            })
        }

    };
    fillFormWithResult = (c) => {
        console.log(c);
        this.context.updateState("company", "name", c.Name.replace("&amp;", "&"));
        this.context.updateState("company", "vatId", c.Number);
        if (this.context.state.general.country === "GB" && this.context.state.general.report === "ec_sl") {
            this.context.updateState("company", "postcode", c.Address[c.Address.length - 1]);
        }
    };

    render() {
        const state = this.context.state;
        if (state.general !== undefined && (state.general.country === "DE" || state.general.country === "ES" || state.general.country === "AG" || state.general.report === "manual_gst"
            || (state.general.country === "AT" && state.general.report !== "manual_intrastat"))
            || (!EUCountries.includes(state.general.country))

        ) {
            return null
        }
        return <>
            <em onClick={this.search}><FaSearch/> Search by Name</em>
            <Popup
                className={"popup-modal"}
                open={this.state.open}
                onClose={() => this.setState({open: false})}
                modal>
                {close => <div>
                    <h1>Companies Matching</h1>
                    <h4>Please select one of the below!</h4><br/>

                    {this.state.results.slice(0, 6).map(c => <div style={{padding: "8px 0", borderBottom: "2px solid #444"}}>
                        <span style={{float: "right"}}>{c.CC.toUpperCase()} {c.Number}</span>
                        <em onClick={() => {
                            this.fillFormWithResult(c);
                            close()
                        }}>{c.Name}</em><br/>
                        {c.Address.join(", ")}

                    </div>)}
                </div>
                }
            </Popup>
        </>
    }
}

export default SearchUID
