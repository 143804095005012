import React from 'react';
import FilingsByCompany from "../filing/filings_bycompany";
import GBInformation from "./gb_information";
import FilesList from "../files/files_list";
import CollaboratorsList from "./collaboratorsList";


class CompanyView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            latest: null
        };

        this.key = (props.match.params === undefined || props.match.params.key === undefined) ? props.key : props.match.params.key;
    }


    render() {
        return (
            <div id={"filings_list"}>
                {
                    this.key.substr(0, 2) === "GB" && this.state.latest?.type !== "manual_vat_return_gb" &&
                    <GBInformation match={this.props.match}/>
                }

                <FilesList match={this.props.match} latestFiling={this.state.latest}/>

                <CollaboratorsList match={this.props.match}/>

                <FilingsByCompany match={this.props.match} addLatest={(l) => this.setState({latest: l})}/>


            </div>
        );
    }
}


export default CompanyView;
