import Consts from "./consts";

const dataRaw = [
    {
        "Requirement of nil return submission:": "Country",
        "Column2": "ECSL",
        "Column3": "Intrastat",
        "Column4": "BE B2B Sales list",
        "Column5": "Control Statement",
        "Column6": "PT SAFT",
        "Column7": "IT Vat payment"
    },
    {
        "Requirement of nil return submission:": "Austria",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Belgium",
        "Column2": "no",
        "Column3": "yes",
        "Column4": "yes"
    },
    {
        "Requirement of nil return submission:": "Bulgaria",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Croatia",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Cyprus",
        "Column2": "yes",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Czech Republic",
        "Column2": "no",
        "Column3": "yes",
        "Column5": "no"
    },
    {
        "Requirement of nil return submission:": "Denmark",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Estonia",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Finland",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "France",
        "Column2": "No ",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Germany",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Greece",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Hungary",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Ireland",
        "Column2": "yes",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Italy",
        "Column2": "n\/a",
        "Column3": "no",
        "Column7": "no"
    },
    {
        "Requirement of nil return submission:": "Latvia",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Lithuania",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Luxembourg",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Malta",
        "Column2": "Yes (not 100% may need to be changed)",
        "Column3": "no"
    },
    {
        "Requirement of nil return submission:": "Netherlands",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Poland",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Portugal",
        "Column2": "no",
        "Column3": "yes",
        "Column6": "yes"
    },
    {
        "Requirement of nil return submission:": "Romania",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Slovakia",
        "Column2": "no",
        "Column3": "yes",
        "Column5": "no"
    },
    {
        "Requirement of nil return submission:": "Slovenia",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Spain",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "Sweden",
        "Column2": "no",
        "Column3": "yes"
    },
    {
        "Requirement of nil return submission:": "United Kingdom",
        "Column2": "no",
        "Column3": "recommended, but not required"
    }
]


let data = {}
const yesParser = a => a?.toLowerCase().startsWith("yes");
for (const index in dataRaw) {
    const row = dataRaw[index]
    data[Consts.countriesMapReverse[row["Requirement of nil return submission:"]]] = {
        vat_return: true,
        ec_sl: yesParser(row.Column2),
        intrastat: yesParser(row.Column3),
        b2b_sales_list: yesParser(row.Column4),
        control_statement: yesParser(row.Column5),
        saft: yesParser(row.Column6),
        vat_payment: yesParser(row.Column7),
    }
}
const NilReturnsRequired = data;
export {NilReturnsRequired}
