import React from "react"
import CompaniesList from "./company_list";
import CompanyView from "./company_view";

export default class CompanyRouter extends React.Component {
    componentDidMount() {
        console.log("mounted router")
    }

    componentWillUnmount() {
    }

    render() {
        const {location} = this.props;
        return <>
            {
                location.pathname === "/companies" && <div style={{display: location.pathname !== "/companies" ? "none" : "block"}}>
                    <CompaniesList {...this.props}/>
                </div>
            }
            {
                /*
                <Route exact path="/companies" component={CompaniesList}/>
                <Route exact path="/companies/:key" component={CompanyView}/>
                <Route exact path="/companies/:key/:parent_ID" component={CompanyView}/>

                 */
                location.pathname !== "/companies" &&

                <CompanyView {...this.props}/>
            }

        </>
    }
}