import axios from "axios";
import Consts from "./consts";

const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
export {encodeGetParams}

export function getLocalIP() {


// NOTE: window.RTCPeerConnection is "not a constructor" in FF22/23
    var RTCPeerConnection = /*window.RTCPeerConnection ||*/ window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

    if (RTCPeerConnection) {
        var rtc = new RTCPeerConnection({iceServers: []});
        if (1 || window.mozRTCPeerConnection) {      // FF [and now Chrome!] needs a channel/stream to proceed
            rtc.createDataChannel('', {reliable: false});
        }

        rtc.onicecandidate = ({candidate}) => {
            // convert the candidate to SDP so we can run it through our general parser
            // see https://twitter.com/lancestout/status/525796175425720320 for details
            if (candidate) {
                //console.log("candidate",candidate.candidate)
                grepSDP("a=" + candidate.candidate);
            }
        };
        rtc.createOffer((offerDesc) => {
            grepSDP(offerDesc.sdp);
            rtc.setLocalDescription(offerDesc);
        }, (e) => {
            return ""
        });


        var addrs = Object.create(null);
        addrs["0.0.0.0"] = false;
        const updateDisplay = (newAddr) => {
            if (newAddr in addrs) return;
            else addrs[newAddr] = true;
            var displayAddrs = Object.keys(addrs).filter(function (k) {
                return addrs[k];
            });
            localStorage.setItem("localIP", displayAddrs.join(",") || "")
            localStorage.setItem("localIPTime", new Date().toISOString())
        }

        const grepSDP = (sdp) => {
            var hosts = [];
            sdp.split('\r\n').forEach(function (line) { // c.f. http://tools.ietf.org/html/rfc4566#page-39
                if (~line.indexOf("a=candidate")) {     // http://tools.ietf.org/html/rfc4566#section-5.13
                    var parts = line.split(' '),        // http://tools.ietf.org/html/rfc5245#section-15.1
                        addr = parts[4],
                        type = parts[7];
                    if (type === 'host') updateDisplay(addr);
                } else if (~line.indexOf("c=")) {       // http://tools.ietf.org/html/rfc4566#section-5.7
                    var parts = line.split(' '),
                        addr = parts[2];
                    updateDisplay(addr);
                }
            });
        }
    }


}

export function getFraudData() {
    let plugins = [];
    for (let i = 0; i < navigator.plugins.length; i++) {
        plugins.push(encodeURI(navigator.plugins[i].name))
    }
    return {
        "Browser-Plugins": plugins.join(","),
        "Browser-Screen": encodeGetParams({
            width: window.screen.width,
            height: window.screen.height,
            "scaling-factor": window.devicePixelRatio,
            "colour-depth": window.screen.colorDepth,
        }),
        "Browser-Time": localStorage.getItem("localIPTime") || "",
        "LocalIP": localStorage.getItem("localIP") || "",
        "Timezone": new Date().toString().slice(28, 33),
        "Vatomator-Version": window.vatomatorVersion,
    };
}

export function apiGet(url, success = (resp) => {
    this.setState({
        error: "",
        status: resp.message !== undefined ? resp.message : "",
        loading: false,
    })
}, apiPrefix = true) {
    try {
        return axios.get((apiPrefix ? Consts.API_PREFIX : "") + url, {
            headers: {
                'Content-Type': 'application/json',
                ...getFraudData()
            }
        })
            .then(response => {

                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false,
                    })

                } else {
                    success(response.data)
                }

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false,
                })


            });
    } catch (e) {
        console.log("error")
        this.setState({
            error: e,
            status: "",
            loading: false,
        })
    }
}

export function apiPost(url, data, success = (resp) => {
    this.setState({
        error: "",
        status: resp.message !== undefined ? resp.message : "",
        loading: false,
    })
}) {
    try {
        axios.post(Consts.API_PREFIX + url, data, {
            headers: {
                'Content-Type': 'application/json',
                ...getFraudData()
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false,
                    })

                } else {
                    success(response.data)
                }

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false,
                })


            });
    } catch (e) {
        console.log("error",e)
        this.setState({
            error: e,
            status: "",
            loading: false,
        })
    }
}


export function apiDelete(url, data, success = (resp) => {
    this.setState({
        error: "",
        status: resp.message !== undefined ? resp.message : "",
        loading: false,
    })
}) {
    try {
        axios.delete(Consts.API_PREFIX + url, {
            headers: {
                'Content-Type': 'application/json',
                ...getFraudData()
            }, data,
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false,
                    })

                } else {
                    success(response.data)
                }

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: error.message,
                    status: "",
                    loading: false,
                })


            });
    } catch (e) {
        console.log("error",e)
        this.setState({
            error: e,
            status: "",
            loading: false,
        })
    }
}


