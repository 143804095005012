import React, {useMemo, useState} from "react";
import {InsertIfAdmin, MiniBtn, MyModal, SelectfieldInput} from "../core/input_fields";

export default function SicSelector({selection}) {
    const [changeMode, setChangeMode] = useState(false)

    return <>
        Business Activity (SIC Code):&nbsp;
        {
            changeMode ?
                <Selector/> :
                <>
                    {(selection || []).map(a => a.label).sort((a, b) => a > b ? 1 : -1).join(", ")}&nbsp;
                    <InsertIfAdmin>
                        <MiniBtn onClick={() => setChangeMode(true)}>change</MiniBtn>
                    </InsertIfAdmin>
                </>
        }
    </>
}

function Selector() {
    const [selections, setSelections] = useState([])
    useMemo(() => {
        import("./sic_codes").then(a => {
            setSelections(a.SicCodes)
        })
    }, [])
    console.log(selections)
    return <SelectfieldInput name={""} inline noLabel multiple tag={"data_sic"} type={"reactselect"} width={500} selectives={selections.map(([c, l]) => ({value: c, label: c + " " + l}))}/>
}
