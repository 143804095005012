import React, {useContext, useEffect, useState} from "react";
import axios from "axios/index";
import Consts from "./consts";
import {dateFormatTime} from "../filing/filing_support";
import ViewConfig from "./view_config"
import ReactSelect from 'react-select';
import {FormContext} from "./form_context";
import {UserContext} from "../user/UserContext";
import DatePicker from "react-datepicker";
import {FaTimesCircle} from "react-icons/all"
import Dialog from "@material-ui/core/Dialog";
//import {Select2} from "react-select2-wrapper";
import "../../node_modules/react-datepicker/dist/react-datepicker.css"
import {Editor} from "@tinymce/tinymce-react";
import branding from "./branding";
import Status from "./status";
import {FaInfoCircle} from "react-icons/fa";


export class SelectfieldInput extends React.Component {
    static contextType = FormContext;
    state = {
        selectedOption: null,
    };


    localHandleChange = (selectedOption) => {
        //console.log(selectedOption)
        if (this.props.readOnly) {
            alert("Dieses Feld kann leider nicht verändert werden.")
        }

        this.setState({selectedOption});
        const t = this.props.tag.split("_");
        if (t.length === 2) {
            //console.log(t)
            //console.log(this.context.state[t[0]][t[1]])
            if (!!this.props.multiple) {
                //const n = [...((this.context.state[t[0]] || {})[t[1]] || []), selectedOption.map(s => s.value)];
                //console.log(n)
                this.context.updateState(t[0], t[1], selectedOption)
            } else {
                this.context.updateState(t[0], t[1], selectedOption?.value)
            }
        } else {
            console.log("not handled")
        }

        //this.context.updateState("remind", "daysBefore", selectedOption.map(a => a.value))
        //this.context.updateState("remind", "daysBeforeSelect", selectedOption)

    };

    constructor(props) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.myRef = React.createRef();
    };

    componentDidMount() {
        //console.log("mount of " + this.props.tag, this.props)
    }

    componentDidUpdate() {
        const t = this.props.tag.split("_");
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (t.length === 2 && !this.props.multiple) {
            if ((state[t[0]] || {})[t[1]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]][t[1]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                if (stateValue !== domValue && this.context.updateState !== undefined) {
                    this.context.updateState(t[0], t[1], domValue)
                }
            }
        }
        if (t.length === 1) {
            if (state[t[0]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                if (stateValue !== domValue && this.props.updateState !== undefined) {
                    this.context.updateState(t[0], "", domValue)
                }
            }
        }

    }

    render() {

        const s = this.props.tag.split("_");
        let dv = "";
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }

        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        let object_select = null;
        let defaultLabel;
        if (this.props.type !== "reactselect") {
            if (Array.isArray(this.props.selectives[0])) {
                object_select = this.props.selectives.filter(a => a !== null).map(a => <option key={a[0]}
                                                                                               value={a[0]}>{a[1]}</option>)
            } else {
                object_select = this.props.selectives.filter(a => a !== null).map(a => {
                    a = a?.toString();
                    return <option key={a} value={a}>{a.split("|")[0]}</option>
                })
            }
        } else {
            if (!!this.props.multiple) {

                dv = null;
                let defaultLabel = null

            } else {
                let defaultLabelArray = this.props.selectives.filter(f => f.value === dv);
                if (defaultLabelArray.length > 0) {
                    defaultLabel = defaultLabelArray[0].label
                }
                if (dv !== undefined && dv.length === 0) {
                    dv = null;
                    defaultLabel = null
                }
            }

        }

        //const selectedOption = null//this.props.type !== "reactselect" ? null : state.remind.daysBeforeSelect;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                /*minHeight: 20,
                fontSize: 20,
                paddingTop: 0,
                paddingBottom: 0,
                borderTop: "1px solid #aaa",
                cursor: "pointer"*/
            }),
            control: (provided) => ({
                ...provided,
                width: this.props.width || 200,
                padding: 0,
            }),
            input: (provided) => {
                //console.log(provided)
                return {
                    ...provided,
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            },
        };
        const t = this.props.tag.split("_");
        return <>
            {(this.props.selectives !== undefined && this.props.type === "reactselect") ?
                <div style={(!this.props.inline ? {} : {display: "inline-block"})}>
                    {
                        !this.props.noLabel && <label>
                        <span style={(!this.props.labelWidth ? {} : {width: this.props.labelWidth + "px"})}>
                            {this.props.name}
                        </span>
                        </label>
                    }
                    <div style={{width: this.props.width || "300px", display: "inline-block"}}>
                        <UserContext.Consumer>
                            {
                                ({club}) =>
                                    <ReactSelect
                                        key={this.props.tag}
                                        name={this.props.tag}
                                        style={{width: this.props.width}}
                                        placeholder={this.props.ph || "Select..."}
                                        styles={customStyles}
                                        value={!!dv ? {label: defaultLabel || dv, value: dv} : (!!this.props.multiple ? (this.context.state[t[0]] || {})[t[1]] || [] : null)}
                                        //defaultValue={{label: defaultLabel || dv, value: dv}}
                                        isMulti={!!this.props.multiple}
                                        className={"reactSelectContainer"}
                                        onChange={this.localHandleChange}
                                        options={this.props.selectives}
                                        isClearable
                                        classNamePrefix={"MaxSelect"}
                                        onMenuOpen={this.props.onMenuOpen}
                                        defaultMenuIsOpen={this.props.defaultOpen}
                                        menuPortalTarget={document.getElementById("reactselectportal")}
                                        components={{}}
                                        isDisabled={this.props.readOnly}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                                primary25: branding.colorLight,
                                                primary: branding.colorLight,
                                                neutral20: branding.colorLight,
                                                neutral30: branding.colorLight,

                                            },
                                        })
                                        }
                                    />
                            }
                        </UserContext.Consumer>
                    </div>
                </div> :

                <label style={this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}}>
                    {
                        !this.props.noLabel &&
                        <span>
                        {this.props.name}
                     </span>
                    }
                    {this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives === undefined &&
                        <span>nichts auszuwählen</span>
                    }
                    {this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives !== undefined &&
                        this.props.noselectives
                    }
                    {/*this.props.selectives !== undefined && this.props.selectives.length > 0 && this.props.type === "select2" &&
                <Select2
                    data={this.props.selectives.map(a => {
                        return {id: a.id, "text": a.text.substr(0, 40)}
                    })}
                    value={dv}
                    onChange={(e) => {
                        if (this.props.tag.split("_").length == 2) {
                            this.props.updateState(this.props.tag.split("_")[0], this.props.tag.split("_")[1], e.target.value)
                        }
                    }
                    }
                    options={{placeholder: 'search for ' + this.props.name,}}
                />*/
                    }

                    {this.props.selectives !== undefined && (this.props.selectives.length > 0 || !Array.isArray(this.props.selectives)) && this.props.type !== "select2" && this.props.type !== "reactselect" &&
                        <select
                            key={this.props.tag}
                            name={this.props.tag}
                            style={{width: this.props.width}}
                            ref={this.myRef}
                            disabled={this.props.readOnly}
                            //multiple
                            onChange={() => {
                            }}
                            value={dv}
                            //className="js-example-basic-single"
                        >
                            {
                                (this.props.demandSelect || !!this.props.demandSelectText) &&
                                <option value="">{this.props.demandSelectText || "please choose below"}</option>
                            }

                            {
                                object_select
                            }
                        </select>
                    }
                    &nbsp;{this.props.children}
                    {this.props.noBreaks === undefined && <br/>}
                </label>
            }
        </>
    }
}

class SelectField extends React.Component {
    constructor(props, context) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.myRef = React.createRef();

    };

    state = {
        selectedOption: null,
    };

    static contextType = FormContext;

    componentDidMount = () => {
        this.updateValueAfterUpdate()
    };
    componentDidUpdate = () => {
        this.updateValueAfterUpdate()
    };

    componentWillUnmount = () => {
        const t = this.props.tag.split("_");
        if (this.context.updateState !== undefined && t.length === 2) {
        }
    };

    updateValueAfterUpdate = () => {
        const context = this.context;
        const props = this.props;
        const t = props.tag.split("_");
        const state = context === undefined || context.state === undefined ? props.state : context.state;
        if (state === undefined) {
            return
        }
        if (t.length === 2) {
            if (((state || {})[t[0]] || {})[t[1]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]][t[1]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + props.tag, " having state", stateValue, " and value", domValue)

                if (stateValue !== domValue && this.context.updateState !== undefined) {
                    this.context.updateState(t[0], t[1], domValue)
                }
            }
        }
        if (t.length === 1) {
            if (state[t[0]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                if (stateValue !== domValue && props.updateState !== undefined) {
                    context.updateState(t[0], "", domValue)
                }
            }
        }
    }

    localHandleChange = (selectedOption) => {
        this.setState({selectedOption});

        if (this.props.multiple) {
            if (this.props.tag === "remind_daysBefore") {
                this.context.updateState("remind", "daysBefore", selectedOption.map(a => a.value));
                this.context.updateState("remind", "daysBeforeSelect", selectedOption)
            } else {
                const s = this.props.tag.split("_");
                this.context.updateState(s[0], s[1], selectedOption)
            }

        } else {
            const s = this.props.tag.split("_");
            this.context.updateState(s[0], s[1], selectedOption.value)
        }

    };

    render() {

        const s = this.props.tag.split("_");
        let dv = "";
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }

        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        let object_select = null;
        if (this.props.type !== "reactselect") {

            if (Array.isArray(this.props.selectives[0])) {
                object_select = this.props.selectives.filter(a => a !== null).map(a => <option key={a[0]} value={a[0]}>{a[1]}</option>)
            } else {
                object_select = this.props.selectives.filter(a => a !== null).map(a => {
                    a = a?.toString();
                    return <option key={a} value={a}>{a.split("|")[0]}</option>
                })
            }
        }
        const selectedOption = this.props.type !== "reactselect" ? null : (state[s[0]] || {})[s[1]?.replace("daysBefore", "daysBeforeSelect")];
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                minHeight: 20,
                fontSize: 20,
                paddingTop: 0,
                paddingBottom: 0,
                borderTop: "1px solid #aaa",
                cursor: "pointer"
            }),
            control: (provided) => ({
                ...provided,
                width: this.props.width || 200,
                padding: 0,
            }),
            input: (provided) => {
                //console.log(provided)
                return {
                    ...provided,
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            },
        };
        let labelValue = this.props.selectives.reduce((a, b) => b[0] === dv ? b[1] : a, undefined);
        if (labelValue === undefined && this.props.type === "reactselect-simple") {
            if (this.props.demandSelect) {
                if (dv !== undefined) {
                    //this.context.updateState(s[0], s[1], undefined)
                }
                labelValue = "please select"
            } else {
                if (this.props.selectives.length > 0) {
                    console.log(s, this.props.selectives)
                    this.context.updateState(s[0], s[1], Array.isArray(this.props.selectives[0]) ? this.props.selectives[0] : this.props.selectives[0][0])
                    labelValue = Array.isArray(this.props.selectives[0]) ? this.props.selectives[0] : this.props.selectives[0][1]
                }
            }
        }
        return (
            <label style={this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}}>
                {
                    !this.props.hideLabel && <span style={this.props.labelStyle}>{this.props.name}</span>
                }
                {
                    this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives === undefined &&
                    <span>&nbsp; no things to select for {this.props.name} available</span>
                }
                {
                    this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives !== undefined &&
                    this.props.noselectives
                }


                {
                    this.props.selectives !== undefined && (this.props.selectives.length > 0 || !Array.isArray(this.props.selectives)) && this.props.type === "reactselect" &&
                    <ReactSelect
                        key={this.props.tag}
                        name={this.props.tag}
                        style={{width: this.props.width}}
                        //multiple
                        styles={customStyles}
                        value={selectedOption}
                        isDisabled={this.props.disabled}
                        //className="js-example-basic-single"
                        //defaultValue={this.props.defaultValues!==undefined ? this.props.defaultValues : {}}
                        //defaultValue={selectedOption}
                        isMulti={this.props.multiple ? true : false}
                        className={"reactSelectContainer"}
                        onChange={this.localHandleChange}
                        options={this.props.selectives}
                        theme={(theme) => {

                            return {
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                    ...theme.colors,
                                    primary25: branding.colorLight,
                                    primary: branding.colorLight,
                                    neutral20: branding.colorLight,
                                    neutral30: branding.colorLight,

                                },
                            }
                        }}
                    />
                }
                {
                    this.props.selectives !== undefined && (this.props.selectives.length > 0 || !Array.isArray(this.props.selectives)) && this.props.type !== "select2" && this.props.type !== "reactselect" && this.props.type !== "reactselect-simple" &&
                    <select
                        key={this.props.tag}
                        name={this.props.tag}
                        style={{width: this.props.width}}
                        ref={this.myRef}
                        //multiple
                        onChange={() => {
                        }}
                        value={dv}
                        disabled={this.props.disabled || ((this.context || {}).state || {}).write_allowed === false}
                        //className="js-example-basic-single"
                    >
                        {
                            (this.props.demandSelect || !!this.props.demandSelectText) &&
                            <option value="">{this.props.demandSelectText || "please choose below"}</option>
                        }

                        {
                            object_select
                        }
                    </select>
                }
                &nbsp;{this.props.children}
                {this.props.noBreaks === undefined && <br/>}
            </label>
        )
    }

}

class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.fieldName = props.tag === undefined ? "fileID" : props.tag;
        if (props.tag && props.tag.split("_").length === 2) {
            this.fieldName = props.tag.split("_")[1];
        }
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
        this.setState = this.setState.bind(this);
    }

    static contextType = FormContext;
    saveFile = (e) => {
        e.preventDefault();
        this.setState({loading: true});

        var formData = new FormData();
        formData.append("excel_file", this.fileField.current.files[0]);
        formData.append("store_in_workbench", this.props.storeInWorkbench ?? false)

        axios.post(Consts.API_PREFIX + "/file/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({loading: false, error: response.data.message, status: ""})
                } else {

                    this.setState({loading: false, error: "", renew: false})
                    if (this.props.onUpload) {
                        this.props.onUpload(response.data);
                    } else {
                        this.props.setStateParent({
                            status: response.data.message,
                            [this.fieldName]: response.data.file_name,
                            [this.fieldName + "Name"]: response.data.file_name_orig,
                        });
                    }
                    if (!!this.fileField.current) {
                        this.fileField.current.value = "";
                    }
                }
            })
            .catch((error) => {
                //this.setState({loading: false})
                this.setState({
                    loading: false,
                    error: error.response?.statusText || error.message,
                    status: ""
                })
            });

    };

    /* loadBox(){
         let folderId = '59771699940';
         var accessToken = 'abc';
         var contentExplorer = new Box.ContentExplorer();
         contentExplorer.show(folderId, "qzCTquq9kJNCFYYj8k4fhUppIgCZ0quQ", {
             container: '.container'
         });
     }*/
    render() {
        const tagSplit = this.props.tag?.split("_") || [];
        const s = this.props.state || (tagSplit.length === 2 ? this.context.state[tagSplit[0]] : this.context.state);
        return <>
            <Status type={"error"} text={this.state.error}/>
            {
                (!(s || {})[this.fieldName]) && <div id="excel_file">
                    <label>
                        {
                            !!this.props.name && <span style={this.props.labelStyle}>
                            {this.props.name}
                        </span>
                        }
                        <input
                            style={{paddingTop: 10, paddingBottom: 6, fontSize: 17}}
                            type={"file"}
                            disabled={!this.props.forceAllowWrite && ((this.context || {}).state || {}).write_allowed === false}
                            ref={this.fileField}
                        />
                        {this.state.loading &&
                            <label>
                                <img alt={"loader"} src="/src/img/ajax-loader-fff.gif"/>
                                {
                                    this.state.progress > 0 && <>{this.state.progress} %</>
                                }
                                {(this.state.progress === 100 || isNaN(this.state.progress)) && <span>(processing file)</span>}
                            </label>
                        }
                    </label>
                    <button className={"mini"} onClick={this.saveFile}>upload file</button>
                </div>
            }
            {
                !!(s || {})[this.fieldName] &&
                <label>
                     {
                            !!this.props.name && <span style={this.props.labelStyle}>
                            {this.props.name}
                        </span>
                        }
                    <a href={Consts.API_PREFIX + "/file/download/" + s[this.fieldName]}>
                        download
                    </a>
                    &nbsp;({s[this.fieldName]?.split(".")[1]},&nbsp;
                    {
                        s[this.fieldName]?.length < 40 ?
                            dateFormatTime(s[this.fieldName]?.split("-")[1].split(".")[0] * 1000) :
                            dateFormatTime(s[this.fieldName].split("/")[1].split("-")[0] * 1000)
                    }) &nbsp;&nbsp;&nbsp;&nbsp;
                    <em onClick={() => {
                        if (this.props.onUpload) {
                            this.props.onUpload("");
                        } else {
                            this.props.setStateParent({[this.fieldName]: ""});
                        }
                    }}>
                        new file
                    </em>
                    <br/>
                </label>
            }


            {/*s.loading !== undefined && s.loading &&
            <img alt={"loader"} src="/src/img/ajax-loader-fff.gif"/>
            */
            }
        </>
    }
}


const tinymcKey = "5yqoi0ysxzwd3182o8lhp0kuoaixer0cvelp8elcvg3v1wz3";


class EditorInput extends React.Component {
    static contextType = FormContext;
    handleChange = c => {
        const s = this.props.tag.split("_");
        if (this.context.updateState) {
            this.context.updateState(s[0], s[1], c)
        } else {
            this.context.setState({[s[0]]: {...this.context.state[s[0]], [s[1]]: c}})
        }
    };


    render() {
        const s = this.props.tag.split("_");
        const write_allowed = ((this.context || {}).state || {}).write_allowed;
        //console.log("dv",this.defaultValue)
        return <div>
            <div style={{marginBottom: "10px"}}>{this.props.name}</div>

            {/*<ReactQuill
                modules={this.modules}
                onChange={this.handleChange}
                value={this.context.state[s[0]][s[1]] || ""}
            />*/}

            <Editor tinymceScriptSrc={"/static/tinymce/tinymce.js"} value={(this.context.state[s[0]] || {})[s[1]] || ""} onEditorChange={this.handleChange} init={{
                //language: "de",
                //plugins: "media mediaembed",
                mediaembed_max_width: 450,
                //height: 600,
                plugins: "media autoresize",
                //plugins: 'advcode casechange formatpainter lists pageembed powerpaste table advtable image media link imagetools autoresize fullscreen',
                //toolbar: 'casechange checklist code formatpainter pageembed permanentpen table',
                images_upload_url: Consts.f("/", "/api/") + "page_blocks/photo/upload",
                //images_upload_credentials: true,
                toolbar_mode: 'floating',
                removed_menuitems: "newdocument",
                toolbar: "undo redo | styleselect | bold italic underline | colorpicker | link image | numlist bullist | fullscreen"
            }}/>

        </div>
    }
}


class TextfieldInput extends React.Component {
    constructor(props, context) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.localRef = React.createRef();

    }
    ;

    static
    contextType = FormContext;


    componentWillUnmount = () => {
        const t = this.props.tag.split("_");
        if (this.context.updateState !== undefined && t.length === 2) {
            //this.context.updateState(t[0], t[1], undefined);
            //console.log("unmounting " + this.props.tag);
        }
    };

    componentDidMount() {
        if (this.props.events === undefined) {
            return
        }
        for (var key in this.props.events) {
            console.log(this.localRef.current);
            this.localRef.current.addEventListener(key, this.props.events[key])
        }
    }
    ;

    render() {
        const s = this.props.tag.split("_");
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }
        let dv = "";
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        const write_allowed = ((this.context || {}).state || {}).write_allowed;
        return (

            <label style={this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}}>
            <span style={this.props.labelStyle}>
                {this.props.name}
            </span>
                <input
                    ref={this.props.myref !== undefined ? this.props.myref : (this.props.events !== undefined ? this.localRef : "")}
                    name={this.props.tag}
                    style={this.props.style}
                    type={this.props.type === undefined ? "text" : this.props.type}
                    value={dv}
                    onChange={() => {
                    }}
                    placeholder={this.props.ph}
                    disabled={(!this.props.forceAllowWrite && write_allowed !== undefined && write_allowed === false) || this.props.disabled}
                />
                {this.props.children}

            </label>
        )
    }

}

class TextareaInput extends React.Component {
    constructor(props) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.localRef = React.createRef();
    }
    ;

    static
    contextType = FormContext;

    componentDidMount() {
        if (this.props.events === undefined) {
            return
        }
        for (var key in this.props.events) {
            this.localRef.current.addEventListener(key, this.props.events[key])
        }
    }
    ;

    render() {
        const s = this.props.tag.split("_");
        const state = this.props?.state || this.context?.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }
        console.log({state})
        let dv = "";
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        return (

            <label style={this.props.type === "hidden" ? {display: "none"} : {display: "block"}} className={"textareaLabel"}>
            <span>
                {this.props.name}
            </span><br/>
                <textarea
                    ref={this.props.myref !== undefined ? this.props.myref : (this.props.events !== undefined ? this.localRef : "")}
                    name={this.props.tag}
                    style={{...this.props.style}}
                    type={this.props.type === undefined ? "text" : this.props.type}
                    value={dv}
                    onChange={() => {
                    }}
                    placeholder={this.props.ph}
                />
                {this.props.children}

            </label>
        )
    }

}

class DateInput extends React.Component {
    static
    contextType = FormContext;
    handleChange = (date) => {
        let dateSave = !!date ? date?.getTime() / 1000 : null;
        const s = this.props.tag.split("_");
        let newData;
        this.context.updateState(s[0], s[1], dateSave)


        if (!!this.props.afterUpdate) {
            this.props.afterUpdate(s[0], s[1], s.length === 1 ? dateSave : {[s[1]]: dateSave})
        }

    };

    render() {
        let d = null;
        let date;
        const s = this.props.tag.split("_");
        if (this.context.state[s[0]] !== undefined) {
            if (s.length === 2) {
                date = (this.context.state[s[0]] || {})[s[1]];
            } else {
                date = this.context.state[this.props.tag];
            }
            d = new Date(date * 1000);
            if (isNaN(d.getTime()) || !date) {
                d = this.props.default ?? null;
                //d = new Date();
            }
        } else {
            d = this.props.default ?? null;
            //d = new Date();
        }
        return <>
            <label style={{display: "inline-block"}}>
                {
                    !!this.props.name && <span style={this.props.labelStyle}>{this.props.name}</span>
                }
                <DatePicker selected={d} dateFormat="dd MMM yyyy" dis placeholderText={"click to select date"} disabled={this.props.disabled} onChange={this.handleChange}/>
            </label>
        </>;
    }
}

/*
*/
class CheckboxInput extends React.Component {
    static
    contextType = FormContext;

    componentWillUnmount = () => {
        const t = this.props.tag.split("_");
        if (this.context.updateState !== undefined && t.length === 2) {
            //this.context.updateState(t[0], t[1], undefined);
            //console.log("unmounting " + this.props.tag);
        }
    };

    render() {
        const tag = this.props.tag.split("_")
        return <label className={"maxCheckbox"} style={this.props.style}>
                <span style={this.props.labelStyle}>
                {this.props.name}&nbsp;
                </span>
            <label className={"switch"}>
                <input type="checkbox" name={this.props.tag} disabled={this.props.disabled} checked={

                    (tag.length === 2 && (this.context.state[tag[0]] || {})[tag[1]] === 1) || this.context.state[tag[0]] === 1 ? "checked" : ""
                }/>
                <span className="slider"/>
            </label>
        </label>

    }
}

const config = new ViewConfig();

class Container extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: config.get(this.props.name, this.props.visible | 0),
        };
        this.changeVisibility = this.changeVisibility.bind(this);
        this.setState = this.setState.bind(this)
    }

    changeVisibility = () => {
        if (this.props.nomanip) {
            return
        }
        const newVisiblity = this.state.visible === 1 ? 0 : 1;
        this.setState({visible: newVisiblity});
        config.save({[this.props.name]: newVisiblity})
    };

    render() {
        const props = this.props;
        return (
            <div id={props.name !== undefined && props.name !== "" ? props.name.replace(/\s/g, "_").toLowerCase() : ""} className={"reactContainer " + (props.type || "")}>
                {props.name !== undefined && props.name !== "" &&
                    <h2><b onClick={this.changeVisibility}><i className={"fa fa-angle-" + (this.state.visible === 1 ? "down" : "right")}/>&nbsp; {props.name}</b></h2>}
                {this.state.visible === 1 && props.children}
                {!this.props.nobreak && <br/>}
            </div>
        )
    }
}


const LightContainer = (
    {
        name, children, style, hideHeading = false
    }
) =>
    <div style={style} id={name?.replace(/\s/g, "_").toLowerCase()}>
        {
            !hideHeading && !!name && <h1>{name}</h1>
        }
        {children}
    </div>
const MaxBtn = ({
                    style = {}, children, className = "", onClick = () => {
    }
                }) => <button style={style} onClick={onClick} className={"maxbtn " + (className || "")}>{children}</button>;
const MiniBtn = ({style, children, className, onClick}) => <MaxBtn style={style} onClick={onClick} className={"mini " + (className || "")}>{children}</MaxBtn>;

const Loader = (
    {
        loading, div = null, style = null
    }
) => loading ? (div ?
        <div><img alt={"loader"} style={style} src="/src/img/ajax-loader-fff.gif"/></div>
        :
        <img alt={"loader"} style={style} src="/src/img/ajax-loader-fff.gif"/>
) : null;

class InsertIfNonClient extends React.Component {
    static
    contextType = UserContext;

    render() {

        if (this.context.user.Role > 10 || this.props.otherCondition) {
            return this.props.children;
        }
        if (this.props.alternatively !== undefined) {
            return this.props.alternatively;
        }
        return null

    }
}

class InsertIfAdmin extends React.Component {
    static
    contextType = UserContext;

    render() {

        if (this.context.user.Role >= 80) {
            return this.props.children;
        }
        if (this.props.alternatively !== undefined) {
            return this.props.alternatively;
        }
        return null

    }
}

const MyModal = (
        {
            trigger, defaultOpen = false, children, hoverEnabled = false, onOpen = null, onClose = null
        }
    ) => {
        const [open, setOpenRaw] = useState(!!defaultOpen);
        const setOpen = (open) => {
            if (open && onOpen !== null) {
                onOpen()
            }
            setOpenRaw(open)
        };
        const close = () => {
            if (onClose !== null) {
                onClose()
            }
            setOpen(false);
        };
        return <>
            <i
                style={{cursor: "pointer"}}
                onClick={(e) => {
                    e.preventDefault();
                    setOpen(!open)
                }}
                onMouseOver={() => hoverEnabled && setOpen(!open)}
            >
                {trigger}
            </i>
            <Dialog
                open={open}
                onClose={close}
                classes={{root: 'MyDialogRoot', paper: 'MyDialogPaper'}}
                maxWidth={"lg"}
            >
                <div className={"closeButton"} onClick={close}><FaTimesCircle/></div>
                {typeof children === "function" ? children(close) : children}
            </Dialog>
        </>
    }
;

function ListStringHuman(list, El = React.Fragment) {
    let out = [];
    if (list.length === 0) {
        return out
    }
    if (El === React.Fragment) {
        out.push(list.slice(0, -2).map(a => <><El>{a}</El>, </>));
        if (list.length > 1) {
            out.push(<El>{list[list.length - 2]}</El>);
            out.push(" and ")
        }
        out.push(<El>{list[list.length - 1]}</El>);
    } else {
        out.push(list.slice(0, -2).map(a => <><El content={a}>{a}</El>, </>));
        if (list.length > 1) {
            out.push(<El content={list[list.length - 2]}>{list[list.length - 2]}</El>);
            out.push(" and ")
        }
        out.push(<El content={list[list.length - 1]}>{list[list.length - 1]}</El>);
    }

    return out
}

const InfoTooltip = ({children}) => <MyModal trigger={<em><FaInfoCircle/></em>}><LightContainer>{children}</LightContainer></MyModal>;

export
{
    TextfieldInput, TextareaInput, SelectField, EditorInput, Container, FileUpload, Loader, LightContainer, InsertIfNonClient,
    InsertIfAdmin, ListStringHuman, CheckboxInput, DateInput, MyModal,
    MiniBtn, MaxBtn, InfoTooltip
}
    ;
