import axios from "axios";
import Consts from "./consts";

export function getSessID() {
    let c = document.cookie;
    let d = c.split(";");
    let sessID = "";
    d.forEach(x => {
        if (x.split("=")[0].trim() === "sess") {
            sessID = x.split("=")[1].trim();
        }
    });
    return sessID
}
export function deleteUsersCache(){
    localStorage.removeItem("users_cache");
    localStorage.removeItem("users_updatetime");
}
export async function getUsers(apiGet, setState) {
    if (new Date().getTime() - parseInt(localStorage.getItem("users_updatetime") || 0) > 300 * 1000 || (localStorage.getItem("users_cache") && JSON.parse(localStorage.getItem("users_cache")).length === 0)) {
        console.log("loading not from local storage ")
        const {data: resp} = await axios.get(Consts.API_PREFIX + "/user/list", {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (resp.message) {
            return []
        }
        setState({
            selectUsers: resp,
            error: "",
        })
        localStorage.setItem("users_cache", JSON.stringify(resp));
        localStorage.setItem("users_updatetime", new Date().getTime());
        return resp
    } else {
        //console.log("userscache",JSON.parse(localStorage.getItem("users_cache")))
        const users = JSON.parse(localStorage.getItem("users_cache"))

        setState({
            selectUsers: users
        })
        return users
    }
}
