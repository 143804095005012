import React from "react";
import {apiGet} from "./api";
import axios from "axios";
import Consts from "./consts";
import {Loader} from "./input_fields";

class MaxRedirect extends React.Component {
    apiGet = apiGet.bind(this);

    render() {
        const url = this.props.location.pathname.replace("/api", "")
        try {
            axios.get(Consts.API_PREFIX + url+"?noredir", {maxRedirects: 1})
                .then(response => {
                    console.log(response)
                    //window.location.href=response.data
                })
                .catch((error) => {
                    console.log("error", error);
                });
        } catch (e) {
            console.log("error")

        }
        return <Loader loading={true} div/>
    }
}

export default MaxRedirect;