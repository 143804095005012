import React from 'react';
import Status from '../core/status';
import {apiGet} from "../core/api";
import {Container, Loader, MiniBtn, MyModal} from "../core/input_fields";
import ReactTable from "react-table";
import "react-table/react-table.css";

class GBInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            liabilities: [],
            payments: [],
        };

        this.key = (props.match.params === undefined || props.match.params.key === undefined) ? props.key : props.match.params.key;

        this.apiGet = apiGet.bind(this);
        this.apiGet("/vat_return/gb/load_data/" + this.key, resp => {
            this.setState({
                ...resp,
                loading: false,
                error: "",
            })

        });
    }

    render() {
        return (
            <div id={"filings_list"}>
                <Loader loading={this.state.loading}/>
                <GBLiabilities liabilities={this.state.liabilities || []}/>
                <GBPayments payments={this.state.payments || []}/>
                <GBObligations obligations={this.state.obligations || []}/>
                <GBPenalties {...this.state}/>
                {/*<GBPenalties penalties={[(this.state.latePaymentPenalty?.details || []), ...(this.state.latePaymentPenalty?.details || [])]*/}
                {/*    .sort((a, b) => (a.communicationDate ?? a.penaltyCreationDate ?? a.principalChargeDueDate) < (b.communicationDate ?? b.penaltyCreationDate ?? a.principalChargeDueDate) ? 1 : -1)*/}
                {/*}/>*/}
            </div>
        )
    }
}

class GBLiabilities extends React.Component {
    state = {}

    render() {
        const {liabilities} = this.props;
        const outstanding = liabilities.reduce((sum, curr) => sum + curr.outstandingAmount, 0);
        return (

            <Container name={"Liabilities"} visible>
                <Status type={"error"} text={this.state.error}/>

                <Loader loading={this.state.loading}/>
                {liabilities.length > 0 && <>
                    <ReactTable
                        data={liabilities}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Type",
                                        accessor: "type",
                                        minWidth: 150
                                    },
                                    {
                                        Header: "Tax Period Start",
                                        accessor: "taxPeriod.from",
                                    },
                                    {
                                        Header: "Tax Period End",
                                        accessor: "taxPeriod.to",
                                    },
                                    {
                                        Header: "Due",
                                        accessor: "due",
                                    },
                                    {
                                        Header: "Outstanding Amount",
                                        accessor: "outstandingAmount",
                                        Cell: row => formatter.format(row.value)

                                    },
                                    {
                                        Header: "Original Amount",
                                        accessor: "originalAmount",
                                        Cell: row => formatter.format(row.value)
                                    },
                                ]
                            }
                        ]}
                        defaultPageSize={liabilities.length}
                        className="-striped -highlight"
                        showPagination={false}
                    />
                    <br/>
                    Sum of Outstanding: {
                    formatter.format(outstanding)
                }
                    <br/>
                </>
                }
                {liabilities.length === 0 && !this.state.loading && this.state.status === "success" &&
                    <>No data there yet.<br/></>
                }
            </Container>
        );
    }
}

class GBPayments extends React.Component {
    state = {}

    render() {
        const {payments} = this.props;
        return (

            <Container name={"Payments"}>
                <Status type={"error"} text={this.state.error}/>
                <Loader loading={this.state.loading}/>
                {payments.length > 0 &&
                    <ReactTable
                        data={payments}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Received",
                                        accessor: "received",
                                        Cell: row => row.value === undefined ? "unkown" : row.value
                                    },

                                    {
                                        Header: "Amount",
                                        accessor: "amount",
                                        Cell: row => formatter.format(row.value)
                                    },
                                ]
                            }
                        ]}
                        defaultPageSize={payments.length}
                        className="-striped -highlight"
                        showPagination={false}
                    />
                }
                {payments.length === 0 && !this.state.loading && this.state.status === "success" &&
                    <>No data there yet.<br/></>
                }
            </Container>
        );
    }
}


class GBObligations extends React.Component {
    state = {}

    render() {
        const {obligations} = this.props;
        return <Container name={"Obligations"}>
            <Status type={"error"} text={this.state.error}/>
            <Loader loading={this.state.loading}/>
            {obligations.length > 0 &&
                <ReactTable
                    data={obligations}
                    columns={[
                        {

                            columns: [
                                {
                                    Header: "Start",
                                    accessor: "start",
                                },
                                {
                                    Header: "End",
                                    accessor: "end",
                                },
                                {
                                    Header: "Due",
                                    accessor: "due",
                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    Cell: row => row.value === "F" ? <>filed ({row.original.received})</> : "open",
                                },
                            ]
                        }
                    ]}
                    defaultPageSize={obligations.length}
                    className="-striped -highlight"
                    showPagination={false}
                />
            }
            {
                obligations.length === 0 && !this.state.loading && this.state.status === "success" &&
                <>No data there yet.<br/></>
            }
        </Container>;
    }
}

class GBPenalties extends React.Component {
    state = {}

    render() {
        const {totalisations, lateSubmissionPenalty, latePaymentPenalty} = this.props;
        if (!totalisations) {
            return null
        }

        return <Container name={"Penalties"}>
            <Status type={"error"} text={this.state.error}/>

            <h3  style={{fontWeight: 400}}>Totals</h3>
            <ReactTable

                data={Object.entries(totalisations)}
                defaultPageSize={Object.entries(totalisations || {})?.length}
                className="-striped -highlight"
                showPagination={false}
                columns={[
                    {
                        columns: [
                            {
                                Header: "Name",
                                id: "name",
                                accessor: row => ({totalOverdue: "Charge amount that is overdue"}[row[0]] || row[0].replace(/([A-Z]+)/g, " $1").toLowerCase()),
                            },
                            {
                                Header: "Value",
                                accessor: "1",
                                Cell: row => formatter.format(row.value)
                            },
                        ]
                    }
                ]
                }
            />
            <h3  style={{fontWeight: 400}}>Late Submission</h3>
            <ReactTable
                data={Object.entries(lateSubmissionPenalty?.summary || {})}
                defaultPageSize={Object.entries(lateSubmissionPenalty?.summary || {})?.length}
                className="-striped -highlight"
                showPagination={false}
                columns={[
                    {
                        columns: [
                            {
                                Header: "Name",
                                id: "name",
                                accessor: row => row[0].replace(/([A-Z]+)/g, " $1").toLowerCase(),
                            },
                            {
                                Header: "Value",
                                accessor: "1",
                            },
                        ]
                    }
                ]
                }
            />
            <ReactTable

                data={lateSubmissionPenalty?.details}
                defaultPageSize={lateSubmissionPenalty?.details?.length}
                className="-striped -highlight"
                showPagination={false}
                columns={[
                    {
                        columns: [
                            {
                                Header: "Penalty Number",
                                accessor: "penaltyNumber"
                            },
                            {
                                Header: "Status",
                                accessor: "penaltyStatus",
                            },
                            {
                                Header: "Creation Date",
                                accessor: "penaltyCreationDate",
                            },
                            {
                                Header: "Expiry Date",
                                accessor: "penaltyExpiryDate",
                            },
                            {
                                Header: "Details",
                                id: "details",
                                Cell: ({original}) => {
                                    const subdata = Object.entries(original).filter(k => k[0] !== "lateSubmissions")
                                    return <MyModal trigger={<MiniBtn>Details</MiniBtn>}>
                                        <div>
                                            <ReactTable

                                                data={subdata}
                                                defaultPageSize={subdata.length}
                                                className="-striped -highlight"
                                                showPagination={false}
                                                columns={[
                                                    {
                                                        columns: [
                                                            {
                                                                Header: "Name",
                                                                id: "name",
                                                                accessor: row => row[0].replace(/([A-Z]+)/g, " $1").toLowerCase(),
                                                            },
                                                            {
                                                                Header: "Value",
                                                                accessor: "1",
                                                            },
                                                        ]
                                                    }
                                                ]
                                                }
                                            />
                                            {
                                                original.lateSubmissions?.length > 0 && <ReactTable

                                                    data={original.lateSubmissions}
                                                    defaultPageSize={original.lateSubmissions.length}
                                                    className="-striped -highlight"
                                                    showPagination={false}
                                                    columns={[
                                                        {
                                                            columns: Object.keys(original.lateSubmissions[0]).map(k => ({
                                                                Header: k.replace(/([A-Z]+)/g, " $1").toLowerCase(),
                                                                accessor: k
                                                            }))

                                                        }
                                                    ]
                                                    }
                                                />
                                            }

                                        </div>
                                    </MyModal>
                                }
                            },
                        ]
                    }
                ]
                }
            />

            <h3 style={{fontWeight: 400}}>Late Payment</h3>

            <ReactTable

                data={latePaymentPenalty?.details}
                defaultPageSize={latePaymentPenalty?.details?.length}
                className="-striped -highlight"
                showPagination={false}
                columns={[
                    {
                        columns: [
                            {
                                Header: "Charge Creation Date",
                                accessor: "penaltyChargeCreationDate"
                            },
                            {
                                Header: "Charge Reference",
                                accessor: "penaltyChargeReference",
                            },
                            {
                                Header: "Amount Posted",
                                accessor: "penaltyAmountPosted",
                            },
                            {
                                Header: "Amount Outstanding",
                                accessor: "penaltyAmountOutstanding",
                            },
                            {
                                Header: "Charge Due Date",
                                accessor: "penaltyChargeDueDate",
                            },
                            {
                                Header: "Details",
                                id: "details",
                                Cell: ({original}) => {
                                    const subdata = Object.entries(original)
                                    return <MyModal trigger={<MiniBtn>Details</MiniBtn>}>
                                        <div>
                                            <ReactTable

                                                data={subdata}
                                                defaultPageSize={subdata.length}
                                                className="-striped -highlight"
                                                showPagination={false}
                                                columns={[
                                                    {
                                                        columns: [
                                                            {
                                                                Header: "Name",
                                                                id: "name",
                                                                minWidth: 250,
                                                                accessor: row => row[0].replace(/([A-Z]+)/g, " $1").toLowerCase(),
                                                            },
                                                            {
                                                                Header: "Value",
                                                                accessor: "1",
                                                                     minWidth: 150,
                                                            },
                                                        ]
                                                    }
                                                ]
                                                }
                                            />


                                        </div>
                                    </MyModal>
                                }
                            },
                        ]
                    }
                ]
                }
            />
        </Container>;
    }
}


const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
});

export default GBInformation;
