import branding from "./branding";
import CreatableSelect from "react-select/creatable";
import {components} from "react-select";
import React, {useContext, useMemo, useState} from "react";
import {maxiGet, maxiPost} from "./maxios";
import {encodeGetParams} from "./api";
import Status from "./status";
import {FormContext, FormContextWrapper} from "./form_context";
import {FaEdit} from "react-icons/all";
import {FaTrashAlt} from "react-icons/fa";
import {MaxBtn, MyModal, TextfieldInput} from "./input_fields";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        width: 400,
        padding: 0,
    }),

};
export default function TagInput({type, name, tag, labelStyle}) {
    const context = useContext(FormContext)
    const tagSplit = tag.split("_")

    const [options, setOptions] = useState([]);
    const [{error, loading}, setStatusVar] = useState({});

    const [tagToEdit, setTagToEdit] = useState(null);

    const loadOptions = () => maxiGet("/tags?" + encodeGetParams({type}), {setStatusVar})
        .then(tags => setOptions(tags.map(({label, ID}) => ({label, value: ID}))))

    useMemo(loadOptions, [type])

    const handleChange = a => {
        if (a?.__isNew__) {
            maxiPost("/tags", {type, label: a.label}, {setStatusVar}).then(tag => {
                context.updateState(tagSplit[0], tagSplit[1], tag.ID)
            })
        } else {
            context.updateState(tagSplit[0], tagSplit[1], a.value)
        }
    }
    const Option = (props) => {
        return <>
         <span style={{float: "right", cursor: "pointer", marginRight: "20px", marginTop: "10px"}} onClick={(e) => {
             window.confirm("Remove Agent from the whole Environment?") && maxiPost("/tags/delete", {ID: props.data.value}, {setStatusVar}).then(loadOptions)
         }}><FaTrashAlt/>
         </span>
            <span style={{float: "right", cursor: "pointer", marginRight: "10px", marginTop: "10px"}} onClick={(e) => {
                setTagToEdit(props.data)
            }}><FaEdit/></span>
            <components.Option {...props}>
                {props.children}
            </components.Option>
        </>;
    };
    let value = tagSplit.length === 2 ? (context.state[tagSplit[0]] || {})[tagSplit[1]] : context.state[tagSplit[0]]
    if (value?.value && options) {
    }
    const valueObject = options.find(tag => value === tag.value) || value

    return <>
        <p style={{marginTop: 5}}>
        <span style={labelStyle}>
            {name}
        </span>
            <CreatableSelect
                styles={customStyles}
                options={options}
                isSearchable={true}
                isClearable={true}
                value={valueObject}
                components={{Option}}
                onChange={handleChange}
                //defaultValue={this.state.newCollaborator}
                //value={this.state.newCollaborator}
                className={"reactSelectContainer reactCreatableSelect"}
                formatCreateLabel={inputValue => "Add \"" + inputValue + "\""}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary25: branding.colorLight,
                        primary: branding.colorLight,
                        neutral20: branding.colorLight,
                        neutral30: branding.colorLight,

                    },
                })
                }
            />
        </p>
        {
            tagToEdit && <TagEditModal key={tagToEdit.value} ID={tagToEdit.value} label={tagToEdit.label} resetTagToEdit={() => {
                loadOptions()
                setTagToEdit(null)
            }}/>
        }

        <Status type={"error"} text={error}/>
    </>
}


function TagEditModal({ID, label, resetTagToEdit}) {
    const [state, setState] = useState({label: label});
    const save = (close) => {
        maxiPost("/tags", {ID, label: state.label}).then(() => {
            close()
            resetTagToEdit()
        })
    }
    return <MyModal defaultOpen={true} onClose={resetTagToEdit}>
        {
            close => <div>
                <FormContextWrapper value={{state, setState}} onSubmit={() => save(close)}>
                    <h1>Modify Name</h1>
                    <TextfieldInput name={<>Name &nbsp;</>} tag={"label"}/><br/>
                    <MaxBtn>Speichern</MaxBtn>
                </FormContextWrapper>
            </div>
        }
    </MyModal>
}
