import React from "react";
import {Loader, MaxBtn, MiniBtn} from "../../core/input_fields";
import {maxiPost} from "../../core/maxios";
import {useState} from "react";
import Status from "../../core/status";

export function FRLoadImportFigures({filing}) {
    const [{loading, error}, setStatusVar] = useState({})
    const [importFigures, setImportFigures] = useState(null);
    const load = () => maxiPost("/vat_return/fr/get_import_figures", filing, {setStatusVar}).then(setImportFigures)
    return <p>
        <br/>
        <Status type={"error"} text={error}/>
        <MiniBtn onClick={load}>Load Import Figures</MiniBtn>
        <Loader loading={loading}/>
        {
            importFigures && <ul>
                <li>A4 - Importations (autres que les produits pétroliers): {importFigures[12]} EUR</li>
                {
                [
                    "I1: Taux normal 20 %",
                    "I2: Taux réduit 10 %",
                    "I3: Taux réduit 8,5 %",
                    "I4: Taux réduit 5,5 %",
                    "I5: Taux réduit 2,1 %",
                    "I6: Taux réduit 1,05 %",
                ].map((k, i) => <li>{k}: {importFigures[i * 2] || 0} EUR / {importFigures[i * 2 + 1] || 0} EUR</li>)
            }
            </ul>
        }
    </p>
}
