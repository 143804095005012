import "babel-polyfill";
import {polyfill} from 'es6-promise';
import 'core-js';
import 'raf/polyfill';
import Intl from "intl";
import {IntlProvider, addLocaleData} from "react-intl"
import en from "react-intl/locale-data/en"
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'classlist.js';
import 'core-js/es/reflect';
import 'web-animations-js';
import "intl/locale-data/jsonp/en.js"
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

try {
    addLocaleData(en)
    polyfill();

} catch (e) {
    console.log(e, Intl)
}

ReactDOM.render(<IntlProvider locale={"en"}>
    <App/>
</IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
