import React from 'react';
import {id2finanzamt} from "./finanzamter"
import {apiGet} from "../../core/api";

class Finanzamt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            finId: this.props.match.params.id,
        };
        this.apiGet = apiGet.bind(this);

        console.log(this.state, this.props.match.params.id);
        this.apiGet("/vat_return/de/finanzamt/" + this.state.finId,resp=>{
            this.setState({
                error: "",
                status: "",
                loading: false,
                finanzamt: resp
            })
        })
    }


    render() {
        const keyNames = ["IBAN", "BIC", "Bank Name", "Receiver Name"];

        return (
            <div>
                {this.state.finanzamt === undefined &&
                <img alt="loader" src="/src/img/ajax-loader-fff.gif"/>}
                {this.state.finanzamt !== undefined &&
                <div>
                    <h1>Finance Authority {id2finanzamt[this.state.finId]}</h1>
                    <table style={{emptyCells: "show"}}>
                        <tbody>
                        {

                            Object.keys(this.state.finanzamt).map((k) => {
                                if (k === "bankverbindungen")
                                    return null;
                                if (k === "URL") {
                                    return  <tr key={"bv"+k}>
                                        <td style={{paddingRight: "10px"}}>{k}</td>
                                        <td><a target={"_blank"}
                                               href={"http://" + this.state.finanzamt[k]}>{this.state.finanzamt[k]}</a>
                                        </td>
                                    </tr>

                                }
                                if (k === "Mail") {
                                    return  <tr key={"bv"+k}>
                                        <td style={{paddingRight: "10px"}}>{k}</td>
                                        <td><a target={"_blank"}
                                               href={"mailto:" + this.state.finanzamt[k]}>{this.state.finanzamt[k]}</a>
                                        </td>
                                    </tr>

                                }
                                return <tr key={"bv"+k}>
                                    <td style={{paddingRight: "10px"}}>{k}</td>
                                    <td>{this.state.finanzamt[k]}</td>
                                </tr>

                            })
                        }
                        <tr>
                            <td style={{fontWeight: "bold", paddingRight: "10px"}}>Bankverbindungen</td>
                        </tr>
                        {

                            this.state.finanzamt.bankverbindungen.map((v,i) => {
                                return [Object.keys(v).map((k,j) => {
                                    return <tr key={"bv"+i+"-"+j}>
                                        <td style={{paddingRight: "10px"}}>{keyNames[k]}</td>
                                        <td>{v[k]}</td>
                                    </tr>
                                }),
                                    <tr key={"bvwhite"+i}>
                                        <td style={{color: "white"}}> f</td>
                                    </tr>
                                ]

                            })
                        }
                        </tbody>
                    </table>
                </div>
                }


            </div>
        );
    }
}


export default Finanzamt;