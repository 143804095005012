import React from 'react';
import {LightContainer, Loader} from "../core/input_fields";
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";

import ReactTable from "react-table";
import {dateFormatTime} from "../filing/filing_support";
import Consts from "../core/consts"
import {Link} from "react-router-dom";
import {FileIcon} from "./core";
import {FaArrowCircleLeft} from "react-icons/fa";

class FilesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            files: [],
            newFile: "",
            parent_ID: 0,
            parent: null,
            newFolderName: "",
            editMode: false,
            initial: true
        };
        this.fieldName = props.tag === undefined ? "fileID" : props.tag;
        this.token = this.props.match.params.token;
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.loadFiles();
        this.setState = this.setState.bind(this)
    }

    loadFiles = () => {
        if (!this.state.initial) {
            this.setState({loading: true})
        }
        const pID = this.props.match.params.parent_ID ;

        this.apiGet("/files/listwithtoken/" + this.props.match.params.token + "/" + (pID || 0), resp => {
            this.setState({
                ...resp,
                loading: false,
                error: "",
                showingParent_ID: pID,
                initial: false,
            })
        });
    };


    render() {
        if (this.props.match.params.parent_ID !== this.state.showingParent_ID && !this.state.loading && this.state.error === "") {
            this.loadFiles()
        }
        const parent=this.state.parent
        return (

            <LightContainer name={"Files" + (this.state.parent !== null ? " in folder " + this.state.parent.name : "")}>
                <Status type={"error"} text={this.state.error}/>

                <Loader div loading={this.state.loading}/>

                {
                    this.state.parent !== null ?
                        <Link to={"/files/" + this.token + "/" + parent.parent_ID}>
                            <FaArrowCircleLeft/> back to {parent.name}
                        </Link> : null
                }


                {
                <div id={"files_table"}>
                    <ReactTable
                        data={this.state.files}
                        style={{marginTop: "10px"}}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Type",
                                        Cell: row => <FileIcon type={row.original.file.type}/>,
                                        maxWidth: 50
                                    },

                                    {
                                        Header: "Name",
                                        id: "name",
                                        accessor: "file.name",
                                        Cell: row => row.original.file.type === "fold" ? <Link to={"/files/" + this.token + "/" + row.original.file.ID}>{row.value} </Link> :
                                            <Link to={Consts.API_PREFIX + "/files/download/" + row.original.file.ID + "/" + row.original.file.name}>{row.original.file.name}</Link>
                                    },
                                    {
                                        Header: "Last Modified",
                                        accessor: "file.last_modified",
                                        Cell: row => row.value > 0 ? dateFormatTime(row.value * 1000) : "unknown",
                                        maxWidth: 150,
                                    },
                                ]
                            }
                        ]}
                        pageSize={Math.max(this.state.files.length,2)}
                        className="-striped -highlight"
                        showPagination={false}
                        noDataText={"no file found"}
                        defaultSorted={[
                            {
                                id: "name",
                                desc: false
                            }
                        ]}
                    />
                </div>
                }

            </LightContainer>
        );
    }
}



export default FilesView;
