import React, {useContext, useMemo, useState} from 'react';
import Status from '../core/status';
import Consts from '../core/consts'
import {DateInput, InsertIfAdmin, LightContainer, Loader, SelectField, TextareaInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import GroupCompanyRegistrations from "./group_registrations";
import FilingsByCompany from "../filing/filings_bycompany";
import SicSelector from "./group_sicselector";
import {maxiGet, maxiPost} from "../core/maxios";
import {UserContext} from "../user/UserContext";
import {getUsers} from "../core/core";
import {GroupCountries} from "./group_contacts";
import UploadMultipleFiles from "../core/multi_file";


export default function GroupCompany({match}) {
    const id = match?.params?.id;
    const newGroup = id === 'new';
    const userContext = useContext(UserContext);

    const [state, setState] = useState(newGroup ? {data: {}} : null)
    const [selectUsers, setSelectUsers] = useState({})
    const [latest, setLatest] = useState({});
    const [{loading, error}, setStatusVar] = useState({loading: !newGroup});
    const [reloadKey, setReloadKey] = useState(1)

    const loadCompany = () => !newGroup && maxiGet("/companies/groups/" + id, {setStatusVar}).then(resp => setState(resp));
    useMemo(loadCompany, [])
    useMemo(() => getUsers(maxiGet, (a) => setSelectUsers(a.selectUsers)), [])
    const handleSubmit = () => {
        setStatusVar({loading: true})
        maxiPost("/companies/groups", {...state, "latest": null}, {setStatusVar}).then(resp => {
            if (newGroup) {
                window.location.href = "/groupcompanies/" + resp.ID
            }
        })
    }

    const disabled = userContext?.user?.Role < 50;
    const Selector = ({userContext, ...props}) => <SelectField
        type={"reactselect"}
        width={Math.min(window.innerWidth - 50, 700)}
        multiple
        disabled={disabled}
        selectives={selectUsers?.filter && selectUsers?.filter(u => u.role > 70).sort((a, b) => a.name < b.name ? -1 : 1).map(u => ({value: u.ID?.toString() /*json search needs string*/, label: u.name + (userContext?.user?.Role > 10 ? " (" + u.email + ")" : "")})) || []}
        {...props}
    />;
    return <div>
        <Status type={"error"} text={error}/>
        {/*<JSONPretty json={state}/>*/}
        <h1>Company</h1>
        {
            (newGroup || state) && <LightContainer name={"General Data"}>
                <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
                    <SelectField name="Account Status" tag="status" selectives={[["active", "Active"], ["inactive", "Inactive"], ["suspended", "Suspended"]]} noBreaks   {...{disabled}}/>
                    {
                        ["inactive", "suspended"].includes(state.status) && <DateInput name={"Date"} tag={"statusDate"}/>
                    }
                    {
                        state.status === "inactive" && <>
                            <SelectField name="Account Status" tag="statusReason" demandSelect selectives={[
                                ["deregistered", "De-registered"],
                                ["switched", "Switched Compliance Company"],
                                ["unresponsiveWhiteLabel", "Unresponsive - White Label request no further action"],
                                ["unresponsiveEssentia", "Unresponsive - Essentia request no further action"],
                                ["other", "Other"],
                            ]} noBreaks   {...{disabled}}/>
                            {
                                state.statusReason === 'other' && <TextfieldInput name={"Reason"} tag={"data_statusReasonOther"} {...{disabled}} ph={"other reason"} style={{width: 400, maxWidth: "60%"}}/>
                            }
                        </>
                    }

                    <TextfieldInput name={"Name"} tag={"name"} {...{disabled}} style={{width: 400, maxWidth: "60%"}} ph={"Example Ltd"}/>
                    <TextfieldInput name={"Whitelabel"} tag={"data_whitelabel"} {...{disabled}} ph={"ABC123"}/>
                    {/*white label*/}
                    <br/>
                    <SelectField name="Country" tag="country" selectives={Consts.countries.slice(1)} demandSelect noBreaks   {...{disabled}}/>
                    <TextfieldInput name={"Address Line 1"} tag={"street"}  {...{disabled}} style={{width: 400, maxWidth: "60%"}} ph={"Street"}/>
                    <br/>
                    <TextfieldInput name={"Postal Code"} tag={"postalcode"}  {...{disabled}} ph={"12345"}/>
                    <TextfieldInput name={"City"} tag={"city"}  {...{disabled}} ph={"Exampletown"}/>
                    <br/>
                    <SicSelector selection={state.data?.sic}/> &nbsp;
                    <TextfieldInput name={"Business Activity Remarks"} tag={"data_sicNotes"} {...{disabled}} ph={"note abc"} style={{width: 400, maxWidth: "60%"}}/>
                    <br/>
                    <SelectField name="Country of Fixed Establishment" tag="data_countryFixedEstablishment"  {...{disabled}} selectives={Consts.countries.slice(1)} demandSelect noBreaks/>
                    <TextfieldInput name="Country of Fixed Establishment VAT ID" tag="data_countryFixedEstablishmentVatId"  {...{disabled}} ph={"CC123456789"}/>
                    <br/>
                    <UploadMultipleFiles label={"Contracts"} scope={"contracts"} files={state.data?.contracts} setStateParent={(obj) => {
                        setState(old => ({
                            ...old,
                            data: {
                                ...old.data,
                                contracts: obj.contracts,
                            }
                        }))
                    }
                    }/>
                    <UploadMultipleFiles label={"Onbording Form"} scope={"onbordingForm"} files={state.data?.onbordingForm} setStateParent={(obj) => {
                        setState(old => ({
                            ...old,
                            data: {
                                ...old.data,
                                onbordingForm: obj.onbordingForm,
                            }
                        }))
                    }
                    }/>
                    <Selector tag={"data_salesTeamPerson"} name={"Sales Team Person"} {...{userContext, disabled}}/><br/>
                    <Selector tag={"data_consultantTeamPerson"} name={"Consultant Team Person"} {...{userContext, disabled}}/><br/>
                    <Selector tag={"data_accountManagers"} name={"Account Managers"} {...{userContext, disabled}}/><br/>
                    <Selector tag={"data_accountManagersBackup"} name={"Backup Account Managers"} {...{userContext, disabled}}/><br/>
                    <TextareaInput tag={"data_notes"} name={"Notes"} {...{userContext, disabled}}/><br/>


                </FormContextWrapper>
                <InsertIfAdmin>
                    <button onClick={handleSubmit}>{newGroup ? "create" : "save"}</button>
                </InsertIfAdmin>
                <br/>
                <GroupCountries data={state.contacts} scope={"contacts"} id={id} setState={setState} groupcompany_ID={state.ID} {...{disabled, reload: loadCompany}}/>

                {
                    !newGroup && <>
                        <br/> <GroupCompanyRegistrations id={id} reload={() => setReloadKey(Math.random())} showAll groupcompany={state}/>
                    </>
                }
                <br/>
                <GroupCountries data={state.countries} scope={"countries"} id={id} setState={setState} groupcompany_ID={state.ID} {...{disabled, reload: loadCompany}}/>


            </LightContainer>
        }
        <Loader loading={loading}/>

        {
            !newGroup && <FilingsByCompany key={reloadKey} addLatest={(l) => setLatest(l)} groupcompany_ID={id}/>

        }
    </div>
}


