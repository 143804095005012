import React, {Component} from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: ""};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error: error?.toString()};
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info);
        this.setState({info})
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <><h1>Something went wrong: </h1>
                <br/>
                {this.state.error}<br/>
                Please report this error to <a href="mailto:max@vatomator.com">max@vatomator.com</a> with reference {window.vatomatorVersion}!

                <br/>

                {JSON.stringify(this.state.info)}
            </>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary
