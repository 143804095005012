import React, {useMemo, useRef, useState} from "react";
import ReactTable from "react-table";

export const MyReactTable = ({
                          data, columns, defaultSorted,
                          onTableChange = () => null,
                          pageSizeDef = 25, loading = false, onPageChange = null,
                          manualUpdate = null, resolveData = null,
                          onFilteredChange = null, onSortedChange = null,
                          getTrProps = () => ({}),
                          hideNothingMessage,
                      }) => {
    const tableRef = useRef(null);
    const [pageSize, setPageSize] = useState(pageSizeDef);
    const pushTableData = () => {
        return onTableChange !== undefined && tableRef.current !== null && tableRef.current.getResolvedState().sortedData !== undefined ? onTableChange(tableRef.current.getResolvedState().sortedData) : null
    };
    useMemo(() => {
        window.setTimeout(() => {
            console.log("push")
            pushTableData()
        }, 800)
    }, [data?.length])
    /*if (manualUpdate) {
        manualUpdate(pushTableData)
    }*/
    const noData = data?.length === 0;
    return <>
        <ReactTable
            loading={loading}
            /*getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                    cursor: 'pointer',
                    overflow: 'visible'
                },
                onClick: () => this.onSelect(rowInfo, column)
            })}*/
            data={data}
            resolveData={resolveData || (data => data)}
            headerStyle={{textAlign: "left"}}
            getTrProps={getTrProps}
            defaultFilterMethod={({id, value: search}, row) => {
                let negativeMatching = false;
                if (search[0] === "!") {
                    negativeMatching = true;
                    search = search.slice(1)
                    if (search === "") {
                        return !row[id]
                    }
                    if (search === "!") {
                        return !!row[id]
                    }
                }
                const returnValue = search => {
                    const searchFloat = parseFloat(search.slice(1));
                    const searchFloatEqual = parseFloat(search.slice(2));
                    if (search[0] === "<") {
                        if (search[1] === "=") {
                            return row[id] !== undefined && (row[id] <= searchFloatEqual || search.length === 2)
                        }
                        return row[id] !== undefined && (row[id] < searchFloat || search.length === 1)
                    }
                    if (search[0] === ">") {
                        if (search[1] === "=") {
                            return row[id] !== undefined && (row[id] >= searchFloatEqual || search.length === 2)
                        }
                        return row[id] !== undefined && (row[id] > searchFloat || search.length === 1)
                    }
                    return (row[id] ?? "").toString().toLowerCase().includes(search.toLowerCase())
                }
                if (search.includes(" UND ")) {
                    return !search.split(" UND ").some(e => !returnValue(e))
                }
                if (search.includes(" ODER ")) {
                    return search.split(" ODER ").some(e => returnValue(e))
                }

                if (negativeMatching) {
                    return !returnValue(search)
                }
                return returnValue(search)
            }}
            style={{width: "100%"}}
            columns={[
                {
                    columns: columns
                }
            ]
            }
            ref={tableRef}

            onSortedChange={pushTableData}
            onFetchData={pushTableData}
            defaultSorted={defaultSorted !== undefined ? defaultSorted : []}
            //defaultPageSize={pageSize}
            showPagination={data?.length > pageSize}
            pageSize={Math.min(data?.length, pageSize)}
            onPageChange={onPageChange}
            className="-striped -highlight"
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onFilteredChange={(a) => {
                pushTableData();
                onFilteredChange && onFilteredChange(a)
            }}/>
    </>
};
